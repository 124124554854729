import * as React from "react";
import debounce from "lodash/debounce";
import cable from "app/lib/cable";

import Spinner from "app/components/shared/Spinner";

import JobInformationStore from "app/stores/JobInformationStore";

type Props = {
  job: {
    id: string;
    basePath: string;
  };
};

type State = {
  count: number | null | undefined;
};

const getStateFromStore = (jobId: string) => JobInformationStore.get(jobId, "artifactsCount");

export default class ArtifactsCount extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      count: getStateFromStore(props.job.id),
    };
  }

  reload = debounce(
    function () {
      return JobInformationStore.refetch(
        // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
        this.props.job.id,
        "artifactsCount",
        // @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
        this.props.job.basePath + "/artifacts/count",
      );
    },
    2000,
    { leading: true, maxWait: 4000 },
  );

  subscription: any;

  componentDidMount() {
    JobInformationStore.addChangeListener(this.handleStoreChange);

    // Reload count if this job's artifacts change
    this.subscription = cable.subscriptions.create(
      { channel: "Pipelines::JobChannel", uuid: this.props.job.id },
      {
        component: this,

        received({ event }) {
          if (event === "artifacts:changed") {
            this.component.reload();
          }
        },
      },
    );

    this.reload();
  }

  componentWillUnmount() {
    JobInformationStore.removeChangeListener(this.handleStoreChange);

    this.subscription.unsubscribe();
  }

  render() {
    if (this.state.count === undefined) {
      return <Spinner className="ml-1" />;
    }

    return <span className="badge badge--outline ml-1 tabular-numerals">{this.state.count}</span>;
  }

  handleStoreChange = () => {
    this.setState({ count: getStateFromStore(this.props.job.id) });
  };

  handleWebsocketEvent = ({ jobID }: { jobID: string }) => {
    if (jobID === this.props.job.id) {
      this.reload();
    }
  };
}

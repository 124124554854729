import * as React from "react";
import * as HoverCardPrimitive from "@radix-ui/react-hover-card";
import { cva, VariantProps } from "class-variance-authority";
import { Nib } from "app/components/shared/Nib";
import { twMerge } from "tailwind-merge";

const HoverCard = HoverCardPrimitive.Root;

const HoverCardTrigger = HoverCardPrimitive.Trigger;

export const HoverCardPortal = HoverCardPrimitive.Portal;

const HoverCardArrow = React.forwardRef<
  React.ElementRef<typeof HoverCardPrimitive.Arrow>,
  React.ComponentPropsWithoutRef<typeof HoverCardPrimitive.Arrow>
>(({ ...props }, ref) => (
  <HoverCardPrimitive.Arrow ref={ref} asChild={true} {...props} width={16} height={8}>
    <Nib />
  </HoverCardPrimitive.Arrow>
));

HoverCardArrow.displayName = HoverCardPrimitive.Arrow.displayName;

const hoverCardVariants = cva(
  "z-20 bg-white rounded px-4 py-3 shadow-depth-800 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=open]:duration-100 data-[state=closed]:duration-100 data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
  {
    variants: {
      size: {
        sm: "w-44",
        "max-sm": "max-w-44",
        md: "w-64",
        "max-md": "max-w-64",
        lg: "w-[512px]",
        "max-lg": "max-w-[512px]",
      },
    },
    defaultVariants: {
      size: "sm",
    },
  },
);

const HoverCardContent = React.forwardRef<
  React.ElementRef<typeof HoverCardPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof HoverCardPrimitive.Content> &
    VariantProps<typeof hoverCardVariants>
>(({ children, className, sideOffset = 4, collisionPadding = 10, size, ...props }, ref) => (
  <HoverCardPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    collisionPadding={collisionPadding}
    className={twMerge(hoverCardVariants({ size }), className)}
    {...props}
  >
    <HoverCardArrow />
    {children}
  </HoverCardPrimitive.Content>
));

HoverCardContent.displayName = HoverCardPrimitive.Content.displayName;

// Composed component for rendering in Rails views
function ComposedHoverCard({
  trigger,
  triggerProps,
  content,
  contentProps,
  ...props
}: {
  content: string;
  trigger: string;
  contentProps?: React.ComponentPropsWithoutRef<typeof HoverCardContent>;
  triggerProps?: React.ComponentPropsWithoutRef<typeof HoverCardTrigger> & {
    as?: React.ElementType;
  };
} & React.ComponentPropsWithoutRef<typeof HoverCard>) {
  const TriggerTag = triggerProps?.as ?? "a";

  return (
    <HoverCard {...props}>
      <HoverCardTrigger {...triggerProps} asChild={true}>
        <TriggerTag dangerouslySetInnerHTML={{ __html: trigger }} />
      </HoverCardTrigger>

      <HoverCardContent {...contentProps}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </HoverCardContent>
    </HoverCard>
  );
}

export { HoverCard, HoverCardTrigger, HoverCardContent, ComposedHoverCard };

import { useState } from "react";
import styled from "styled-components";
import classNames from "classnames";
import { useSelect } from "downshift";

import Emojify from "app/components/shared/Emojify";
import Button from "app/components/shared/Button";
import Icon from "app/components/shared/Icon";

const Menu = styled.ul<{ visible: boolean }>`
  position: absolute;
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  width: 100%;
  top: 40px;
  max-height: 230px;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: contain;
  background-color: white;
  border-radius: 4px;
  padding: 8px;
  margin-top: 4px;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  box-shadow:
    0px 20px 20px rgba(0, 0, 0, 0.07),
    0px 10px 20px rgba(0, 0, 0, 0.05),
    0px 13px 18px rgba(0, 0, 0, 0.04),
    0px 7px 9px rgba(0, 0, 0, 0.03);
  z-index: 2;
`;

export type PipelineTemplate = {
  uuid?: string;
  name: string;
  description?: string | null;
  configuration: string;
};

export type PipelineTemplateFormSelectProps = {
  id?: string;
  name: string;
  selected: PipelineTemplate | null;
  items: Array<PipelineTemplate>;
  onChange?: (pipelineTemplate: PipelineTemplate) => void;
  hasError?: boolean;
};

const StyledButton = styled(Button)<{ hasError: boolean }>`
  box-shadow: none !important;
  border-color: ${({ hasError }) => (hasError ? "#D0A9A7" : "#ccc")};

  &:focus {
    border-color: #888;
  }
`;

function PipelineTemplateFormSelect(props: PipelineTemplateFormSelectProps) {
  const [selectedTemplate, setSelectedTemplate] = useState(
    () => props.items.find((template) => template.uuid === props.selected?.uuid) || null,
  );

  const {
    isOpen,
    selectedItem,
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
  } = useSelect<PipelineTemplate>({
    id: props.id,
    defaultSelectedItem: selectedTemplate,
    items: props.items,
    onSelectedItemChange: (changes) => {
      if (!changes.selectedItem) {
        return;
      }
      if (props.onChange) {
        props.onChange(changes.selectedItem);
      }
      setSelectedTemplate(changes.selectedItem);
    },
  });

  return (
    <>
      <div className="relative" style={{ width: "100%" }}>
        <StyledButton
          type="button"
          data-testid="pipeline-templates-dropdown"
          className="flex items-center justify-between gap1 cursor-pointer"
          style={{ width: "100%", height: "40px" }}
          {...getToggleButtonProps()}
          hasError={props.hasError}
        >
          <DropdownLabel selectedItem={selectedItem} />

          <Icon
            icon={isOpen ? "up-triangle" : "down-triangle"}
            className="flex-none"
            style={{ width: 7, height: 7 }}
          />
        </StyledButton>

        <Menu {...getMenuProps()} visible={isOpen}>
          {isOpen &&
            props.items.map((item: PipelineTemplate, index: number) => {
              if (!item.uuid) {
                return (
                  <BlankItem
                    key="blank-item"
                    active={!selectedItem?.uuid}
                    itemsCount={props.items.length}
                    item={item}
                    index={index}
                    selected={highlightedIndex === index}
                    getItemProps={getItemProps}
                  />
                );
              }

              return (
                <Item
                  key={item.uuid}
                  item={item}
                  index={index}
                  active={selectedItem?.uuid === item.uuid}
                  selected={highlightedIndex === index}
                  getItemProps={getItemProps}
                />
              );
            })}
        </Menu>
      </div>

      <input
        type="hidden"
        data-testid="pipeline-template-uuid-input"
        name={props.name}
        value={selectedTemplate?.uuid ?? ""}
      />
    </>
  );
}

type DropdownLabelProps = {
  selectedItem: PipelineTemplate | null;
};

function DropdownLabel(props: DropdownLabelProps) {
  const placeholder = "Select a template";
  const label = props.selectedItem ? props.selectedItem.name : placeholder;
  const isPlaceholder = label === placeholder;

  return (
    <span
      className={classNames("font-normal m-0 truncate max-w-full leading-tight", {
        "charcoal-300": isPlaceholder,
      })}
    >
      <Emojify text={label} className="truncate max-w-full" />
    </span>
  );
}

type ItemProps = {
  active: boolean;
  selected: boolean;
  index: number;
  item: PipelineTemplate;
  getItemProps: ReturnType<typeof useSelect<PipelineTemplate>>["getItemProps"];
};

function Item(props: ItemProps) {
  const className = classNames(
    "flex items-center btn black hover-purple focus-purple rounded-1.5 leading-normal p-2",
    { "purple bg-light-purple": props.active, purple: props.selected },
  );

  return (
    <li
      data-testid={`pipeline-templates-${props.item.uuid || ""}`}
      className={className}
      {...props.getItemProps({ item: props.item, index: props.index })}
    >
      <div className="flex flex-col gap.5 truncate max-w-full">
        <Emojify text={props.item.name} className="font-semibold truncate max-w-full" />
        {props.item.description && (
          <Emojify text={props.item.description} className="medium truncate max-w-full" />
        )}
      </div>
    </li>
  );
}

type BlankItemProps = ItemProps & { itemsCount: number };

function BlankItem(props: BlankItemProps) {
  const { itemsCount, ...itemProps } = props;

  return (
    <>
      <Item {...itemProps} />

      {itemsCount > 1 && (
        <div
          className="border-b border-gray"
          style={{ margin: "0px -8px 6px -8px", paddingBottom: "6px" }}
        />
      )}
    </>
  );
}

export default PipelineTemplateFormSelect;

import parseEmoji from "app/lib/parseEmoji";
import jobCommandOneliner from "app/lib/jobCommandOneliner";

import { Step } from "app/stores/ProjectPipelineStore";

type Props = {
  step: Step;
};

/* eslint-disable react/require-optimization */
// TODO: Fix the architecture of these components so this one can be optimised.
// The problem is the `step` object is actually passed from the store, which is always
// the same object reference, even if mutated, so ShallowCompare functions do not work.

const StepName = ({ step }: Props) => {
  if (step.name) {
    const nameWithEmoji = parseEmoji(step.name);

    return (
      <span
        dangerouslySetInnerHTML={{
          __html: nameWithEmoji,
        }}
      />
    );
  } else if (step.command) {
    return <span className="monospace">{jobCommandOneliner(step.command)}</span>;
  }
  return <span>Run script or command</span>;
};
StepName.displayName = "StepName";

export default StepName;

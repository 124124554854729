import { CommandJob, TriggerJob, Job } from "app/components/build/Show/lib/types";
import { useBuild } from "app/components/build/Show/lib/BuildContext";
import Duration from "app/components/shared/Duration";
import Icon from "app/components/shared/Icon";
import { getDuration } from "app/lib/date";
import { SortFn } from ".";

const getWaitTimestamps = (job: CommandJob | TriggerJob) => {
  if (
    job.state === "skipped" ||
    job.state === "blocked" ||
    job.state === "waiting" ||
    job.state === "waiting_failed"
  ) {
    return null;
  }

  return {
    from: job.runnableAt || job.createdAt,
    to: job.startedAt || job.canceledAt,
  };
};

export function WaitDuration({ job }: { job: Job }) {
  const { build } = useBuild();
  if (!build) {
    throw new Error("Missing build context");
  }

  if (job.type !== "script" && job.type !== "trigger") {
    return null;
  }

  const timestamps = getWaitTimestamps(job);
  if (!timestamps?.from) {
    return null;
  }

  return (
    <div className="flex items-center gap-1">
      <Icon icon="custom/outline/hourglass" className="w-4 h-3 block text-gray-800" />
      <Duration.Short {...timestamps} />
    </div>
  );
}

const getWaitDuration = (job: CommandJob | TriggerJob) => {
  const timestamps = getWaitTimestamps(job);
  if (!timestamps?.from) {
    return 0;
  }
  return getDuration(timestamps.from, timestamps.to).asSeconds();
};

// eslint-disable-next-line id-length
export const sortByWaitDuration: SortFn = (direction) => (a, b) => {
  let aDuration = 0;
  let bDuration = 0;

  if (a.type === "script" || a.type === "trigger") {
    aDuration = getWaitDuration(a);
  }

  if (b.type === "script" || b.type === "trigger") {
    bDuration = getWaitDuration(b);
  }

  if (aDuration === bDuration) {
    return 0;
  }

  if (direction === "asc") {
    return aDuration > bDuration ? 1 : -1;
  }

  return aDuration > bDuration ? -1 : 1;
};

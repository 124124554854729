import Bar, { ActivityProps } from "./Bar";
import StackedBars from "./StackedBars";

type Props = {
  activities: Array<ActivityProps>;
  wrapperHeight?: string;
  groupByField?: "runId";
};

const Activity = ({ activities, wrapperHeight = "75px", groupByField }: Props) => {
  if (activities.length === 0) {
    return null;
  }

  const calcMaxDuration = () => {
    if (activities.length === 0) {
      return 0;
    }

    const max = activities.reduce((prev, current) => {
      return prev.duration > current.duration ? prev : current;
    });

    return max.duration;
  };

  const maxDuration = calcMaxDuration();

  const activityGroups = groupByField
    ? Object.values(groupActivities(activities, groupByField))
    : [];

  const bars = !groupByField ? (
    <>
      {activities.map((activity) => (
        <Bar key={activity.id} activity={activity} height={activity.duration / maxDuration} />
      ))}
    </>
  ) : (
    <>
      {activityGroups.map((activities) => (
        <StackedBars
          key={activities[0]?.id || Math.random().toString()}
          height={relativeHeightInGroups(activityGroups, activities)}
          activities={activities}
        />
      ))}
    </>
  );

  return (
    <div data-testid="chart">
      <div className="flex items-end justify-start" style={{ height: wrapperHeight }}>
        {bars}
      </div>
    </div>
  );
};

function groupActivities(
  activities: Array<ActivityProps>,
  groupByField: NonNullable<Props["groupByField"]>,
): Record<string, ActivityProps[]> {
  return activities.reduce((groups, activity) => {
    const key = activity[groupByField] || "";
    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(activity);
    return groups;
  }, {});
}

function relativeHeightInGroups(groups: ActivityProps[][], target: ActivityProps[]) {
  const sumDuration = (activities: ActivityProps[]) =>
    activities.reduce((sum, activity) => sum + activity.duration, 0.0);

  const maxDuration = groups.reduce((prev, current) => {
    const now = sumDuration(current);
    return prev > sumDuration(current) ? prev : now;
  }, 0.0);

  return sumDuration(target) / maxDuration;
}

export default Activity;

import agentWarning from "app/images/agentWarning.svg";
import { createFragmentContainer, graphql } from "react-relay";
import Icon from "app/components/shared/Icon";

type Props = {
  agent: any;
};

function AgentBadge({ agent }: Props) {
  if (!agent || !agent.organization) {
    return null;
  }

  let agentPath;

  const { clusterQueue } = agent;
  if (clusterQueue) {
    agentPath = `/organizations/${agent.organization.slug}/clusters/${clusterQueue.cluster.uuid}/queues/${clusterQueue.uuid}/agents/${agent.uuid}`;
  } else {
    agentPath = `/organizations/${agent.organization.slug}/unclustered/agents/${agent.uuid}`;
  }

  return (
    <>
      <a href={agentPath} className="flex items-center">
        <Icon
          icon="custom/outline/14x/agent"
          className="mr-1"
          // we're setting the icon color to bootstrap's `link-hover-color` to give it more contrast in this context
          style={{ width: "14px", height: "14px", color: "#0D8654" }}
        />
        {agent.name}
      </a>
      {agent.versionHasKnownIssues ? (
        <span aria-label="Upgrade Required — this agent has known issues">
          <img src={agentWarning} className="ml-1 inline" />
        </span>
      ) : null}
    </>
  );
}

export default createFragmentContainer(AgentBadge, {
  agent: graphql`
    fragment AgentBadge_agent on Agent {
      name
      uuid
      version
      versionHasKnownIssues
      clusterQueue {
        id
        uuid
        cluster {
          uuid
        }
      }
      organization {
        slug
      }
    }
  `,
});

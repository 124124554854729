import * as React from "react";

import Button from "app/components/shared/Button";
import Dialog from "app/components/shared/Dialog";
import FormTextarea from "app/components/shared/FormTextarea";

type Props = {
  url: string;
  note: string | null | undefined;
};

type State = {
  isOpen: boolean;
};

export default class InvoiceNoteModal extends React.PureComponent<Props, State> {
  invoiceNoteInput: {
    current: null | HTMLInputElement;
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.invoiceNoteInput = React.createRef();
  }

  handleShowButtonClick = (evt: React.MouseEvent<HTMLAnchorElement>) => {
    evt.preventDefault();

    this.setState(
      {
        isOpen: true,
      },
      () => {
        if (this.invoiceNoteInput.current) {
          this.invoiceNoteInput.current.focus();
        }
      },
    );
  };

  handleCloseClick = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const hasBeenSet = this.props.note && this.props.note.length > 0;

    const linkText = hasBeenSet ? "Update your invoice note" : "Add an invoice note";

    const actionText = hasBeenSet ? "Update Invoice Note" : "Add Invoice Note";

    const helpText = hasBeenSet
      ? "Note included in the top of all your invoices."
      : "Note to include on the top of all your invoices.";

    return (
      <>
        <p className="m-0">
          {"Need to add tax or other administrative details to your invoices? "}
          <a id="show-invoice-note-modal" href="#" onClick={this.handleShowButtonClick}>
            {linkText}
          </a>
        </p>
        <Dialog isOpen={this.state.isOpen} onRequestClose={this.handleCloseClick}>
          <form action={this.props.url} acceptCharset="UTF-8" method="post">
            <input name="utf8" type="hidden" value="✓" />
            <input name="_method" type="hidden" value="patch" />
            <input type="hidden" name={window._csrf.param} value={window._csrf.token} />

            <div className="p-5">
              <h1 className="m-0 text-xl semi-bold mb-3">{actionText}</h1>

              <FormTextarea
                name="account[invoice_note]"
                id="account_invoice_note"
                label="Invoice Note"
                help={helpText}
                rows={3}
                defaultValue={this.props.note}
                ref={this.invoiceNoteInput}
                style={{
                  width: "100%",
                  maxWidth: "100%",
                  boxSizing: "border-box",
                  resize: "vertical",
                }}
              />

              <Button type="submit" className="w-full">
                {actionText}
              </Button>
            </div>
          </form>
        </Dialog>
      </>
    );
  }
}

import { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";

import Colors from "app/constants/analytics/Colors";
import Icon from "app/components/shared/Icon";
import useLoadMore, { LoadMoreProps } from "../../shared/hooks/useLoadMore";
import LoadMoreButton from "../../shared/LoadMoreButton";
import ErrorState from "app/components/analytics/shared/ErrorState";
import EmptyState from "app/components/shared/EmptyState";
import Span from "./Span";
import Button from "app/components/shared/Button";

const StyledTimeline = styled.div`
  position: relative;
  margin: 0 auto;

  .span-container:not(:last-child):after {
    content: "";
    position: absolute;
    width: 1px;
    background-color: #cccccc;
    top: 0px;
    bottom: 0px;
    left: 20px;
    z-index: 1;
  }

  .span-container:first-child:not(:only-child):after {
    content: "";
    position: absolute;
    width: 1px;
    background-color: #cccccc;
    top: 28px;
    left: 20px;
    bottom: -32px;
    z-index: 1;
  }

  .span-container:last-child:not(:only-child):after {
    content: "";
    position: absolute;
    width: 1px;
    height: 28px;
    background-color: #cccccc;
    top: 0px;
    left: 20px;
    z-index: 1;
  }

  .span-container {
    padding: 11px 10px 10px 32px;
    position: relative;
    background-color: inherit;
    border-radius: 4px;
  }

  .span-content {
    font-family:
      SFMono-Regular,
      SF Mono,
      Monaco,
      Menlo,
      Consolas,
      Liberation Mono,
      Courier,
      monospace;
    color: ${Colors.DARK_GRAY};
    font-size: 12px;
    line-height: 25px;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    overflow: hidden;
    display: block;
    overflow: hidden;
  }

  .annotation .span-content {
    background: #fff9e7;
    border: 1px solid #e0cfa0;
    border-radius: 3px;
    padding: 10px 15px;
    margin-left: 5px;
    position: relative;
  }

  .duration {
    font-family:
      SF Pro Text,
      -apple-system,
      BlinkMacSystemFont,
      Segoe UI,
      Roboto,
      Helvetica Neue,
      Helvetica,
      sans-serif;
    color: ${Colors.BASE_GRAY};
    margin-left: auto;
    padding-left: 30px;
    font-size: 14px;
  }

  .circle {
    width: 11px;
    height: 21px;
    top: 14px;
    left: 15px;
    background: white;
    position: absolute;
    z-index: 2;
  }

  .circle::before {
    content: "";
    position: absolute;
    box-sizing: border-box;
    border: 1px solid #cccccc;
    border-radius: 50%;
    width: 3px;
    height: 3px;
    left: 4px;
    top: 9px;
  }

  .circle::after {
    content: "";
    position: absolute;
    box-sizing: border-box;
    border: 1px solid #cccccc;
    border-radius: 50%;
    width: 11px;
    height: 11px;
    left: 0px;
    top: 5px;
  }

  .annotation .circle {
    top: 24px;
  }
`;

const Annotation = ({ content }: { content: string }) => {
  return (
    <div className="span-container annotation">
      <div className="circle" />
      <div className="flex content">
        <pre className="span-content">{content}</pre>
      </div>
    </div>
  );
};

const Loading = () => (
  <>
    {[...Array(7)].map((_el, index) => (
      <div key={index} className="span-container">
        <div className="circle" />
        <div style={{ marginTop: "5px" }}>
          <Skeleton count={3} containerTestId="loading-skeleton" />
        </div>
      </div>
    ))}
  </>
);

const Timeline = ({ url }: { url: string }) => {
  const { loading, error, collection, hasNextPage, handleClickLoadMore } = useLoadMore({ url });
  return (
    <TimelineData
      error={error}
      loading={loading}
      collection={collection}
      hasNextPage={hasNextPage}
      handleClickLoadMore={handleClickLoadMore}
    />
  );
};

export const TimelineData = ({
  error,
  loading,
  collection,
  hasNextPage,
  handleClickLoadMore,
}: LoadMoreProps) => {
  if (error) {
    return (
      <ErrorState
        emoji="🕳️"
        heading="There was an error loading span data"
        subheading="Try refreshing the page; if the problem persists"
        urlText="let us know"
        url="mailto:support@buildkite.com"
      />
    );
  }

  const timelineHasSpans = collection.length !== 0;

  if (!loading && !timelineHasSpans) {
    return (
      <EmptyState
        emoji="🤖"
        heading="No span data found"
        subheading="The test execution did not have span data, or your test framework is not configured to send Test Analytics this information."
        link={
          <Button theme="primary" href="https://buildkite.com/docs/test-analytics/test-suites">
            Learn more in Docs
          </Button>
        }
      />
    );
  }

  const displayLoadMoreButton = timelineHasSpans && hasNextPage;

  return (
    <StyledTimeline>
      {!timelineHasSpans && loading ? (
        <Loading />
      ) : (
        collection.map((spanData) => {
          switch (spanData.section) {
            case "annotation":
              return (
                <Annotation key={spanData.end_at.toString()} content={spanData.detail.content} />
              );
            default:
              return (
                <Span
                  key={spanData.end_at.toString()}
                  detail={spanData.detail}
                  formattedDuration={spanData.formatted_duration}
                  section={spanData.section}
                />
              );
          }
        })
      )}

      {displayLoadMoreButton && (
        <LoadMoreButton
          loading={loading}
          collectionName="spans"
          handleLoadMore={handleClickLoadMore}
          buttonStyles={{ paddingLeft: "10px" }}
        />
      )}
    </StyledTimeline>
  );
};

export default Timeline;

/* eslint-disable react/require-optimization */
import * as React from "react";

import BuildsStore from "app/stores/BuildsStore";
import BuildRow from "app/components/build/BuildRow";

import BuildHeaderComponent from "app/components/build/Header";
import NoBuildsMessage from "./NoBuildsMessage";
import { Build } from "app/components/build/Show/lib/types";

type Props = {
  store: BuildsStore;
  emptyContext: React.ReactNode;
  emptyMessage?: React.ReactNode;
  showJobs?: boolean;
  showPipeline?: boolean;
  showTableView?: boolean;
  showOrganization?: boolean;
};

type State = {
  builds: Build[];
};

export default class BuildsList extends React.Component<Props, State> {
  static defaultProps = {
    emptyContext: "There are",
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      builds: this.props.store.all(),
    };
  }

  componentDidMount() {
    this.props.store.on("change", this.handleChange);
  }

  componentWillUnmount() {
    this.props.store.off("change", this.handleChange);
  }

  handleChange = () => {
    this.setState({
      builds: this.props.store.all(),
    });
  };

  getNoBuildsMessage() {
    const { state, creator } = this.props.store.query;
    const { emptyContext, emptyMessage } = this.props;

    if (emptyMessage) {
      return emptyMessage;
    }

    return (
      <NoBuildsMessage
        state={state}
        creator={creator}
        // @ts-expect-error - TS2322 - Type 'ReactNode' is not assignable to type 'string | undefined'.
        emptyContext={emptyContext}
      />
    );
  }

  renderNoBuildsMessage() {
    return (
      <div className="dark-gray my-1" style={{ maxWidth: "40em" }}>
        {this.getNoBuildsMessage()}
      </div>
    );
  }

  render() {
    if (this.state.builds.length === 0) {
      return this.renderNoBuildsMessage();
    }

    if (this.props.showTableView) {
      return (
        <div className="panel panel-default better-branch-panel border-none depth-150 rounded-1.5">
          {this.state.builds.map((build) => (
            <BuildRow
              key={build.id}
              build={build}
              store={this.props.store}
              showStepsToggle={true}
            />
          ))}
        </div>
      );
    }

    return (
      <div>
        {this.state.builds.map((build) => (
          <BuildHeaderComponent
            key={build.id}
            build={build}
            store={this.props.store}
            showJobs={this.props.showJobs}
            showProject={this.props.showPipeline}
            showOrganization={this.props.showOrganization}
          />
        ))}
      </div>
    );
  }
}

import { createFragmentContainer, graphql } from "react-relay";
import JobEvent from "../JobEvent";

type Props = {
  event: any;
  previousTimestamp: string | null | undefined;
};

function JobEventAcceptedExpired({ event, previousTimestamp }: Props) {
  return (
    <JobEvent timestamp={event.timestamp} previousTimestamp={previousTimestamp}>
      <JobEvent.Label>Accepted Expired</JobEvent.Label>
      <JobEvent.Description>
        The agent took too long to start the job, and so the job was re-scheduled.
      </JobEvent.Description>
    </JobEvent>
  );
}

export default createFragmentContainer(JobEventAcceptedExpired, {
  event: graphql`
    fragment JobEventAcceptedExpired_event on JobEvent {
      timestamp
    }
  `,
});

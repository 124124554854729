import ExecutionResult from "app/components/analytics/shared/ResultIcons/ExecutionResult";
import FailureReason from "app/components/analytics/shared/FailureReason";
import Emojify from "app/components/shared/Emojify";
import { Execution, Run } from "app/components/analytics/shared/type";
import MetadataRow from "app/components/analytics/shared/Metadata/MetadataRow";
import { FailureRow } from "../RunTable/Row";
import ExecutionDrawerButton from "./ExecutionDrawerButton";

export type ExecutionRow = Omit<Execution, "duration" | "id"> & {
  run: Omit<Run, "createdAt" | "humanDuration" | "duration" | "id" | "result" | "tags">;
};

type Props = {
  execution: ExecutionRow;
};

// Currently only used in the tests#show executions table
const Row = ({ execution }: Props) => {
  const failed = execution.result === "failed" && execution.failureReason !== null;
  const { name, buildNumber, buildUrl, branch, commitSha, repoUrl } = execution.run;

  return (
    <li className="row-link__container">
      <div className="ta-panel-row grid-cols-[3rem_auto_6rem]">
        <div data-testid="result-icon">
          <ExecutionResult.Regular result={execution.result.toUpperCase()} />
        </div>
        <div className="main-section">
          {execution.turboFrameSrc ? (
            <ExecutionDrawerButton
              url={execution.turboFrameSrc}
              classes="block font-medium text-inherit no-underline hover:underline focus:underline truncate max-w-full"
            >
              <Emojify text={name} />
            </ExecutionDrawerButton>
          ) : (
            <a
              href={execution.url}
              className="block font-medium text-inherit no-underline hover:underline focus:underline truncate max-w-full"
            >
              <span className="semi-bold block">
                <Emojify text={name} />
              </span>
            </a>
          )}

          <MetadataRow
            buildNumber={buildNumber}
            buildUrl={buildUrl}
            branch={branch}
            commitSha={commitSha}
            createdAt={execution.createdAt}
            repoUrl={repoUrl}
            linkToBranchTag={true}
          />
        </div>
        <div className="text-right">
          {execution.humanDuration} <span className="sr-only"> execution duration</span>
        </div>
        {failed && (
          <FailureRow>
            <FailureReason
              failureReason={execution.failureReason}
              endpoint={execution.failureExpandedUrl}
            />
          </FailureRow>
        )}
      </div>
    </li>
  );
};

export default Row;

/* eslint-disable id-length */

import { ChartDataset, TooltipModel } from "chart.js";
import { Fragment, useEffect } from "react";
import { sumBy, take } from "lodash";

import LineChart, { LineChartUnit } from "../LineChart";
import SparkLineChart, { dataForSparkline } from "../SparkLineChart";
import {
  Card,
  CardSection,
  CardChart,
  CardLabel,
  CardStat,
  CardDelta,
  CardLabelWithTooltip,
} from "../Card";
import { ChartLegend } from "../ChartLegend";
import { TimePeriod } from "../Renderer";
import {
  calculateMax,
  formatPercentage,
  getLatestValue,
  getThemeFromDelta,
  getTimePeriodString,
  labelHeader,
  Scope,
} from "./utils";
import { useChart } from "app/components/shared/Chart/chart";
import { Tooltip, TooltipLabel, TooltipValue } from "../Tooltip";
import { useChartData } from "./hooks";

interface DataPoint {
  x: number;
  active: number;
  percentage: number;
  running_jobs: number;
}

export function QueueAgentUtilizationSpark({
  datasets: { current, previous },
}: {
  datasets: {
    current: DataPoint[];
    previous: DataPoint[];
  };
}) {
  const percentage = getLatestValue(current, "percentage");
  const previousPercentage = getLatestValue(previous, "percentage");
  const active = getLatestValue(current, "active");

  const delta = Math.round(Math.abs(percentage - previousPercentage));
  const theme = getThemeFromDelta(delta);

  return (
    <Card>
      <CardSection>
        <CardLabelWithTooltip
          label="Agent utilization"
          tooltip={{
            title: `Active agent percentage · ${getTimePeriodString(TimePeriod.Hour)}`,
            description: "Percentage of your agent fleet actively processing jobs.",
          }}
        />
        {!!delta && (
          <CardDelta formattedValue={formatPercentage(delta)} value={delta} color={theme.color} />
        )}
      </CardSection>

      <CardSection>
        <CardStat
          label={`${active} active agents`}
          value={formatPercentage(percentage)}
          size="lg"
          layout="stacked"
        />
      </CardSection>

      <CardChart>
        <SparkLineChart data={dataForSparkline(previous, current, "percentage", theme)} />
      </CardChart>
    </Card>
  );
}

export function QueueAgentUtilizationDetail({
  scope,
  dataset,
  timePeriod,
}: {
  scope: Scope;
  dataset: ChartDataset<"line", DataPoint[]>[];
  timePeriod: TimePeriod;
}) {
  const { chartRef, updateData } = useChart<"line", DataPoint>();

  const data = useChartData({
    datasets: dataset,
    orderDatasetBy: ({ data }) => calculateMax(data, "percentage"),
    orderDirection: "asc",
    colorsOffset: 1,
  });

  useEffect(() => {
    updateData(data);
  }, [data, updateData]);

  const active = sumBy(dataset, ({ data }) => getLatestValue(data, "active"));
  const runningJobs = sumBy(dataset, ({ data }) => getLatestValue(data, "running_jobs"));

  return (
    <Card className="lg:flex-row">
      <div className="flex flex-col grow gap-3 min-w-0">
        <CardSection>
          <CardLabelWithTooltip
            label="Agent utilization"
            description={`Percentage of agents running jobs · ${getTimePeriodString(timePeriod)}`}
            tooltip={{
              title: "Agent Utilization",
              description:
                "Monitor queue resource efficiency. High utilization (>95%) indicates potential resource constraints. Analyze high utilization patterns across clusters to optimize distribution. Maximum utilization less than 70% indicates over-resourcing.",
            }}
          />

          <CardSection className="flex-col">
            <CardStat value={active.toLocaleString()} label="Active Agents" />
            <CardStat value={runningJobs.toLocaleString()} label="Running Jobs" />
          </CardSection>
        </CardSection>

        <CardChart>
          <LineChart
            ref={chartRef}
            data={data}
            tooltip={ChartTooltip}
            units={{ y: LineChartUnit.percentage }}
            options={{ parsing: { yAxisKey: "percentage" } }}
          />
        </CardChart>
      </div>

      <ChartLegend
        reverse={true}
        labelHeader={labelHeader(scope, "Queue")}
        datasets={data.datasets}
        chartRef={chartRef}
        columns={{
          Utilization: ({ data }) => formatPercentage(getLatestValue(data, "percentage")),
          "Active agents": ({ data }) => getLatestValue(data, "active").toLocaleString(),
        }}
      />
    </Card>
  );
}

const ChartTooltip = ({ tooltip }: { tooltip: TooltipModel<"line"> }) => {
  const visibleDataPoints = take(tooltip.dataPoints, 8);
  const hiddenDataPoints = tooltip.dataPoints.length - 8;

  return (
    <Tooltip tooltip={tooltip}>
      {visibleDataPoints.map(({ dataset, parsed }) => (
        <Fragment key={dataset.label}>
          <TooltipLabel color={dataset.borderColor as string}>{dataset.label}</TooltipLabel>
          <TooltipValue>{formatPercentage(parsed.y)}</TooltipValue>
        </Fragment>
      ))}

      {hiddenDataPoints > 0 && (
        <span className="col-span-2 text-[11px] font-medium text-charcoal-300">
          ({hiddenDataPoints} not shown)
        </span>
      )}
    </Tooltip>
  );
};

import ReactPaginate from "react-paginate";
import styled from "styled-components";

type Props = {
  pageCount: number;
  onPageChange: any;
  nextPrevOnly?: boolean;
};

const StyledPagination = styled.div`
  display: flex;
  margin-top: 10px;
  width: 100%;

  ul {
    list-style: none;
  }

  ul a {
    border-radius: 5px;
    width: 34px;
    padding: 8px 13px;
    text-align: center;
    line-height: 1;
    color: var(--gray-800);
    border: 1px solid transparent;

    &:hover {
      border: 1px solid var(--gray-500);
    }
  }

  li {
    list-style: none;
    margin: 0 2px;

    &.previous.disabled,
    &.next.disabled {
      display: none;
    }
  }

  a {
    line-height: 1;
    padding-top: 16px;
    cursor: pointer;
  }

  [aria-label]:before,
  [aria-label]:after {
    display: none;
  }

  .selected a,
  .previous a,
  .next a {
    border: 1px solid var(--gray-500);
    background-color: var(--base-0);
    color: var(--charcoal-900);
    font-weight: 500;
  }
`;

const Pagination = (props: Props) => {
  if (props.pageCount <= 1) {
    return null;
  }

  return (
    <StyledPagination
      data-testid="pagination"
      className={props.nextPrevOnly ? "justify-center" : "justify-end"}
    >
      <ReactPaginate
        pageCount={props.pageCount}
        onPageChange={props.onPageChange}
        pageClassName={`inline-block${props.nextPrevOnly ? " hidden" : ""}`}
        pageLinkClassName="no-underline"
        nextClassName={`next inline-block${props.nextPrevOnly ? "" : " hidden"}`}
        nextLinkClassName="no-underline"
        previousClassName={`previous inline-block${props.nextPrevOnly ? "" : " hidden"}`}
        previousLinkClassName="no-underline"
        breakClassName={`break inline-block${props.nextPrevOnly ? " hidden" : ""}`}
        nextLabel="Next ›"
        previousLabel="‹ Prev"
      />
    </StyledPagination>
  );
};

export default Pagination;

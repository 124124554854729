import { PureComponent } from "react";

type Props = Record<any, any>;

export default class DockerRateLimitedWarning extends PureComponent<Props> {
  render() {
    return (
      <div className="alert alert-info mb-2" role="alert">
        <p>
          This job contains a Docker Hub rate limit error, which may impact the job outcome. Docker
          Hub{" "}
          <a href="https://www.docker.com/blog/docker-hub-image-retention-policy-delayed-and-subscription-updates/">
            introduced rate limits for unauthenticated and free accounts on 2nd November 2020
          </a>
          . Documentation for working within the new rate limits on Buildkite is available{" "}
          <a href="https://buildkite.com/docs/integrations/docker-hub">here</a>.
        </p>
        <p>
          {"Still need help? Email us at "}
          <strong>
            <a href="mailto:support@buildkite.com">support@buildkite.com</a>
          </strong>
          {/*
           */}
          —we’d be happy to help!
        </p>
      </div>
    );
  }
}

import BuildTriggerLabel from "app/components/shared/BuildTriggerLabel";
import Emojify from "app/components/shared/Emojify";
import Icon from "app/components/shared/Icon";

interface Props {
  source: string;
  rebuiltFrom?: {
    url: string;
    number: number;
  };
  pipelineSchedule?: {
    url: string;
    label: string;
  };
  triggeredFrom?: {
    uuid: string;
    name: string;
    url: string;
    project: {
      name: string;
    };
    build: {
      number: number;
    };
  };
}

export function MetaInformation({ source, rebuiltFrom, pipelineSchedule, triggeredFrom }: Props) {
  let rebuiltFromNode;
  let triggeredFromNode;
  let pipelineScheduleNode;

  const sourceNode = (
    <div className="small dark-gray">
      <BuildTriggerLabel source={source} />
    </div>
  );

  if (rebuiltFrom) {
    rebuiltFromNode = (
      <div className="small dark-gray">
        {"Rebuilt from "}
        <a href={rebuiltFrom.url} className="semi-bold color-inherit hover-color-inherit">
          {`#${rebuiltFrom.number}`}
        </a>
      </div>
    );
  }

  if (pipelineSchedule) {
    pipelineScheduleNode = (
      <div className="small dark-gray flex">
        <a
          href={pipelineSchedule.url}
          className="semi-bold color-inherit hover-color-inherit flex items-center"
        >
          <Icon icon="schedules" style={{ width: 15, height: 15, marginRight: 3 }} />
          <Emojify text={pipelineSchedule.label} />
        </a>
      </div>
    );
  }

  if (triggeredFrom && triggeredFrom.url) {
    const jobName = triggeredFrom.name || `Job #${triggeredFrom.uuid}`;

    triggeredFromNode = (
      <div className="small dark-gray">
        <a href={triggeredFrom.url} className="semi-bold color-inherit hover-color-inherit">
          <span>
            {triggeredFrom.project.name}
            {" - Build #"}
            {triggeredFrom.build.number}
            {" / "}
            <Emojify text={jobName} />
          </span>
        </a>
      </div>
    );
  }

  return (
    <>
      {sourceNode}
      {rebuiltFromNode}
      {triggeredFromNode}
      {pipelineScheduleNode}
    </>
  );
}

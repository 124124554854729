import { PureComponent } from "react";

type State = {
  message: string;
  dots: number;
};

export default class LegacyLoading extends PureComponent<Record<any, any>, State> {
  // @ts-expect-error - TS2564 - Property 'loadingInterval' has no initializer and is not definitely assigned in the constructor.
  loadingInterval: number;

  state = {
    message: "Loading",
    dots: 0,
  };

  componentDidMount() {
    // Keep showing dots as a poor-mans loading symbol.
    this.loadingInterval = setInterval(() => {
      if (this.state.dots === 3) {
        this.setState({
          message: "Loading",
          dots: 0,
        });
      }

      this.setState({
        message: `${this.state.message}.`,
        dots: this.state.dots + 1,
      });
    }, 350);
  }

  componentWillUnmount() {
    clearInterval(this.loadingInterval);
  }

  render() {
    return <span>{this.state.message}</span>;
  }
}

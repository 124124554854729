import PipelineStateIcon from "app/components/shared/PipelineStateIcon";
import Icon from "app/components/shared/Icon";
import { StepProps } from "./Step";
import { WaitJob } from "app/components/build/Show/lib/types";

export default function WaiterStep({ job, stepClassName }: StepProps<WaitJob>) {
  if (job.continueOnFailure) {
    return (
      <div
        key={job.id}
        className={`${stepClassName} dark-gray relative`}
        title="Wait for all previous steps to finish including failures"
      >
        <Icon
          icon="heroicons/20/solid/x-mark"
          className="absolute text-xs top-[3px] right-[3px] h-3 w-3"
        />
        <PipelineStateIcon job={job} />
      </div>
    );
  }

  return (
    <div
      key={job.id}
      className={`${stepClassName} dark-gray`}
      title="Wait for all previous steps to pass"
    >
      <PipelineStateIcon job={job} />
    </div>
  );
}

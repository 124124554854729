import { useState, useCallback, MouseEvent, useLayoutEffect, useRef, useMemo } from "react";
import { ForwardedChartHandle } from "app/components/shared/Chart/chart";
import { ChartDataset, DefaultDataPoint } from "chart.js";
import { map, orderBy, sortBy } from "lodash";
import { colorsForIndex } from "../Theme";

function solidColor(color: string) {
  return color.substring(0, 7);
}

function transparentColor(color: string) {
  return `${color}20`;
}

export function useChartData<T extends ChartDataset<"line", unknown>>({
  datasets,
  orderDatasetBy,
  orderDirection = "desc",
  colorsOffset = 0,
}: {
  datasets: T[];
  orderDatasetBy: (dataset: T) => number;
  orderDirection?: "asc" | "desc";
  colorsOffset?: number;
}): {
  datasets: T[];
} {
  const orderedLabels = useRef<string[]>(
    map(orderBy(datasets, orderDatasetBy, orderDirection), "label") as string[],
  );

  const data = useMemo(() => {
    const coloredDatasets = datasets.map((dataset, index) => ({
      ...dataset,
      ...colorsForIndex(index + colorsOffset),
    }));

    const sortedDatasets = sortBy(coloredDatasets, ({ label }) =>
      orderedLabels.current.indexOf(label as string),
    );

    return {
      datasets: sortedDatasets,
    };
  }, [datasets]);

  return data;
}

export function useHighlightDataset(chartRef: React.RefObject<ForwardedChartHandle>) {
  const highlightDataset = useCallback(
    (hoveredIndex: number) => {
      const chartInstance = chartRef.current?.getChartInstance();
      if (!chartInstance) {
        return;
      }

      if (chartInstance.getDatasetMeta(hoveredIndex).hidden) {
        return;
      }

      chartInstance.data.datasets.forEach((dataset, index) => {
        if (index === hoveredIndex) {
          dataset.borderColor = solidColor(dataset.borderColor as string);
          dataset.backgroundColor = solidColor(dataset.backgroundColor as string);
        } else {
          dataset.borderColor = transparentColor(dataset.borderColor as string);
          dataset.backgroundColor = transparentColor(dataset.backgroundColor as string);
        }
      });

      chartInstance.update();
    },
    [chartRef],
  );

  const reset = useCallback(() => {
    const chartInstance = chartRef.current?.getChartInstance();
    if (!chartInstance) {
      return;
    }

    chartInstance.data.datasets.forEach((dataset) => {
      dataset.borderColor = solidColor(dataset.borderColor as string);
      dataset.backgroundColor = solidColor(dataset.backgroundColor as string);
    });

    chartInstance.update();
  }, [chartRef]);

  return (index: number) => ({
    onMouseEnter: () => highlightDataset(index),
    onMouseUp: reset,
    onMouseLeave: reset,
  });
}

export function useToggleDatasets(chartRef: React.RefObject<ForwardedChartHandle>) {
  const [hiddenDatasets, setHiddenDatasets] = useState(new Set());

  useLayoutEffect(() => {
    const chartInstance = chartRef.current?.getChartInstance();
    if (!chartInstance) {
      return;
    }

    chartInstance.data.datasets.forEach((_dataset, datasetIndex) => {
      if (hiddenDatasets.has(datasetIndex)) {
        chartInstance.hide(datasetIndex);
      } else {
        chartInstance.show(datasetIndex);
      }
    });

    chartInstance.update();
  }, [chartRef, hiddenDatasets]);

  const toggleDataset = useCallback(
    (index: number) => {
      const chartInstance = chartRef.current?.getChartInstance();
      if (!chartInstance) {
        return;
      }

      const nextHiddenDatasets = new Set(hiddenDatasets);

      if (hiddenDatasets.has(index)) {
        nextHiddenDatasets.delete(index);
      } else {
        if (chartInstance.getVisibleDatasetCount() === 1) {
          return;
        }

        nextHiddenDatasets.add(index);
      }

      setHiddenDatasets(nextHiddenDatasets);
    },
    [hiddenDatasets, chartRef],
  );

  const focusDataset = useCallback(
    (index: number) => {
      const chartInstance = chartRef.current?.getChartInstance();
      if (!chartInstance) {
        return;
      }

      const nextHiddenDatasets = new Set();

      const alreadyFocussed =
        chartInstance.getVisibleDatasetCount() === 1 && !hiddenDatasets.has(index);

      if (!alreadyFocussed) {
        chartInstance.data.datasets.forEach((_dataset, datasetIndex) => {
          if (datasetIndex !== index) {
            nextHiddenDatasets.add(datasetIndex);
          }
        });
      }

      setHiddenDatasets(nextHiddenDatasets);
    },
    [chartRef, hiddenDatasets],
  );

  const isDatasetVisible = useCallback((index) => !hiddenDatasets.has(index), [hiddenDatasets]);

  const visibilityHandlers = (index: number) => ({
    onClick: (event: MouseEvent) => {
      if (event.altKey) {
        focusDataset(index);
      } else {
        toggleDataset(index);
      }
    },
  });

  return {
    hiddenDatasets,
    isDatasetVisible,
    focusDataset,
    toggleDataset,
    visibilityHandlers,
  };
}

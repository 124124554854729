import React from "react";

import Panel from "app/components/shared/Panel";
import AgentTokenItem from "./AgentTokenItem";

interface Props {
  agentTokens: Array<{
    id: string;
    token: string;
    public: boolean;
    description?: string;
  }>;
  canViewToken: boolean;
  title?: string;
  setupMode?: boolean;
}

class AgentTokenList extends React.Component<Props> {
  static defaultProps = {
    title: "Agent Token",
    setupMode: false,
  };

  render() {
    return (
      <Panel className="mb-3">
        <Panel.Header>{this.props.title}</Panel.Header>
        <Panel.Section>
          <span>
            Your Buildkite agent token is used to configure and start new Buildkite agents.{" "}
          </span>
          {!this.props.setupMode && (
            <span>
              See the{" "}
              <a className="lime hover-lime no-underline hover-underline" href="/docs/agent">
                agent documentation
              </a>{" "}
              to learn more.
            </span>
          )}
        </Panel.Section>
        {this.renderBody()}
      </Panel>
    );
  }

  renderBody() {
    if (!this.props.canViewToken) {
      return (
        <Panel.Section>
          <p className="dark-gray">
            You don’t have permission to see your organization’s Agent tokens.
          </p>
        </Panel.Section>
      );
    }

    return this.props.agentTokens?.map((agentToken) => {
      return (
        <AgentTokenItem
          key={agentToken.id}
          // @ts-expect-error - TS2769 - No overload matches this call.
          agentToken={agentToken}
          showDescription={this.props.agentTokens.length > 1}
        />
      );
    });
  }
}

export default AgentTokenList;

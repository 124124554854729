import FriendlyTime from "app/components/shared/FriendlyTime";

interface TestLabelEvent {
  uuid: string;
  timestamp: Date;
  data: {
    label: string;
    message?: string;
    actor_system?: string;
    actor_user?: {
      name: string;
    };
  };
}

interface TestLabelAddedEvent extends TestLabelEvent {
  type: "test_label_added";
}

interface TestLabelRemovedEvent extends TestLabelEvent {
  type: "test_label_removed";
}

export type TestLabelEventType = TestLabelAddedEvent | TestLabelRemovedEvent;

const TestLabelEvent = ({ event }: { event: TestLabelEventType }) => {
  const { data } = event;
  return (
    <div className="flex">
      <div className="grow">
        <div>
          <span className="badge badge--outline">{data.label}</span> label was{" "}
          {event.type === "test_label_added" ? "added" : "removed"}
          {data.actor_user && ` by ${data.actor_user.name}`}
          {data.actor_system && ` via ${data.actor_system}`}.
        </div>
        {data.message && <div className="text-sm dark-gray mt-1">{data.message}</div>}
      </div>
      <div className="justify-self-end">
        <FriendlyTime className="dark-gray" value={event.timestamp} />
      </div>
    </div>
  );
};

export default TestLabelEvent;

import { createFragmentContainer, graphql } from "react-relay";

import TeamLabels from "app/components/Teams/Labels";
import Emojify from "app/components/shared/Emojify";

type Props = {
  team: {
    name: string;
    description?: string;
    slug: string;
  };
};

const TeamSuggestion = ({ team }: Props) => (
  <div>
    <div className="flex items-center">
      <Emojify text={team.name} className="semi-bold truncate max-w-full" />
      <TeamLabels team={team} />
    </div>
    <div className="m-0 p-0 dark-gray truncate max-w-full">
      <Emojify text={team.description || "n/a"} />
    </div>
  </div>
);

export default createFragmentContainer(TeamSuggestion, {
  team: graphql`
    fragment TeamSuggestion_team on Team {
      name
      description
      slug
      ...Labels_team
    }
  `,
});

import Step from "./Step";
import { useFormContext } from "react-hook-form";
import { Form, Vendor } from "../form";
import styled from "styled-components";
import { Checkable } from "../Field/Checkable";

interface Props {
  step: number;
  of: number;
  displayProgress: boolean;
  onSubmit: () => void;
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 15px;
`;

const options = [
  { value: Vendor.Buildkite, label: "Buildkite" },
  { value: Vendor.CircleCI, label: "CircleCI" },
  { value: Vendor.GithubActions, label: "GitHub Actions" },
  { value: Vendor.Harness, label: "Harness" },
  { value: Vendor.Jenkins, label: "Jenkins" },
  { value: Vendor.TeamCity, label: "TeamCity" },
  { value: Vendor.GitlabCI, label: "GitLab CI/CD" },
  { value: Vendor.BitbucketPipelines, label: "Bitbucket Pipelines" },
  { value: Vendor.Semaphore, label: "Semaphore" },
  { value: Vendor.Other, label: "Others" },
];

export default function Vendors(props: Props) {
  const { watch, register, handleSubmit } = useFormContext<Form>();
  const vendors = watch("vendors");

  return (
    <Step
      {...props}
      title="What CI/CD tools have you used?"
      disabled={!vendors.length}
      onSubmit={handleSubmit(props.onSubmit)}
    >
      <Grid>
        {options.map(({ value, label }) => (
          <Checkable type="checkbox" key={value} value={value} {...register("vendors")}>
            {value === Vendor.Other && vendors.includes(value) ? (
              <input
                autoFocus={true}
                type="text"
                {...register("otherVendors")}
                placeholder={label}
              />
            ) : (
              label
            )}
          </Checkable>
        ))}
      </Grid>
    </Step>
  );
}

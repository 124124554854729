/* eslint-disable react/display-name */

import { useState } from "react";
import { commitMutation, createFragmentContainer, graphql } from "react-relay";
import { ConnectionHandler } from "relay-runtime";

import Button from "app/components/shared/Button";
import Emojify from "app/components/shared/Emojify";
import Panel from "app/components/shared/Panel";

import permissions from "app/lib/permissions";
import FlashesStore from "app/stores/FlashesStore";

import AccessLevel from "app/components/Teams/Pipelines/access-level";
import Environment from "app/lib/relay/environment";

const UPDATE_ACCESS_LEVEL_MUTATION = graphql`
  mutation TeamRowUpdate_Mutation($input: TeamPipelineUpdateInput!) {
    teamPipelineUpdate(input: $input) {
      teamPipeline {
        accessLevel
      }
    }
  }
`;

const DELETE_TEAM_ROW_MUTATION = graphql`
  mutation TeamRowDelete_Mutation($input: TeamPipelineDeleteInput!) {
    teamPipelineDelete(input: $input) {
      deletedTeamPipelineID
      team {
        id
        pipelines {
          count
        }
      }
    }
  }
`;

const Description = ({ description }: { description?: string }) =>
  !!description && (
    <div className="font-normal dark-gray">
      <Emojify text={description} />
    </div>
  );

const Associations = ({ pipelines, members }: { pipelines: number; members: number }) => (
  <div className="flex flex-auto min-w-0 items-center">
    <div className="font-normal dark-gray">
      {`${pipelines} Pipeline${pipelines === 1 ? "" : "s"}, ${members} Member${
        members === 1 ? "" : "s"
      }`}
    </div>
  </div>
);

const Actions = ({
  teamPipeline,
  onAccessChange,
  savingNewAccessLevel,
  isRemoving,
  onRemove,
}: {
  teamPipeline: any;
  savingNewAccessLevel: string | null | undefined;
  isRemoving: boolean;
  onAccessChange: () => void;
  onRemove: () => void;
}) => {
  return permissions(teamPipeline.permissions).collect(
    {
      allowed: "teamPipelineUpdate",
      render: (idx) => (
        <AccessLevel
          key={idx}
          teamPipeline={teamPipeline}
          onAccessLevelChange={onAccessChange}
          saving={savingNewAccessLevel}
        />
      ),
    },
    {
      allowed: "teamPipelineDelete",
      render: (idx) => (
        <Button
          key={idx}
          // @ts-expect-error - TS2322 - Type 'false | "Removing…"' is not assignable to type 'boolean | undefined'.
          loading={isRemoving ? "Removing…" : false}
          className="ml-3"
          onClick={onRemove}
        >
          Remove
        </Button>
      ),
    },
  );
};

type Props = {
  teamPipeline: {
    id: string;
    pipeline: {
      id: string;
    };
    team: {
      id: string;
      name: string;
      slug: string;
      description?: string;
      members?: {
        count: number;
      };
      pipelines?: {
        count: number;
      };
      permissions?: {
        teamPipelineUpdate: {
          allowed: boolean;
        };
        teamPipelineDelete: {
          allowed: boolean;
        };
      };
    };
  };
  organization: {
    slug: string;
  };
};

const TeamRow = ({ teamPipeline, organization }: Props) => {
  const environment = Environment.get();
  const [state, setState] = useState({
    savingNewAccessLevel: null,
    isRemoving: false,
  });

  const handleAccessLevelChange = (accessLevel: undefined) => {
    // @ts-expect-error - TS2322 - Type 'undefined' is not assignable to type 'null'.
    setState({ ...state, savingNewAccessLevel: accessLevel });

    commitMutation(environment, {
      mutation: UPDATE_ACCESS_LEVEL_MUTATION,
      variables: {
        input: {
          id: teamPipeline.id,
          accessLevel: accessLevel,
        },
      },
      onCompleted: () => {
        setState({ ...state, savingNewAccessLevel: null });
      },
      onError: (err) => {
        setState({ ...state, savingNewAccessLevel: null });
        // @ts-expect-error - TS2339 - Property 'ERROR' does not exist on type 'FlashesStore'.
        FlashesStore.flash(FlashesStore.ERROR, err);
      },
      configs: [
        {
          type: "FIELDS_CHANGE",
          fieldIDs: {
            teamPipeline: teamPipeline.id,
          },
        },
      ],
    });
  };

  const handleRemove = () => {
    setState({ ...state, isRemoving: true });

    const updater = (store: any) => {
      // Manually perform optimistic update and remove the edge
      const PipelineProxy = store.get(teamPipeline.pipeline.id);
      const conn = ConnectionHandler.getConnection(PipelineProxy, "Page_pipeline_teams");
      ConnectionHandler.deleteNode(conn, teamPipeline.id);
    };

    commitMutation(environment, {
      mutation: DELETE_TEAM_ROW_MUTATION,
      variables: {
        input: {
          id: teamPipeline.id,
        },
      },
      onError: (err) => {
        setState({ ...state, isRemoving: false });
        // @ts-expect-error - TS2339 - Property 'ERROR' does not exist on type 'FlashesStore'.
        FlashesStore.flash(FlashesStore.ERROR, err);
      },
      updater,
    });
  };

  return (
    <Panel.Row>
      <div className="flex">
        <div className="flex items-center" style={{ width: "20em" }}>
          <div>
            <div className="m-0 semi-bold">
              <a
                href={`/organizations/${organization.slug}/teams/${teamPipeline.team.slug}`}
                className="semi-bold black hover-lime no-underline"
              >
                <Emojify text={teamPipeline.team.name} />
              </a>
            </div>

            {/* @ts-expect-error - TS2786 - 'Description' cannot be used as a JSX component. */}
            <Description description={teamPipeline.team.description} />
          </div>
        </div>

        <Associations
          pipelines={teamPipeline.team.pipelines?.count || 0}
          members={teamPipeline.team.members?.count || 0}
        />
        <Panel.RowActions>
          <Actions
            teamPipeline={teamPipeline}
            // @ts-expect-error - TS2322 - Type '(accessLevel: undefined) => void' is not assignable to type '() => void'.
            onAccessChange={handleAccessLevelChange}
            savingNewAccessLevel={state.savingNewAccessLevel}
            isRemoving={state.isRemoving}
            onRemove={handleRemove}
          />
        </Panel.RowActions>
      </div>
    </Panel.Row>
  );
};

export default createFragmentContainer(TeamRow, {
  organization: graphql`
    fragment TeamRow_organization on Organization {
      slug
    }
  `,
  teamPipeline: graphql`
    fragment TeamRow_teamPipeline on TeamPipeline {
      id
      accessLevel
      pipeline {
        id
      }
      team {
        id
        name
        description
        slug
        members {
          count
        }
        pipelines {
          count
        }
      }
      permissions {
        teamPipelineUpdate {
          allowed
        }
        teamPipelineDelete {
          allowed
        }
      }
    }
  `,
});

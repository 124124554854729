import { useCallback } from "react";
import LegacyDispatcher from "app/lib/legacyDispatcher";
import BrokenScriptStep from "./BrokenScriptStep";
import {
  ParallelGroupFooter,
  ParallelGroupHeader,
  StepContentWrapper,
  StepIcon,
  StepName,
  StepProps,
} from "./Step";
import { isJobTerminated, jobName } from "./utils/job";
import Icon from "app/components/shared/Icon";
import { getCssValue } from "app/lib/cssValues";
import getLabelColor from "./utils/getLabelColor";
import { CommandJob } from "app/components/build/Show/lib/types";

export default function ScriptStep(props: StepProps<CommandJob>) {
  const { job, group, stepClassName, build } = props;

  const groupedJob = Boolean(group && job.parallelGroupTotal);
  const showGroupHeader = Boolean(groupedJob && job.groupUuid && job.parallelGroupIndex === 0);
  const showGroupFooter = Boolean(
    groupedJob &&
      job.groupUuid &&
      job.parallelGroupTotal &&
      job.parallelGroupIndex === job.parallelGroupTotal - 1,
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      // return if the user did a middle-click, right-click, or used a modifier
      // key (like ctrl-click, meta-click, shift-click, etc.)
      if (event.button !== 0 || event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
        return;
      }

      event.preventDefault();

      // @ts-expect-error - TS2554 - Expected 6 arguments, but got 2.
      LegacyDispatcher.emit("job:focus", { job });
    },
    [job],
  );

  if (job.state === "broken") {
    return (
      <BrokenScriptStep
        {...props}
        groupedJob={groupedJob}
        showGroupHeader={showGroupHeader}
        showGroupFooter={showGroupFooter}
      />
    );
  }

  const jobTerminated = isJobTerminated(job);
  const jobHardFailed = jobTerminated && !job.passed && !job.softFailed && job.state !== "canceled";

  const groupedJobTitle = job.name;

  return (
    <>
      {showGroupHeader && <ParallelGroupHeader job={job} />}

      <a
        href={`${build.path}#${job.id}`}
        onClick={handleClick}
        className={stepClassName}
        // @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'string | undefined'.
        title={groupedJob ? groupedJobTitle : undefined}
        style={groupedJob ? {} : { maxWidth: "15em" }}
      >
        <StepContentWrapper shouldWrap={!jobHardFailed}>
          <span className="flex items-center">
            <StepIcon job={job} />
          </span>

          {job.retriedInJobUuid && (
            <Icon
              icon="retry"
              style={{ height: 12, width: 12, marginRight: 5 }}
              className="relative"
            />
          )}

          {groupedJob ? (
            <StepContentWrapper>
              <div
                className="rounded white semi-bold bg-white small tabular-numerals px-1 border"
                style={{
                  color: "currentColor",
                  borderColor: getLabelColor(job),
                  backgroundColor: getCssValue("--base-0"),
                  lineHeight: 1.75,
                }}
              >
                {/* @ts-expect-error - TS2532 - Object is possibly 'undefined'. */}
                {job.parallelGroupIndex + 1}
              </div>
            </StepContentWrapper>
          ) : (
            <StepContentWrapper shouldWrap={jobHardFailed}>
              <StepName job={job} />
            </StepContentWrapper>
          )}
        </StepContentWrapper>
      </a>

      {showGroupFooter && <ParallelGroupFooter />}
    </>
  );
}

import React, { CanvasHTMLAttributes, useEffect, useRef } from "react";
import ChartJS, { ChartData, ChartOptions, ChartType } from "chart.js/auto";
import "chartjs-adapter-moment";
import { merge } from "lodash";

interface ChartProps extends CanvasHTMLAttributes<HTMLCanvasElement> {
  height?: number;
  chartType: ChartType;
  chartOptions: ChartOptions;
  chartData: ChartData;
}

ChartJS.defaults.font.family =
  "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, sans-serif";

export const ComputeChart = (props: ChartProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<ChartJS | null>();

  const { chartOptions, chartData } = props;

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
  }, [chartOptions]);

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
  }, [chartData]);

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    chartRef.current = new ChartJS(canvasRef.current, {
      type: props.chartType,
      data: colorChartData(props.chartData),
      options: merge({}, defaultChartOptions, props.chartOptions) as ChartOptions,
    });

    return () => chartRef.current?.destroy();
  });

  return <canvas data-testid="compute-chart" height={props.height} ref={canvasRef} />;
};

const colorChartData = (data: ChartData): ChartData => {
  return {
    ...data,
    datasets: colorDatasets(data.datasets),
  };
};

const colorDatasets = (datasets: ChartData["datasets"]): ChartData["datasets"] => {
  return datasets.map((dataset, index) => {
    return {
      ...dataset,
      backgroundColor: `rgba(${getColor(index)}, 0.2)`,
      borderColor: `rgba(${getColor(index)}, 0.7)`,
    };
  });
};

const getColor = (index: number): string => {
  const colors = [
    "100, 210, 80",
    "90, 165, 255",
    "170, 90, 240",
    "180, 180, 180",
    "110, 110, 110",
    "170, 150, 110",
    "130, 50, 20",
    "240, 50, 50",
    "255, 180, 50",
    "255, 220, 90",
  ];

  return colors[index % colors.length];
};

const defaultChartOptions: ChartOptions = {
  animation: false,
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      // disable legend click as it's not helpful
      // when we repaint the chart while updating
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: () => {},
    },
  },
  clip: false,
  layout: {
    padding: 5,
  },
  scales: {
    // eslint-disable-next-line id-length
    y: {
      grace: "5%",
      grid: {
        display: false,
      },
      ticks: {
        precision: 0,
        maxTicksLimit: 10,
        padding: 0,
        color: "#737373",
      },
      min: 0,
      title: {
        display: true,
      },
    },
    // eslint-disable-next-line id-length
    x: {
      type: "time",
      time: {
        unit: "minute",
      },
      grid: {
        display: false,
      },
      ticks: {
        maxTicksLimit: 5,
        color: "#737373",
        padding: 15,
      },
    },
  },
};

import * as React from "react";
import Dropdown from "app/components/shared/Dropdown";
import Duration from "app/components/shared/Duration";
import { getDateString, getDuration, getDurationString, DateLike } from "app/lib/date";
import UserAvatar from "app/components/shared/UserAvatar";
import { second } from "metrick/duration";

type DurationFormats = "Short" | "Micro";
import { Build } from "app/components/build/Show/lib/types";
import { track } from "app/lib/segmentAnalytics";

type Props = {
  durationFormat: DurationFormats;
  build: Build;
};

type State = {
  total: number;
  running: number;
  runningRetries: number;
  idle: number;
  lastTick: DateLike;
};

export default class BuildDuration extends React.PureComponent<Props, State> {
  static defaultProps = {
    durationFormat: "Short",
  };

  state = {
    total: 0,
    running: 0,
    runningRetries: 0,
    idle: 0,
    lastTick: Date.now(),
  };

  // @ts-expect-error - TS2564 - Property '_timeout' has no initializer and is not definitely assigned in the constructor.
  _timeout: Timeout;

  constructor(initialProps: Props) {
    super(initialProps);

    if (window.Features.BuildDurationMetricsUI) {
      const {
        total = 0,
        running = 0,
        runningRetries = 0,
        idle = 0,
      } = this.props.build.duration || {};

      this.state = {
        total: total,
        running: running,
        runningRetries: runningRetries,
        idle: idle,
        lastTick: Date.now(),
      };
    }
  }

  tick() {
    const updateFrequency = second.bind(1);
    const { jobsRunning = false, retriesRunning = false, jobsIdle = false } = this.props.build;
    const { total, running, runningRetries, idle } = this.state;
    const delta = getDuration(this.state.lastTick, Date.now()).asSeconds();
    const newState = {
      lastTick: Date.now(),
    };

    if (jobsRunning || jobsIdle) {
      newState["total"] = total + delta;
    }

    if (jobsRunning) {
      newState["running"] = running + delta;
    }

    if (retriesRunning) {
      newState["runningRetries"] = runningRetries + delta;
    }

    if (jobsIdle) {
      newState["idle"] = idle + delta;
    }

    this.setState(newState);

    if (jobsRunning || retriesRunning || jobsIdle) {
      this._timeout = setTimeout(() => {
        this.tick();
      }, updateFrequency);
    }
  }

  cancelTick() {
    if (this._timeout) {
      clearTimeout(this._timeout);
    }
  }

  componentDidMount() {
    if (window.Features.BuildDurationMetricsUI) {
      this.tick();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!window.Features.BuildDurationMetricsUI) {
      return;
    }

    const { jobsRunning = false, retriesRunning = false, jobsIdle = false } = this.props.build;
    const {
      total = 0,
      running = 0,
      runningRetries = 0,
      idle = 0,
    } = this.props.build.duration || {};

    if (
      jobsRunning !== prevProps.build.jobsRunning ||
      retriesRunning !== prevProps.build.retriesRunning ||
      jobsIdle !== prevProps.build.jobsIdle
    ) {
      this.cancelTick();

      this.setState({
        total: total,
        running: running,
        runningRetries: runningRetries,
        idle: idle,
        lastTick: Date.now(),
      });

      this.tick();
    }
  }

  componentWillUnmount() {
    this.cancelTick();
  }

  render() {
    if (window.Features.BuildDurationMetricsUI) {
      return (
        <Dropdown
          width={200}
          className="inline-block"
          onToggle={(toggleOpen) => {
            if (toggleOpen) {
              track("Toggled Build Timing Information", {
                organization_slug: this.props.build.account.slug,
                build_number: this.props.build.number,
                pipeline: this.props.build.project.slug,
              });
            }
          }}
        >
          <span className="cursor-pointer underline decoration-dashed underline-offset-2 decoration-gray-700">
            {this.renderStateDuration()}
          </span>

          <ul className="px-4">
            <li className="flex my-2">
              <b className="flex-auto min-w-0">Total:</b>
              {getDurationString(this.state.total, "short")}
            </li>
            <li className="flex my-2">
              <b className="flex-auto min-w-0">Running:</b>
              {getDurationString(this.state.running, "short")}
            </li>
            <li className="flex my-2">
              <b className="flex-auto min-w-0">Running retries:</b>
              {getDurationString(this.state.runningRetries, "short")}
            </li>
            <li className="flex my-2">
              <b className="flex-auto min-w-0">Idle:</b>
              {getDurationString(this.state.idle, "short")}
            </li>
          </ul>
        </Dropdown>
      );
    }

    return this.renderStateDuration();
  }

  renderStateDuration() {
    const { build } = this.props;

    const DurationFormat = Duration[this.props.durationFormat];

    if (build.state === "passed") {
      return (
        <span>
          {"Passed in "}
          <DurationFormat from={build.startedAt} to={build.finishedAt} />
        </span>
      );
    } else if (build.state === "failing") {
      return (
        <span>
          {"Running for "}
          {/* @ts-expect-error - TS2322 - Type '{ from: DateLike | null | undefined; live: boolean; }' is not assignable to type 'IntrinsicAttributes & PartialProps'. */}
          <DurationFormat from={build.startedAt} live={true} />
          {" and failing"}
        </span>
      );
    } else if (build.state === "failed") {
      return (
        <span>
          {"Failed in "}
          <DurationFormat from={build.startedAt} to={build.finishedAt} />
        </span>
      );
    } else if (build.state === "blocked") {
      if (build.startedAt) {
        return (
          <span>
            {"Passed in "}
            <DurationFormat from={build.startedAt} to={build.finishedAt} />
            {" and blocked"}
          </span>
        );
      }

      return <span>Blocked</span>;
    } else if (build.state === "canceled") {
      const prefixNode =
        build.cancelReason === "build_skipping" ? (
          <span>Canceled automatically</span>
        ) : build.cancelledBy ? (
          <span>
            <UserAvatar
              user={{
                name: build.cancelledBy.name,
                avatar: { url: build.cancelledBy.avatar },
              }}
              style={{ width: 20, height: 20, marginRight: 2 }}
              suppressAltText={true}
            />
            <strong className="semi-bold">{build.cancelledBy.name}</strong>
            {" canceled"}
          </span>
        ) : build.cancelReason === "branch_deleted" ? (
          <span>Canceled due to branch deletion</span>
        ) : (
          <span>Canceled</span>
        );

      if (build.startedAt) {
        // @ts-expect-error - TS2345 - Argument of type 'DateLike | null | undefined' is not assignable to parameter of type 'DateLike'.
        const canceledAtString = getDateString(build.canceledAt);
        const title =
          build.cancelReason === "build_skipping"
            ? `This build was automatically canceled by your Build Skipping settings at ${canceledAtString}`
            : build.canceledAt;

        return (
          <span title={title}>
            {prefixNode}
            {" after "}
            <DurationFormat from={build.startedAt} to={build.canceledAt} />
          </span>
        );
      }

      return <span title={build.canceledAt}>{prefixNode}</span>;
    } else if (build.state === "creating") {
      return <span>Creating</span>;
    } else if (build.state === "canceling") {
      return <span>Canceling</span>;
    } else if (build.state === "skipped") {
      return <span>Skipped</span>;
    } else if (build.state === "not_run") {
      return <span>Not Run</span>;
    } else if (build.state === "scheduled") {
      return (
        <span>
          {"Waiting for "}
          {/* @ts-expect-error - TS2322 - Type '{ from: DateLike | null | undefined; live: boolean; }' is not assignable to type 'IntrinsicAttributes & PartialProps'. */}
          <DurationFormat from={build.scheduledAt} live={true} />
        </span>
      );
    } else if (build.state === "started") {
      return (
        <span>
          {"Running for "}
          {/* @ts-expect-error - TS2322 - Type '{ from: DateLike | null | undefined; live: boolean; }' is not assignable to type 'IntrinsicAttributes & PartialProps'. */}
          <DurationFormat from={build.startedAt} live={true} />
        </span>
      );
    }

    return null;
  }
}

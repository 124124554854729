import { Job } from "app/components/build/Show/lib/types";
import { SortFn } from ".";
import { getDateString } from "app/lib/date";

export function StartTime({ job }: { job: Job }) {
  if (job.type !== "script" && job.type !== "trigger") {
    return null;
  }

  if (!job.startedAt) {
    return null;
  }

  return getDateString(job.startedAt, { withSeconds: true, withYear: false });
}

// eslint-disable-next-line id-length
export const sortByStartTime: SortFn = (direction) => (a, b) => {
  let aStartedAt = 0;
  if (a.type === "script" || a.type === "trigger") {
    aStartedAt = a.startedAt ? new Date(a.startedAt).getTime() : 0;
  }

  let bStartedAt = 0;
  if (b.type === "script" || b.type === "trigger") {
    bStartedAt = b.startedAt ? new Date(b.startedAt).getTime() : 0;
  }

  if (aStartedAt === bStartedAt) {
    return 0;
  }

  if (direction === "asc") {
    return aStartedAt > bStartedAt ? 1 : -1;
  }

  return aStartedAt > bStartedAt ? -1 : 1;
};

import { useEffect, useState } from "react";
import { DropdownContainer } from "app/components/shared/Dropdown";
import calculateViewportOffsets from "app/components/shared/Popover/calculate-viewport-offsets";
import { ActivityProps } from "app/components/analytics/shared/Activity/Bar";

export interface Test {
  activity: {
    activities: Array<ActivityProps>;
    limit: number;
  };
  reliability: string;
  failedCount: number;
  p50Duration: number;
  p95Duration: number;
  url: string;
}

type TestTooltipProps = {
  wrapperNode: HTMLElement;
};

const TestTooltip = ({ wrapperNode }: TestTooltipProps) => {
  const [showing, setShowing] = useState(false);
  const { offsetX, offsetY, width } = calculateViewportOffsets(280, wrapperNode);

  useEffect(() => {
    // Delay the rendering of the Tooltip to trigger an animation
    const showingTimeout = setTimeout(() => {
      setShowing(true);
    }, 100);

    return () => {
      clearTimeout(showingTimeout);
    };
  }, []);

  return (
    <DropdownContainer
      offsetX={offsetX}
      offsetY={offsetY}
      width={width}
      showing={showing}
      nibOffsetX={0}
    >
      <div className="p-5 system">
        <div className="flex flex-col text-center justify-center">
          <h5 className="mb-2">Looking for Test Engine data?</h5>
          <p>Check out the Tests tab 👆</p>
        </div>
      </div>
    </DropdownContainer>
  );
};

export default TestTooltip;

import React, { ReactNode, useMemo } from "react";
import Skeleton from "react-loading-skeleton";

type LoadingRowsProps = {
  rows: number;
  columns: number;
};

export const LoadingRows = ({ rows, columns }: LoadingRowsProps) => {
  const skeletonRow = useMemo(
    () => (
      <tr data-testid="loading">
        {[...Array(columns)].map((_el, index) => (
          <td key={index}>
            <Skeleton />
          </td>
        ))}
      </tr>
    ),
    [columns],
  );

  return (
    <>
      {[...Array(rows)].map<ReactNode>((_el, index) => (
        <React.Fragment key={index}>{skeletonRow}</React.Fragment>
      ))}
    </>
  );
};

export const LoadingPanels = ({ rows, columns }: LoadingRowsProps) => {
  const skeletonRow = useMemo(
    () => (
      <div data-testid="loading" className="flex border rounded border-gray mb-4">
        {[...Array(columns)].map((_el, index) => (
          <div key={index} className="flex-auto min-w-0 p-5">
            <Skeleton />
          </div>
        ))}
      </div>
    ),
    [columns],
  );

  return (
    <>
      {[...Array(rows)].map<ReactNode>((_el, index) => (
        <React.Fragment key={index}>{skeletonRow}</React.Fragment>
      ))}
    </>
  );
};

export const LoadingChart = ({
  barWidth,
  barCount = 8,
}: {
  barWidth: string;
  barCount?: number;
}) => (
  <div className="flex items-end overflow-hidden gap1" data-testid="loading-skeleton">
    {Array.from({ length: barCount }, (_element, index) => {
      const height = 20 + Math.floor(Math.random() * 30);

      return <Skeleton key={index} width={barWidth} height={height} inline={true} />;
    })}
  </div>
);

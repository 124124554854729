import React from "react";
import Loadable from "react-loadable";
import { IntrospectionQuery } from "graphql";
import { default as CodeMirror, CodeMirrorOptions } from "codemirror";

import Spinner from "app/components/shared/Spinner";

const AUTO_COMPLETE_AFTER_KEY = /^[a-zA-Z0-9_@(]$/;

const codeMirrorConfiguration: CodeMirrorOptions = (schema) => ({
  lineNumbers: true,
  tabSize: 2,
  mode: "graphql",
  keyMap: "sublime",
  autoCloseBrackets: true,
  matchBrackets: true,
  showCursorWhenSelecting: true,
  viewportMargin: Infinity,
  gutters: ["CodeMirror-linenumbers"],
  theme: "graphql",
  extraKeys: {
    "Cmd-Space": (codemirror) => codemirror.showHint({ completeSingle: true }),
    "Ctrl-Space": (codemirror) => codemirror.showHint({ completeSingle: true }),
    "Alt-Space": (codemirror) => codemirror.showHint({ completeSingle: true }),
    "Shift-Space": (codemirror) => codemirror.showHint({ completeSingle: true }),
    // Editor improvements
    "Ctrl-Left": "goSubwordLeft",
    "Ctrl-Right": "goSubwordRight",
    "Alt-Left": "goGroupLeft",
    "Alt-Right": "goGroupRight",
  },
  lint: {
    schema: schema,
  },
  hintOptions: {
    schema: schema,
    completeSingle: false,
  },
});

type LoadedProps = {
  CodeMirror: typeof CodeMirror;
};

type ReactLoadableLoadingProps = {
  error?: string;
  pastDelay?: boolean;
};

type SharedProps = {
  name: string;
  value: string;
  schema: IntrospectionQuery;
  onChange: (query: string) => void;
};

type Props = SharedProps & {
  CodeMirror: typeof CodeMirror;
};

class GraphqlEditor extends React.PureComponent<Props> {
  input: HTMLTextAreaElement | null | undefined;
  editor: CodeMirror;

  componentDidMount() {
    const { CodeMirror } = this.props;

    if (this.input) {
      this.editor = CodeMirror.fromTextArea(this.input, this.codeMirrorConfig);
      this.editor.on("change", this.onEditorChange);
      this.editor.on("keyup", this.onEditorKeyUp);
    }
  }

  componentWillUnmount() {
    if (this.editor) {
      this.editor.toTextArea();
      this.editor.off("keyup", this.onEditorKeyUp);
      this.editor.off("change", this.onEditorChange);
      delete this.editor;
    }
  }

  componentDidUpdate() {
    if (this.editor && this.props.value && this.props.value !== this.editor.getValue()) {
      this.editor.setValue(this.props.value);
    }
  }

  render() {
    return (
      <div className="grow min-h-full cursor-text">
        <textarea
          name={this.props.name}
          defaultValue={this.props.value}
          ref={(input) => (this.input = input)}
        />
      </div>
    );
  }

  onEditorKeyUp = (
    codeMirrorInstance: CodeMirror,
    event: {
      key: string;
    },
  ) => {
    if (AUTO_COMPLETE_AFTER_KEY.test(event.key)) {
      codeMirrorInstance.execCommand("autocomplete");
    }
  };

  onEditorChange = () => {
    if (this.editor) {
      this.props.onChange(this.editor.getValue());
    }
  };

  get codeMirrorConfig() {
    return codeMirrorConfiguration(this.props.schema);
  }
}

export default Loadable.Map({
  loader: {
    CodeMirror: () =>
      import("./codemirror").then(
        (module) =>
          // Add a "zero" delay after the module has loaded, to allow their
          // styles to take effect.
          new Promise((resolve: (result: Promise<never>) => void) => {
            setTimeout(() => resolve(module.default), 0);
          }),
      ),
  },

  loading(props: ReactLoadableLoadingProps) {
    if (props.error) {
      return <div>{props.error}</div>;
    } else if (props.pastDelay) {
      return (
        <div className="flex items-center justify-center h-[250px]">
          <Spinner /> Loading GraphQL Editor…
        </div>
      );
    }

    return null;
  },

  render(loaded: LoadedProps, props: Props) {
    return (
      <div className="flex min-h-[250px]">
        <GraphqlEditor
          CodeMirror={loaded.CodeMirror}
          name={props.name}
          schema={props.schema}
          onChange={props.onChange}
          value={props.value}
        />
      </div>
    );
  },
});

import { Component, useImperativeHandle, createRef, forwardRef, useRef, useCallback } from "react";
import ChartJS, { Chart as ChartJSChart, ChartConfiguration } from "chart.js/auto";
import {
  LinearScale,
  CategoryScale,
  ChartData,
  ChartDataset,
  ChartType,
  DefaultDataPoint,
} from "chart.js";
import { BoxPlotController, BoxAndWiskers } from "@sgratzl/chartjs-chart-boxplot";
import "chartjs-adapter-moment";
import doughnutChartBackground from "./doughnutChartBackground";

type Props = {
  chartOptions: ChartConfiguration<any, any> & {
    dangerouslyEvaluateFunctions?: boolean;
  };
  canvasId?: string;
};

ChartJS.register(
  doughnutChartBackground,
  BoxPlotController,
  BoxAndWiskers,
  LinearScale,
  CategoryScale,
);

ChartJS.defaults.font.family =
  "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, sans-serif";

export default class Chart extends Component<Props> {
  canvasRef: {
    current: null | HTMLCanvasElement;
  };
  // @ts-expect-error - TS2564 - Property 'chart' has no initializer and is not definitely assigned in the constructor.
  chart: ChartJSChart;

  shouldComponentUpdate() {
    return false;
  }
  constructor(props: Props) {
    super(props);
    this.canvasRef = createRef();
  }
  componentDidMount() {
    ChartJS.defaults.font.family =
      "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, sans-serif";

    let target;

    if (this.props.canvasId) {
      target = this.props.canvasId;
    } else {
      target = this.canvasRef.current;
    }

    this.props.chartOptions.options.devicePixelRatio = Math.max(window.devicePixelRatio, 2);

    // Convert any tooltip filters into functions
    if (this.props.chartOptions?.dangerouslyEvaluateFunctions) {
      if (this.props.chartOptions?.options?.plugins?.tooltip?.filter ?? false) {
        this.props.chartOptions.options.plugins.tooltip.filter = new Function(
          "tooltipItem",
          "data",

          this.props.chartOptions.options.plugins.tooltip.filter,
        );
      }

      // Convert any tooltip label callbacks into function
      if (this.props.chartOptions?.options?.plugins?.tooltip?.callbacks?.label ?? false) {
        this.props.chartOptions.options.plugins.tooltip.callbacks.label = new Function(
          "context",

          this.props.chartOptions.options.plugins.tooltip.callbacks.label,
        );
      }

      // convert y axis ticks callback into function
      if (this.props.chartOptions?.options?.scales?.y?.ticks?.callback ?? false) {
        this.props.chartOptions.options.scales.y.ticks.callback = new Function(
          "value",
          "index",
          "ticks",

          this.props.chartOptions.options.scales.y.ticks.callback,
        );
      }
    }

    this.chart = new ChartJS(target, this.props.chartOptions);
  }
  componentWillUnmount() {
    this.chart.destroy();
  }
  render() {
    // If no canvas ID is passed in, we have clready created the canvas element with the ID we want to attach to
    // so return an empty string rather than returning another canvas element.
    if (!this.props.canvasId) {
      return <canvas ref={this.canvasRef} data-testid="chart" />;
    }
    return "";
  }
}

export type ForwardedChartHandle = {
  getChartInstance: () => ChartJSChart | undefined;
};

export const ForwardedChart = forwardRef<ForwardedChartHandle, Props>((props, ref) => {
  const chartRef = createRef<Chart>();

  // Expose the ChartJS instance to the parent via ref
  useImperativeHandle(ref, () => ({
    getChartInstance: () => chartRef.current?.chart,
  }));

  return <Chart {...props} ref={chartRef} />;
});

/**
 * Hook for hooking into the ChartJS instance, allowing for data updates
 * when paired with the `ForwardedChart` component.
 *
 * Example usage:
 *   const { chartRef, updateData } = useChart();
 *
 *   useEffect(() => {
 *    updateData(data);
 *   }, [data]);
 *
 *   return <ForwardedChart ref={chartRef} chartOptions={chartOptions} />;
 */
export function useChart<Type extends ChartType = ChartType, DataPoint = DefaultDataPoint<Type>>() {
  const chartRef = useRef<ForwardedChartHandle>(null);

  const updateData = useCallback((data: ChartData<Type, DataPoint[]>) => {
    const instance = chartRef.current?.getChartInstance();
    if (!instance) {
      return;
    }

    const currentDatasets: any[] = instance.data?.datasets || [];

    // Preserve the visibility and colours of the existing datasets
    const datasets: ChartDataset[] = data.datasets.map((dataset, index) => {
      return {
        ...currentDatasets[index],
        ...dataset,
        hidden: !instance.isDatasetVisible(index),
      };
    });

    instance.data = {
      labels: data.labels,
      datasets,
    };
    instance.update();
  }, []);

  return { chartRef, updateData };
}

import styled from "styled-components";
import Colors from "app/constants/analytics/Colors";
import Skeleton from "react-loading-skeleton";
import Tooltip from "../../Tooltips/BasicTooltip";

type Props = {
  loading?: boolean;
  error?: boolean;
  value: string;
  label: string;
  description?: string;
};

const StyledCard = styled.div`
  border-radius: 3px;
  padding: 15px 20px;
  background-color: ${Colors.WHITE};
  flex: 1 1 0;

  span {
    color: ${Colors.BASE_GRAY};
  }

  &:only-child {
    .card-error {
      max-width: unset;
    }
  }

  .card-error {
    @media (min-width: 1200px) {
      max-width: 175px;
    }
  }
`;

const StyledLabel = styled.span`
  display: inline-block;
  height: 40px;
`;

const Card = ({ label, value, description, loading, error }: Props) => {
  return (
    <StyledCard data-testid="metric-card">
      <div className="relative">
        <StyledLabel>{label} </StyledLabel>
        {description && (
          <div className="right-0 absolute" style={{ top: "-.25em" }}>
            <Tooltip name={label}>
              <>
                <p className="font-semibold m-0">{label}</p>
                <p className="m-0 mt-1" dangerouslySetInnerHTML={{ __html: description }} />
              </>
            </Tooltip>
          </div>
        )}
        <br />
        {loading && (
          <Skeleton containerTestId="card-loading-skeleton" style={{ maxWidth: "200px" }} />
        )}
        {error && (
          <p className="card-error">There was an error loading {label.toLowerCase()} data</p>
        )}
        {!loading && !error && <div className="text-2xl mb-2">{value}</div>}
      </div>
    </StyledCard>
  );
};

export default Card;

import * as React from "react";

type Props = {
  name: string;
  matched: boolean;
  metaData: Array<string> | null | undefined;
  onMetaDataClick: (metaDataIndex: string) => unknown;
};

export default class AgentQueryRulesResult extends React.PureComponent<Props> {
  render() {
    const metaDataNode =
      this.props.metaData && this.props.metaData.length > 0 ? (
        this.props.metaData.map((metaData, index) => (
          <span
            className="AgentQueryRulesResultComponent__MetaData__Data"
            key={metaData}
            data-index={index}
            onClick={this.handleMetaDataClick}
          >
            {metaData}
          </span>
        ))
      ) : (
        <span>No metadata</span>
      );

    const className = this.props.matched
      ? "AgentQueryRulesResultComponent--matched"
      : "AgentQueryRulesResultComponent";

    return (
      <div className={className}>
        <div className="AgentQueryRulesResultComponent__Name">{this.props.name}</div>
        <div className="AgentQueryRulesResultComponent__MetaData">{metaDataNode}</div>
      </div>
    );
  }

  handleMetaDataClick = (evt: React.MouseEvent<HTMLSpanElement>) => {
    evt.preventDefault();

    if (!(evt.target instanceof HTMLSpanElement)) {
      return;
    }

    // @ts-expect-error - TS2345 - Argument of type 'string | null' is not assignable to parameter of type 'string'.
    const index = parseInt(evt.target.getAttribute("data-index"), 10);

    if (!this.props.metaData) {
      return;
    }

    return this.props.onMetaDataClick(this.props.metaData[index]);
  };
}

import invariant from "invariant";
import { Network, RecordSource, Environment as RelayEnvironment, Store } from "relay-runtime";
import RelayFeatureFlags from "relay-runtime/lib/util/RelayFeatureFlags";
import makeFetch from "./makeFetch";

RelayFeatureFlags.ENABLE_RELAY_CONTAINERS_SUSPENSE = false;

let relayEnvironment = null;

export default class Environment {
  static get(): RelayEnvironment {
    invariant(
      relayEnvironment,
      `Cannot get the current Relay Environment as one has not yet been configured!`,
    );
    return relayEnvironment;
  }

  static create(): RelayEnvironment {
    if (relayEnvironment === null) {
      const network = Network.create(makeFetch);
      const source = new RecordSource({});
      const store = new Store(source);
      relayEnvironment = new RelayEnvironment({ network, store });
    }
    return relayEnvironment;
  }
}

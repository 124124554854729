/* eslint-disable id-length */
import { ChartData, ChartOptions, DefaultDataPoint } from "chart.js";
import { useEffect } from "react";

import { ForwardedChart, useChart } from "app/components/shared/Chart/chart";
import { linearFillGradient } from "./linearFillGradient";
import Theme from "./Theme";
import { chartOptions } from "./chartOptions";
import { map } from "lodash";

export function withDashedLine<DataPoint = DefaultDataPoint<"line">>(data: DataPoint[]) {
  return {
    data: data,
    borderDash: [2, 3],
    borderWidth: 1,
    borderColor: "#8e8e8e",
  };
}

export function withGradientFillLine<DataPoint = DefaultDataPoint<"line">>(
  data: DataPoint[],
  theme: Theme,
) {
  return {
    data: data,
    borderColor: theme.color,
    borderWidth: 1.75,
    fill: true,
    backgroundColor: linearFillGradient(theme.fillColors),
  };
}

export function dataForSparkline<T = DefaultDataPoint<"line">>(
  previous: T[],
  current: T[],
  key: keyof T,
  theme: Theme,
) {
  return {
    labels: map(current, "x"),
    datasets: [withGradientFillLine(map(current, key), theme), withDashedLine(map(previous, key))],
  };
}

export default function SparkLineChart<DataPoint = DefaultDataPoint<"line">>({
  data,
  options,
}: {
  data: ChartData<"line", DataPoint[]>;
  options?: ChartOptions;
}) {
  const { chartRef, updateData } = useChart<"line", DataPoint>();

  useEffect(() => {
    updateData(data);
  }, [data, updateData]);

  return (
    <ForwardedChart
      ref={chartRef}
      chartOptions={{
        type: "line",
        data,
        options: chartOptions(
          {
            events: [], // Disable all interactions
            scales: {
              x: { display: false },
              y: {
                display: false,
                beginAtZero: true,
              },
            },
          },
          options,
        ),
      }}
    />
  );
}

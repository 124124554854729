import React from "react";
import Duration from "app/components/shared/Duration";
import UserAvatar from "app/components/shared/UserAvatar";
import { getDateString } from "app/lib/date";
import { Build } from "app/components/build/Show/lib/types";

export function getDurationValues(build: Build) {
  switch (build.state) {
    case "passed":
    case "failed":
    case "blocked":
      return {
        from: build.startedAt,
        to: build.finishedAt,
      };
    case "failing":
    case "started":
      return {
        from: build.startedAt,
        live: true,
      };
    case "canceled":
      if (build.startedAt) {
        return {
          from: build.startedAt,
          to: build.canceledAt,
        };
      }
      return null;
    case "scheduled":
      return {
        from: build.scheduledAt,
        live: true,
      };
    default:
      return null;
  }
}

export function getDurationPrefix(build: Build) {
  switch (build.state) {
    case "passed":
      return "Passed in";
    case "failing":
      return "Running for";
    case "failed":
      return "Failed in";
    case "blocked":
      return "Passed in";
    case "canceled":
      if (build.cancelReason === "build_skipping") {
        return build.startedAt ? "Canceled automatically after" : "Canceled automatically";
      }
      return build.startedAt ? "Canceled after" : "Canceled";
    case "scheduled":
      return "Waiting for";
    case "started":
      return "Running for";
    case "creating":
      return "Creating";
    case "canceling":
      return "Canceling";
    case "skipped":
      return "Skipped";
    case "not_run":
      return "Not run";
    default:
      return null;
  }
}

export function getDurationSuffix(build: Build) {
  switch (build.state) {
    case "failing":
      return "and failing";
    case "blocked":
      return "and blocked";
    default:
      return null;
  }
}

function BuildDuration({
  build,
  durationFormat,
}: {
  durationFormat: "Short" | "Micro";
  build: Build;
}) {
  const DurationFormat = Duration[durationFormat];

  const duration = getDurationValues(build);
  const suffix = getDurationSuffix(build);
  const prefix = getDurationPrefix(build);

  let prefixNode: React.ReactNode = null;

  if (build.state === "canceled") {
    if (build.cancelReason === "build_skipping") {
      // When the build was skipped, include a tooltip to explain why.
      const canceledAt = getDateString(build.canceledAt || "");
      const title = `This build was automatically canceled by your Build Skipping settings at ${canceledAt}`;
      prefixNode = <span title={title}>{prefix}</span>;
    } else if (build.cancelledBy) {
      // When the build was canceled by a user, include their avatar and name and show canceled time on hover.
      prefixNode = (
        <span title={build.canceledAt}>
          <UserAvatar
            user={{
              name: build.cancelledBy.name,
              avatar: {
                url: build.cancelledBy.avatar,
              },
            }}
            style={{
              width: 20,
              height: 20,
              marginRight: 2,
            }}
            suppressAltText={true}
          />
          <strong className="semi-bold">{build.cancelledBy.name}</strong>{" "}
          {prefix && prefix.toLowerCase()}
        </span>
      );
    } else {
      // Otherwise, display the canceled time on hover.
      prefixNode = <span title={build.canceledAt}>{prefix}</span>;
    }
  }

  return (
    <span>
      {prefixNode || prefix} {duration && <DurationFormat {...duration} />} {suffix}
    </span>
  );
}

BuildDuration.defaultProps = {
  durationFormat: "Short",
};

export default BuildDuration;

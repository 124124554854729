import { PureComponent } from "react";

type Props = {
  name: string;
  className?: string;
};

export default class RootField extends PureComponent<Props> {
  render() {
    const [type, name] = this.props.name.split(".");

    return (
      <span className={this.props.className}>
        <a
          className="cm-property underline-dotted no-underline"
          href={`/user/graphql/documentation/${type}/${name}`}
        >
          {name}
        </a>
        <span className="cm-punctuation">(</span>
        <span className="cm-punctuation">)</span>
      </span>
    );
  }
}

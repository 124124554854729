import cable from "app/lib/cable";

type Callbacks = {
  connected?: () => void;
  disconnected?: (data: { willAttemptReconnect: boolean }) => void;
  rejected?: () => void;
  received?: (data: { [key: string]: any }) => void;
};

export default class BaseChannel {
  subscription:
    | {
        unsubscribe: () => void;
        perform: (arg1: string, arg2: Record<any, any>) => void;
      }
    | null
    | undefined;
  params: Record<any, any>;
  callbacks: Callbacks;

  static channelName: string;

  static subscribe(params: Record<any, any> | null | undefined = {}, callbacks: Callbacks) {
    return new this(params, callbacks).subscribe();
  }

  get channelName(): string {
    // @ts-expect-error - TS2339 - Property 'channelName' does not exist on type 'Function'.
    return this.constructor.channelName;
  }

  constructor(params: Record<any, any> | null | undefined = {}, callbacks: Callbacks) {
    // @ts-expect-error - TS2322 - Type 'Record<any, any> | null' is not assignable to type 'Record<any, any>'.
    this.params = params;
    this.callbacks = callbacks;
  }

  subscribe() {
    this.subscription = cable.subscriptions.create(
      {
        ...this.params,
        channel: this.channelName,
      },
      this,
    );

    return this;
  }

  perform(action: string, data: Record<any, any> = {}) {
    if (this.subscription) {
      this.subscription.perform(action, data);
    }
  }

  unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }

    return this;
  }

  connected = () => {
    if (this.callbacks.connected) {
      this.callbacks.connected();
    }
  };

  disconnected = (data: { willAttemptReconnect: boolean }) => {
    if (this.callbacks.disconnected) {
      this.callbacks.disconnected(data);
    }
  };

  rejected = () => {
    if (this.callbacks.rejected) {
      this.callbacks.rejected();
    }
  };

  received = (data: Record<any, any>) => {
    if (this.callbacks.received) {
      this.callbacks.received(data);
    }
  };
}

/* eslint-disable id-length */

import { TooltipModel } from "chart.js";
import { Fragment, useEffect } from "react";
import { reverse, take } from "lodash";

import { Card, CardSection, CardChart, CardLabel, CardLabelWithTooltip } from "../Card";
import LineChart from "../LineChart";
import { useChart } from "app/components/shared/Chart/chart";
import { TimePeriod } from "../Renderer";
import { Tooltip, TooltipLabel, TooltipValue } from "../Tooltip";
import { ChartLegend } from "../ChartLegend";
import { useChartData } from "./hooks";
import { stackedChart, chartOptionsForScope } from "../chartOptions";
import {
  calculateMin,
  calculateMax,
  getLatestValue,
  getTimePeriodString,
  calculateSum,
  Scope,
} from "./utils";

interface DataPoint {
  x: number;
  active: number;
  percentage: number;
  running_jobs: number;
}

interface Props {
  scope: Scope;
  dataset: {
    label: string;
    data: DataPoint[];
  }[];
  timePeriod: TimePeriod;
}

export function ConnectedAgents({ scope, dataset, timePeriod }: Props) {
  const { chartRef, updateData } = useChart<"line", DataPoint>();

  const data = useChartData({
    datasets: dataset,
    orderDatasetBy: ({ data }) => calculateMax(data, "active"),
    orderDirection: "asc",
    colorsOffset: 2,
  });

  useEffect(() => {
    updateData(data);
  }, [data, updateData]);

  return (
    <Card className="lg:flex-row">
      <div className="flex flex-col grow gap-3 min-w-0">
        <CardSection>
          <CardLabelWithTooltip
            label="Active agents"
            description={`Connected agents · ${getTimePeriodString(timePeriod)}`}
            tooltip={{
              title: "Active Agents",
              description:
                "Monitor agent fleet size and availability. Analyze agent count trends to optimize resource allocation and ensure sufficient capacity for your workload.",
            }}
          />
        </CardSection>

        <CardChart>
          <LineChart
            ref={chartRef}
            data={data}
            tooltip={ChartTooltip}
            options={{
              ...chartOptionsForScope(scope, {
                [Scope.Organization]: stackedChart,
                [Scope.Cluster]: stackedChart,
              }),
              parsing: { yAxisKey: "active", xAxisKey: "x" },
            }}
          />
        </CardChart>
      </div>

      <ChartLegend
        reverse={true}
        datasets={data.datasets}
        chartRef={chartRef}
        columns={{
          Min: ({ data }) => calculateMin(data, "active"),
          Max: ({ data }) => calculateMax(data, "active"),
          "Active agents": ({ data }) => getLatestValue(data, "active"),
        }}
      />
    </Card>
  );
}

const ChartTooltip = ({ tooltip }: { tooltip: TooltipModel<"line"> }) => {
  const totalActive = calculateSum(tooltip.dataPoints.map(({ parsed }) => parsed));

  const visibleDataPoints = take(reverse(tooltip.dataPoints), 8);
  const hiddenDataPoints = tooltip.dataPoints.length - 8;

  return (
    <Tooltip tooltip={tooltip}>
      {visibleDataPoints.map(({ dataset, parsed }) => (
        <Fragment key={dataset.label}>
          <TooltipLabel color={dataset.borderColor as string}>{dataset.label}</TooltipLabel>
          <TooltipValue>{parsed.y}</TooltipValue>
        </Fragment>
      ))}

      <TooltipLabel>Total</TooltipLabel>
      <TooltipValue>{totalActive}</TooltipValue>

      {hiddenDataPoints > 0 && (
        <span className="col-span-2 text-[11px] font-medium text-charcoal-300">
          ({hiddenDataPoints} not shown)
        </span>
      )}
    </Tooltip>
  );
};

import { useCallback, useState, useEffect } from "react";
import useFetch from "use-http";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";

import Pagination from "app/components/shared/Pagination";
import usePagination, {
  PaginationProps,
} from "app/components/analytics/shared/hooks/usePagination";
import Panel from "app/components/shared/Panel";
import ErrorState from "app/components/analytics/shared/ErrorState";
import EmptyState from "app/components/shared/EmptyState";
import ExecutionsList, { FlakyExecution } from "./ExecutionsList";
import FeedbackMessage from "app/components/analytics/shared/FeedbackMessage";
import Emojify from "app/components/shared/Emojify";
import CommitSha from "../../shared/Metadata/CommitSha";

type FlakyInstanceProps = {
  commitSha: string;
  commitMessage: string;
  latestBranch: string;
  executions: Array<FlakyExecution>;
  repoUrl: string | null | undefined;
};

const StyledPanel = styled(Panel)<{ expanded?: boolean }>`
  margin: 5px 0;
  width: ${(props) => (props.expanded ? "100%" : "calc(100% - 10px)")};
  border-color: ${(props) => (props.expanded ? "var(--gray-600)" : "var(--gray-500)")};
  border-radius: 4px;
  transition: all 0.1s ease-out;

  > button.panel-header {
    padding: 20px 30px;
    border-radius: 3px;
    height: 60px;
  }

  > button:hover.panel-header {
    background-color: var(--slate-100);
  }

  > button.panel-header {
    > svg {
      display: none;
    }
  }
`;
StyledPanel.displayName = "StyledPanel";

const FlakyHeader = styled.span`
  font-weight: 600;
  line-height: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
FlakyHeader.displayName = "FlakyHeader";

const GrayedOutFlakyHeader = styled.span`
  color: var(--charcoal-300);
`;
GrayedOutFlakyHeader.displayName = "GrayedOutFlakyHeader";

const FlakyMetadata = styled.div`
  font-family:
    SFMono-Regular,
    SF Mono,
    Monaco,
    Menlo,
    Consolas,
    Liberation Mono,
    Courier,
    monospace;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;

  div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  #commit-sha {
    max-width: 80px;
  }

  #branch {
    max-width: 200px;
  }

  div:not(:last-child) {
    padding: 0px 10px 0px 10px;
    border-right: 1px solid var(--gray-500);
  }

  div:last-child {
    padding-left: 14px;
  }
`;

const FlakyInstance = ({
  commitSha,
  commitMessage,
  latestBranch,
  expanded,
  executions,
  repoUrl,
}: FlakyInstanceProps & { expanded: boolean }) => {
  const [panelExpanded, setPanelExpanded] = useState(expanded);
  const handleOnClick = () => setPanelExpanded(!panelExpanded);

  return (
    <StyledPanel expanded={panelExpanded} separator={false}>
      <Panel.RowAccordion onClick={handleOnClick} expanded={panelExpanded} className="panel-header">
        <div className="flex flex-auto min-w-0 justify-between items-center">
          {commitMessage ? (
            <FlakyHeader>
              <Emojify text={commitMessage} />
            </FlakyHeader>
          ) : (
            <GrayedOutFlakyHeader>(No commit message)</GrayedOutFlakyHeader>
          )}
          <FlakyMetadata>
            {latestBranch && <div id="branch">{latestBranch}</div>}
            {commitSha && (
              <div>
                <CommitSha commitSha={commitSha} repoUrl={repoUrl} />
              </div>
            )}
          </FlakyMetadata>
        </div>
      </Panel.RowAccordion>
      {panelExpanded &&
        (executions.length === 0 ? (
          <Skeleton style={{ margin: "10px 15px", width: "97%", height: "20px" }} />
        ) : (
          <ExecutionsList executions={executions} />
        ))}
    </StyledPanel>
  );
};

const LoadingPanels = () => (
  <>
    {[...Array(10)].map((_el, index) => (
      <StyledPanel key={index}>
        <Panel.Row>
          <Skeleton
            style={{ margin: "10px 15px", width: "97%", height: "20px" }}
            containerTestId="loading-panel"
          />
        </Panel.Row>
      </StyledPanel>
    ))}
  </>
);

const FlakyInstances = ({
  endpoint,
  executionsEndpoint,
}: {
  endpoint: string;
  executionsEndpoint?: string;
}) => {
  const { currentData, metadata, pageCount, currentPage, handleOnChange, loading, error } =
    usePagination({ endpoint });

  if (!executionsEndpoint) {
    return (
      <FlakyInstancesData
        currentData={currentData}
        metadata={metadata}
        pageCount={pageCount}
        handleOnChange={handleOnChange}
        loading={loading}
        error={error}
      />
    );
  }

  // if executionsEndpoint is set, we will be loading the panel details
  // asynchronously and `latestData` will be passed to the `currentData`
  // prop instead
  const [latestData, setLatestData] = useState(currentData);

  // When the pagination hook retrieves data, update latestData
  useEffect(() => {
    if (currentData.length > 0) {
      loadData();
    }
    setLatestData(currentData);
  }, [currentData]);

  const url = new URL(executionsEndpoint);
  url.searchParams.append("page", `${currentPage}`);

  const { get, response } = useFetch(url.href);

  const loadData = async () => {
    const newData = await get();

    if (response.ok) {
      const { data } = newData;

      if (data) {
        setLatestData(data);
      }
    }
  };

  return (
    <FlakyInstancesData
      currentData={latestData}
      metadata={metadata}
      pageCount={pageCount}
      handleOnChange={handleOnChange}
      loading={loading}
      error={error}
    />
  );
};

type FlakyInstancesPaginationProps = Omit<PaginationProps, "currentData"> & {
  currentData: Array<FlakyInstanceProps & { id: string }>;
};

export const FlakyInstancesData = ({
  currentData,
  metadata,
  pageCount,
  handleOnChange,
  loading,
  error,
}: FlakyInstancesPaginationProps) => {
  const { past_instances_exist, instance_count, current_period } = metadata || {};

  if (error) {
    return (
      <ErrorState
        emoji="🕳️"
        heading="There was an error loading the flaky instances"
        subheading="Try refreshing the page; if the problem persists"
        urlText="let us know"
        url="mailto:support@buildkite.com"
      />
    );
  }

  if (!loading && currentData.length === 0) {
    return (
      <EmptyState
        emoji="🪴"
        heading={
          past_instances_exist
            ? `No flaky instances found in the past ${current_period}`
            : "No flaky instances found"
        }
        subheading={past_instances_exist ? undefined : "It must be your day."}
      />
    );
  }

  return (
    <>
      <div id="flaky-instances" className="flex flex-col items-center">
        {loading ? (
          <LoadingPanels />
        ) : (
          currentData.map((flaky, index) => (
            <FlakyInstance key={flaky.id} expanded={index === 0} {...flaky} />
          ))
        )}

        <Pagination pageCount={pageCount} onPageChange={handleOnChange} nextPrevOnly={true} />
      </div>
      {instance_count && current_period && (
        <FeedbackMessage
          message={`This test has flaked on ${instance_count} unique commits over the last ${current_period}.`}
        />
      )}
    </>
  );
};

export default FlakyInstances;

import classNames from "classnames";
import { twMerge } from "tailwind-merge";

type Props = {
  className?: string;
  animate?: boolean;
};

const Indicator = ({ className, animate = true }: Props) => (
  <span
    data-testid="indicator"
    className={twMerge(
      "w-2.5 h-2.5 block rounded-full bg-red-600 border border-white ring-red-300 ring-2",
      classNames({ "animate-scaleIn": animate }),
      className,
    )}
  />
);

export default Indicator;

import Icon from "app/components/shared/Icon";
import styled from "styled-components";

type Props = {
  collectionName?: string;
  handleLoadMore: () => Promise<void>;
  loading: boolean;
  buttonStyles?: {
    paddingLeft?: string;
  };
};

const ButtonContainer = styled.div<{ paddingLeft?: string }>`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  padding-top: 20px;
  padding-left: ${(props) => props.paddingLeft};
  button {
    font-family:
      SF Pro Text,
      -apple-system,
      BlinkMacSystemFont,
      Segoe UI,
      Roboto,
      Helvetica Neue,
      Helvetica,
      sans-serif;
    padding: 0;
    background-color: white;
    border: none;
    font-size: 14px;
    font-weight: 500;
    color: var(--charcoal-700);
    display: flex;
    flex-direction: row;
    align-items: center;

    &:hover:not(:disabled) {
      cursor: pointer;
      color: var(--charcoal-400);
    }

    svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      color: currentColor;
    }

    .spinning-arrows {
      animation: spin 1s linear infinite;
    }
  }
`;

const LoadMoreButton = ({ collectionName, handleLoadMore, loading, buttonStyles }: Props) => {
  return (
    <ButtonContainer {...buttonStyles}>
      <button onClick={handleLoadMore}>
        {loading ? (
          <>
            <Icon icon="heroicons/outline/arrow-path" className="spinning-arrows" />
            Loading {collectionName}...
          </>
        ) : (
          <>
            <Icon icon="heroicons/outline/chevron-double-down" />
            Load more {collectionName}
          </>
        )}
      </button>
    </ButtonContainer>
  );
};

export default LoadMoreButton;

import { hasJobPassed, hasJobFailed } from "app/lib/jobs";
import { JobStates, Job } from "app/components/build/Show/lib/types";
import { JOB_STATES_DOWNCASED as JOB_STATES } from "app/constants/JobStates";

const WAITING_STATES: JobStates[] = [
  JOB_STATES.WAITING,
  JOB_STATES.PENDING,
  JOB_STATES.LIMITED,
  JOB_STATES.LIMITING,
  JOB_STATES.SCHEDULED,
  JOB_STATES.ASSIGNED,
  JOB_STATES.ACCEPTED,
];

const FAILURE_STATES: JobStates[] = [
  JOB_STATES.CANCELED,
  JOB_STATES.EXPIRED,
  JOB_STATES.WAITING_FAILED,
  JOB_STATES.BLOCKED_FAILED,
  JOB_STATES.UNBLOCKED_FAILED,
];

const TERMINAL_STATES: JobStates[] = [
  JOB_STATES.CANCELED,
  JOB_STATES.TIMED_OUT,
  JOB_STATES.EXPIRED,
  JOB_STATES.BROKEN,
  JOB_STATES.WAITING_FAILED,
  JOB_STATES.BLOCKED_FAILED,
  JOB_STATES.UNBLOCKED_FAILED,
  JOB_STATES.FINISHED,
  JOB_STATES.SKIPPED,
];

export default class JobGroup {
  type: "job_group";
  id: string;
  jobs: Array<Job>;
  step:
    | {
        label: string | null | undefined;
      }
    | null
    | undefined;
  name: string | null | undefined;
  command: string | null | undefined;
  state: JobStates;
  passed: boolean | null | undefined;
  total: number | null | undefined;
  softFailed: boolean | null | undefined;
  parallel: boolean | null | undefined;
  matrix: boolean | null | undefined;

  // job state counts
  finishedCount: number;
  expiredCount: number;
  brokenCount: number;
  skippedCount: number;
  canceledCount: number;
  cancelingCount: number;
  waitingCount: number;
  blockedCount: number;
  blockedFailedCount: number;
  runningCount: number;
  unblockedCount: number;
  unblockedFailedCount: number;
  waitingFailedCount: number;
  timingOutCount: number;
  timedOutCount: number;
  failedCount: number;
  softFailedCount: number;

  constructor(id: string) {
    this.id = id;
    this.type = "job_group";
    this.jobs = [];

    this.name = null;
    this.command = null;
    this.state = "scheduled";
    this.passed = null;
    this.parallel = false;
    this.total = null;
    this.matrix = false;
    this.softFailed = null;

    this.finishedCount = 0;
    this.expiredCount = 0;
    this.brokenCount = 0;
    this.skippedCount = 0;
    this.cancelingCount = 0;
    this.canceledCount = 0;
    this.waitingCount = 0;
    this.blockedCount = 0;
    this.blockedFailedCount = 0;
    this.unblockedCount = 0;
    this.unblockedFailedCount = 0;
    this.runningCount = 0;
    this.waitingFailedCount = 0;
    this.timingOutCount = 0;
    this.timedOutCount = 0;
    this.failedCount = 0;
    this.softFailedCount = 0;
  }

  appendJob(job: Job) {
    // Set some of the initial values based on the first job we see
    if (this.jobs.length === 0) {
      if (job.type === "script" && !job.groupUuid) {
        this.name = job.name;
        this.step = job.step;
        this.command = job.command;
        this.parallel = true;
        this.total = job.parallelGroupTotal;
      } else {
        this.name = job.groupLabel || job.groupIdentifier;
      }
    }

    this._updateStateCounts(job);
    this._updatePassedStatus(job);

    this.jobs.push(job);

    this.state = this._deriveGroupStateFromJobs();
  }

  hasSoftFailedJobs() {
    return this.softFailedCount > 0;
  }

  _updateStateCounts(job: Job) {
    const jobState = job.state;

    // Keep a count of the number of jobs we consider to be 'waiting'
    if (WAITING_STATES.includes(jobState)) {
      this.waitingCount += 1;
    }

    // Keep a count of the number of jobs we consider to be 'terminated'
    if (TERMINAL_STATES.includes(jobState)) {
      this.finishedCount += 1;
    }

    if (jobState === JOB_STATES.BLOCKED) {
      this.blockedCount += 1;
    }

    if (jobState === JOB_STATES.UNBLOCKED) {
      this.unblockedCount += 1;
    }

    if (jobState === JOB_STATES.CANCELING) {
      this.cancelingCount += 1;
    }

    if (jobState === JOB_STATES.TIMING_OUT) {
      this.timingOutCount += 1;
    }

    if (jobState === JOB_STATES.RUNNING) {
      this.runningCount += 1;
    }

    if (jobState === JOB_STATES.FINISHED && hasJobFailed(job)) {
      this.failedCount += 1;
      if (job.softFailed) {
        this.softFailedCount += 1;
      }
    }

    if (jobState === JOB_STATES.CANCELED) {
      this.canceledCount += 1;
    }

    if (jobState === JOB_STATES.TIMED_OUT) {
      this.timedOutCount += 1;
    }

    if (jobState === JOB_STATES.EXPIRED) {
      this.expiredCount += 1;
    }

    if (jobState === JOB_STATES.BROKEN) {
      this.brokenCount += 1;
    }

    if (jobState === JOB_STATES.WAITING_FAILED) {
      this.waitingFailedCount += 1;
    }

    if (jobState === JOB_STATES.BLOCKED_FAILED) {
      this.blockedFailedCount += 1;
    }

    if (jobState === JOB_STATES.UNBLOCKED_FAILED) {
      this.unblockedFailedCount += 1;
    }

    if (jobState === JOB_STATES.SKIPPED) {
      this.skippedCount += 1;
    }
  }

  _updatePassedStatus(job: Job) {
    const jobState = job.state;

    // Update the 'passed' state of the group based on the current job
    // We track this as we go because we need to know the passed state of the group
    // before all of the jobs have terminated

    if (this.passed === null && hasJobPassed(job)) {
      // If we haven't recorded a passed state yet, and the current job has passed, then
      // we'll call the group passed (for now)
      this.passed = true;
    }

    // If the job can be considered a failure, then the group has failed
    if (
      (([JOB_STATES.FINISHED, JOB_STATES.TIMED_OUT] as JobStates[]).includes(jobState) &&
        hasJobFailed(job)) ||
      FAILURE_STATES.includes(jobState)
    ) {
      this.passed = false;
      this.softFailed = false;
    }

    // But if all of the job failures are soft failures, then the group is soft failed
    if (this.failedCount > 0 && this.failedCount === this.softFailedCount) {
      this.softFailed = true;
    }
  }

  _deriveGroupStateFromJobs(): JobStates {
    if (this.finishedCount === this.jobs.length) {
      switch (true) {
        case this.failedCount > 0:
          return JOB_STATES.FINISHED;
        case this.canceledCount > 0:
          return JOB_STATES.CANCELED;
        case this.timedOutCount > 0:
          return JOB_STATES.TIMED_OUT;
        case this.expiredCount > 0:
          return JOB_STATES.EXPIRED;
        case this.brokenCount > 0:
          return JOB_STATES.BROKEN;
        case this.waitingFailedCount > 0:
          return JOB_STATES.WAITING_FAILED;
        case this.blockedFailedCount > 0:
          return JOB_STATES.BLOCKED_FAILED;
        case this.unblockedFailedCount > 0:
          return JOB_STATES.UNBLOCKED_FAILED;
        case this.skippedCount > 0:
          return JOB_STATES.SKIPPED;
        default:
          return JOB_STATES.FINISHED;
      }
    }

    switch (true) {
      case this.waitingCount === this.jobs.length:
        return JOB_STATES.WAITING;
      case this.blockedCount === this.jobs.length:
        return JOB_STATES.BLOCKED;
      case this.unblockedCount === this.jobs.length:
        return JOB_STATES.UNBLOCKED;
      case this.cancelingCount > 0:
        return JOB_STATES.CANCELING;
      case this.timingOutCount > 0:
        return JOB_STATES.TIMING_OUT;
      case this.runningCount > 0:
        return JOB_STATES.RUNNING;
      default:
        return JOB_STATES.WAITING;
    }
  }
}

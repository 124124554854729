import { useCallback, useState } from "react";
import FlakyInstances from "../FlakyInstances";
import ExecutionsTable from "../ExecutionsTable";
import TabControl, { TabButton } from "app/components/analytics/shared/Tabs";
import TestEvents from "../TestEvents";

type TabsProps = {
  flaky_instances_endpoint: string;
  flaky_instances_executions_endpoint?: string;
  executions_endpoint: string;
  tooltip: string;
  show_flaky_tests_ui: boolean;
  test_events_endpoint: string;
};

const TABS = {
  FLAKY: "flaky",
  EXECUTIONS: "executions",
  EVENTS: "events",
};

const Tabs = ({
  flaky_instances_endpoint,
  flaky_instances_executions_endpoint,
  executions_endpoint,
  tooltip,
  show_flaky_tests_ui,
  test_events_endpoint,
}: TabsProps) => {
  const getDefaultAnchor = () => {
    const initialAnchor = window.location.hash.replace("#", "");

    if (
      initialAnchor === TABS.EXECUTIONS ||
      initialAnchor === TABS.EVENTS ||
      (initialAnchor === TABS.FLAKY && show_flaky_tests_ui)
    ) {
      return initialAnchor;
    }

    return TABS.EXECUTIONS;
  };

  const anchor = getDefaultAnchor();

  const [activeTab, setActiveTab] = useState(anchor);
  const handleFlakyTabClick = useCallback(() => setActiveTab(TABS.FLAKY), []);
  const handleTestExecutionsTabClick = useCallback(() => {
    setActiveTab(TABS.EXECUTIONS);
  }, []);
  const handleEventsTabClick = useCallback(() => setActiveTab(TABS.EVENTS), []);

  return (
    <div style={{ marginTop: "60px" }}>
      <TabControl className="justify-center">
        <TabButton
          active={activeTab === TABS.EXECUTIONS}
          href={`#${TABS.EXECUTIONS}`}
          onClick={handleTestExecutionsTabClick}
          icon="heroicons/outline/clipboard-document-list"
        >
          Test executions
        </TabButton>
        {show_flaky_tests_ui && (
          <TabButton
            active={activeTab === TABS.FLAKY}
            href={`#${TABS.FLAKY}`}
            onClick={handleFlakyTabClick}
            icon="fire"
          >
            Flaky instances
          </TabButton>
        )}
        <TabButton
          active={activeTab === TABS.EVENTS}
          href={`#${TABS.EVENTS}`}
          onClick={handleEventsTabClick}
          icon="auditLog"
        >
          Test events
        </TabButton>
      </TabControl>
      {activeTab === TABS.FLAKY && show_flaky_tests_ui && (
        <FlakyInstances
          endpoint={flaky_instances_endpoint}
          executionsEndpoint={flaky_instances_executions_endpoint}
        />
      )}
      {activeTab === TABS.EXECUTIONS && (
        <ExecutionsTable endpoint={executions_endpoint} tooltip={tooltip} />
      )}
      {activeTab === TABS.EVENTS && <TestEvents endpoint={test_events_endpoint} />}
    </div>
  );
};

export default Tabs;

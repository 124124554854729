import * as React from "react";

import UserStore from "app/stores/UserStore";

import LegacyDispatcher from "app/lib/legacyDispatcher";
import Icon from "app/components/shared/Icon";

const getAllEmailSettingsState = function (
  projects: Array<{
    accountName: string;
    id: string;
    name: string;
  }>,
) {
  const settings: Array<ProjectSetting> = [];

  for (const project of Array.from(projects)) {
    const projectSettings = UserStore.getEmailSettings(project);

    if (projectSettings.emailMode || projectSettings.includeBuildPassed) {
      settings.push({
        accountName: project.accountName,
        projectName: project.name,
        projectId: project.id,
        emailMode: projectSettings.emailMode,
        includeBuildPassed: projectSettings.includeBuildPassed,
      });
    }
  }

  return { settings };
};

type JQueryWithTooltipPlugin = JQuery & {
  tooltip: () => unknown;
};

declare let jQuery: {
  (selector: string, context?: Element | JQuery | JQueryWithTooltipPlugin): JQueryWithTooltipPlugin;
} & JQueryStatic;

type EmailMode = "nothing" | "starred" | "everything";

type Props = {
  projects: Array<{
    id: string;
    name: string;
    accountName: string;
  }>;
};

type ProjectSettingPartial = {
  emailMode?: EmailMode | null | undefined;
  includeBuildPassed?: boolean | null | undefined;
};

type ProjectSetting = ProjectSettingPartial & {
  accountName: string;
  projectName: string;
  projectId: string;
};

type State = {
  settings: Array<ProjectSetting>;
  saving: boolean;
};

export default class PipelineEmailSettings extends React.Component<Props, State> {
  hideSavingTimeout: number | null | undefined;

  constructor(props: Props) {
    super(props);

    this.state = {
      ...getAllEmailSettingsState(this.props.projects),
      saving: false,
    };
  }

  componentDidMount() {
    UserStore.addChangeListener(this.handleStoreChange);
  }

  componentWillUnmount() {
    UserStore.removeChangeListener(this.handleStoreChange);
  }

  render() {
    const contents =
      this.state.settings && this.state.settings.length > 0 ? (
        <table className="table project-email-settings__table">
          <thead>
            <tr className="small text-center">
              <th />
              <th>None</th>
              <th>Starred Branches</th>
              <th>All Branches</th>
              <th>Every Build</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.state.settings.map((setting) => (
              <tr key={setting.projectId} className="text-center">
                <th className="project-email-settings__table__project-name">
                  {setting.accountName}
                  {" \x2F "}
                  {setting.projectName}
                </th>
                <td>
                  <input
                    type="radio"
                    name={`${setting.projectId}-email-mode`}
                    onChange={this.getModeChangeHandler(setting, "nothing")}
                    checked={setting.emailMode === "nothing"}
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    name={`${setting.projectId}-email-mode`}
                    onChange={this.getModeChangeHandler(setting, "starred")}
                    checked={setting.emailMode === "starred"}
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    name={`${setting.projectId}-email-mode`}
                    onChange={this.getModeChangeHandler(setting, "everything")}
                    checked={setting.emailMode === "everything"}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    value="false"
                    // @ts-expect-error - TS2322 - Type 'boolean | null | undefined' is not assignable to type 'boolean | undefined'.
                    checked={setting.includeBuildPassed}
                    // @ts-expect-error - TS2322 - Type '(evt: React.MouseEvent<HTMLInputElement>) => void' is not assignable to type 'ChangeEventHandler<HTMLInputElement>'.
                    onChange={this.getIncludeBuildPassedChangeHandler(setting)}
                  />
                </td>
                <td>
                  <a
                    className="project-email-settings__table__delete"
                    href="#"
                    aria-label="Remove this pipeline’s custom settings"
                    // @ts-expect-error - TS2322 - Type '(evt: React.MouseEvent<HTMLInputElement>) => void' is not assignable to type 'MouseEventHandler<HTMLAnchorElement>'.
                    onClick={this.getDeleteModeClickHandler(setting)}
                  >
                    <Icon icon="heroicons/20/solid/x-circle" className="h-4 w-4 !align-baseline" />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="text-muted project-email-settings__none">
          You have no pipeline-specific notification settings
        </p>
      );

    const saveClasses = ["label", "label-success", "project-email-settings__saved"];

    if (this.state.saving) {
      saveClasses.push("project-email-settings__saved--saving");
    }

    return (
      <div className="panel panel-default kinda-new-panel">
        <div className="panel-heading">
          <span>Pipeline Specific Email Settings</span>
          <small className={saveClasses.join(" ")} key="saved-label">
            Saved
          </small>
        </div>
        <div className="panel-body">
          <p>You can override your email notification settings for each pipeline.</p>
          {contents}
        </div>
      </div>
    );
  }

  handleStoreChange = () => {
    this.setState(getAllEmailSettingsState(this.props.projects));
  };

  getModeChangeHandler(setting: ProjectSetting, emailMode: EmailMode) {
    return () => this.emitChange(setting.projectId, { emailMode });
  }

  getIncludeBuildPassedChangeHandler(setting: ProjectSetting) {
    return (evt: React.MouseEvent<HTMLInputElement>) => {
      if (!(evt.target instanceof HTMLInputElement)) {
        return;
      }

      return this.emitChange(setting.projectId, {
        includeBuildPassed: evt.target.checked,
      });
    };
  }

  getDeleteModeClickHandler(setting: ProjectSetting) {
    return (evt: React.MouseEvent<HTMLInputElement>) => {
      evt.preventDefault();
      return this.emitChange(setting.projectId, {
        emailMode: null,
        includeBuildPassed: null,
      });
    };
  }

  emitChange(projectId: string, settings: ProjectSettingPartial) {
    this.showSaving();
    // @ts-expect-error - TS2554 - Expected 6 arguments, but got 2.
    LegacyDispatcher.emit("pipeline_settings:update", {
      project: { id: projectId },
      settings,
    });
  }

  showSaving() {
    // If we've scheduled to hide the saving message already, cancel it, and
    // restart the timeout. This way the saving message will always be shown
    // for at least 750ms after a form change
    if (this.hideSavingTimeout) {
      clearTimeout(this.hideSavingTimeout);
    }

    this.hideSavingTimeout = setTimeout(() => this.hideSaving(), 750);

    this.setState({
      saving: true,
    });
  }

  hideSaving() {
    this.setState({
      saving: false,
    });
  }
}

/* eslint-disable id-length */
import { ChartDataset, TooltipModel } from "chart.js";
import { min, max, take, reverse } from "lodash";
import { Fragment, useEffect } from "react";

import LineChart from "../LineChart";
import { ChartLegend } from "../ChartLegend";
import { TimePeriod } from "../Renderer";
import { useChart } from "app/components/shared/Chart/chart";
import { Tooltip, TooltipLabel, TooltipValue } from "../Tooltip";
import { useChartData } from "./hooks";
import SparkLineChart, { dataForSparkline } from "../SparkLineChart";
import { Card, CardSection, CardChart, CardDelta, CardStat, CardLabelWithTooltip } from "../Card";
import {
  calculateMax,
  calculateMin,
  calculateSum,
  getLatestValue,
  getThemeFromDelta,
  getTimePeriodString,
  labelHeader,
  Scope,
} from "./utils";
import { stackedChart, chartOptionsForScope } from "../chartOptions";

interface DataPoint {
  x: number;
  y: number;
}

export function QueueJobsWaitingSpark({
  datasets: { current, previous },
}: {
  datasets: {
    current: DataPoint[];
    previous: DataPoint[];
  };
}) {
  const previousValue = getLatestValue(previous);
  const latestValue = getLatestValue(current);

  const delta = latestValue - previousValue;
  const theme = getThemeFromDelta(delta, true);

  return (
    <Card>
      <CardSection className="justify-between items-start">
        <CardLabelWithTooltip
          label="Queued jobs waiting"
          tooltip={{
            title: `Jobs waiting to start · ${getTimePeriodString(TimePeriod.Hour)}`,
            description: "Shows peak queue depth and volume for the time period.",
          }}
        />
        {!!delta && (
          <CardDelta formattedValue={Math.abs(delta)} value={delta} color={theme.color} />
        )}
      </CardSection>

      <CardSection>
        <CardStat label="Waiting" value={latestValue} size="lg" layout="stacked" />

        <CardSection className="flex-col">
          <CardStat label="Min" value={calculateMin(current)} />
          <CardStat label="Max" value={calculateMax(current)} />
        </CardSection>
      </CardSection>

      <CardChart>
        <SparkLineChart data={dataForSparkline(previous, current, "y", theme)} />
      </CardChart>
    </Card>
  );
}

export function QueueJobsWaitingDetail({
  datasets,
  timePeriod,
  scope,
}: {
  scope: Scope;
  timePeriod: TimePeriod;
  datasets: ChartDataset<"line", DataPoint[]>[];
}) {
  const { chartRef, updateData } = useChart<"line", DataPoint>();

  const data = useChartData({
    datasets: datasets,
    orderDatasetBy: ({ data }) => calculateMax(data),
    orderDirection: "asc",
  });

  useEffect(() => {
    updateData(data);
  }, [data, updateData]);

  const totalMin = min(datasets.map(({ data }) => calculateMin(data)));
  const totalMax = max(datasets.map(({ data }) => calculateMax(data)));

  return (
    <Card className="lg:flex-row">
      <div className="flex flex-col grow gap-3 min-w-0">
        <CardSection>
          <CardLabelWithTooltip
            label="Queue jobs waiting"
            description={`Jobs awaiting assignment · ${getTimePeriodString(timePeriod)}`}
            tooltip={{
              title: "Queue Jobs Waiting",
              description:
                "Shows peak queue depth for a time period and volume trends by cluster. Sustained spikes indicate agent availability constraints. Brief spikes that resolve themselves reveal high job-activity periods with matching agent availability.",
            }}
          />

          <CardSection className="flex-col">
            <CardStat value={totalMin.toLocaleString()} label="Min" />
            <CardStat value={totalMax.toLocaleString()} label="Max" />
          </CardSection>
        </CardSection>

        <CardChart>
          <LineChart
            data={data}
            ref={chartRef}
            options={chartOptionsForScope(scope, {
              [Scope.Organization]: stackedChart,
              [Scope.Cluster]: stackedChart,
            })}
            tooltip={ChartTooltip}
          />
        </CardChart>
      </div>

      <ChartLegend
        reverse={true}
        labelHeader={labelHeader(scope, "Queue")}
        datasets={data.datasets}
        chartRef={chartRef}
        columns={{
          Waiting: ({ data }) => getLatestValue(data).toLocaleString(),
          Min: ({ data }) => calculateMin(data).toLocaleString(),
          Max: ({ data }) => calculateMax(data).toLocaleString(),
        }}
      />
    </Card>
  );
}

const ChartTooltip = ({ tooltip }: { tooltip: TooltipModel<"line"> }) => {
  const sumJobsWaiting = calculateSum(tooltip.dataPoints.map(({ parsed }) => parsed));

  const visibleDataPoints = take(reverse(tooltip.dataPoints), 8);
  const hiddenDataPoints = tooltip.dataPoints.length - 8;

  return (
    <Tooltip tooltip={tooltip}>
      {visibleDataPoints.map(({ dataset, parsed }) => (
        <Fragment key={dataset.label}>
          <TooltipLabel color={dataset.borderColor as string}>{dataset.label}</TooltipLabel>
          <TooltipValue>{parsed.y}</TooltipValue>
        </Fragment>
      ))}

      <TooltipLabel>Total</TooltipLabel>
      <TooltipValue>{sumJobsWaiting}</TooltipValue>

      {hiddenDataPoints > 0 && (
        <span className="col-span-2 text-[11px] font-medium text-charcoal-300">
          ({hiddenDataPoints} not shown)
        </span>
      )}
    </Tooltip>
  );
};

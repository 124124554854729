import Button from "app/components/shared/Button";
import FormInputHelp from "app/components/shared/FormInputHelp";
import AssetUploader, { AssetUploaderError, AssetUploaderFile } from "app/lib/AssetUploader";
import MarkdownEditor from "app/lib/MarkdownEditor";
import Icon from "app/components/shared/Icon";
import autosize from "autosize";
import classNames from "classnames";
import React from "react";

import bold from "./icons/bold.svg";
import code from "./icons/code.svg";
import italics from "./icons/italics.svg";
import link from "./icons/link.svg";
import ol from "./icons/ol.svg";
import quote from "./icons/quote.svg";
import ul from "./icons/ul.svg";

const FormattingButton = ({ icon, className = "", ...props }) => (
  <Button
    type="button"
    tabIndex={-1}
    theme="default"
    className={classNames("px-2 py-1", className)}
    {...props}
  >
    <img src={icon} />
  </Button>
);

type Props = {
  id: string | null | undefined;
  name: string | null | undefined;
  value: string | null | undefined;
  placeholder: string | null | undefined;
  rows: number | null | undefined;
  onChange: () => unknown | null | undefined;
};

type State = {
  draggingFile: boolean;
  focused: boolean;
  error?: string | null | undefined;
};

class FormMarkdownEditorField extends React.PureComponent<Props, State> {
  textarea: HTMLTextAreaElement | null | undefined;
  // @ts-expect-error - TS2564 - Property 'markdownEditor' has no initializer and is not definitely assigned in the constructor.
  markdownEditor: MarkdownEditor;
  // @ts-expect-error - TS2564 - Property 'assetUploader' has no initializer and is not definitely assigned in the constructor.
  assetUploader: AssetUploader;

  state = {
    draggingFile: false,
    focused: false,
  };

  componentDidMount() {
    if (this.textarea) {
      this.markdownEditor = new MarkdownEditor(this.textarea);
      autosize(this.textarea);
    }

    this.assetUploader = new AssetUploader({
      onAssetUploaded: this.handleAssetUploaded,
      onError: this.handleAssetUploadError,
    });
  }

  componentWillUnmount() {
    autosize.destroy(this.textarea);

    // @ts-expect-error - TS2790 - The operand of a 'delete' operator must be optional.
    delete this.markdownEditor;
    // @ts-expect-error - TS2790 - The operand of a 'delete' operator must be optional.
    delete this.assetUploader;
  }

  render() {
    const containerClasses = classNames({
      "has-success": this.state.draggingFile,
    });
    let errorNode;

    // @ts-expect-error - TS2339 - Property 'error' does not exist on type '{ draggingFile: boolean; focused: boolean; }'.
    if (this.state.error) {
      errorNode = (
        <div className="mt-2 mb-2 border border-red p-2 red rounded flex justify-between">
          <div>
            <Icon icon="heroicons/16/solid/exclamation-triangle" className="h-4 mr-1" />
            {/* @ts-expect-error - TS2339 - Property 'error' does not exist on type '{ draggingFile: boolean; focused: boolean; }'. */}
            {this.state.error}
          </div>
          <button className="btn m-0 p-0 self-start" onClick={this.handleErrorDismissClick}>
            <Icon icon="heroicons/16/solid/x-mark" className="h-3 w-3 align-top" />
          </button>
        </div>
      );
    }

    return (
      <div className={containerClasses}>
        <div className="mb-2">
          <FormattingButton
            title="Bold"
            icon={bold}
            className="mr-1"
            onClick={this.handleBoldButtonClick}
          />
          <FormattingButton
            title="Italics"
            icon={italics}
            className="mr-3"
            onClick={this.handleItalicButtonClick}
          />

          <FormattingButton
            title="Quote"
            icon={quote}
            className="mr-1"
            onClick={this.handleQuoteButtonClick}
          />
          <FormattingButton
            title="Code block"
            icon={code}
            className="mr-1"
            onClick={this.handleCodeButtonClick}
          />
          <FormattingButton
            title="Link"
            icon={link}
            className="mr-3"
            onClick={this.handleLinkButtonClick}
          />

          <FormattingButton
            title="Unordered list"
            icon={ul}
            className="mr-1"
            onClick={this.handleBulletedListButtonClick}
          />
          <FormattingButton
            title="Ordered list"
            icon={ol}
            className="mr-1"
            onClick={this.handleNumberedListButtonClick}
          />
        </div>
        {errorNode}
        <textarea
          // @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'string | undefined'.
          id={this.props.id}
          // @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'string | undefined'.
          name={this.props.name}
          // @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'string | undefined'.
          placeholder={this.props.placeholder}
          // @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'string | number | readonly string[] | undefined'.
          defaultValue={this.props.value}
          // @ts-expect-error - TS2322 - Type 'number | null | undefined' is not assignable to type 'number | undefined'.
          rows={this.props.rows}
          onFocus={this.handleOnFocus}
          onBlur={this.handleOnBlur}
          onChange={this.handleOnChange}
          onPaste={this.handleOnPaste}
          onDragEnter={this.handleOnDragEnter}
          onDragOver={this.handleOnDragOver}
          onDragLeave={this.handleOnDragLeave}
          onDrop={this.handleOnDrop}
          ref={(textarea) => (this.textarea = textarea)}
          style={{ overflowY: "hidden", resize: "vertical" }}
          className="input"
        />
        <FormInputHelp>Insert images by dragging & dropping or pasting them above.</FormInputHelp>
      </div>
    );
  }

  _uploading(files: Array<AssetUploaderFile>) {
    // Were there any files uploaded in this event?
    if (files.length > 0) {
      // Insert each of the files into the textarea
      files.forEach((file) => {
        const prefix = file.type.indexOf("image/") === 0 ? "!" : "";
        let text = prefix + "[Uploading " + file.name + "...]()";

        // If the input is currently in focus, insert the image where the users
        // cursor is.
        if (this.state.focused) {
          // If we're inserting the image at the end of a line with text, add a
          // new line before the insertion so it goes onto a new line.
          if (!this.markdownEditor.isCursorOnNewLine()) {
            text = "\n" + text;
          } else {
            text = text + "\n";
          }

          this.markdownEditor.insert(text);
        } else {
          // If there's something already in the textrea, add a new line and
          // add it to the bottom of the text.
          if (this.textarea && this.textarea.value.length > 0) {
            text = "\n" + text;
          }
          this.markdownEditor.append(text);
        }
      });

      this._changed();
    }
  }

  _changed() {
    autosize.update(this.textarea);
    if (this.props.onChange) {
      this.props.onChange();
    }
  }

  handleAssetUploaded = (file: AssetUploaderFile, { url }: any) => {
    // Replace the "uploading..." version of the file with the correct encoded URL
    this.markdownEditor.replace(
      "[Uploading " + file.name + "...]()",
      "[" + file.name + "](" + encodeURI(url) + ")",
    );
    this._changed();
  };

  handleAssetUploadError = (exception: AssetUploaderError) => {
    this.setState({ error: exception.message });
  };

  handleErrorDismissClick = (evt: React.MouseEvent<HTMLElement>) => {
    evt.preventDefault();

    this.setState({ error: null });
  };

  handleBoldButtonClick = (evt: React.MouseEvent<HTMLElement>) => {
    evt.preventDefault();

    this.markdownEditor.bold();
    this._changed();
    this.textarea && this.textarea.focus();
  };

  handleItalicButtonClick = (evt: React.MouseEvent<HTMLElement>) => {
    evt.preventDefault();

    this.markdownEditor.italic();
    this._changed();
    this.textarea && this.textarea.focus();
  };

  handleQuoteButtonClick = (evt: React.MouseEvent<HTMLElement>) => {
    evt.preventDefault();

    this.markdownEditor.quote();
    this._changed();
    this.textarea && this.textarea.focus();
  };

  handleNumberedListButtonClick = (evt: React.MouseEvent<HTMLElement>) => {
    evt.preventDefault();

    this.markdownEditor.numberedList();
    this._changed();
    this.textarea && this.textarea.focus();
  };

  handleBulletedListButtonClick = (evt: React.MouseEvent<HTMLElement>) => {
    evt.preventDefault();

    this.markdownEditor.bulletedList();
    this._changed();
    this.textarea && this.textarea.focus();
  };

  handleCodeButtonClick = (evt: React.MouseEvent<HTMLElement>) => {
    evt.preventDefault();

    this.markdownEditor.code();
    this._changed();
    this.textarea && this.textarea.focus();
  };

  handleLinkButtonClick = (evt: React.MouseEvent<HTMLElement>) => {
    evt.preventDefault();

    this.markdownEditor.link();
    this._changed();
    this.textarea && this.textarea.focus();
  };

  handleOnDragEnter = (evt: React.DragEvent<HTMLTextAreaElement>) => {
    if (this.assetUploader.doesEventContainFiles(evt)) {
      this.setState({ draggingFile: true });
    }
  };

  handleOnDragOver = (evt: React.DragEvent<HTMLTextAreaElement>) => {
    // When you drag a file over a text area, the default browser behaviour
    // will show an insert caret at the cursor postition. Since there's no way
    // to get that caret, it doesn't make sense to show it, and then have to
    // insert the image at the end of the text area. So we'll just turn that
    // behaviour off.
    evt.preventDefault();
  };

  handleOnDragLeave = () => {
    // We don't really need to check if there were files in the drag, we can
    // just turn off the state.
    this.setState({ draggingFile: false });
  };

  handleOnDrop = (evt: React.DragEvent<HTMLTextAreaElement>) => {
    // Drag leave won't fire on a drop, so we need to switch the state here
    // manually.
    this.setState({ draggingFile: false });

    const files = this.assetUploader.uploadFromDropEvent(evt);

    if (files.length > 0) {
      // Since we've caught these files, we don't want the browser redirecting
      // to the file's location on the filesystem
      evt.preventDefault();
    }

    this._uploading(files);
  };

  handleOnPaste = (evt: React.ClipboardEvent<HTMLTextAreaElement>) => {
    const files = this.assetUploader.uploadFromPasteEvent(evt);

    if (files.length > 0) {
      // Since we've caught these files, we don't want the browser redirecting
      // to the file's location on the filesystem
      evt.preventDefault();
    }

    this._uploading(files);
  };

  handleOnChange = () => {
    autosize.update(this.textarea);
  };

  handleOnFocus = () => {
    this.setState({ focused: true });
  };

  handleOnBlur = () => {
    this.setState({ focused: false });
  };
}

export default FormMarkdownEditorField;

import classNames from "classnames";
import PropTypes from "prop-types";
import { PureComponent } from "react";
import { createFragmentContainer, graphql } from "react-relay";

import UserAvatar from "./UserAvatar";

class User extends PureComponent {
  static propTypes = {
    user: PropTypes.shape({
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      avatar: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    className: PropTypes.string,
    inheritHoverColor: PropTypes.bool.isRequired,
    style: PropTypes.object,
  };

  static defaultProps = {
    inheritHoverColor: false,
  };

  render() {
    return (
      <div className={classNames("flex", this.props.className)} style={this.props.style}>
        <div key="avatar" className="flex-none icon-mr" style={{ width: 39, height: 39 }}>
          <UserAvatar
            user={this.props.user}
            className="max-w-full"
            style={{ width: 38, height: 38 }}
            suppressAltText={true}
          />
        </div>
        <div key="text" className="flex-auto min-w-0 flex flex-col overflow-hidden justify-center">
          <strong className="truncate max-w-full semi-bold" title={this.props.user.name}>
            {this.props.user.name}
          </strong>
          <small
            className={classNames("truncate max-w-full dark-gray", {
              "hover-color-inherit": this.props.inheritHoverColor,
            })}
            title={this.props.user.email}
          >
            {this.props.user.email}
          </small>
        </div>
      </div>
    );
  }
}

export default createFragmentContainer(User, {
  user: graphql`
    fragment User_user on User {
      name
      email
      avatar {
        url
      }
    }
  `,
});

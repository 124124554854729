import * as React from "react";
import classNames from "classnames";

type Props = {
  children: React.ReactNode;
  className?: string;
};

export default class Header extends React.PureComponent<Props> {
  render() {
    return (
      <h2
        className={classNames(
          "text-sm m-0 bg-silver py-2 px-4 semi-bold leading-normal",
          this.props.className,
        )}
      >
        {this.props.children}
      </h2>
    );
  }
}

import { createFragmentContainer, graphql } from "react-relay";
import AgentBadge from "app/components/AgentBadge";
import JobEvent from "../JobEvent";

type Props = {
  event: any;
  previousTimestamp: string | null | undefined;
};

function JobEventAssigned({ event, previousTimestamp }: Props) {
  let description;

  if (event.actor.type === "AGENT") {
    description = (
      <JobEvent.Description>
        The job was assigned to this agent because the agent explicitly{" "}
        <a href="https://buildkite.com/docs/agent/v3/cli-start#acquire-job">acquired</a> the job.
      </JobEvent.Description>
    );
  }

  return (
    <JobEvent timestamp={event.timestamp} previousTimestamp={previousTimestamp}>
      <JobEvent.Label>
        {event.actor.type === "AGENT" ? (
          <>
            <AgentBadge agent={event.assignedAgent} /> Assigned Job
          </>
        ) : (
          <>
            Dispatcher Assigned Job to <AgentBadge agent={event.assignedAgent} />
          </>
        )}
      </JobEvent.Label>
      {description}
    </JobEvent>
  );
}

export default createFragmentContainer(JobEventAssigned, {
  event: graphql`
    fragment JobEventAssigned_event on JobEvent {
      timestamp
      ... on JobEventAssigned {
        assignedAgent {
          ...AgentBadge_agent
        }
      }
      actor {
        type
      }
    }
  `,
});

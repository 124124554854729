import { default as BuildsStore, Query } from "app/stores/BuildsStore";

import cable from "app/lib/cable";
import Database from "app/lib/Database";
import { Build } from "app/components/build/Show/lib/types";

type Project = {
  id: string;
};

export default class PipelineBuildsStore extends BuildsStore {
  project: Project;

  constructor(project: Project, builds: Array<Build>, query: Query) {
    // Call super on the parent store
    super(builds, query);

    // Initialize the project
    this.project = Database.parse(project);

    // Handle new builds (but only if we're on the first page of the
    // builds list)
    if (!this.query.page || this.query.page === 1) {
      // @ts-expect-error - TS2339 - Property 'pipelineSubscription' does not exist on type 'PipelineBuildsStore'.
      this.pipelineSubscription = cable.subscriptions.create(
        { channel: "Pipelines::PipelineChannel", uuid: this.project.id },
        {
          store: this,

          received({ event }) {
            if (event === "build:created") {
              // IMPORTANT: Since new build might not match filters, refresh the whole set of builds if we notice a new build.
              this.store.reload();
            }
          },
        },
      );
    }
  }
}

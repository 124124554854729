import * as React from "react";

import Icon from "app/components/shared/Icon";

type Props = {
  value: string | null | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void | null | undefined;
};

export default class SearchInput extends React.PureComponent<Props> {
  render() {
    return (
      <div className="relative flex flex-auto min-w-0">
        <Icon
          icon="search"
          className="dark-gray absolute"
          style={{ width: 14, height: 14, left: 11, top: 10 }}
        />

        <input
          type="text"
          className="input"
          style={{ paddingLeft: 28 }}
          onChange={this.handleInputChange}
          // @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'string | number | readonly string[] | undefined'.
          value={this.props.value}
          placeholder="Search by agent query rules…"
          spellCheck={false}
          data-testid="job-explorer-search-input"
        />
      </div>
    );
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange && this.props.onChange(event);
  };
}

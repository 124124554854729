import classNames from "classnames";
import { isTerminalBuildState, BuildStates } from "app/constants/BuildStates";
import { useBuild } from "app/components/build/Show/lib/BuildContext";

interface Props {
  job: {
    missingDependencies: string[];
    groupUuid: string | null | undefined;
  };
  build: {
    state: BuildStates;
  };
}

export const MissingDependenciesNode = (props: Props) => {
  const { store } = useBuild();

  if (!store) {
    throw new Error("Missing build context");
  }

  const missingGroupDependencies = props.job.groupUuid
    ? store.steps.get(props.job.groupUuid)?.missingDependencies || []
    : [];

  if (!props.job.missingDependencies?.length && !missingGroupDependencies?.length) {
    return null;
  }

  const isBuildTerminal = isTerminalBuildState(props.build.state);
  const missingDependenciesMessage =
    missingGroupDependencies?.length > 0
      ? "Group step dependencies not found"
      : "Step dependencies not found";

  return (
    <div
      className={classNames("job-stats flex-none max-md:sr-only ", {
        "!text-orange-800 bg-orange-500/[0.18] px-1 rounded": !isBuildTerminal,
      })}
    >
      <span>
        {isBuildTerminal ? missingDependenciesMessage : "Waiting on unresolved dependencies"}
      </span>
    </div>
  );
};

import classNames from "classnames";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from "app/components/shared/DropdownMenu";
import Icon from "app/components/shared/Icon";
import { useTrack } from "app/components/build/Show/lib/useTrack";

import { DisplayMode, useFilterStore } from "./useFilterStore";

const options = {
  [DisplayMode.State]: "States",
  [DisplayMode.Pipeline]: "Pipeline",
};

export default function DisplayModeDropdown() {
  const [displayMode, setDisplayMode] = useFilterStore((state) => [
    state.displayMode,
    state.setDisplayMode,
  ]);
  const track = useTrack();

  return (
    <DropdownMenu>
      <div className="flex">
        <DropdownMenuTrigger asChild={true}>
          <button
            data-testid="display-mode-dropdown"
            className={classNames(
              "group rounded-md inline-flex items-center px-2 py-1 space-x-1 self-stretch text-charcoal-700 hover:bg-purple-100 hover:text-purple-600 transition-colors max-w-full focus:z-10",
            )}
          >
            <Icon icon="custom/outline/group" className="w-3 h-3 shrink-0" />

            {displayMode && (
              <span className="truncate max-w-full">
                <span>Display: </span>
                <span className="font-medium">{options[displayMode]}</span>
              </span>
            )}

            {!displayMode && <span className="truncate max-w-full">Display</span>}

            <Icon icon="down-triangle" className="flex-none" style={{ width: 7, height: 7 }} />
          </button>
        </DropdownMenuTrigger>
      </div>
      <DropdownMenuPortal>
        <DropdownMenuContent
          align="start"
          alignOffset={1}
          data-testid="display-mode-dropdown-content"
        >
          {Object.values(DisplayMode).map((option) => (
            <DropdownMenuItem
              id={option}
              key={option}
              asChild={true}
              active={displayMode === option}
            >
              <button
                onClick={() => {
                  track(`Group Sidebar Steps`, { group_by: option });
                  setDisplayMode(option);
                }}
              >
                {options[option]}
              </button>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu>
  );
}

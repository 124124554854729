import { Fragment } from "react";
import PropTypes from "prop-types";
import createReactClass from "create-react-class";
import isEqual from "lodash/isEqual";
import debounce from "lodash/debounce";

import RemoteButtonComponent from "app/components/shared/RemoteButtonComponent";
import Icon from "app/components/shared/Icon";

import JobInformationStore from "app/stores/JobInformationStore";

import cable from "app/lib/cable";
import Database from "app/lib/Database";

import FriendlyTime from "app/components/shared/FriendlyTime";

/* eslint-disable react/prefer-es6-class */
// TODO: Move to a class

export default createReactClass({
  displayName: "JobArtifacts",

  propTypes: {
    job: PropTypes.shape({
      id: PropTypes.string.isRequired,
      basePath: PropTypes.string.isRequired,
    }).isRequired,
  },

  _getFromStore() {
    return Database.parse(JobInformationStore.get(this.props.job.id, "artifacts"));
  },

  _getCountFromStore() {
    return Database.parse(JobInformationStore.get(this.props.job.id, "artifactsCount"));
  },

  reload: debounce(
    function () {
      return JobInformationStore.refetch(
        this.props.job.id,
        "artifacts",
        this.props.job.basePath + "/artifacts",
      );
    },
    2000,
    { leading: true, maxWait: 4000 },
  ),

  _setStoreData(artifacts) {
    return JobInformationStore.set(this.props.job.id, "artifacts", artifacts);
  },

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props, nextProps) || !isEqual(this.state.artifacts, nextState.artifacts);
  },

  getInitialState() {
    return {
      artifacts: this._getFromStore(),
      count: this._getCountFromStore(),
    };
  },

  componentDidMount() {
    JobInformationStore.addChangeListener(this._onStoreChange);

    this.subscription = cable.subscriptions.create(
      { channel: "Pipelines::JobChannel", uuid: this.props.job.id },
      {
        component: this,

        received({ event }) {
          if (event === "artifacts:changed") {
            this.component.reload();
          }
        },
      },
    );

    this.reload();
  },

  componentWillUnmount() {
    JobInformationStore.removeChangeListener(this._onStoreChange);

    this.subscription.unsubscribe();
  },

  artifactListNodes() {
    return this.state.artifacts
      .toSorted((left, right) => left.path.localeCompare(right.path))
      .map((artifact, index, list) => {
        const checksum = artifact.sha256sum || artifact.sha1sum;
        const checksumNode = checksum && (
          <small className="flex-auto min-w-0 text-right text-muted overflow-hidden overflow-ellipsis">
            {checksum}
          </small>
        );

        const isLastItem = index === list.length - 1;
        const isTruncated = this.state.artifacts.length < this.state.count;

        const paddingClass = index === 0 ? "pb-2" : isLastItem && !isTruncated ? "pt-2" : "py-2";

        const borderClass =
          !isLastItem || (isLastItem && isTruncated) ? " border-b border-gray" : "";

        // deleted artifacts aren't returned by the API, but it probably makes
        // sense to keep this guard just in case.
        if (artifact.state === "deleted") {
          return (
            <div key={artifact.id} className={`${paddingClass}${borderClass}`}>
              This artifact has been deleted.
            </div>
          );
        }

        return (
          <Fragment key={artifact.id}>
            <div
              className={`flex items-start px-1 ${paddingClass}${borderClass} ${artifact.state}`}
            >
              <div className="items-center flex-none mr-3">{this.renderArtifactIcon(artifact)}</div>
              <div className="flex-auto min-w-0 flex flex-col">
                <div className="flex-auto min-w-0 flex flex-wrap items-center">
                  {this.renderArtifactLink(artifact)}
                  <small className="text-muted">{artifact.fileSize}</small>
                </div>
                <div className="flex-auto min-w-0 flex flex-wrap items-center">
                  <small className="text-muted">{artifact.mimeType}</small>
                  {checksumNode}
                </div>
              </div>
              <div className="items-center flex-none ml-3">{this.renderDeleteButton(artifact)}</div>
            </div>
            {isLastItem && isTruncated ? this.renderTruncatedMessage() : null}
          </Fragment>
        );
      });
  },

  renderTruncatedMessage() {
    return (
      <div className="px-1 pt-4">
        <strong>Need more than 1,000? </strong>
        To show all {this.state.count} artifacts, please use{" "}
        <a
          href="/docs/apis/rest-api/artifacts"
          className="lime hover-lime no-underline hover-underline"
          target="_blank"
        >
          our API
        </a>
      </div>
    );
  },

  renderArtifactIcon(artifact) {
    if (artifact.state === "finished") {
      return <Icon icon="heroicons/outline/document" className="h-4 w-4" />;
    } else if (artifact.state === "error") {
      return <Icon icon="heroicons/outline/document" className="h-4 w-4 red" />;
    }

    return <Icon icon="heroicons/outline/document" className="h-4 w-4 dark-grey" />;
  },

  renderArtifactLink(artifact) {
    if (artifact.state === "finished") {
      return (
        <a
          href={artifact.url}
          title={artifact.path}
          className="flex-auto min-w-0 flex items-center overflow-hidden overflow-ellipsis"
          target="_blank"
          rel="noopener noreferrer"
        >
          {artifact.path}
        </a>
      );
    } else if (artifact.state === "error") {
      return (
        <span className="flex-auto min-w-0 flex items-center red overflow-hidden overflow-ellipsis">
          {`${artifact.path} (failed to upload, see log for details)`}
        </span>
      );
    } else if (artifact.state === "new") {
      return (
        <span className="flex-auto min-w-0 flex items-center dark-gray overflow-hidden overflow-ellipsis">
          {`${artifact.path} (uploading…)`}
        </span>
      );
    } else if (artifact.state === "expired") {
      return (
        <span className="flex-auto min-w-0 flex items-center dark-gray overflow-hidden overflow-ellipsis">
          {artifact.path} (expired&nbsp;{" "}
          <FriendlyTime value={artifact.expiresAt} capitalized={false} />)
        </span>
      );
    }
  },

  renderDeleteButton(artifact) {
    if (artifact.state === "finished" && artifact.canDeleteArtifact.allowed) {
      const confirmText = artifact.selfHosted
        ? "As this file is not hosted by Buildkite, deleting it will remove the record from our database, but the file will remain where it was uploaded to. Are you sure you want to delete it?"
        : "Deleting this artifact will remove it from Buildkite and the file will be permanently deleted from our systems. This action cannot be undone. Are you sure you want to delete it?";

      return (
        <RemoteButtonComponent
          url={this.props.job.basePath + `/artifacts/${artifact.id}`}
          method="delete"
          confirmText={confirmText}
          onSuccess={this.handleArtifactDeleteSuccess}
          tooltipText="Delete"
          className="text-center dark-gray"
        >
          <Icon icon="heroicons/outline/trash" className="h-4 w-4 hover-black" />
        </RemoteButtonComponent>
      );
    }
  },

  handleArtifactDeleteSuccess(event, data) {
    return this._setStoreData(JSON.parse(data));
  },

  render() {
    return (
      <div data-testid="JobArtifactsComponent" className="mt-[10px] artifacts-list pb-2">
        {this.state.artifacts && this.state.artifacts.length ? (
          <div className="artifacts-table">{this.artifactListNodes()}</div>
        ) : this.state.artifacts && this.state.artifacts.length === 0 ? (
          <p className="m-0">There are no artifacts for this job.</p>
        ) : (
          <span>Loading…</span>
        )}
      </div>
    );
  },

  _onStoreChange() {
    return this.setState({
      artifacts: this._getFromStore(),
      count: this._getCountFromStore(),
    });
  },

  _onWebsocketEvent({ jobID }) {
    if (jobID === this.props.job.id) {
      this.reload();
    }
  },
});

import * as React from "react";
import classNames from "classnames";

import Icon from "app/components/shared/Icon";

type Props = {
  children: React.ReactNode;
  to?: string;
  href?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  className?: string;
};

export default class RowLink extends React.PureComponent<Props> {
  static displayName = "Panel.RowLink";

  render() {
    if (this.props.to) {
      return (
        <a
          href={this.props.to}
          className={classNames(
            "btn p-4 flex items-center hover-bg-silver hover-black focus-black",
            this.props.className,
          )}
        >
          <div className="flex-auto min-w-0">{this.props.children}</div>
          <Icon
            icon="chevron-right"
            className="flex-none dark-gray"
            style={{ height: 15, width: 15 }}
          />
        </a>
      );
    }

    return (
      <a
        href={this.props.href}
        onClick={this.props.onClick}
        className={classNames(
          "btn py-2 px-4 flex items-center hover-bg-silver hover-black focus-black",
          this.props.className,
        )}
      >
        <div className="flex-auto min-w-0">{this.props.children}</div>
        <Icon
          icon="chevron-right"
          className="flex-none dark-gray"
          style={{ height: 15, width: 15 }}
        />
      </a>
    );
  }
}

import { getCssValue } from "app/lib/cssValues";
import AnchoredPopover from "./Popover/anchored";
import Chart from "./Chart";
import Icon from "./Icon";

const CHART_SIZE = 40;

function formatPercentage(value: number, total: number) {
  return `${total && Math.round((value / total) * 100)}%`;
}

export default function ClusterQueueMetricsAgentsChart({
  busyAgentsCount,
  idleAgentsCount,
  connectedAgentsCount,
  pausedAgentsCount,
}: {
  busyAgentsCount: number;
  idleAgentsCount: number;
  connectedAgentsCount: number;
  pausedAgentsCount: number;
}) {
  const busyAgentPercentage = formatPercentage(busyAgentsCount, connectedAgentsCount);
  const idleAgentPercentage = formatPercentage(idleAgentsCount, connectedAgentsCount);
  const pausedAgentPercentage = formatPercentage(pausedAgentsCount, connectedAgentsCount);

  if (!connectedAgentsCount || (!busyAgentsCount && !idleAgentsCount && !pausedAgentsCount)) {
    return null;
  }

  return (
    <div
      className="relative"
      style={{ width: CHART_SIZE, height: CHART_SIZE }}
      id="cluster-queue-metrics-agents-chart"
    >
      <AnchoredPopover position="absolute" id="cluster-queue-metrics-agents-chart-popover">
        <Chart
          height={CHART_SIZE}
          width={CHART_SIZE}
          chartOptions={{
            type: "doughnut",
            data: {
              datasets: [
                {
                  data: [busyAgentsCount, idleAgentsCount, pausedAgentsCount],
                  backgroundColor: [
                    getCssValue("--blue-500"),
                    getCssValue("--slate-500"),
                    getCssValue("--yellow-500"),
                  ],
                },
              ],
            },
            options: {
              devicePixelRatio: Math.max(window.devicePixelRatio, 2),
              responsive: true,
              maintainAspectRatio: false,
              cutout: "60%",
              borderAlign: "inner",
              borderColor: "white",
              borderRadius: 10,
              borderWidth: 1,
              animation: {
                animateRotate: false,
              },
              hover: {
                mode: null,
              },
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: false,
                },
              },
            },
          }}
        />

        <div className="p5 divide-y">
          <p className="m-0 semi-bold">Agent Utilization</p>

          <div className="flex flex-col gap2">
            <p className="m-0 flex items-center gap1.5">
              <Icon icon="donut" className="blue-500" style={{ height: 10, width: 10 }} />
              <span>
                <strong>{busyAgentPercentage}</strong> In use
              </span>
            </p>

            <p className="m-0 flex items-center gap1.5">
              <Icon icon="donut" className="slate-500" style={{ height: 10, width: 10 }} />
              <span>
                <strong>{idleAgentPercentage}</strong> Idle
              </span>
            </p>

            {window.Features.AgentPausing && (
              <p className="m-0 flex items-center gap1.5">
                <Icon icon="donut" className="yellow-500" style={{ height: 10, width: 10 }} />
                <span>
                  <strong>{pausedAgentPercentage}</strong> Paused
                </span>
              </p>
            )}
          </div>
        </div>
      </AnchoredPopover>
    </div>
  );
}

import Icon from "app/components/shared/Icon";
import { Step } from "app/lib/pipeline";
import { StepLink } from "./StepLink";
import { resolveStepUrl } from "app/components/build/Show/lib/urlForView";
import useCurrentJobOrStep from "app/components/build/Show/lib/useCurrentJobOrStep";

interface Props {
  step: Step;
  depth?: number;
}

export function WaitStepListItem({ step, depth = 0 }: Props) {
  const { currentStep } = useCurrentJobOrStep();

  return (
    <StepLink
      to={resolveStepUrl(step.uuid)}
      type={step.type}
      className="justify-center py-1.5"
      active={step.uuid === currentStep?.uuid}
      depth={depth}
    >
      <Icon icon="heroicons/outline/chevron-down" className="h-4 w-4" />
    </StepLink>
  );
}

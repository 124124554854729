import Metadata, { MetadataType } from "app/components/analytics/shared/Metadata";
import MetadataCard, { MetadataCardType } from "./MetadataCard";
import { Metrics } from "app/components/analytics/shared/MetricCards";
import FailureReason from "app/components/analytics/shared/FailureReason";
import useFetch from "use-http";
import Card from "../../shared/MetricCards/Card";
import { Execution } from "../../shared/type";

export const SPAN_LABELS = ["Test execution duration", "Total SQL duration", "Total HTTP duration"];

type Props = {
  metadata: MetadataType;
  preloadedMetrics?: Metrics;
  metricsEndpoint: string;
  hasSpans: boolean;
  failedExecutionEndpoint: string;
  metadataCard: MetadataCardType;
  breakdown: Pick<Execution, "duration" | "failureReason">;
  repoUrl: string | null | undefined;
};

const Header = ({
  metadata,
  preloadedMetrics,
  metricsEndpoint,
  failedExecutionEndpoint,
  metadataCard,
  breakdown,
  hasSpans,
  repoUrl,
}: Props) => {
  const metricLabels = hasSpans ? SPAN_LABELS : [SPAN_LABELS[0]];
  const { loading, error, data = [] } = useFetch<Metrics>(metricsEndpoint, []);

  const getCardProps = (label: string) => {
    const preloaded = preloadedMetrics?.find((item) => item.label === label);
    if (preloaded) {
      return { label, value: preloaded.value };
    }

    if (error) {
      return { label, error: true, value: "-" };
    }

    const matchingMetric = data.find((item) => item.label === label);
    const value = matchingMetric?.value || "-";

    return { label, loading, value };
  };

  return (
    <div>
      <Metadata {...metadata} repoUrl={repoUrl} linkToBranchTag={true} />

      {breakdown.failureReason != null && (
        <div className="pb-4 w-full">
          <FailureReason
            failureReason={breakdown.failureReason}
            endpoint={failedExecutionEndpoint}
          />
        </div>
      )}

      <div className="flex flex-wrap gap-4 pb-4">
        {metricLabels.map((label) => {
          const cardProps = getCardProps(label);
          return (
            <div key={label} className="flex min-w-[100px] flex-auto">
              <Card {...cardProps} />
            </div>
          );
        })}

        <div className="min-w-[200px] flex-auto">
          <MetadataCard {...metadataCard} />
        </div>
      </div>
    </div>
  );
};

export default Header;

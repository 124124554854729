/* eslint-disable react/display-name */

import { useState } from "react";
import { commitMutation, createRefetchContainer, graphql } from "react-relay";

import AutocompleteDialog from "app/components/shared/Autocomplete/Dialog";
import Button from "app/components/shared/Button";
import Emojify from "app/components/shared/Emojify";
import permissions from "app/lib/permissions";
import Environment from "app/lib/relay/environment";
import FlashesStore from "app/stores/FlashesStore";

const Chooser = ({ team, relay, onChoose, disabled }) => {
  const [loading, setLoading] = useState(false);
  const [showingDialog, setShowingDialog] = useState(false);
  const [search, setSearch] = useState(null);

  const handleDialogOpen = () => {
    setLoading(true);

    relay.refetch(
      { teamSelector: `!${team.slug}`, registryAddSearch: "" },
      null,
      () => {
        setLoading(false);
      },
      { force: true },
    );

    setShowingDialog(true);
  };

  const handleDialogClose = () => {
    setShowingDialog(false);
    setSearch(null);
  };

  const handleRegistrySearch = (registryAddSearch) => {
    setSearch(registryAddSearch);

    relay.refetch(
      {
        teamID: team.id,
        registryAddSearch: registryAddSearch,
        teamSelector: `!${team.slug}`,
      },
      null,
      null,
      { force: true },
    );
  };

  const handleRegistrySelect = (registry) => {
    setShowingDialog(false);
    const environment = Environment.get();

    const input = { teamID: team.id, registryID: registry.id };
    commitMutation(environment, {
      mutation: graphql`
        mutation RegistriesChooserMutation($input: TeamRegistryCreateInput!) {
          teamRegistryCreate(input: $input) {
            clientMutationId
            team {
              id
              registries {
                count
              }
            }
          }
        }
      `,
      variables: { input },
      onCompleted: onChoose,
      onError: (error) => {
        FlashesStore.flash(FlashesStore.ERROR, error.message);
      },
    });
  };

  // Logic to render dropdown suggestion
  const renderAutoCompleteSuggestions = () => {
    // Render Loader if we're still loading or org has no registries has no edges.
    if (!team.organization?.registries || loading) {
      return [<AutocompleteDialog.Loader key="loading" />];
    }

    // If we have registries from the response, render them as suggestions
    if (team.organization.registries.edges.length > 0) {
      return team.organization.registries.edges.map(({ node }) => {
        return [
          <div key={node.id}>
            <strong className="truncate max-w-full semi-bold block" title={node.name}>
              <Emojify text={node.name} />
            </strong>
            <small className="truncate max-w-full dark-gray block" title={node.slug}>
              {node.slug}
            </small>
          </div>,
          node,
        ];
      });
    } else if (search !== "" && search !== null) {
      // If registries is empty but user has searched for a registry, render
      return [
        <AutocompleteDialog.ErrorMessage key="error">
          Could not find a package registry with name <em>{search}</em>
        </AutocompleteDialog.ErrorMessage>,
      ];
    } else if (search === null) {
      // User has not begun searching
      return [
        <AutocompleteDialog.ErrorMessage key="error">
          Search for a package registry
        </AutocompleteDialog.ErrorMessage>,
      ];
    }

    // If none of the above match - The user most likely hasn't searched anything and also has no registries.
    // i.e they have no registries in their org.
    return [
      <AutocompleteDialog.ErrorMessage key="error">
        Could not find any more registries to add
      </AutocompleteDialog.ErrorMessage>,
    ];
  };

  return permissions(team.permissions).check({
    allowed: "teamRegistryCreate",
    render: () => (
      <div>
        <Button className="mb-3" theme="primary" onClick={handleDialogOpen} disabled={disabled}>
          Add Registry
        </Button>
        <AutocompleteDialog
          isOpen={showingDialog}
          onRequestClose={handleDialogClose}
          onSearch={handleRegistrySearch}
          onSelect={handleRegistrySelect}
          items={renderAutoCompleteSuggestions()}
          placeholder="Search all registries…"
          selectLabel="Add"
          popover={false}
        />
      </div>
    ),
  });
};

const TeamsRegistriesChooserContainer = createRefetchContainer(
  Chooser,
  {
    team: graphql`
      fragment RegistriesChooser_team on Team
      @argumentDefinitions(
        registryAddSearch: { type: "String", defaultValue: "" }
        teamSelector: { type: "TeamSelector" }
      ) {
        id
        slug
        organization {
          registries(search: $registryAddSearch, first: 10, order: NAME, team: $teamSelector) {
            edges {
              node {
                id
                name
                slug
              }
            }
          }
        }

        permissions {
          teamRegistryCreate {
            allowed
          }
        }
      }
    `,
  },
  graphql`
    query RegistriesChooserQuery(
      $team: ID!
      $registryAddSearch: String
      $teamSelector: TeamSelector
    ) {
      team(slug: $team) {
        ...RegistriesChooser_team
          @arguments(registryAddSearch: $registryAddSearch, teamSelector: $teamSelector)
      }
    }
  `,
);

export default TeamsRegistriesChooserContainer;

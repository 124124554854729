import { PureComponent } from "react";
import { RelayProp, createFragmentContainer, graphql, commitMutation } from "react-relay";
import Button from "app/components/shared/Button";
import RecoveryCodeList from "app/components/RecoveryCodeList";

type Props = {
  relay: RelayProp;
  totp: any;
};

type State = {
  generatingNewCodes: boolean;
  newCodesAvailable: boolean;
};

class RecoveryCodes extends PureComponent<Props, State> {
  state = {
    generatingNewCodes: false,
    newCodesAvailable: false,
  };

  render() {
    let contents;
    if (this.state.newCodesAvailable) {
      contents = this.renderNewCodesSection();
    } else {
      contents = this.renderGenerateSection();
    }

    return <div className="p-5">{contents}</div>;
  }

  renderGenerateSection() {
    return (
      <>
        <h2 className="m-0 text-xl semi-bold mb-5">Generate New Recovery Codes</h2>
        <p>
          When you generate new recovery codes, your previous codes will no longer be valid. Please
          ensure you save a copy of your new recovery codes after they’ve been generated.
        </p>
        <Button
          className="w-full"
          theme="primary"
          onClick={this.handleRegenerateRecoveryCodes}
          // @ts-expect-error - TS2322 - Type 'false | "Generating New Recovery Codes…"' is not assignable to type 'boolean | undefined'.
          loading={this.state.generatingNewCodes && "Generating New Recovery Codes…"}
          disabled={this.state.generatingNewCodes}
        >
          Generate New Recovery Codes
        </Button>
      </>
    );
  }

  renderNewCodesSection() {
    return (
      <>
        <div className="mb-4 border green rounded border-green p-4">
          <div className="font-semibold">Here are your new recovery codes.</div>
          <div>
            Your previous codes have been removed. Please make sure you store them in a safe place
          </div>
        </div>

        <div className="mt-4">
          <RecoveryCodeList recoveryCodes={this.props.totp.recoveryCodes} />
        </div>
      </>
    );
  }

  handleRegenerateRecoveryCodes = () => {
    this.setState({ generatingNewCodes: true });

    commitMutation(this.props.relay.environment, {
      mutation: graphql`
        mutation RecoveryCodesRegenerateMutation($input: TOTPRecoveryCodesRegenerateInput!) {
          totpRecoveryCodesRegenerate(input: $input) {
            totp {
              id
              recoveryCodes {
                id
                codes {
                  code
                  consumed
                }
              }
            }
          }
        }
      `,
      variables: { input: { totpId: this.props.totp.id } },
      onCompleted: () => {
        this.setState({ newCodesAvailable: true });
      },
      onError: (error) => {
        alert(error);
      },
    });
  };
}

export default createFragmentContainer(RecoveryCodes, {
  totp: graphql`
    fragment RecoveryCodes_totp on TOTP {
      id
      recoveryCodes {
        id
        ...RecoveryCodeList_recoveryCodes
        codes {
          code
          consumed
        }
      }
    }
  `,
});

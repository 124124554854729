import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import Icon from "app/components/shared/Icon";
import styled from "styled-components";
import { twMerge } from "tailwind-merge";
import type { Build } from "app/components/build/Show/lib/types";
import { useTrack } from "app/components/build/Show/lib/useTrack";
import type { AnnotationStyles } from "app/components/build/Show/components/Annotation";

// Helper types and constants
type AnnotationType = Lowercase<AnnotationStyles>;

const TYPE_ORDER: readonly AnnotationType[] = [
  "error",
  "warning",
  "info",
  "success",
  "default",
] as const;

const VALID_ANNOTATION_TYPES = new Set(TYPE_ORDER);
const STACK_LAYER_BREAKPOINTS = [3, 10, 15, 16] as const;
const GROUPING_THRESHOLD = 3;

interface Annotation {
  type: AnnotationType;
  count: number;
}

interface GroupedAnnotation {
  count: number;
  annotations: Annotation[];
}

const TYPE_CONFIG: Record<
  AnnotationType,
  { color: string; icon?: string; iconClassName?: string }
> = {
  error: {
    color: "text-red-500 bg-red-100 ring-red-500",
    icon: "cross-tiny",
  },
  warning: {
    color: "text-orange-700 bg-orange-100 ring-orange-500",
    icon: "alert-tiny",
  },
  info: {
    color: "text-blue-600 bg-blue-100 ring-[#A3CCFF]",
    icon: "heroicons/outline/information-circle",
  },
  success: {
    color: "text-green-700 bg-green-100 ring-green-400",
    icon: "check-tiny",
  },
  default: {
    color: "text-charcoal-300 bg-gray-100 ring-gray-700",
    icon: "heroicons/outline/pencil",
    iconClassName: "h-[13px] w-[13px]",
  },
} as const;

type Props = Pick<Build, "annotationCountsByStyle" | "path">;

// Helper functions
const normalizeAnnotationType = (type: string): AnnotationType => {
  const normalizedType = type.toLowerCase() as AnnotationType;
  if (VALID_ANNOTATION_TYPES.has(normalizedType)) {
    return normalizedType;
  }
  return "default";
};

const calculateStackLayers = (errorCount: number): 0 | 1 | 2 | 3 => {
  if (errorCount <= STACK_LAYER_BREAKPOINTS[0]) {
    return 0;
  }
  if (errorCount <= STACK_LAYER_BREAKPOINTS[1]) {
    return 1;
  }
  if (errorCount <= STACK_LAYER_BREAKPOINTS[2]) {
    return 2;
  }
  return 3;
};

const groupAnnotations = (counts: Annotation[]) => {
  const errors = counts.filter((annotation) => annotation.type === "error");
  const warnings = counts.filter((annotation) => annotation.type === "warning");
  const others = counts.filter((annotation) => !["error", "warning"].includes(annotation.type));

  // If only one type of annotation, don't group
  if (counts.length === 1) {
    return counts;
  }

  const groups: (Annotation | GroupedAnnotation)[] = [...errors, ...warnings];
  const othersCount = others.reduce((sum, { count }) => sum + count, 0);

  if (others.length > 0) {
    // Only group others if:
    // 1. There are multiple types of others AND
    // 2. There are error/warning badges present AND
    // 3. The total count of others meets the threshold
    const shouldGroup =
      others.length > 1 &&
      (errors.length > 0 || warnings.length > 0) &&
      othersCount >= GROUPING_THRESHOLD;

    if (shouldGroup) {
      groups.push({
        count: othersCount,
        annotations: others,
      });
    } else {
      groups.push(...others);
    }
  }

  return groups;
};

const LAYER_CONFIGS = [
  {
    top: "-top-[0.2rem]",
    sides: "left-[0.35rem] right-[0.35rem]",
    ring: "ring-1 ring-red-500/40",
    zIndex: "z-[3]",
    transform:
      "group-hover:translate-y-[0.1rem] group-hover:scale-[0.98] group-hover:delay-[150ms]",
    opacity: "group-hover:opacity-0",
  },
  {
    top: "-top-[0.38rem]",
    sides: "left-[0.8rem] right-[0.8rem]",
    ring: "ring-1 ring-red-500/25",
    zIndex: "z-[2]",
    transform: "group-hover:translate-y-[0.4rem] group-hover:scale-[0.96] group-hover:delay-[75ms]",
    opacity: "group-hover:opacity-0",
  },
  {
    top: "-top-[0.55rem]",
    sides: "left-[1.25rem] right-[1.25rem]",
    ring: "ring-1 ring-red-500/10",
    zIndex: "z-[1]",
    transform: "group-hover:translate-y-[0.55rem] group-hover:scale-[0.94]",
    opacity: "group-hover:opacity-0",
  },
] as const;

const ACTIVE_ROUTE_STYLES = `
  bg-gray-50/90
  shadow-[inset_0_0_1px_rgba(0,0,0,0.05),0_0_0_1px_rgba(0,0,0,0.08)]
`;

const ERROR_STYLES = `
  bg-red-100 pl-3 ring-1 ring-red-500/25
  before:absolute before:left-0 before:top-0 before:w-1.5 before:h-full before:bg-red-500 before:rounded-l-lg hover:-translate-y-[1px]
  shadow-[inset_0_0_1px_rgba(220,38,38,0.18),0_0_0_1px_rgba(220,38,38,0.25)]
  hover:shadow-[inset_0_0_1px_rgba(220,38,38,0.18),0_0_0_1px_rgba(220,38,38,0.35),0_4px_12px_-2px_rgba(220,38,38,0.18)]
`;

const DEFAULT_STYLES = `
  bg-gray-100/90
  shadow-[inset_0_0_1px_rgba(0,0,0,0.05),0_0_0_1px_rgba(0,0,0,0.08)]
  hover:shadow-[inset_0_0_1px_rgba(0,0,0,0.05),0_0_0_1px_rgba(0,0,0,0.08),0_4px_8px_-2px_rgba(0,0,0,0.18)]
  hover:bg-gray-100/95
`;

export function AnnotationsLink({ annotationCountsByStyle, path }: Props) {
  const track = useTrack();
  const location = useLocation();

  const isOnAnnotationsRoute = location.pathname.endsWith("/summary/annotations");

  // Check if we have query params
  const hasUrlParams = location.search.includes("?");

  // Disable the link when on the annotations page without query params
  const isDisabled = isOnAnnotationsRoute && !hasUrlParams;

  const { badges, totalCount, errorCount } = useMemo(() => {
    const counts = Object.entries(annotationCountsByStyle)
      .map(([type, count]) => ({
        type: normalizeAnnotationType(type),
        count: Number(count),
      }))
      .filter(({ count }) => count > 0)
      .sort((first, second) => TYPE_ORDER.indexOf(first.type) - TYPE_ORDER.indexOf(second.type));

    const badges = groupAnnotations(counts);
    const errorCount = counts
      .filter((annotation) => annotation.type === "error")
      .reduce((sum, { count }) => sum + count, 0);

    return {
      badges,
      totalCount: counts.reduce((sum, { count }) => sum + count, 0),
      errorCount,
    };
  }, [annotationCountsByStyle]);

  const stackLayers = calculateStackLayers(errorCount);
  const hasErrors = errorCount > 0;

  if (totalCount === 0) {
    return null;
  }

  const getBadgeStyles = (type: AnnotationType, isGrouped = false) =>
    isGrouped ? "text-charcoal-700 bg-gray-100 ring-gray-500 pl-3 pr-2.5" : TYPE_CONFIG[type].color;

  const renderBadgeIcon = (type: AnnotationType) => {
    const icon = TYPE_CONFIG[type]?.icon;
    return icon ? (
      <Icon
        icon={icon}
        style={{ fill: "none" }}
        className={twMerge("h-4 w-4", TYPE_CONFIG[type].iconClassName)}
      />
    ) : null;
  };

  return (
    <div
      className={twMerge(
        "relative group",
        !isOnAnnotationsRoute &&
          ["mt-[0.1rem]", "mt-[0.2rem]", "mt-[0.4rem]", "mt-[0.6rem]"][stackLayers],
      )}
    >
      {!isOnAnnotationsRoute &&
        Array.from({ length: stackLayers }, (stackLayer, index) => {
          const layerConfig = LAYER_CONFIGS[index];
          return layerConfig ? (
            <div
              key={index}
              className={twMerge(
                "absolute rounded-md bg-white h-full origin-top",
                "transition-[transform,opacity] duration-200 ease-out",
                layerConfig.ring,
                layerConfig.top,
                layerConfig.sides,
                layerConfig.zIndex,
                layerConfig.transform,
                layerConfig.opacity,
              )}
              aria-hidden="true"
            />
          ) : null;
        })}

      <Link
        to={`${path}/summary/annotations`}
        onClick={() => track("Open Annotations")}
        className={twMerge(
          "group relative flex items-center w-full px-2 py-1 rounded-md mt-[1px] max-w-[calc(100%-2px)]",
          "transition-all duration-300 min-w-[140px] z-[4]",
          "no-underline hover:no-underline",
          isDisabled ? ACTIVE_ROUTE_STYLES : hasErrors ? ERROR_STYLES : DEFAULT_STYLES,
          // Only disable pointer events when on the base annotations page without parameters
          isDisabled && "cursor-default pointer-events-none",
        )}
      >
        <div className="flex items-center w-full gap-x-1">
          <div className="flex-1 flex flex-wrap items-center gap-x-2 gap-y-1">
            <div
              className={twMerge(
                "flex items-center -space-x-1",
                isOnAnnotationsRoute ? "min-h-[32px]" : "min-h-[36px]",
              )}
            >
              {badges.map((badge, index) => {
                const isGrouped = "annotations" in badge;
                const type = isGrouped ? "default" : badge.type;
                const showPlus = isGrouped;

                return (
                  <div
                    key={isGrouped ? "grouped" : `${type}-${index}`}
                    style={{ zIndex: badges.length - index }}
                    className={twMerge(
                      "group/circle relative flex items-center justify-center w-auto pl-1.5 pr-2 py-1 h-auto rounded-xl ring-1",
                      "transition-all duration-150",
                      "shadow-[0_0_0_2px_white]",
                      getBadgeStyles(type, isGrouped),
                    )}
                    data-testid={`annotation-badge-${type}`}
                  >
                    <div className="flex items-center gap-1">
                      {!showPlus && renderBadgeIcon(type)}
                      <span className="text-xs font-medium">
                        {showPlus ? `+${badge.count}` : badge.count}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>

            <span
              className={twMerge(
                "inline-flex flex-col text-sm font-medium whitespace-nowrap",
                isDisabled
                  ? "text-charcoal-400"
                  : "text-charcoal-700 group-hover:text-charcoal-900",
              )}
            >
              <span className="flex flex-col leading-4" data-testid="annotations-label">
                {totalCount} Annotation{totalCount === 1 ? "" : "s"}
              </span>
              <span className="text-xs font-normal text-charcoal-300">
                {!isDisabled && "View summary"}
              </span>
            </span>
          </div>

          {!isDisabled && (
            <Icon
              icon="heroicons/outline/chevron-right"
              className="w-3.5 h-3.5 text-charcoal-400 group-hover:text-charcoal-900 flex-shrink-0"
            />
          )}
        </div>
      </Link>
    </div>
  );
}

export default AnnotationsLink;

import styled from "styled-components";
import logo from "app/components/shared/logo.svg";

const Alert = styled.div`
  background: #dcfce4;
  border-radius: 4px;
  padding: 1.1em;

  p:last-child {
    margin-bottom: 0;
  }

  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1em;
`;

const Header = styled.strong`
  display: block;
  margin-bottom: 0.3em;
`;

const WhiteCircle = styled.div`
  background: white;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  padding: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  name: string;
}

const Hello = ({ name }: Props) => (
  <Alert>
    <WhiteCircle>
      <img src={logo} width={28} height={28} />
    </WhiteCircle>
    <div>
      <Header>Welcome, {name} 👋</Header>
      <p>Tell us a bit about yourself so we can make Buildkite work for you.</p>
    </div>
  </Alert>
);

export default Hello;

import { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default class ErrorMessage extends PureComponent {
  static displayName = "Autocomplete.ErrorMessage";

  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
  };

  render() {
    const classes = classNames(this.props.className, "px-2 py-2 dark-gray");

    return <li className={classes}>{this.props.children}</li>;
  }
}

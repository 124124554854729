/* eslint-disable react/jsx-no-bind */
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuCheckboxItem,
  DropdownMenuTrigger,
  DropdownMenuPortal,
} from "app/components/shared/DropdownMenu";
import Icon from "app/components/shared/Icon";
import Badge from "app/components/shared/Badge";
import { useFilterStore, FilterByOption, useStateCounts } from "./useFilterStore";
import classNames from "classnames";
import { useTrack } from "app/components/build/Show/lib/useTrack";

// Note: ordering intentional from most to least important
const stateFilterLabel: Record<FilterByOption, string> = {
  [FilterByOption.Failed]: "Failed",
  [FilterByOption.Running]: "Running",
  [FilterByOption.Scheduled]: "Scheduled",
  [FilterByOption.Waiting]: "Waiting",
  [FilterByOption.SoftFailed]: "Soft failed",
  [FilterByOption.Passed]: "Passed",
  [FilterByOption.Canceled]: "Canceled",
};

export default function FilterByDropdown() {
  const filterBy = useFilterStore((state) => state.filterBy);
  const setFilterBy = useFilterStore((state) => state.setFilterBy);
  const resetFilters = useFilterStore((state) => state.resetFilters);
  const track = useTrack();
  const counts = useStateCounts();

  function toggleFilterBy(option: FilterByOption) {
    let newFilterBy: FilterByOption[] = [];

    if (filterBy.includes(option)) {
      // If the filter is already applied, remove it
      newFilterBy = filterBy.filter((existingOption) => existingOption !== option);
    } else {
      // If the filter is not applied, add it
      newFilterBy = [...filterBy, option];
    }

    setFilterBy(newFilterBy);

    track("Change State Filter", {
      enabled: newFilterBy,
    });
  }

  function disableAutomaticClose(event: Event) {
    event.preventDefault();
  }

  return (
    <DropdownMenu>
      <div className="flex">
        <DropdownMenuTrigger asChild={true}>
          <button
            data-testid="filter-by-dropdown"
            className={classNames(
              "rounded-md inline-flex items-center px-2 py-1 space-x-1 self-stretch text-charcoal-700 hover:bg-purple-100 hover:text-purple-600 transition-colors max-w-full focus:z-10",
              {
                "bg-purple-100 text-purple-600": filterBy.length > 0,
                "rounded-r-none": filterBy.length > 0,
              },
            )}
          >
            <Icon icon="heroicons/outline/funnel" className="h-3 w-3 shrink-0" />

            <div className="flex items-center truncate max-w-full gap-1">
              {filterBy.length === 0 && "Filter"}
              {filterBy.length === 1 && (
                <>
                  State is <span className="font-medium">{stateFilterLabel[filterBy[0]]}</span>
                </>
              )}
              {filterBy.length > 1 && (
                <>
                  States{" "}
                  <Badge outline={true} className="leading-normal border-current ml-0">
                    {filterBy.length}
                  </Badge>
                </>
              )}
            </div>

            {filterBy.length === 0 && (
              <Icon icon="down-triangle" className="flex-none" style={{ width: 7, height: 7 }} />
            )}
          </button>
        </DropdownMenuTrigger>

        {filterBy.length > 0 && (
          <button
            data-testid="reset-filters-button"
            onClick={resetFilters}
            className="bg-purple-100 self-stretch text-charcoal-700 hover:text-purple-600 hover:bg-purple-100 rounded-md w-7 flex items-center justify-center border-l-[1px] border-white rounded-l-none hover:bg-opacity-75"
          >
            <Icon icon="heroicons/16/solid/x-mark" className="w-4 h-4" />
          </button>
        )}
      </div>

      <DropdownMenuPortal>
        <DropdownMenuContent
          className="space-y-0.5"
          align="start"
          alignOffset={-1}
          data-testid="filter-by-dropdown-content"
        >
          {Object.keys(stateFilterLabel).map((filter) => {
            const isChecked = filterBy.includes(filter as FilterByOption);

            return (
              <DropdownMenuCheckboxItem
                id={filter}
                key={filter}
                onCheckedChange={() => toggleFilterBy(filter as FilterByOption)}
                checked={isChecked}
                onSelect={disableAutomaticClose}
              >
                <div className="flex items-center justify-between flex-auto min-w-0 font-medium">
                  {stateFilterLabel[filter]}

                  {counts[filter] > 0 && (
                    <span className="text-xs font-medium px-1.5 py-0.5 bg-gray-100 rounded-sm">
                      <span>{counts[filter]}</span>
                    </span>
                  )}
                </div>
              </DropdownMenuCheckboxItem>
            );
          })}
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu>
  );
}

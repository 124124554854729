export default class TabManager {
  element: HTMLElement;
  id: string;

  constructor(element: HTMLElement) {
    this.element = element;

    const elementId = element.getAttribute("id");
    if (!elementId) {
      throw new Error("[TabManager] Supplied element must have an `id` attribute.");
    }

    this.id = elementId;

    this.element.addEventListener("click", (event: MouseEvent) => {
      if (!(event.target instanceof HTMLElement)) {
        return;
      }

      const button = event.target.closest("*[data-tab-button-name]");

      if (!(button instanceof HTMLElement)) {
        return;
      }

      const buttonName = button.getAttribute("data-tab-button-name");

      if (!buttonName) {
        return;
      }

      this.toggle(buttonName);
      button.blur();
    });
  }

  toggle(name: string) {
    // Find the corresponding button and section elements next to the current element
    const button = this.element.querySelector(`button[data-tab-button-name='${name}']`);
    const section =
      this.element.parentElement &&
      this.element.parentElement.querySelector(
        `*[data-tab-content-name='${name}'][data-tab-tablist-id='${this.id}']`,
      );

    if (button && section) {
      // Hide all the panels next to the nav element
      this.element.parentElement &&
        this.element.parentElement
          .querySelectorAll(`*[data-tab-content-name][data-tab-tablist-id='${this.id}']`)
          .forEach((el) => el.classList.add("hidden"));

      // Remove the "active" class from the current button
      this.element
        .querySelectorAll(".btn-tab--active[data-tab-button-name]")
        .forEach((el) => el.classList.remove("btn-tab--active"));

      // Show the button as "active"
      button.classList.add("btn-tab--active");

      // Re-show the new tab panel
      section.classList.remove("hidden");
    }
  }
}

import * as React from "react";
import { RelayRefetchProp, createRefetchContainer, graphql } from "react-relay";
import searchQuery from "search-query-parser";
import { seconds } from "metrick/duration";
import throttle from "lodash/throttle";

import Spinner from "app/components/shared/Spinner";
import Panel from "app/components/shared/Panel";
import Button from "app/components/shared/Button";

import Row from "./Row";

const PAGE_SIZE = 50;
const SEARCH_KEYWORDS = ["state", "priority", "concurrency-group", "passed"];

type Props = {
  clusterQueueId?: string;
  organization: any;
  query: string | null | undefined;
  onSuggestionClick: (suggestion: string) => void;
  relay: RelayRefetchProp;
};

type State = {
  loading: boolean;
  paginating: boolean;
  error: Error | null | undefined;
  pageSize: number;
};

type ParsedSearchQuery = {
  clusterQueue: string | null | undefined;
  concurrency: {
    group: string | null | undefined;
  };
  states: Array<string> | null | undefined;
  passed: boolean | null | undefined;
  priority:
    | {
        number: number;
      }
    | null
    | undefined;
  agentQueryRules: string | null | undefined;
};

class Jobs extends React.PureComponent<Props, State> {
  // @ts-expect-error - TS2564 - Property 'jobListRefreshTimeout' has no initializer and is not definitely assigned in the constructor.
  jobListRefreshTimeout: number;

  constructor(initialProps: any) {
    super(initialProps);

    this.state = {
      // Only default "loading" to true if there's a default query
      loading: !!initialProps.query,
      paginating: false,
      error: null,
      pageSize: PAGE_SIZE,
    };
  }

  refetchVariables() {
    return {
      isMounted: true,
      pageSize: this.state.pageSize,
      ...this.parseSearchQuery(this.props.query),
    };
  }

  componentDidMount() {
    this.props.relay.refetch(this.refetchVariables(), null, (error) => {
      this.setState({
        loading: false,
        error,
      });

      // Only start job list refresh timeout on cluster queue show page since we want to be cautious
      // of performance on the "secret" job explorer page (/organizations/:organization-slug/jobs)
      if (this.props.clusterQueueId) {
        this.startTimeout();
      }
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.query !== nextProps.query) {
      this.setState({ loading: true }, () => {
        this.props.relay.refetch(this.refetchVariables(), null, (error) => {
          this.setState({ loading: false, error });
        });
      });
    }
  }

  // This timeout interval mirrors the polling interval used on the cluster queue advanced metrics chart
  startTimeout = () => {
    this.jobListRefreshTimeout = setTimeout(this.reloadJobsList, seconds.bind(10));
  };

  // Throttle the refetch function so we don't ever reload the entire list
  // more than once every 3 seconds
  reloadJobsList = throttle(() => {
    this.props.relay.refetch(this.refetchVariables(), null, () => this.startTimeout(), {
      force: true,
    });
  }, 3000);

  componentWillUnmount() {
    clearTimeout(this.jobListRefreshTimeout);
  }

  parseSearchQuery(query: string | null | undefined): ParsedSearchQuery {
    const searchQueryParams = searchQuery.parse(query || "", {
      keywords: SEARCH_KEYWORDS,
    });

    const variables: ParsedSearchQuery = {
      concurrency: {
        group: null,
      },
      states: null,
      passed: null,
      priority: null,
      agentQueryRules: null,
      clusterQueue: this.props.clusterQueueId ? this.props.clusterQueueId : null,
    };

    if (typeof searchQueryParams === "string") {
      variables.agentQueryRules = searchQueryParams;
    } else if (searchQueryParams) {
      // @ts-expect-error - TS2322 - Type 'string | string[] | undefined' is not assignable to type 'string | null | undefined'.
      variables.agentQueryRules = searchQueryParams.text;
      variables.concurrency.group = searchQueryParams["concurrency-group"];

      const passed = searchQueryParams["passed"];
      if (passed === "true") {
        variables.passed = true;
      } else if (passed === "false") {
        variables.passed = false;
      }

      // Ensure the states are all upper case since it's a GraphQL enum
      const states = searchQueryParams["state"];
      if (states) {
        if (typeof states === "string") {
          // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'string[] | null | undefined'.
          variables.states = states.toUpperCase();
        } else {
          variables.states = states.map((state) => state.toUpperCase());
        }
      }

      const priority = searchQueryParams["priority"];
      if (priority) {
        variables.priority = { number: parseInt(priority, 10) };
      }
    }

    return variables;
  }

  render() {
    // Just return a null component if no query was defined
    if (!this.props.query) {
      return null;
    }

    return (
      <Panel className="border-none">
        {this.renderJobs()}
        {this.renderFooter()}
      </Panel>
    );
  }

  renderJobs() {
    const jobs = this.props.organization.jobs;

    if (this.state.error) {
      return (
        <Panel.Section>
          <div className="red">{this.state.error.message}</div>
        </Panel.Section>
      );
    } else if (!jobs || !jobs.edges || this.state.loading) {
      return (
        <Panel.Section className="text-center">
          <Spinner />
        </Panel.Section>
      );
    }

    if (jobs.edges.length === 0) {
      return (
        <Panel.Section>
          <p className="text-charcoal-300">No jobs match your filters.</p>
        </Panel.Section>
      );
    }

    return jobs.edges.map((edge) => {
      if (!edge || !edge.node) {
        throw new Error("An item was missing the edge or node");
      }

      return (
        <Row
          key={edge.node.id}
          job={edge.node}
          onConcurrencyGroupClick={this.handleConcurrencyGroupClick}
          onPriorityClick={this.handlePriorityClick}
          onAgentQueryRuleClick={this.handleAgentQueryRuleClick}
        />
      );
    });
  }

  renderFooter() {
    if (this.state.error) {
      return;
    } else if (this.state.paginating) {
      return (
        <Panel.Footer className="text-center">
          <Spinner style={{ margin: 9.5 }} />
        </Panel.Footer>
      );
    } else if (
      !this.state.loading &&
      this.props.organization.jobs &&
      this.props.organization.jobs.pageInfo &&
      this.props.organization.jobs.pageInfo.hasNextPage
    ) {
      return (
        <Panel.Footer className="text-center">
          <Button onClick={this.handleLoadMoreClick}>Load more…</Button>
        </Panel.Footer>
      );
    }
  }

  handleLoadMoreClick = () => {
    this.setState(
      {
        paginating: true,
        pageSize: this.state.pageSize + PAGE_SIZE,
      },
      () => {
        this.props.relay.refetch(this.refetchVariables(), null, (error) => {
          this.setState({
            paginating: false,
            error,
          });
        });
      },
    );
  };

  handleConcurrencyGroupClick = (concurrencyGroup: any) => {
    this.props.onSuggestionClick(`concurrency-group:${concurrencyGroup}`);
  };

  handlePriorityClick = (priority: any) => {
    this.props.onSuggestionClick(`priority:${priority}`);
  };

  handleAgentQueryRuleClick = (agentQueryRule: any) => {
    this.props.onSuggestionClick(agentQueryRule);
  };
}

export default createRefetchContainer(
  Jobs,
  {
    organization: graphql`
      fragment Jobs_organization on Organization
      @argumentDefinitions(
        isMounted: { type: "Boolean!", defaultValue: false }
        priority: { type: "JobPrioritySearch" }
        agentQueryRules: { type: "[String!]" }
        clusterQueue: { type: "[ID!]" }
        concurrency: { type: "JobConcurrencySearch" }
        states: { type: "[JobStates!]" }
        passed: { type: "Boolean" }
        pageSize: { type: "Int", defaultValue: 50 }
      ) {
        name
        slug
        jobs(
          first: $pageSize
          type: COMMAND
          state: $states
          priority: $priority
          agentQueryRules: $agentQueryRules
          clusterQueue: $clusterQueue
          concurrency: $concurrency
          passed: $passed
        ) @include(if: $isMounted) {
          edges {
            node {
              ... on JobTypeCommand {
                id
              }
              ...Row_job
            }
          }
          pageInfo {
            hasNextPage
          }
        }
      }
    `,
  },
  graphql`
    query JobsRefetchQuery(
      $organizationSlug: ID!
      $isMounted: Boolean!
      $priority: JobPrioritySearch
      $agentQueryRules: [String!]
      $clusterQueue: [ID!]
      $concurrency: JobConcurrencySearch
      $states: [JobStates!]
      $passed: Boolean
      $pageSize: Int
    ) {
      organization(slug: $organizationSlug) {
        ...Jobs_organization
          @arguments(
            isMounted: $isMounted
            priority: $priority
            agentQueryRules: $agentQueryRules
            clusterQueue: $clusterQueue
            concurrency: $concurrency
            states: $states
            passed: $passed
            pageSize: $pageSize
          )
      }
    }
  `,
);

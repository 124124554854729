import Emojify from "app/components/shared/Emojify";
import parseEmoji from "app/lib/parseEmoji";
import { CommandStep, isMatrixCommandStep } from "app/lib/pipeline";

export default function CommandStepLabel(step: CommandStep) {
  if (!step.label && step.commandScript) {
    return (
      <span className="font-mono text-[90%]" title={step.commandScript}>
        {step.commandScript}
      </span>
    );
  }

  if (isMatrixCommandStep(step)) {
    const html = parseEmoji(step.label).replace(/{{\s?matrix\.(.*?)\s?}}/g, (match, key) => {
      return `<span class="font-mono text-[90%]">{${key}}</span>`;
    });

    return <span dangerouslySetInnerHTML={{ __html: html }} title={step.label} />;
  }

  return <Emojify text={step.label} />;
}

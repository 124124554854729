import { isJobFinished, hasJobFailed } from "app/lib/jobs";
import { Job } from "app/components/build/Show/lib/types/Job";

type RetryGroup = {
  type: "retry_group";
  jobs: Array<Job>;
};

class QueryableJobsCollection {
  // `retryMap` is a key/value store to support quick lookup of a job by the id of the job
  // that retried it (i.e. to walk the `retriedInJobUuid` linked list in reverse).
  // For example, if we have the following two jobs:
  //   - job1 = { id: 'uuid-for-job-1', retriedInJobUuid: 'uuid-for-job-2'}
  //   - job2 = { id: 'uuid-for-job-2', retriedInJobUuid: null }
  // the resulting mapping would be:
  //   - `retryMap = { [job1.retriedInJobUuid]: job1 }`
  // which allows us to look up job1 (the root node) via job2 (the terminal node) to
  // 'walk up' the linked list of job retries i.e. `retryMap[job2.id]` => job1
  retryMap: {
    [key: string]: Job;
  } = {};

  constructor(jobs: Array<Job>) {
    jobs.forEach((job) => {
      // `job.type` checks are just to keep flow happy 🙈
      if ((job.type === "script" || job.type === "trigger") && job.retriedInJobUuid) {
        this.retryMap[job.retriedInJobUuid] = job;
      }
    });
  }

  /**
   * Walks the linked list of job retries in reverse to find all jobs that were retried
   * leading up to a given job
   *
   * @param {Job} job - the job to use as a 'leaf' node in a linked list of retried jobs
   * @returns Array<Step> the collection of jobs that form the linked list of retried
   * jobs prior to the given job
   */
  getPriorJobsInRetryChain(job: Job): Array<Job> {
    const retries: Array<Job> = [];

    let currentRetry = this.retryMap[job.id];
    while (currentRetry) {
      retries.push(currentRetry);
      currentRetry = this.retryMap[currentRetry.id];
    }

    return retries.reverse();
  }

  /**
   * Checks whether the given job is a retry of another job
   *
   * @param {Job} job - the job to check
   * @returns {boolean} true if the job is a retry of a previous job
   */
  isRetryOfAnotherJob(job: Job) {
    return Boolean(this.retryMap[job.id]);
  }
}

type FilterJobsToIssuesOptions = {
  preserveRetriesOfIds?: Array<string>;
};

/**
 * A filter to extract all jobs from a jobs collection that we deem to have "issues",
 * where an issue is defined as a job that has failed, and has not been retried resulting
 * in a pass.
 *
 * @param {Array<Job>} jobs - the collection of jobs to filter
 * @param {FilterJobsToIssuesOptions} options - options to adjust the filtering behaviour
 * @param {Array<String>} options.preserveRetriesOfIds - a list of IDs to preserve when
 * filtering jobs. This is useful if we want to keep retry chains in the returned results
 * regardless of whether a retry terminated in a failure (i.e. is an issue). If a retry
 * chain contains one or more of the IDs in this list, the retry chain will be included in
 * the returned results.
 * @returns {Array<Job>} a new collection containing only jobs that have issues
 */
function filterJobsToIssues(
  jobs: Array<Job>,
  options?: FilterJobsToIssuesOptions | null,
): Array<Job> {
  const { preserveRetriesOfIds } = options || {};

  const jobsCollection = new QueryableJobsCollection(jobs);

  const filteredJobs: Array<Job> = [];
  jobs.forEach((job, index) => {
    // we care about jobs that have finished, failed and haven't been/won't be retried
    // i.e. standalone failures, or terminal failures (failures at the end of a retry
    // chain)
    if (isJobFinished(job) && hasJobFailed(job) && !_hasOrWillBeRetried(job)) {
      if (jobsCollection.isRetryOfAnotherJob(job)) {
        // terminal retries need to be added to the filtered list, along with any prior
        // jobs in the retry chain
        const priorRetriedJobs = jobsCollection.getPriorJobsInRetryChain(job);
        filteredJobs.push(...priorRetriedJobs, job);
      } else {
        // this is a standalone failure and should be added to the filtered list
        filteredJobs.push(job);
      }
    } else if (preserveRetriesOfIds && jobsCollection.isRetryOfAnotherJob(job)) {
      // if the job is at the end of a retry chain, but has not failed, we need to check
      // whether it belongs to a retry chain containing any of the ids specified in the
      // optional `preserveRetriesOfIds` collection, and if so add the retry chain to the
      // filtered list

      // NOTE: we can't use `_hasOrWillBeRetried` in this case because, at the time of
      // writing, a failed manual retry job transitions through a `retryPending: true`
      // state before settling on `retryPending: false`. this results in the current retry
      // chain disappearing from the filter collection until that job state settles 😞
      const nextJobIsRetryOfThisJob =
        jobs[index + 1] &&
        (job.type === "script" || job.type === "trigger") &&
        jobs[index + 1].id === job.retriedInJobUuid;
      if (nextJobIsRetryOfThisJob) {
        return;
      }

      const priorRetriedJobs = jobsCollection.getPriorJobsInRetryChain(job);
      if (priorRetriedJobs.some((priorJob) => preserveRetriesOfIds.includes(priorJob.id))) {
        filteredJobs.push(...priorRetriedJobs, job);
      }
    }
  });

  return filteredJobs;
}

/**
 * Our backend doesn't currently support grouping of jobs that have been retried, so we
 * need to handle this on the frontend. This function will take a collection of jobs,
 * figure out which jobs are part of a retry chain, and group those jobs into a
 * `retry_group` object. Jobs that do not belong to a retry chain will be returned in the
 * collection as is.
 *
 * @param {Array<Job>} jobs - the collection of jobs to process
 * @returns {Array<Job | RetryGroup>} a new collection containing jobs and retry groups
 */
function convertRetriesToGroups(jobs: Array<Job>): Array<Job | RetryGroup> {
  const jobsCollection = new QueryableJobsCollection(jobs);
  const jobsWithRetryGroups: Array<Job | RetryGroup> = [];

  jobs.forEach((job) => {
    // @ts-expect-error - TS2339 - Property 'retriedInJobUuid' does not exist on type 'Step'.
    if (!job.retriedInJobUuid) {
      if (jobsCollection.isRetryOfAnotherJob(job)) {
        const priorRetriedJobs = jobsCollection.getPriorJobsInRetryChain(job);
        jobsWithRetryGroups.push({
          type: "retry_group",
          jobs: [...priorRetriedJobs, job],
        });
      } else {
        jobsWithRetryGroups.push(job);
      }
    }
  });

  return jobsWithRetryGroups;
}

function _hasOrWillBeRetried(job: Job) {
  return _hasBeenRetried(job) || _hasPendingRetry(job);
}

function _hasBeenRetried(job: Job) {
  // The job type check is to keep flow happy as the `Step` type supports multiple
  // `job.types`, and only `script` and `trigger` jobs can be retried
  return (job.type === "script" || job.type === "trigger") && job.retriedInJobUuid;
}

function _hasPendingRetry(job: Job) {
  // The job type check is to keep flow happy as the `Step` type supports multiple
  // `job.types`, and only `script` and `trigger` jobs can be retried
  return (job.type === "script" || job.type === "trigger") && job.retryPending;
}

export { filterJobsToIssues, convertRetriesToGroups };

import { useBreakpoint } from "react-use-size";
import theme from "tailwindcss/defaultTheme";

// Extract breakpoint from tailwind config and convert to number
const MD_BREAKPOINT = parseInt(theme.screens.md.replace("px", ""), 10);

/**
 * Hook to determine if the current viewport is below the mobile breakpoint
 * Uses Tailwind's 'md' (768px by default) breakpoint
 * @returns boolean - true if viewport is below mobile breakpoint
 */
export function useMobileBreakpoint(): boolean {
  return useBreakpoint(MD_BREAKPOINT);
}

import FriendlyTime from "app/components/shared/FriendlyTime";
import ExecutionResult from "../../shared/ResultIcons/ExecutionResult";
import FailureReason from "../../shared/FailureReason";
import BuildNumber from "../../shared/Metadata/BuildNumber";
import { StyledUnderline } from "../../shared/styles";
import { Execution } from "../../shared/type";
import ExecutionDrawerButton from "../../execution/Row/ExecutionDrawerButton";
import Branch from "../../shared/Metadata/Branch";

export type FlakyExecution = Omit<Execution, "duration" | "humanDuration" | "uuid">;

const ExecutionsList = ({ executions }: { executions: Array<FlakyExecution> }) => (
  <div className="py-5 px-8">
    <ul className="flex flex-col gap-2 list-none">
      {executions.map((execution) => (
        <li key={execution.id} className="flex flex-col gap-2">
          <div className="flex flex-wrap items-center gap-2 text-sm charcoal-800">
            <ExecutionResult.Small result={execution.result.toUpperCase()} />

            <span>
              {execution.turboFrameSrc ? (
                <ExecutionDrawerButton
                  url={execution.turboFrameSrc}
                  classes="color-inherit hover-faded underline"
                >
                  View execution
                </ExecutionDrawerButton>
              ) : (
                <a className="color-inherit hover-faded" href={execution.url}>
                  View execution
                </a>
              )}
            </span>
            {(execution.buildNumber || execution.buildUrl) && (
              <span className="before:content-['·'] before:text-charcoal-300 before:pr-2">
                <BuildNumber number={execution.buildNumber} url={execution.buildUrl} />
              </span>
            )}
            {execution.branch && (
              <span className="flex items-center before:content-['·'] before:text-charcoal-300 before:pr-2">
                <Branch branch={execution.branch} linkToBranchTag={true} />
              </span>
            )}
            <span className="before:content-['·'] before:text-charcoal-300 before:pr-2">
              <StyledUnderline
                as={FriendlyTime}
                value={execution.createdAt}
                className="hover-faded"
              />
            </span>
          </div>

          {execution.failureReason != null && (
            <div className="pl-6">
              <FailureReason
                failureReason={execution.failureReason}
                endpoint={execution.failureExpandedUrl}
                failureExpanded={execution.failureExpanded}
              />
            </div>
          )}
        </li>
      ))}
    </ul>
  </div>
);

export default ExecutionsList;

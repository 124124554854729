import classNames from "classnames";
import { createFragmentContainer, graphql } from "react-relay";

import JobTitle from "./JobTitle";

type Props = {
  job: any;
  className?: string;
  style?: any;
};

const JobLink = ({ job, className, style }: Props) => (
  <a
    href={job.url}
    className={classNames("black hover-lime no-underline", className)}
    style={style}
  >
    <JobTitle job={job} />
  </a>
);

export default createFragmentContainer(JobLink, {
  job: graphql`
    fragment JobLink_job on JobTypeCommand {
      ...JobTitle_job
      url
    }
  `,
});

import { Job } from "app/components/build/Show/lib/types";
import { SortFn } from ".";

export function Command({ job }: { job: Job }) {
  if (job.type !== "script") {
    return null;
  }
  return <code className="text-xs">{job.command}</code>;
}

// eslint-disable-next-line id-length
export const sortByCommand: SortFn = (direction) => (a, b) => {
  const aCommand = a.type === "script" ? a.command : "";
  const bCommand = b.type === "script" ? b.command : "";

  if (direction === "asc") {
    return bCommand?.localeCompare(aCommand);
  }
  return aCommand.localeCompare(bCommand);
};

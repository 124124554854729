import { Fragment } from "react";
import {
  GraphQLSchema,
  GraphQLField,
  GraphQLArgument,
  GraphQLNonNull,
  GraphQLList,
  buildClientSchema,
} from "graphql";

import GraphqlExplorerLayout from "../../Layout";
import RootField from "../RootField";
import Scalar from "../Scalar";

type Props = {
  field: string;
  schema: any;
};

const GraphQLExplorerDocumentationField = ({ field: fieldname, schema }: Props) => {
  const clientSchema: GraphQLSchema = buildClientSchema(schema);

  let fieldType = "query";
  const queryType = clientSchema.getQueryType();
  let field = queryType && queryType.getFields()[fieldname];

  if (!field) {
    fieldType = "mutation";
    const mutationType = clientSchema.getMutationType();
    field = mutationType && mutationType.getFields()[fieldname];
  }

  if (!field) {
    return (
      <div>
        <h2>Not Found</h2>
        <p>No field with that name could be found.</p>
      </div>
    );
  }

  let nonNull = false;
  let array = false;
  let nonNullItems = false;
  let returnType = field.type;

  if (returnType instanceof GraphQLNonNull) {
    nonNull = true;
    returnType = returnType.ofType;
  }

  if (returnType instanceof GraphQLList) {
    array = true;
    returnType = returnType.ofType;
  }

  if (returnType instanceof GraphQLNonNull) {
    nonNullItems = true;
    returnType = returnType.ofType;
  }

  if (returnType instanceof GraphQLList) {
    throw new TypeError(
      "Return type was still a List after processing. This shouldn't be possible.",
    );
  }

  return (
    <div className="cm-s-graphql">
      <h2>
        {fieldType[0].toUpperCase() + fieldType.slice(1)}:{" "}
        <RootField name={`${fieldType}.${fieldname}`} className="monospace" />
      </h2>
      {field.isDeprecated && <p>This query is deprecated. {field.deprecationReason}</p>}
      <p>{field.description || "No description available."}</p>
      {renderArguments(field)}
      <h3>Returns</h3>
      <span className="monospace">
        <span className="cm-punctuation">{array && "["}</span>
        {/* @ts-expect-error - TS2339 - Property 'name' does not exist on type 'GraphQLScalarType | GraphQLInterfaceType | GraphQLUnionType | GraphQLEnumType | GraphQLObjectType<any, any> | GraphQLNonNull<...>'. */}
        <Scalar name={returnType.name} />
        <span className="cm-punctuation">
          {nonNullItems && "!"}
          {array && "]"}
          {nonNull && "!"}
        </span>
      </span>
      {/* @ts-expect-error - TS2339 - Property 'description' does not exist on type 'GraphQLScalarType | GraphQLInterfaceType | GraphQLUnionType | GraphQLEnumType | GraphQLObjectType<any, any> | GraphQLNonNull<...>'. */}
      <p>{returnType.description || "No description available."}</p>
    </div>
  );
};

const renderArguments = (field: GraphQLField<any, any>) => {
  return (
    <>
      <h3 id="arguments">Arguments</h3>
      {field.args && field.args.length > 0 ? (
        field.args.map(renderArgument)
      ) : (
        <p>No arguments accepted.</p>
      )}
    </>
  );
};

const renderArgument = (argument: GraphQLArgument) => {
  let nonNull = false;
  let array = false;
  let nonNullItems = false;
  let type = argument.type;

  if (type instanceof GraphQLNonNull) {
    nonNull = true;
    type = type.ofType;
  }

  if (type instanceof GraphQLList) {
    array = true;
    type = type.ofType;
  }

  if (type instanceof GraphQLNonNull) {
    nonNullItems = true;
    type = type.ofType;
  }

  if (type instanceof GraphQLList) {
    throw new TypeError("Type was still a List after processing. This shouldn't be possible.");
  }

  return (
    <Fragment key={argument.name}>
      <h4 id={argument.name} className="monospace">
        <span className="cm-attribute">{argument.name}</span>
        <span className="cm-punctuation">: {array && "["}</span>
        {/* @ts-expect-error - TS2339 - Property 'name' does not exist on type 'GraphQLScalarType | GraphQLEnumType | GraphQLInputObjectType | GraphQLNonNull<GraphQLScalarType | GraphQLEnumType | GraphQLInputObjectType | GraphQLList<...>>'. */}
        <Scalar name={type.name} />
        <span className="cm-punctuation">
          {nonNullItems && "!"}
          {array && "]"}
          {nonNull && "!"}
        </span>
      </h4>
      <p>{argument.description || "No description available."}</p>
    </Fragment>
  );
};
const Page = (props: Props) => (
  <GraphqlExplorerLayout>
    <GraphQLExplorerDocumentationField {...props} />
  </GraphqlExplorerLayout>
);

export default Page;

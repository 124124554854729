import RunResult from "app/components/analytics/shared/ResultIcons/RunResult";
import Emojify from "app/components/shared/Emojify";
import MetadataRow from "app/components/analytics/shared/Metadata/MetadataRow";
import { Run, TestPlanProps } from "app/components/analytics/shared/type";

type Props = {
  run: Omit<Run, "duration">;
  url: string;
  testPlans?: TestPlanProps[];
};

const Row = ({ run, url }: Props) => (
  <li className="row-link__container">
    <div className="ta-panel-row grid-cols-[3rem_auto_6rem]">
      <RunResult.Regular result={run.result.toUpperCase()} />
      <div className="main-section">
        <a
          href={url}
          className="block font-medium text-inherit no-underline hover:text-inherit focus:text-inherit hover:underline focus:underline truncate max-w-full"
          data-testid="name"
        >
          <Emojify text={run.name} />
        </a>
        <MetadataRow
          buildNumber={run.buildNumber}
          buildUrl={run.buildUrl}
          branch={run.branch}
          commitSha={run.commitSha}
          createdAt={run.createdAt}
          testPlans={run.testPlans}
          repoUrl={run.repoUrl}
        />
      </div>
      <div className="text-right">
        <span className="sr-only">Run took </span>
        {run.humanDuration}
      </div>
    </div>
  </li>
);

export default Row;

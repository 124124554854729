import classNames from "classnames";
import { twMerge } from "tailwind-merge";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isActive?: boolean;
}

export default function ToolbarButton({ isActive, className, ...props }: Props) {
  return (
    <button
      {...props}
      aria-checked={isActive}
      className={twMerge(
        classNames(
          "border border-transparent rounded-md px-0.5 h-7 flex items-center justify-center transition-colors duration-200 ease-in-out aria-checked:border-purple-300 aria-checked:shadow-sm",
          {
            "bg-purple-100 text-purple-600 hover:bg-purple-200": isActive,
            "bg-gray-100 hover:bg-purple-100 hover:text-purple-600": !isActive,
          },
        ),
        className,
      )}
    />
  );
}

export function ToolbarButtonGroup({ children }: React.PropsWithChildren<object>) {
  return <div className="bg-gray-100 rounded-md flex">{children}</div>;
}

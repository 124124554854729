import * as React from "react";
import { v4 as uuid } from "uuid";

import CopyTextButton from "app/components/shared/CopyTextButton";

type Props = {
  children: React.ReactNode;
  language?: string;
  showCopyButton?: boolean;
};

export default function CodeBlock({ showCopyButton, ...props }: Props) {
  const id = `code-block-${uuid()}`;

  return (
    <div className="relative">
      <pre id={id} className="border border-gray rounded bg-silver overflow-auto p-2 monospace">
        <code
          style={{
            fontFamily:
              '"SFMono-Regular", Monaco, Menlo, Consolas, "Liberation Mono", Courier, monospace',
            fontSize: ".9em",
          }}
          className={props.language && `language-${props.language}`}
        >
          {props.children}
        </code>
      </pre>
      <CopyTextButton
        element={`#${id}`}
        className="absolute w-8 h-8 top-2.5 right-2.5 border border-gray rounded flex items-center justify-center"
      />
    </div>
  );
}

import useFetch from "use-http";

import { LoadingRows } from "app/components/analytics/shared/LoadingStates";
import ErrorState from "app/components/analytics/shared/ErrorState";
import EmptyState from "app/components/shared/EmptyState";
import { Span } from "../../shared/type";
import Row from "./Row";
import Button from "app/components/shared/Button";

const Table = ({ url }: { url: string }) => {
  const { loading, error, data = [] } = useFetch<Span[]>(url, []);

  if (error) {
    return (
      <ErrorState
        emoji="🕳️"
        heading="There was an error loading span data"
        subheading="Try refreshing the page; if the problem persists"
        urlText="let us know"
        url="mailto:support@buildkite.com"
      />
    );
  }

  if (!loading && data.length === 0) {
    return (
      <EmptyState
        emoji="🤖"
        heading="No span data found"
        subheading="The test execution did not have span data, or your test framework is not configured to send Test Analytics this information."
        link={
          <Button href="https://buildkite.com/docs/test-analytics/test-suites" theme="primary">
            Learn more
          </Button>
        }
      />
    );
  }

  return (
    <table className="frontend-table frontend-table--left_aligned border-t-0 border-l-0 border-r-0 table-fixed">
      <thead className="border-separate">
        <tr>
          <th className="w-10/12">Description</th>
          <th className="w-2/12" align="right">
            Duration
          </th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <LoadingRows rows={10} columns={2} />
        ) : (
          data.map((spanData) => {
            return <Row key={spanData.start_at.toString()} {...spanData} />;
          })
        )}
      </tbody>
    </table>
  );
};

export default Table;

import React from "react";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";

import FriendlyTime from "app/components/shared/FriendlyTime";
import { useFetch } from "use-http";
import ErrorState from "../../shared/ErrorState";
import FeedbackMessage from "../../shared/FeedbackMessage";
import EmptyState from "app/components/shared/EmptyState";
import TestStateChanged, { TestStateChangedEvent } from "./TestStateChangedEvent";
import TestResolved, { TestResolvedEvent } from "./TestResolvedEvent";
import TestLabelEvent, { TestLabelEventType } from "./TestLabelEvent";

interface TestEventsProps {
  endpoint: string;
}

const StyledTestEvents = styled.div`
  position: relative;
  margin: 0 auto;

  .container:not(:last-child):after {
    content: "";
    position: absolute;
    width: 1px;
    background-color: #cccccc;
    top: 0px;
    bottom: 0px;
    left: 20px;
    z-index: 1;
  }

  .container:first-child:not(:only-child):after {
    content: "";
    position: absolute;
    width: 1px;
    background-color: #cccccc;
    top: 28px;
    left: 20px;
    bottom: -32px;
    z-index: 1;
  }

  .container:last-child:not(:only-child):after {
    content: "";
    position: absolute;
    width: 1px;
    height: 28px;
    background-color: #cccccc;
    top: 0px;
    left: 20px;
    z-index: 1;
  }

  .container {
    padding: 11px 10px 10px 32px;
    position: relative;
    background-color: inherit;
    border-radius: 4px;
  }

  /*
  This circle implementation are copied from other timeline implementations in the same codebase
  Perhaps we should componentize this.
  */
  .circle {
    width: 11px;
    height: 21px;
    top: 11px;
    left: 15px;
    background: white;
    position: absolute;
    z-index: 2;
  }

  .circle::before {
    content: "";
    position: absolute;
    box-sizing: border-box;
    border: 1px solid #cccccc;
    border-radius: 50%;
    width: 3px;
    height: 3px;
    left: 4px;
    top: 9px;
  }

  .circle::after {
    content: "";
    position: absolute;
    box-sizing: border-box;
    border: 1px solid #cccccc;
    border-radius: 50%;
    width: 11px;
    height: 11px;
    left: 0px;
    top: 5px;
  }
`;

interface GenericTestEvent {
  uuid: string;
  type: "generic";
  timestamp: Date;
  data: {
    message: string;
  };
}

type TestEventType =
  | GenericTestEvent
  | TestStateChangedEvent
  | TestResolvedEvent
  | TestLabelEventType;

interface TestEventProps {
  event: TestEventType;
}

const TestEvent: React.FC<TestEventProps> = ({ event }) => {
  if (event.type === "test_state_changed") {
    return <TestStateChanged event={event} />;
  }

  if (event.type === "test_resolved") {
    return <TestResolved event={event} />;
  }

  if (event.type === "test_label_added" || event.type === "test_label_removed") {
    return <TestLabelEvent event={event} />;
  }

  // The following is for generic event type only.
  return (
    <div className="flex">
      <div className="grow">{event.data.message}</div>
      <div className="justify-self-end">
        <FriendlyTime className="dark-gray" value={event.timestamp} />
      </div>
    </div>
  );
};

const TestEvents: React.FC<TestEventsProps> = ({ endpoint }) => {
  const { loading, error, data } = useFetch(endpoint, []);
  const { result } = data || {};
  const events: TestEventType[] = result || [];

  if (error) {
    return (
      <ErrorState
        emoji="🕳️"
        heading="There was an error loading the test events"
        subheading="Try refreshing the page; if the problem persists"
        urlText="let us know"
        url="mailto:support@buildkite.com"
      />
    );
  }

  if (!loading && !events.length) {
    return <EmptyState emoji="🪴" heading="No test events to show" />;
  }

  return (
    <StyledTestEvents>
      {loading ? (
        <Skeleton count={5} height="20px" className="mb-3" />
      ) : (
        <>
          <div>
            {events.map((event) => (
              <div className="container" key={event.uuid}>
                <div className="circle" />
                <TestEvent event={event} />
              </div>
            ))}
          </div>

          <FeedbackMessage message="Showing latest 100 events" />
        </>
      )}
    </StyledTestEvents>
  );
};

export default TestEvents;

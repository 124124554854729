import { useContext } from "react";
import Step from "./Step";
import { useFormContext } from "react-hook-form";
import { Form, Product } from "../form";
import styled from "styled-components";
import { Checkable } from "../Field/Checkable";
import { OrganizationContext } from "../OrganizationContext";
import { useFetch } from "use-http";

interface Props {
  step: number;
  of: number;
  onSubmit: () => void;
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1em;
`;

const options = [
  { value: Product.Pipelines, label: "Pipelines" },
  { value: Product.TestAnalytics, label: "Test Engine" },
  { value: Product.Packages, label: "Package Registries" },
];

export default function Products(props: Props) {
  const { watch, register, handleSubmit } = useFormContext<Form>();
  const { organization } = useContext(OrganizationContext);
  const product = watch("product");
  const { post } = useFetch({
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": window._csrf.token,
    },
  });

  async function submit() {
    if (!organization) {
      throw new Error("Organization is required");
    }

    switch (product) {
      case Product.TestAnalytics:
        await post(`/organizations/${organization.slug}/welcome`, {
          product: "test_analytics",
        });
        window.location.href = `/organizations/${organization.slug}/analytics/suites/new`;
        break;
      case Product.Packages:
        await post(`/organizations/${organization.slug}/welcome`, {
          product: "packages",
        });
        window.location.href = `/organizations/${organization.slug}/packages/registries/new`;
        break;
      default:
        return props.onSubmit();
    }
  }

  return (
    <Step
      {...props}
      title="Which product do you want to try out first?"
      onSubmit={handleSubmit(submit)}
    >
      <Grid>
        {options.map(({ value, label }) => (
          <Checkable type="radio" key={value} value={value} {...register("product")}>
            {label}
          </Checkable>
        ))}
      </Grid>
    </Step>
  );
}

import * as React from "react";

import Tab from "./Tab";

type Props = {
  children: React.ReactNode;
  className?: string;
};

class TabControl extends React.PureComponent<Props> {
  static Tab = Tab;

  render() {
    let className = "flex p-0 m-0 mb-4 border-b border-gray";

    if (this.props.className) {
      className += " " + this.props.className;
    }

    return <ul className={className}>{this.props.children}</ul>;
  }
}

export default TabControl;

import { Job } from "app/components/build/Show/lib/types";
import JobGroup from "./jobGroup";

export default function getStepClassName(job: Job | JobGroup) {
  let state = job.state;

  if (job.state === "finished" || job.state === "timed_out" || job.state === "expired") {
    if (job.type === "script" || job.type === "trigger" || job.type === "job_group") {
      if (job.passed) {
        // @ts-expect-error - TS2322 - Type '"passed"' is not assignable to type 'JobStates'.
        state = "passed";
      } else if (job.softFailed) {
        // @ts-expect-error - TS2322 - Type '"failed-soft"' is not assignable to type 'JobStates'.
        state = "failed-soft";
        // @ts-expect-error - TS2367 - This condition will always return 'false' since the types '"finished" | "timed_out" | "expired"' and '"canceled"' have no overlap.
      } else if (job.state === "canceled") {
        state = "canceled";
      } else {
        // @ts-expect-error - TS2322 - Type '"failed"' is not assignable to type 'JobStates'.
        state = "failed";
      }
    }
    if (job.type === "manual") {
      if (job.state === "finished" && job.unblockedAt) {
        state = "unblocked";
      }
    }
  } else if (job.state === "canceled") {
    // @ts-expect-error - TS2322 - Type '"canceled" | "canceled-passed"' is not assignable to type 'JobStates'. | TS2339 - Property 'passed' does not exist on type 'Job'.
    state = job.passed ? "canceled-passed" : "canceled";
  } else if (job.type === "job_group" && job.state === "running" && job.passed === false) {
    // @ts-expect-error - TS2322 - Type '"failing"' is not assignable to type 'JobStates'.
    state = "failing";
  }

  const classList = [
    "flex",
    "items-center",
    "build-pipeline-job",
    `build-pipeline-job-${job.type.replace(/_/g, "-")}`,
    `build-pipeline-state-${state}`,
  ];

  if (job.type === "job_group" && job.state === "blocked" && job.hasSoftFailedJobs()) {
    classList.push("build-pipeline-state-soft-failed");
  }

  return classList.join(" ");
}

import { ChartDataset } from "chart.js";
import classNames from "classnames";
import * as Turbo from "@hotwired/turbo";

import { ForwardedChartHandle } from "../shared/Chart/chart";
import { useHighlightDataset, useToggleDatasets } from "./Chart/hooks";
import { LegendIndicator } from "./LegendIndicator";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "./Table";
import Icon from "../shared/Icon";

export function ChartLegend<Dataset extends ChartDataset<"line", unknown> & { url?: string }>({
  labelHeader = "Metric",
  chartRef,
  datasets,
  reverse,
  columns = {},
}: {
  labelHeader?: string;
  chartRef: React.RefObject<ForwardedChartHandle>;
  datasets: Dataset[];
  reverse?: boolean;
  columns?: Record<string, (dataset: Dataset, index: number, visible: boolean) => React.ReactNode>;
}) {
  const { isDatasetVisible, visibilityHandlers } = useToggleDatasets(chartRef);
  const highlightHandlers = useHighlightDataset(chartRef);

  const indexedDatasets: [number, Dataset][] = datasets.map((dataset, index) => [index, dataset]);

  if (reverse) {
    indexedDatasets.reverse();
  }

  return (
    <Table className="lg:basis-96">
      <TableHead>
        <TableRow>
          <TableHeader>{labelHeader}</TableHeader>
          {Object.keys(columns).map((column) => (
            <TableHeader key={column}>{column}</TableHeader>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {indexedDatasets.map(([index, dataset]) => {
          const { label, borderColor } = dataset;
          const visible = isDatasetVisible(index);

          return (
            <TableRow
              key={label}
              className={classNames("group", { "text-slate-500": !visible })}
              {...visibilityHandlers(index)}
              {...highlightHandlers(index)}
            >
              <TableCell className="font-semibold whitespace-normal" title={label}>
                <div className="line-clamp-1 flex gap-1.5 items-baseline">
                  <LegendIndicator
                    color={borderColor as string}
                    className="shrink-0"
                    selected={visible}
                  />
                  <span>
                    {label}

                    {dataset.url && (
                      <a
                        className="inline-block ml-1.5 cursor-pointer text-charcoal-300 hover:text-charcoal-900 bg-inherit invisible group-hover:visible"
                        href={dataset.url}
                        data-turbo="true"
                        onClick={(event) => {
                          // Prevent item from being checked/unchecked
                          event.stopPropagation();

                          // Now manually trigger Turbo because the event was stopped
                          event.preventDefault();
                          Turbo.visit(dataset.url!);
                        }}
                      >
                        <Icon
                          icon="heroicons/outline/arrow-up-right"
                          className="w-3 h-3 inline-block"
                        />
                      </a>
                    )}
                  </span>
                </div>
              </TableCell>

              {Object.keys(columns).map((column) => (
                <TableCell key={column}>{columns[column](dataset, index, visible)}</TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

import * as React from "react";
import debounce from "lodash/debounce";
import { RelayRefetchProp, createRefetchContainer, graphql } from "react-relay";
import SectionLoader from "app/components/shared/SectionLoader";
import ShowMoreFooter from "app/components/shared/ShowMoreFooter";
import UserSessionStore from "app/stores/UserSessionStore";
import Pipeline from "./Pipeline";
import Welcome from "./Welcome";
import * as constants from "./constants";
import notFoundImage from "app/images/not-found.png";
import { getCssValue } from "app/lib/cssValues";
import styled from "styled-components";
import { isEqual } from "lodash";

type Props = {
  relay: RelayRefetchProp;
  teamFilter: string | null | undefined;
  nameFilter: string | null | undefined;
  archivedFilter: boolean;
  tagsFilter: Array<string> | null | undefined;
  organization: any;
  onTagClick: (tag: string) => void;
};

type State = {
  loading: boolean;
  loadingMore: boolean;
  pageSize: number;
  includeGraphData: boolean;
};

// Bit of CSS lever pulling to break out of the page's fixed width container, so we can show a full
// screen-width background
const FavoritedPipelinesContainer = styled.div`
  margin-bottom: 30px;
  padding: 30px 0;
  background-color: ${getCssValue("--slate-100")};

  width: calc(100% + 30px);
  position: relative;
  left: 50%;
  margin-left: calc(-50% - 15px);

  @media (min-width: 768px) {
    width: calc(100vw);
    left: calc(50%);
    margin-left: -50vw;
  }

  // We want a 20px gap between the viewport and container on larger breakpoints
  @media (min-width: 1240px) {
    width: calc(100vw - 40px);
    left: calc(50% + 20px);
    margin-left: -50vw;
    border-radius: 6px;
  }

  .container {
    > :not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;

const PipelinesContainer = styled.div`
  > :not(:last-child) {
    margin-bottom: 10px;
  }
`;

class Pipelines extends React.PureComponent<Props, State> {
  state = {
    loading: false,
    loadingMore: false,
    pageSize: constants.PIPELINES_INITIAL_PAGE_SIZE,
    includeGraphData: false,
  };

  get pipelines() {
    return this.props.organization.pipelines && this.props.organization.pipelines.edges
      ? this.props.organization.pipelines.edges
      : [];
  }

  get fetchVars() {
    return {
      pipelineFilter: this.props.nameFilter,
      teamSearch: this.props.teamFilter,
      archivedFilter: this.props.archivedFilter,
      tagsFilter: this.props.tagsFilter,
      pageSize: this.state.pageSize,
      includeGraphData: this.state.includeGraphData,
    };
  }

  componentDidMount() {
    this.props.relay.refetch({ ...this.fetchVars, includeGraphData: true }, null, () => {
      this.setState({ includeGraphData: true });
    });

    // We might've started out with a new team, so let's see about updating the default!
    this.maybeUpdateDefaultTeam(this.props.organization.id, this.props.teamFilter);
  }

  componentDidUpdate(prevProps: Props) {
    const nextVars: Record<string, any> = {};

    // Are we switching teams?
    if (prevProps.teamFilter !== this.props.teamFilter) {
      nextVars.teamSearch = this.props.teamFilter;
    }

    // Are we filtering, and can we do this locally?
    if (prevProps.nameFilter !== this.props.nameFilter) {
      // if not, go to the server
      nextVars.pipelineFilter = this.props.nameFilter;
    }

    // Do we want archived pipelines?
    if (prevProps.archivedFilter !== this.props.archivedFilter) {
      nextVars.archivedFilter = this.props.archivedFilter;
    }

    // What about showing only pipelines with a set of tags?
    if (!isEqual(prevProps.tagsFilter, this.props.tagsFilter)) {
      nextVars.tagsFilter = this.props.tagsFilter;
    }

    if (Object.keys(nextVars).length > 0) {
      // Start by changing the `loading` state to show the spinner
      this.setState({ loading: true }, () => {
        // Once state has been set, force a full re-fetch of the pipelines
        this.debouncedApplyFilter(() => {
          this.setState({ loading: false });
        });
      });
    }

    // Let's try updating the default team - we don't rely on the last team
    // being different here because the store might've gotten out of sync,
    // and we do out own check!
    this.maybeUpdateDefaultTeam(this.props.organization.id, this.props.teamFilter);
  }

  applyFilter = (callback) => {
    this.props.relay.refetch(this.fetchVars, null, callback, { force: true });
  };

  debouncedApplyFilter = debounce(this.applyFilter, 350);

  maybeUpdateDefaultTeam(organization: any, team: string | null | undefined) {
    const orgDefaultTeamKey = `organization-default-team:${organization}`;

    if (team !== UserSessionStore.get(orgDefaultTeamKey)) {
      if (team) {
        UserSessionStore.set(orgDefaultTeamKey, team);
      } else {
        UserSessionStore.remove(orgDefaultTeamKey);
      }
    }
  }

  render() {
    // Are we switching teams or getting the first set of data? Lets bail out
    // early and show the spinner.
    if (this.state.loading || !this.props.organization.pipelines) {
      return <SectionLoader />;
    }

    // Switch between rendering the actual teams, or showing the "Welcome"
    // message
    if (this.pipelines.length > 0) {
      return (
        <div>
          {this.renderPipelines()}
          <ShowMoreFooter
            connection={this.props.organization.pipelines}
            label="pipelines"
            loading={this.state.loadingMore}
            onShowMore={this.handleShowMorePipelines}
          />
        </div>
      );
    } else if (this.props.nameFilter || this.props.tagsFilter) {
      return (
        <div className="text-center py-5 flex flex-col items-center justify-center gap2">
          <img src={notFoundImage} width={275} height={149} />

          <p>No pipelines found</p>
        </div>
      );
    }

    return <Welcome organization={this.props.organization} team={this.props.teamFilter} />;
  }

  renderPipelines() {
    // Split the pipelines into "favorited" and non "favorited". We don't
    // user a `sort` method so we preserve the current order the pipelines.
    const [favorited, remainder] = this.pipelines.reduce(
      (pipelines, pipeline) => {
        if (pipeline && pipeline.node) {
          pipelines[pipeline.node.favorite ? 0 : 1].push(
            <Pipeline
              key={pipeline.node.id}
              pipeline={pipeline.node}
              includeGraphData={this.state.includeGraphData}
              onTagClick={this.props.onTagClick}
            />,
          );
        }
        return pipelines;
      },
      [[], []],
    );

    if (window.Features.BuildsUsabilityPipelineRowIconsAndStyles) {
      return (
        <>
          {favorited.length ? (
            <FavoritedPipelinesContainer>
              <div className="container">{favorited}</div>
            </FavoritedPipelinesContainer>
          ) : null}
          {remainder.length ? <PipelinesContainer>{remainder}</PipelinesContainer> : null}
        </>
      );
    }

    if (favorited.length > 0 && remainder.length > 0) {
      return favorited.concat(
        <hr
          key="separator"
          className="my-4 bg-gray mx-auto max-w-sm border-none height-0"
          style={{ height: 1 }}
        />,
        remainder,
      );
    } else if (favorited.length > 0) {
      return favorited;
    } else if (remainder.length > 0) {
      return remainder;
    }

    // Just in case
    return [];
  }

  handleShowMorePipelines = () => {
    const pageSize = this.state.pageSize + constants.PIPELINES_PAGE_SIZE;

    this.setState({ loadingMore: true, pageSize }, () => {
      this.applyFilter(() => {
        this.setState({ loadingMore: false });
      });
    });
  };
}

export default createRefetchContainer(
  Pipelines,
  {
    organization: graphql`
      fragment Pipelines_organization on Organization
      @argumentDefinitions(
        teamSearch: { type: "TeamSelector" }
        pageSize: { type: "Int", defaultValue: 30 }
        pipelineFilter: { type: "String" }
        archivedFilter: { type: "Boolean" }
        tagsFilter: { type: "[String!]" }
        includeGraphData: { type: "Boolean", defaultValue: false }
      ) {
        ...Welcome_organization
        id
        slug
        allPipelines: pipelines(team: $teamSearch) {
          count
        }
        pipelines(
          search: $pipelineFilter
          first: $pageSize
          team: $teamSearch
          archived: $archivedFilter
          tags: $tagsFilter
          order: NAME_WITH_FAVORITES_FIRST
        ) {
          ...ShowMoreFooter_connection
          edges {
            node {
              id
              name
              description
              favorite
              ...Pipeline_pipeline @arguments(includeGraphData: $includeGraphData)
            }
          }
        }
      }
    `,
  },
  graphql`
    query PipelinesRefetchQuery(
      $organizationSlug: ID!
      $teamSearch: TeamSelector
      $includeGraphData: Boolean!
      $pageSize: Int!
      $pipelineFilter: String
      $archivedFilter: Boolean!
      $tagsFilter: [String!]
    ) {
      organization(slug: $organizationSlug) {
        ...Pipelines_organization
          @arguments(
            teamSearch: $teamSearch
            includeGraphData: $includeGraphData
            pageSize: $pageSize
            pipelineFilter: $pipelineFilter
            archivedFilter: $archivedFilter
            tagsFilter: $tagsFilter
          )
      }
    }
  `,
);

/* eslint-disable react/jsx-no-bind */
import classNames from "classnames";
import { Outlet, NavLink } from "react-router-dom";

const SummaryPageLink = (props) => {
  return (
    <NavLink
      {...props}
      className={({ isActive }) =>
        classNames(
          "px-2 py-1 text-sm",
          "flex items-center justify-center rounded-md",
          "text-navy-600 hover:text-current focus:text-current no-underline hover:no-underline focus:no-underline",
          "hover:bg-white hover:bg-opacity-50",
          {
            "bg-white shadow-depth-100 text-navy-700": isActive,
          },
        )
      }
    />
  );
};

export default function SummaryPage() {
  return (
    <div className="flex-auto min-w-0 relative">
      <div className="flex w-full h-full absolute overflow-y-auto">
        <div className="w-full p-2 flex flex-col gap-2 relative">
          <div className="flex gap-2">
            <SummaryPageLink to="./annotations">Annotations</SummaryPageLink>
            <SummaryPageLink to="./step-uploads">Step uploads</SummaryPageLink>
          </div>

          <Outlet />
        </div>
      </div>
    </div>
  );
}

import { PureComponent } from "react";
import { RelayProp, createFragmentContainer, graphql, commitMutation } from "react-relay";
import GraphQLErrors from "app/constants/GraphQLErrors";
import Button from "app/components/shared/Button";

type Props = {
  viewer: any;
  relay: RelayProp;
  onDeactivationComplete: () => void;
};

type State = {
  deletingTOTP: boolean;
};

const EnforcedTwoFactorOrganizations = ({ twoFactorOrganizations }) => (
  <>
    <p>
      Two-factor authentication cannot be deactivated because you belong to{" "}
      {twoFactorOrganizations.length}{" "}
      {twoFactorOrganizations.length > 1 ? "organizations" : "organization"} that require it:
    </p>
    <ul>
      {twoFactorOrganizations.map(({ node }) => (
        <li key={node.slug}>
          <a
            href={`/${node.slug}`}
            className="cursor-pointer lime hover-lime no-underline hover-underline"
          >
            {node.name}
          </a>
        </li>
      ))}
    </ul>
    <p>Please remove yourself from these organizations before proceeding.</p>
  </>
);

class TwoFactorDelete extends PureComponent<Props, State> {
  state = {
    deletingTOTP: false,
  };

  render() {
    return (
      <div className="p-5">
        <h1 className="m-0 text-xl semi-bold mb-3">Deactivate Two-Factor Authentication</h1>
        <div>{this.renderCurrentStatus()}</div>
      </div>
    );
  }

  organizationsRequireTwoFactor(): Array<any> {
    if (!this.props.viewer.organizations || !this.props.viewer.organizations.edges) {
      return [];
    }
    return this.props.viewer.organizations.edges.filter(
      (edge: any) => edge.node.membersRequireTwoFactorAuthentication,
    );
  }

  renderCurrentStatus() {
    const options: any = {
      className: "w-full mt-2",
      theme: "danger",
    };

    if (this.organizationsRequireTwoFactor().length) {
      options.disabled = true;
    }

    return (
      <>
        {Boolean(this.organizationsRequireTwoFactor().length) && (
          <EnforcedTwoFactorOrganizations
            twoFactorOrganizations={this.organizationsRequireTwoFactor()}
          />
        )}
        <p>We recommend keeping two-factor authentication active to help secure your account.</p>
        <p>You may reconfigure two-factor authentication at any time.</p>
        <Button
          onClick={this.handleDeleteClick}
          loading={this.state.deletingTOTP && "Deactivating two-factor authentication…"}
          {...options}
        >
          Deactivate Two-Factor Authentication
        </Button>
      </>
    );
  }

  handleDeleteClick = () => {
    if (this.props.viewer.totp) {
      const totpId = this.props.viewer.totp.id;

      this.setState({ deletingTOTP: true }, () => {
        commitMutation(this.props.relay.environment, {
          mutation: graphql`
            mutation TwoFactorDeleteMutation($input: TOTPDeleteInput!) {
              totpDelete(input: $input) {
                clientMutationId
                viewer {
                  totp {
                    id
                  }
                }
              }
            }
          `,
          variables: { input: { id: totpId } },
          onCompleted: this.handleDeleteMutationComplete,
          onError: this.handleDeleteMutationError,
        });
      });
    }
  };

  handleDeleteMutationComplete = () => {
    this.setState({ deletingTOTP: false }, this.props.onDeactivationComplete);
  };

  handleDeleteMutationError = (error: any) => {
    if (error) {
      if (error.source && error.source.type === GraphQLErrors.ESCALATION_ERROR) {
        location.reload();
      } else {
        // if (error.source && error.source.type === GraphQLErrors.RECORD_VALIDATION_ERROR) {
        //   this.setState({ errors: error.source.errors });
        // } else {
        alert(JSON.stringify(error));
      }
    }
  };
}

export default createFragmentContainer(TwoFactorDelete, {
  viewer: graphql`
    fragment TwoFactorDelete_viewer on Viewer {
      totp {
        id
      }
      organizations(first: 50) {
        edges {
          node {
            name
            slug
            membersRequireTwoFactorAuthentication
          }
        }
      }
    }
  `,
});

import UserSessionStore from "app/stores/UserSessionStore";
import { useEffect, useState } from "react";

interface UserSessionStoreChangeEvent extends Event {
  key: string;
  oldValue: string;
  newValue: string;
}

export function useUserSession(
  key: string,
  initialValue: string,
): [string, (newValue: string) => void];
export function useUserSession(
  key: string,
  initialValue: boolean,
): [boolean, (newValue: boolean) => void];
export function useUserSession(key: string, initialValue: string | boolean): unknown {
  const [value, setValue] = useState<string>(UserSessionStore.get(key) ?? String(initialValue));

  function handleChange(event: UserSessionStoreChangeEvent) {
    if (event.key === key) {
      setValue(event.newValue);
    }
  }

  useEffect(() => {
    UserSessionStore.on("change", handleChange);

    return () => {
      UserSessionStore.removeListener("change", handleChange);
    };
  }, []);

  function setSessionValue(newValue: string | boolean) {
    UserSessionStore.set(key, String(newValue));

    setValue(String(newValue));
  }

  return [parseValue(value), setSessionValue];
}

function parseValue(value: string): string | boolean {
  if (value === "true") {
    return true;
  }

  if (value === "false") {
    return false;
  }

  return value;
}

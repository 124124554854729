import { CommandJob } from "app/components/build/Show/lib/types";
import PipelineStateIcon, {
  colorForJob,
} from "app/components/shared/PipelineStateIcon/PipelineStateIcon";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { resolveJobUrl } from "../../lib/urlForView";

interface Props {
  job: CommandJob;
  children: React.ReactNode;
}

export const JobPillLink = ({ job, children }: Props) => {
  return (
    <Link
      to={resolveJobUrl(job.id)}
      key={job.id}
      className={twMerge(
        "py-1.5 px-3",
        "text-inherit hover:text-inherit focus:text-inherit",
        "border border-slate-500 bg-white rounded-lg transition-all flex items-center gap-1.5 hover:no-underline active:shadow-inner",
        classNames({
          "border-lime-500": job.state === "finished" && job.passed,
          "border-red-500 bg-red-100": job.state === "finished" && !job.passed,
          "border-orange-500 bg-orange-100": job.state === "running",
        }),
      )}
    >
      {children}

      <PipelineStateIcon
        job={job}
        className={classNames("block shrink-0", colorForJob(job))}
        style={{ width: "14px", height: "14px" }}
      />
    </Link>
  );
};

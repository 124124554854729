import { useContext } from "react";
import RainbowButton from "../RainbowButton";
import { Container, Title, Content } from "./Step";
import { useFormContext } from "react-hook-form";
import { useFetch } from "use-http";
import { Form, Goal } from "../form";
import { OrganizationContext } from "../OrganizationContext";

interface Props {
  // eslint-disable-next-line react/no-unused-prop-types
  product?: Product;
}

export enum Product {
  Pipelines = "pipelines",
  TestAnalytics = "analytics",
  Packages = "packages",
}

export const products = {
  [Product.Pipelines]: {
    cta: "Create your pipeline",
    description:
      "Ready for the fun part? You’ll create and run a pipeline to see your first green build–all in the next 5 minutes.",
    url: (slug: string) => `/organizations/${slug}/pipelines/new?starter=true`,
  },
  [Product.TestAnalytics]: {
    cta: "Create your test suite",
    description:
      "Ready for the fun part? You’ll set up a test suite and start seeing insights in no time–as soon as your tests are running, in fact.",
    url: (slug: string) => `/organizations/${slug}/analytics`,
  },
};

export const useProduct = (product?: Product) => {
  const { watch } = useFormContext<Form>();
  const goals = watch("goals");

  if (product) {
    return products[product];
  } else if (goals.length === 1 && goals.includes(Goal.ImproveTestQuality)) {
    return products[Product.TestAnalytics];
  }

  return products[Product.Pipelines];
};

export default function Complete(props: Props) {
  const { organization } = useContext(OrganizationContext);
  const { handleSubmit } = useFormContext<Form>();
  const product = useProduct(props.product);

  const { post, loading, error } = useFetch({
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": window._csrf.token,
    },
  });

  function handleClick() {
    if (!organization) {
      throw new Error("Organization is required");
    }

    handleSubmit(async (data) => {
      if (window.analytics) {
        window.analytics.track("Form Submitted", {
          name: "Welcome",
          organization_uuid: organization.uuid,
          ...data,
        });
      }

      await post(`/organizations/${organization.slug}/welcome`, data);
      if (!error) {
        window.location.href = product.url(organization.slug);
      }
    })();
  }

  return (
    <Container>
      <div>
        <Title>Thank you!</Title>
        <Content>
          <p>That’s all we need for now.</p>
          <p style={{ maxWidth: "80%" }}>{product.description}</p>
        </Content>
        <div>
          <RainbowButton theme="onboarding" onClick={handleClick} disabled={loading}>
            {product.cta}
          </RainbowButton>
        </div>
      </div>
    </Container>
  );
}

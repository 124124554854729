import Icon from "app/components/shared/Icon";
import { shortCommit } from "app/lib/commits";
import { twMerge } from "tailwind-merge";

interface Props {
  commitId?: string;
  commitShortLength?: number;
  commitUrl?: string;
  project: {
    provider: {
      frontendIcon?: string;
      url?: string;
      id?: string;
    };
  };
  className?: string;
}

export function Commit({ commitId, commitShortLength, commitUrl, project, className }: Props) {
  if (!commitId) {
    return null;
  }

  let title;
  if (project.provider.id === "github") {
    title = `View commit #${commitId} on GitHub`;
  } else if (project.provider.id === "gitlab") {
    title = `View commit #${commitId} on GitLab`;
  } else {
    title = `View commit ${commitId}`;
  }

  if (commitUrl) {
    return (
      <a
        title={title}
        href={commitUrl}
        className={twMerge(
          "color-inherit hover-color-inherit flex items-center font-mono text-[90%]",
          className,
        )}
      >
        <Icon
          icon={`custom/solid/provider-${project.provider.frontendIcon}`}
          className="h-4 w-4 mr-1 text-gray-800"
        />
        <span className="truncate max-w-full">{shortCommit(commitId, commitShortLength)}</span>
      </a>
    );
  }

  return (
    <span title={commitId} className={twMerge("font-mono text-[90%]", className)}>
      {shortCommit(commitId, commitShortLength)}
    </span>
  );
}

import compact from "lodash/compact";

export const BRANCH_KEY = "scm.branch";
export const RESULT_KEY = "result";
// Tags cannot contain commas or colons which enables us to split by commas and colons.
// We will need to update the constants below logic if this syntax ever changes.
const TAGS_SEPARATOR = ",";
const TAG_KEY_VALUE_SEPARATOR = ":";
const TAG_PARAM = "tags";

export function parseTagParams(tags: string | null | undefined): Tags {
  const tagArray = tags ? tags.split(TAGS_SEPARATOR).map((tag) => tag.trim()) : [];
  return new Tags(tagArray);
}

export function keyValueToTag(key: string, value: string): string {
  return `${key}${TAG_KEY_VALUE_SEPARATOR}${value}`;
}

export function formatTagValueWithOperator(value: string, operator: string): string {
  switch (operator) {
    case "starts_with":
      value += "*";
      break;
    default:
      value;
  }

  return value;
}

export function updateUrlTags({
  key,
  value,
  currentTags,
}: {
  key: string;
  value: string;
  currentTags?: Tags;
}): string {
  let updatedTags;
  const url = new URL(window.location.href);
  const tag = keyValueToTag(key, value);
  const urlTags = currentTags || parseTagParams(url.searchParams.get(TAG_PARAM));

  // Remove the execution params so the drawer does not open on reload
  url.searchParams.delete("execution_id");

  if (urlTags.hasTag(tag)) {
    // Remove the full tag if it already exists in the url
    updatedTags = urlTags.excludeByTag(tag);
  } else {
    // Use `includeByKey` to override any existing tags with the same key
    updatedTags = urlTags.includeByKey(key, value);
  }

  url.searchParams.set(TAG_PARAM, updatedTags.toQueryString());

  return url.toString();
}
class Tags {
  tags: string[];

  constructor(tags: string[]) {
    this.tags = tags;
  }

  hasTag(tag: string): boolean {
    return this.tags.includes(tag);
  }

  excludeByTag(tag: string): Tags {
    if (!tag) {
      return this;
    }

    let newTags = this.tags.filter((parsedTag) => parsedTag !== tag);
    // Remove any empty strings
    newTags = compact(newTags);

    return new Tags(newTags);
  }

  includeByTag(tag: string): Tags {
    if (!tag) {
      return this;
    }

    // Use a Set to ensure that we don't add duplicate tags
    let newTags = Array.from(new Set([...this.tags, ...[tag]]));
    // Remove any empty strings
    newTags = compact(newTags);

    return new Tags(newTags);
  }

  excludeByKey(key: string): Tags {
    if (!key) {
      return this;
    }

    const filteredTags = this.tags.filter(
      (existingTag) => !existingTag.startsWith(`${key}${TAG_KEY_VALUE_SEPARATOR}`),
    );

    return new Tags(filteredTags);
  }

  includeByKey(key: string, value: string): Tags {
    if (!key || !value) {
      return this;
    }

    // Remove any existing tags with the same key and add the new tag
    const updatedTags = this.excludeByKey(key).includeByTag(keyValueToTag(key, value));
    return new Tags(updatedTags.tags);
  }

  toQueryString(): string {
    return this.tags.join(TAGS_SEPARATOR);
  }
}

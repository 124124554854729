import { PureComponent } from "react";
import { createFragmentContainer, graphql } from "react-relay";

import Panel from "app/components/shared/Panel";
import JobLink from "app/components/shared/JobLink";
import FriendlyTime from "app/components/shared/FriendlyTime";
import JobState from "app/components/icons/JobState";

type Props = {
  job: any;
  onConcurrencyGroupClick: (concurrencyGroup: string) => void;
  onPriorityClick: (priority: number) => void;
  onAgentQueryRuleClick: (agentQueryRule: string) => void;
};

class Row extends PureComponent<Props> {
  render() {
    return (
      <Panel.Row>
        <div className="flex flex-col gap-y-1.5" data-testid="job-explorer-job-row">
          <div className="flex flex-row flex-wrap justify-between items-center">
            <div className="flex flex-wrap items-center gap-x-2">
              <div className="flex items-center min-w-0 gap-x-2">
                <JobState.Small job={this.props.job} className="flex-none" />
                <code className="code whitespace-nowrap">
                  {this.props.job.state && this.props.job.state.toLowerCase()}
                </code>
              </div>
              <div className="min-w-0 truncate max-w-full">
                <JobLink job={this.props.job} />
              </div>
            </div>

            {this.props.job.createdAt && (
              <div className="text-sm text-charcoal-300 whitespace-nowrap">
                Created <FriendlyTime value={this.props.job.createdAt} />
              </div>
            )}
          </div>

          <div className="md:ml-8 space-y-1.5 text-xs text-charcoal-300">
            {this.renderQueryRules()}
            {this.renderPriority()}
            {this.renderConcurrency()}
          </div>
        </div>
      </Panel.Row>
    );
  }

  renderConcurrency() {
    if (this.props.job.concurrency) {
      return (
        <div>
          <span className="font-semibold text-charcoal-400">Concurrency group:</span>{" "}
          <code className="font-mono">
            <span
              className="cursor-pointer hover:underline hover:decoration-dashed underline-offset-4"
              title={this.props.job.concurrency.group}
              onClick={this.handleConcurrencyGroupClick}
            >
              {this.props.job.concurrency.group}
            </span>{" "}
            [{this.props.job.concurrency.limit}]
          </code>
        </div>
      );
    }
  }

  renderPriority() {
    if (this.props.job.priority && this.props.job.priority.number !== 0) {
      return (
        <div>
          <span
            className="cursor-pointer hover:underline hover:decoration-dashed underline-offset-4"
            onClick={this.handlePriorityClick}
          >
            <span className="font-semibold text-charcoal-400">Priority</span>{" "}
            <code className="font-mono">{this.props.job.priority.number}</code>
          </span>
        </div>
      );
    }
  }

  renderQueryRules() {
    const agentQueryRules: ReadonlyArray<string> =
      !this.props.job.agentQueryRules || !this.props.job.agentQueryRules.length
        ? []
        : this.props.job.agentQueryRules;

    if (agentQueryRules.length === 0) {
      return null;
    }

    return (
      <div>
        <span className="font-semibold text-charcoal-400">Agent targeting rules:</span>{" "}
        <div className="inline">
          {agentQueryRules.map((agentQueryRule) => (
            <code
              key={agentQueryRule}
              className="font-mono cursor-pointer hover:underline hover:decoration-dashed underline-offset-4 mr-1"
              onClick={(event) => this.handleAgentQueryRuleClick(event, agentQueryRule)}
            >
              {agentQueryRule}
            </code>
          ))}
        </div>
      </div>
    );
  }

  handleConcurrencyGroupClick = (event: any) => {
    event.preventDefault();

    if (this.props.job.concurrency) {
      this.props.onConcurrencyGroupClick(this.props.job.concurrency.group);
    }
  };

  handlePriorityClick = (event: any) => {
    event.preventDefault();

    if (
      this.props.job.priority &&
      this.props.job.priority.number !== undefined &&
      this.props.job.priority.number !== null
    ) {
      this.props.onPriorityClick(this.props.job.priority.number);
    }
  };

  handleAgentQueryRuleClick = (event: any, agentQueryRule: string) => {
    event.preventDefault();

    this.props.onAgentQueryRuleClick(agentQueryRule);
  };
}

export default createFragmentContainer(Row, {
  job: graphql`
    fragment Row_job on Job {
      ... on JobTypeCommand {
        id
        state
        priority {
          number
        }
        agentQueryRules
        passed
        concurrency {
          group
          limit
        }
        createdAt
      }
      ...JobLink_job
    }
  `,
});

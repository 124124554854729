import { useCallback, MouseEvent } from "react";
import styled from "styled-components";

import Icon from "app/components/shared/Icon";
import useCopyToClipboard from "app/components/analytics/shared/hooks/useCopyToClipboard";

const CopiedIndicator = styled.div`
  position: absolute;
  top: 100%;
  margin-top: 5px;
  right: 0;
`;

const CopyButton = styled.button<{ active: boolean }>`
  background-color: var(--slate-100);
  cursor: pointer;
  padding: 0.5rem;
  border: none;
  border-radius: 6px;

  &:hover {
    background-color: var(--slate-300);
  }

  ${(props) =>
    props.active &&
    `
    transition: background-color .2s ease-in-out;
    background-color: var(--green-200);

    &:hover {
      background-color: var(--green-200);
    }
  `}
`;

const ApiKey = ({ token }: { token: string }) => {
  const { copied, handleCopy } = useCopyToClipboard();

  const handleCopyClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.currentTarget.blur();
    handleCopy(token);
  }, []);

  return (
    <div className="relative">
      <CopyButton onClick={handleCopyClick} title="Copy" active={copied}>
        <code>{token}</code>
        <Icon
          className="ml-1"
          icon={copied ? "heroicons/outline/check" : "heroicons/outline/document-duplicate"}
        />
      </CopyButton>
      {copied && <CopiedIndicator>Copied!</CopiedIndicator>}
    </div>
  );
};

export default ApiKey;

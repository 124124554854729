import { Job } from "app/components/build/Show/lib/types";
import JobGroup from "./jobGroup";

type JobOrGroup = Job | JobGroup;

/**
 * Groups jobs into an array of JobGroups and regular, ungrouped jobs. Jobs will be
 * grouped when they are explicitly grouped (by their associated `groupUuid` when
 * belonging to a group step) or implicitly grouped (via parallelism being set on their
 * parent step).
 * @param {*} jobs the list of jobs to parse and group
 * @returns an array containing the appropriate JobGroups and regular Jobs
 */
function groupJobs(jobs: Array<Job>): Array<JobOrGroup> {
  const mappedJobsIncludingGroups = new Map<string, JobOrGroup>();

  for (const job of jobs) {
    const jobHasGroup = Boolean(job.groupUuid);
    const jobHasParallelism = Boolean(
      job.type === "script" && job.parallelGroupTotal && job.parallelGroupTotal > 1,
    );

    if (jobHasGroup || jobHasParallelism) {
      // Ah! We've stumbled onto a grouped or parallel job, let's group it.
      const groupId = jobHasGroup ? job.groupUuid : job.stepUuid;

      if (groupId) {
        if (!mappedJobsIncludingGroups.has(groupId)) {
          mappedJobsIncludingGroups.set(groupId, new JobGroup(groupId));
        }
        const existingGroup = mappedJobsIncludingGroups.get(groupId);

        if (existingGroup instanceof JobGroup) {
          existingGroup.appendJob(job);
        }
      }
    } else {
      // This job isn't part of a group, so we'll add it to our mapping as a plain old job.
      mappedJobsIncludingGroups.set(job.id, job);
    }
  }

  const groupedJobs = Array.from(mappedJobsIncludingGroups.values());

  return groupedJobs;
}

export default groupJobs;

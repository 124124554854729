import reactRenderer from "app/lib/reactRenderer";
import merge from "lodash/merge";
import Chart from "../Chart";
import UsageTooltip from "./UsageTooltip";

const findOrCreateTooltip = (canvas: HTMLCanvasElement) => {
  let tooltipEl = canvas?.parentElement?.querySelector<HTMLDivElement>("#chartjs-tooltip");
  if (canvas == null) {
    return null;
  }

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.id = "chartjs-tooltip";
    tooltipEl.className =
      "absolute bottom-0 left-0 z-10 pointer-events-none transform duration-100 shadow-sm translate-y-full";
    canvas?.parentElement?.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const renderTooltip = (context: any) => {
  const { tooltip, chart } = context;

  const tooltipEl = findOrCreateTooltip(chart.canvas);
  if (tooltipEl == null) {
    return;
  }

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = "0";
    return;
  }

  tooltipEl.style.opacity = "1";
  tooltipEl.style.transform = `translate(${tooltip.caretX}px,0)`;

  reactRenderer(<UsageTooltip tooltip={tooltip} />, tooltipEl);
};

const getUsageChartOptions = ({
  chartData,
  showDateLabels,
}: {
  chartData: any;
  showDateLabels: boolean;
}) => {
  const options = {
    layout: {
      padding: 1,
    },
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "hover",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        mode: "index",
        yAlign: "bottom",
        external: renderTooltip,
      },
    },
    scales: {
      // eslint-disable-next-line id-length
      x: {
        stacked: false,
        grid: {
          display: false,
        },
        time: {
          parser: (dateStr: string) => {
            const _date: Date = new Date(dateStr);
            return new Date(_date.getUTCFullYear(), _date.getUTCMonth(), _date.getUTCDate());
          },
        },
        ticks: {
          display: showDateLabels,
          autoSkip: true,
          maxTicksLimit: 3,
          maxRotation: 0,
          color: "#767676",
          font: {
            weight: 500,
            size: 12,
          },
        },
      },
      // eslint-disable-next-line id-length
      y: {
        display: false,
        beginAtZero: true,
      },
      y1: {
        display: false,
        beginAtZero: true,
      },
    },
  } as const;

  return {
    ...chartData,
    options: merge({}, options, chartData?.options),
  };
};

type UsageChartProps = {
  chartData: {
    [key: string]: any;
  };
  height: number;
  canvasId?: string;
  showDateLabels?: boolean;
};

const UsageChart = ({ showDateLabels = true, chartData, ...rest }: UsageChartProps) => {
  const chartOptions = getUsageChartOptions({
    chartData,
    showDateLabels,
  });
  return <Chart chartOptions={chartOptions} {...rest} />;
};

export default UsageChart;

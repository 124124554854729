import Icon from "app/components/shared/Icon";
import AnchoredPopover from "app/components/shared/Popover/anchored";

import { getDurationString } from "app/lib/date";

type Props = {
  duration: number;
};

const RunTime = ({ duration }: Props) => {
  return (
    <div className="dark-gray">
      <Icon icon="heroicons/outline/clock" className="h-4 w-4" />
      <AnchoredPopover className="ml-1">
        <span className="underline-dotted cursor-pointer">Total Job Run Time:</span>
        <div className="px-4 py-2">
          The total time that jobs spent running on agents for this build.
        </div>
      </AnchoredPopover>
      <span>{` ${getDurationString(duration, "short")}`}</span>
    </div>
  );
};

export default RunTime;

import { useCallback } from "react";

import { Execution } from "app/components/analytics/shared/type";
import { _trackTALinkClicked } from "../segmentEvents";

type BuildProps = {
  url?: Execution["buildUrl"];
  number?: Execution["buildNumber"];
};

const BuildNumber = ({ number, url }: BuildProps) => {
  const handleTrackBuildClick = useCallback(() => {
    if (!url) {
      return;
    }

    _trackTALinkClicked({
      url: url,
      linkTo: "build#show",
      linkTitle: `Build #${number}`,
      source: "Metadata Build Number",
    });
  }, [number, url]);

  if (number && url) {
    return (
      <a className="color-inherit hover-faded" href={url} onClick={handleTrackBuildClick}>
        Build #{number}
      </a>
    );
  }

  if (url) {
    return (
      <a
        className="color-inherit hover-faded"
        href={url}
        rel="noopener noreferrer"
        onClick={handleTrackBuildClick}
      >
        View build
      </a>
    );
  }

  if (number) {
    return <>Build #{number}</>;
  }

  return null;
};

export default BuildNumber;

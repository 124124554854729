import PipelineStateIcon, {
  colorForJob,
} from "app/components/shared/PipelineStateIcon/PipelineStateIcon";
import { Job } from "app/components/build/Show/lib/types";
import classNames from "classnames";
import { sortJobsByState } from "../../Sidebar/useFilterStore";

export function State({ job }: { job: Job }) {
  return (
    <PipelineStateIcon
      job={job}
      className={classNames("w-4 h-4 block", colorForJob(job))}
      style={{ width: undefined, height: undefined }}
    />
  );
}

export const sortByState = sortJobsByState;

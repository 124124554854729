import { ReactNode } from "react";
import styled from "styled-components";

import Icon from "app/components/shared/Icon";

export const Container = styled.div`
  display: flex;
  margin: 30px 0;
  line-height: 22px;
  color: var(--charcoal-700);
  width: 100%;
  svg {
    flex-shrink: 0;
    margin-right: 12px;
    color: currentColor;
  }

  a {
    color: currentColor;
  }
`;

type FeedbackMessageProps = {
  message: string;
  feedbackUrl?: string | null;
  feedbackUrlText?: string;
  messageAfterUrl?: string | null;
  children?: ReactNode;
};

const FeedbackMessage = ({
  message,
  feedbackUrl,
  feedbackUrlText = "get in touch",
  messageAfterUrl,
  children,
}: FeedbackMessageProps) => {
  return (
    <Container>
      <Icon icon="info-circle" />
      <div>
        {children}
        <p className="m-0">
          {message}
          {feedbackUrl && (
            <>
              {" "}
              <a
                className="font-semibold"
                href={feedbackUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {feedbackUrlText}
              </a>
              {messageAfterUrl && ` ${messageAfterUrl}`}.
            </>
          )}
        </p>
      </div>
    </Container>
  );
};

export default FeedbackMessage;

import { useMostRecentParallelJobsForStep } from "app/components/build/Show/lib/BuildContext";
import { CommandStep } from "app/lib/pipeline";
import { sortByState } from "../TableView/TableCell";
import { twMerge } from "tailwind-merge";
import classNames from "classnames";
import { JobPillLink } from "./JobPillLink";

interface Props {
  step: CommandStep;
}

export const ParallelStepDetails = ({ step }: Props) => {
  const jobs = useMostRecentParallelJobsForStep(step.uuid);
  jobs.sort(sortByState("asc"));

  return (
    <div className="flex flex-col flex-auto min-w-0 gap-2 pb-2">
      <div
        className="gap-1.5 grid grid-col-dense"
        style={{
          // 75px is the minimum width that seems to handle 1000+ job counts
          gridTemplateColumns: "repeat(auto-fill, minmax(70px, 1fr))",
        }}
      >
        {jobs.map((job) => {
          const index = job.parallelGroupIndex || 0;
          return (
            <JobPillLink job={job} key={job.id}>
              <span
                className={twMerge(
                  classNames(
                    "rounded white bg-white small tabular-numerals px-1 text-charcoal-700 border border-slate-500 flex-1 justify-center flex",
                    {
                      "border-lime-500": job.state === "finished" && job.passed,
                      "border-red-500": job.state === "finished" && !job.passed,
                      "border-orange-500": job.state === "running",
                    },
                  ),
                )}
              >
                {index + 1}
              </span>
            </JobPillLink>
          );
        })}
      </div>
    </div>
  );
};

import { useEffect, useRef, useState } from "react";

import jobCommandOneliner from "app/lib/jobCommandOneliner";
import {
  HoverCard,
  HoverCardTrigger,
  HoverCardContent,
  HoverCardPortal,
} from "app/components/shared/HoverCard";

function useIsTruncated(element: HTMLElement | null) {
  const [isTruncated, setIsTruncated] = useState(false);

  function updateIsTruncated() {
    if (!element) {
      return;
    }

    const isTruncated = element.scrollWidth > element.clientWidth;

    setIsTruncated(isTruncated);
  }

  useEffect(() => {
    updateIsTruncated();

    window.addEventListener("resize", updateIsTruncated);

    return () => {
      window.removeEventListener("resize", updateIsTruncated);
    };
  }, [element]);

  return isTruncated;
}

export default function Command({
  command,
  onNestedLinkTrigger,
}: {
  command: string;
  onNestedLinkTrigger: (e: React.MouseEvent | React.KeyboardEvent) => void;
}) {
  const commandRef = useRef<HTMLSpanElement>(null);
  const isTruncated = useIsTruncated(commandRef.current);

  return (
    <HoverCard>
      <HoverCardTrigger asChild={true}>
        <span className="flex flex-auto min-w-0 small monospace job-command">
          <span className="truncate max-w-full pointer-events-none" ref={commandRef}>
            {jobCommandOneliner(command)}
          </span>
        </span>
      </HoverCardTrigger>

      {isTruncated && (
        <HoverCardPortal>
          <HoverCardContent
            size="max-lg"
            onClick={onNestedLinkTrigger}
            onKeyDown={onNestedLinkTrigger}
            data-testid="job-command-hover-card"
          >
            <pre className="break-words whitespace-pre-wrap small monospace">{command}</pre>
          </HoverCardContent>
        </HoverCardPortal>
      )}
    </HoverCard>
  );
}

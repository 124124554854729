import React from "react";
import { Transition, motion } from "framer-motion";

const isAimationDisabled = document.body.classList.contains("animation-disable");

// Transition wrapper to handle disabling animations. Useful in tests.
function transition(transition: Transition) {
  if (isAimationDisabled) {
    return { duration: 0 };
  }
  return transition;
}

const variants = {
  enter: (direction: number) => {
    return {
      translateX: direction > 0 ? "100%" : "-100%",
      opacity: 0,
    };
  },
  center: {
    translateX: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      translateX: direction < 0 ? "100%" : "-100%",
      opacity: 0,
    };
  },
};

interface Props {
  children: React.ReactNode;
  direction: number;
}

const SlideIn = ({ children, direction }: Props) => (
  <motion.div
    key="complete"
    custom={direction}
    style={{ position: "absolute", width: "100%", height: "100%", top: 0 }}
    variants={variants}
    initial="enter"
    animate="center"
    exit="exit"
    transition={transition({
      translateX: { type: "spring", damping: 18 },
      opacity: { duration: 0.2 },
    })}
  >
    {children}
  </motion.div>
);

export default SlideIn;

import EventEmitter from "eventemitter3";
import LegacyDispatcher from "app/lib/legacyDispatcher";

class JobLogLineStore {
  eventEmitter: EventEmitter;
  state: {
    [key: string]: boolean;
  };

  constructor() {
    this.eventEmitter = new EventEmitter();
    this.state = {};
    LegacyDispatcher.on("job_log_line:highlight", ({ lineId }) => this.highlight(lineId));
  }

  addChangeListener(lineId: string, callback: any) {
    return this.eventEmitter.addListener(`${lineId}/change`, callback);
  }

  removeChangeListener(lineId: string, callback: any) {
    return this.eventEmitter.removeListener(`${lineId}/change`, callback);
  }

  isHighlighted(lineId: string) {
    return this.state[lineId];
  }

  highlight(lineId: string) {
    // If the line is already highlighted, toggle it off.
    if (this.state[lineId]) {
      delete this.state[lineId];
      this.eventEmitter.emit(`${lineId}/change`);
      return;
    }

    // Line ID's are in a format of: `jobId/lineNumber`
    const [jobId] = lineId.split("/");

    // Remove the highlight from the other line from this job
    for (const key in this.state) {
      if (key.indexOf(jobId) === 0) {
        delete this.state[key];
        this.eventEmitter.emit(`${key}/change`);
        break;
      }
    }

    // Highlight the new line and emit the change
    this.state[lineId] = true;
    return this.eventEmitter.emit(`${lineId}/change`);
  }
}

export default new JobLogLineStore();

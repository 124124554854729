import { QueryRenderer, graphql } from "react-relay";
import Environment from "app/lib/relay/environment";

import usePreloadedRelayQuery from "app/lib/usePreloadedRelayQuery";

import SectionLoader from "app/components/shared/SectionLoader";
import AgentShow from "./AgentShow";

type AgentJobsListRendererProps = {
  params: {
    agent: string;
    organization: string;
  };
};

export default function AgentShowRenderer({ params, ...rest }: AgentJobsListRendererProps) {
  const environment = Environment.get();

  const query = graphql`
    query AgentShowRendererQuery($slug: ID!) {
      agent(slug: $slug) {
        ...AgentShow_agent
      }
    }
  `;

  usePreloadedRelayQuery(
    true,
    {
      environment,
      query,
      variables: { slug: `${params.organization}/${params.agent}` },
    },
    [params.agent, params.organization],
  );

  function renderQuery({ props }: { props: any }) {
    if (!props) {
      return <SectionLoader />;
    }

    return <AgentShow {...props} {...rest} params={params} />;
  }

  return (
    <QueryRenderer
      fetchPolicy="store-and-network"
      environment={environment}
      query={query}
      variables={{ slug: `${params.organization}/${params.agent}` }}
      render={renderQuery}
    />
  );
}

import classNames from "classnames";
import { forwardRef, HTMLProps, Ref } from "react";

const THEMES = {
  primary: "btn-primary",
  default: "btn-default",
  danger: "btn-danger",
  small: "btn-depth btn-small",

  // Button style used throughout onboarding
  onboarding: "btn-onboarding",
};

type BaseProps = HTMLProps<HTMLButtonElement> & HTMLProps<HTMLAnchorElement>;

interface ButtonProps extends BaseProps {
  loading?: boolean;
  iconOnly?: boolean;
  theme?: keyof typeof THEMES;
  type?: "button" | "submit" | "reset";
}

const Button = forwardRef(
  (
    {
      theme = "default",
      loading = false,
      iconOnly = false,
      className = "",
      children,
      ...props
    }: ButtonProps,
    ref: Ref<HTMLButtonElement & HTMLAnchorElement>,
  ) => {
    const Tag = props.href ? "a" : "button";
    return (
      <Tag
        {...props}
        ref={ref}
        className={classNames(
          "btn whitespace-nowrap inline-flex justify-center items-center",
          className,
          THEMES[theme],
          {
            "-icon-only": iconOnly,
            "btn--disabled": !!loading || props.disabled,
          },
        )}
      >
        {loading ? <span>{loading}</span> : children}
      </Tag>
    );
  },
);

Button.displayName = "Button";

export default Button;

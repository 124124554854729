import Spinner from "app/components/shared/Spinner";
import Loadable from "react-loadable";

type LoadingProps = {
  error?: any;
  pastDelay?: boolean;
};

const LoadingMessage = (props: LoadingProps) => {
  if (props.error) {
    return <div>Error loading charts. Please reload</div>;
  } else if (props.pastDelay) {
    return (
      <div className="flex items-center justify-center absolute top-0 right-0 bottom-0 left-0">
        <Spinner />
      </div>
    );
  }
  return null;
};

const LoadableChart = Loadable({
  loader: () => import("./chart"),
  loading: LoadingMessage,
});

type Props = {
  height: number;
  width?: number;
  chartOptions: {
    [key: string]: any;
  };
  canvasId?: string;
};

export default function Chart(props: Props) {
  return (
    <div className="relative" style={{ height: props.height, width: props.width }}>
      <LoadableChart chartOptions={props.chartOptions} canvasId={props.canvasId} />
    </div>
  );
}

import { useContext, useEffect } from "react";
import Step from "./Step";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import useCreateOrganization from "./useCreateOrganization";
import { OrganizationContext } from "../OrganizationContext";

const Label = styled.label`
  display: block;
  font-weight: 600;
  margin-bottom: 0.5em;
`;

const Input = styled.input`
  font-size: 1em !important;
  margin-bottom: 0.5em;

  &[aria-invalid="true"] {
    border-color: red;
  }
`;

const ErrorMessage = styled.span`
  color: red;
  display: block;
  margin-bottom: 0.5em;
`;

interface Fields {
  name: string;
}

interface Props {
  step: number;
  of: number;
  onSubmit: () => void;
}

export default function Organization(props: Props) {
  const { organization, setOrganization } = useContext(OrganizationContext);
  const { createOrganization, loading, error, data } = useCreateOrganization();

  const {
    formState: { errors, isSubmitSuccessful, isSubmitted, isValid },
    register,
    handleSubmit,
    setError,
  } = useForm<Fields>({
    defaultValues: {
      name: organization?.name,
    },
  });

  useEffect(() => {
    if (error) {
      if (data?.error) {
        setError("name", { type: "custom", message: data.error });
      } else {
        setError("name", {
          type: "custom",
          message: "Something went wrong. Please try again.",
        });
      }
    }

    if (data?.organization) {
      setOrganization(data.organization);

      // Small delay to give user a chance to see the success state before
      // moving on to the next step.
      setTimeout(props.onSubmit, 500);
    }
  }, [error, data]);

  return (
    <Step
      {...props}
      title="Set up your account"
      disabled={!organization && !isValid && !loading}
      onSubmit={handleSubmit((data: Fields) => {
        // If we already have an organization then we can just move forward.
        if (organization?.uuid) {
          props.onSubmit();
        } else {
          createOrganization(data);
        }
      })}
      loading={loading}
      completed={!!organization && isSubmitSuccessful}
    >
      <p style={{ marginBottom: "1em" }}>
        Let’s create your first Buildkite organization — a central place for your pipelines, test
        suites, teams and dashboards.
      </p>

      <Label htmlFor="organization">Organization name</Label>
      <Input
        id="organization"
        autoComplete="off"
        className="input"
        autoFocus={true}
        type="text"
        aria-invalid={isSubmitted && !!errors.name}
        disabled={!!organization}
        {...register("name", { required: true })}
      />
      {errors.name?.message && <ErrorMessage>{errors.name?.message}</ErrorMessage>}
    </Step>
  );
}

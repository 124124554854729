import { Popover, PopoverContent, PopoverTrigger } from "app/components/shared/PopoverNext";
import { TooltipLabelStyle, TooltipModel } from "chart.js";

const Title = ({ title }: { title: string }) => (
  <div className="w-full text-charcoal-700 border-b-2 border-gray-100 py-2 px-4">
    <h5 className="m-0">{title}</h5>
  </div>
);

const BodyLine = ({ line, color }: { line: string; color: TooltipLabelStyle }) => {
  const [title, count] = line.split(":");
  return (
    <div
      className="border-l-4 p-2 text-left"
      style={{ borderLeftColor: color.borderColor as string }}
    >
      <p className="m-0 font-medium text-charcoal-300">{title}</p>
      <p className="m-0 text-charcoal-900 text-sm font-medium">{count}</p>
    </div>
  );
};

type UsageTooltipProps = {
  tooltip: TooltipModel<"line"> | TooltipModel<"bar"> | TooltipModel<"doughnut">;
};

const Body = ({ tooltip: { body, labelColors } }: UsageTooltipProps) => (
  <div className="p-2 space-y-1">
    {body.map((bodyLine, idx) => {
      return <BodyLine key={bodyLine.lines[0]} line={bodyLine.lines[0]} color={labelColors[idx]} />;
    })}
  </div>
);

const UsageTooltip = ({ tooltip }: UsageTooltipProps) => (
  <Popover open={true}>
    <PopoverTrigger />
    <PopoverContent className="bg-white shadow-depth-100 border border-gray-100 min-w-[200px] w-auto rounded-md p-0">
      <Title title={tooltip.title[0]} />
      <Body tooltip={tooltip} />
    </PopoverContent>
  </Popover>
);

export default UsageTooltip;

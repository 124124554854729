import React from "react";
import { createRefetchContainer, graphql } from "react-relay";
import styled from "styled-components";

import Duration from "app/components/shared/Duration";
import Emojify from "app/components/shared/Emojify";

import BuildState from "app/components/icons/BuildState";

import { buildStatus } from "app/lib/builds";
import cable from "app/lib/cable";
import { shortMessage } from "app/lib/commits";
import { getDateString } from "app/lib/date";

const BuildLink = styled.a.attrs({
  className: "flex no-underline dark-gray hover-lime mb-2",
})`
  &:hover .build-link-message {
    color: inherit;
  }
`;

type RelayVariables = {
  uuid: string;
};

type Props = {
  showPipeline: boolean;
  build: {
    id: string;
    uuid: string;
    message: string;
    state: string;
    blockedState: string | null | undefined;
    createdAt: string | null | undefined;
    canceledAt: string | null | undefined;
    finishedAt: string | null | undefined;
    url: string;
    commit: string;
    pipeline: {
      name: string;
    };
  };
  relay: {
    refetch(
      variables: RelayVariables | null | undefined,
      renderVariables: any,
      onReadyStateChange: (error?: Error | null | undefined) => void | null | undefined,
      options: any,
    ): void;
  };
};

class BuildsDropdownBuild extends React.PureComponent<Props> {
  subscription: any;

  static defaultProps = {
    showPipeline: true,
  };

  componentDidMount() {
    this.subscription = cable.subscriptions.create(
      { channel: "Pipelines::BuildChannel", uuid: this.props.build.uuid },
      {
        component: this,

        received({ event }) {
          if (
            // Generically changed
            event === "updated" ||
            // Build state transitions which change appearance
            event === "started" ||
            event === "finished" ||
            event === "skipped" ||
            event === "canceling" ||
            // Happens when the commit is resolved from HEAD to a sha
            event === "commit:changed"
            // Notably excludes steps:changed
          ) {
            this.component.reload();
          }
        },
      },
    );
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  reload() {
    this.props.relay.refetch(
      {
        uuid: this.props.build.uuid,
      },
      null,
      // @ts-expect-error - TS2345 - Argument of type 'null' is not assignable to parameter of type '(error?: Error | null | undefined) => void | null | undefined'.
      null,
      { force: true },
    );
  }

  handleWebsocketEvent = (payload: any) => {
    if (payload.subevent === "build:updated" && payload.graphql.id === this.props.build.id) {
      this.reload();
    }
  };

  renderPipeline() {
    return (
      <>
        {" in "}
        <Emojify
          className="build-link-message semi-bold black"
          text={this.props.build.pipeline.name}
        />
      </>
    );
  }

  render() {
    const buildTime = buildStatus(this.props.build).timeValue;
    const buildTimeAbsolute = getDateString(buildTime);

    return (
      <BuildLink href={this.props.build.url}>
        <div className="pr-2 text-center">
          <BuildState.Small
            className="block"
            state={this.props.build.state}
            blockedState={this.props.build.blockedState}
          />
        </div>
        <div className="flex-auto min-w-0">
          <span
            className="block leading-tight overflow-hidden overflow-ellipsis"
            data-testId="buildMessage"
          >
            <Emojify
              className="build-link-message semi-bold black"
              text={shortMessage(this.props.build.message) || "…"}
            />
            {this.props.showPipeline && this.renderPipeline()}
          </span>
          <span className="block" title={buildTimeAbsolute}>
            <Duration.Medium from={buildTime} tabularNumerals={false} /> ago
          </span>
        </div>
      </BuildLink>
    );
  }
}

export default createRefetchContainer(
  BuildsDropdownBuild,
  {
    build: graphql`
      fragment build_build on Build {
        id
        uuid
        message
        state
        blockedState
        createdAt
        canceledAt
        finishedAt
        url
        commit
        pipeline {
          name
        }
      }
    `,
  },
  graphql`
    query buildQuery($uuid: ID!) {
      build(uuid: $uuid) {
        ...build_build
      }
    }
  `,
);

/* eslint-disable react/jsx-no-bind */
import React, { useEffect } from "react";
import { useBuild } from "app/components/build/Show/lib/BuildContext";
import { useFilterStore, FilterByOption, DisplayMode } from "./useFilterStore";
import DisplayModeDropdown from "./DisplayModeDropdown";
import { StepList } from "./StepList";
import { AnnotationsLink } from "./AnnotationsLink";
import Emojify from "app/components/shared/Emojify";

export default function Sidebar() {
  const { build } = useBuild();
  if (!build) {
    throw new Error("Missing build context");
  }

  const setFilterBy = useFilterStore((state) => state.setFilterBy);
  const displayMode = useFilterStore((state) => state.displayMode);

  useEffect(() => {
    // If already grouping by state, don't bother applying any filters when running.
    if (displayMode === DisplayMode.State && build.state === "failing") {
      return;
    }

    // If the build has failed, default the view to show failed steps
    if (build.state === "failed" || build.state === "failing") {
      setFilterBy([FilterByOption.Failed]);
    }
  }, []);

  return (
    <div
      className="absolute -left-2 -right-2 px-2 h-full overflow-y-auto no-scrollbar flex flex-col z-0"
      data-testid="sidebar"
    >
      <div className="flex flex-col sticky top-0 bg-white z-10 gap-2">
        {build.project.description && (
          <Emojify className="line-clamp-2 text-charcoal-300" text={build.project.description} />
        )}

        <AnnotationsLink {...build} />

        <div className="pb-0.5">
          <DisplayModeDropdown />
        </div>
      </div>

      <div className="flex flex-col gap-3 pt-0.5">
        <StepList />
      </div>
    </div>
  );
}

import Step from "./Step";
import { useFormContext } from "react-hook-form";
import { Form, Role as RoleEnum } from "../form";
import styled from "styled-components";
import { Checkable } from "../Field/Checkable";

const Grid = styled.div`
  display: grid;
  gap: 15px;
`;

interface Props {
  step: number;
  of: number;
  displayProgress: boolean;
  onSubmit: () => void;
}

const options = [
  { value: RoleEnum.Creator, label: "I maintain CI/CD for my team" },
  {
    value: RoleEnum.Platformer,
    label: "I work on our developer experience",
  },
  { value: RoleEnum.Architect, label: "I lead engineering" },
  {
    value: RoleEnum.TestChampion,
    label: "I champion our testing strategy",
  },
  {
    value: RoleEnum.Consumer,
    label: "I’m an engineer just trying to ship code",
  },

  { value: RoleEnum.Other, label: "Other" },
];

export default function Role(props: Props) {
  const { register, watch, handleSubmit } = useFormContext<Form>();
  const role = watch("role");

  return (
    <Step
      {...props}
      title="What’s your role?"
      disabled={!role}
      onSubmit={handleSubmit(props.onSubmit)}
    >
      <Grid>
        {options.map(({ value, label }) => (
          <Checkable type="radio" key={value} value={value} {...register("role")}>
            {value === RoleEnum.Other && role === value ? (
              <input autoFocus={true} type="text" {...register("otherRole")} placeholder={label} />
            ) : (
              label
            )}
          </Checkable>
        ))}
      </Grid>
    </Step>
  );
}

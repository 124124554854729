import Bar, { ActivityProps } from "./Bar";

type StackedBarsProps = {
  activities: Array<ActivityProps>;
  height: number;
};

const StackedBars = ({ activities, height }: StackedBarsProps) => {
  const totalDuration = activities.reduce((sum, activity) => sum + activity.duration, 0);

  const bars = (
    <>
      {activities.map((activity) => (
        <Bar key={activity.id} activity={activity} height={activity.duration / totalDuration} />
      ))}
    </>
  );

  return (
    <div
      className="flex flex-col items-start gap-y-[1px]"
      style={{ height: `${Math.round(height * 100.0)}%`, width: 12 }}
    >
      {bars}
    </div>
  );
};

export default StackedBars;

import { Job } from "app/components/build/Show/lib/types";
import { SortFn } from ".";
import { AgentIcon } from "app/components/job/Job/Agent";

export function Agent({ job }: { job: Job }) {
  if (!job.agentPath || job.type !== "script") {
    return null;
  }

  return (
    <div className="flex items-center gap-1">
      <AgentIcon os={job?.instanceShape?.operatingSystem} />
      <a
        href={job.agentPath}
        // eslint-disable-next-line react/jsx-no-bind, id-length
        onClick={(e) => e.stopPropagation()}
        className="text-inherit hover:text-inherit focus:text-inherit truncate max-w-full"
      >
        {job.agentName}
      </a>
    </div>
  );
}

// eslint-disable-next-line id-length
export const sortByAgent: SortFn = (direction) => (a, b) => {
  if (direction === "asc") {
    return b.agentName?.localeCompare(a.agentName);
  }
  return a.agentName?.localeCompare(b.agentName);
};

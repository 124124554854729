import { create } from "zustand";
import { useFetch, CachePolicies } from "use-http";
import { Step } from "app/lib/pipeline";

export type WaterfallBarType = {
  percentage_of_total: number;
  bar_start_percentage: number;
  waiting_percentage: number;
  dispatching_percentage: number;
  running_percentage: number;
  total_bar_duration: string;
  waiting_duration: string;
  dispatching_duration: string;
  running_duration: string;
  passed: boolean;
  job_url?: string | null;
};

export type WaterfallRowType = {
  bars: WaterfallBarType[];
  children: WaterfallRowType[];
  end_time: null | string;
  type: Step["type"];
  job_uuid?: string;
  step_uuid: string;
  job_url?: string | null;
  label: { text: string; format: "emojify" | "code" | "raw" };
  start_time: null | string;
  duration: string;
} & ({ parallel_group_index: number; parallel_group_total: number } | undefined);

export type WaterfallData = {
  chart_data: WaterfallRowType[];
  bar_container_padding: number;
};

interface WaterfallStore {
  data: WaterfallData | null;
  setData: (data: WaterfallData) => void;
}

export const useWaterfallStore = create<WaterfallStore>((set) => ({
  data: null,
  setData: (data) => set({ data }),
}));

import { createFragmentContainer, graphql } from "react-relay";
import { AttributeTable, AttributeTableRow } from "app/components/shared/AttributeTable";
import { UserAvatarRelay as UserAvatar } from "app/components/shared/UserAvatar";
import JobEvent from "../JobEvent";

// Unfortunately we need to pull this in to use for linking to Jobs.
import LegacyDispatcher from "app/lib/legacyDispatcher";

const ACTOR_TYPES = {
  SYSTEM: "SYSTEM",
  USER: "USER",
} as const;

function handleJobClick(event: any) {
  if (event.currentTarget.dataset.jobUuid) {
    event.preventDefault();
    // @ts-expect-error - TS2554 - Expected 6 arguments, but got 2.
    LegacyDispatcher.emit("job:focus", {
      job: {
        id: event.currentTarget.dataset.jobUuid,
        path: event.currentTarget.href,
      },
    });
  }
}

type Props = {
  event: any;
  previousTimestamp: string | null | undefined;
};

function JobEventRetriedAutomatically({ event, previousTimestamp }: Props) {
  if (!event.retriedInJob) {
    return null;
  }

  return (
    <JobEvent timestamp={event.timestamp} previousTimestamp={previousTimestamp}>
      <JobEvent.Label>
        {"Job Automatically "}
        <a
          onClick={handleJobClick}
          href={`#job-${event.retriedInJob.uuid}`}
          data-job-uuid={event.retriedInJob.uuid}
        >
          Retried
        </a>
        {" Due to Matching Retry Rule"}
      </JobEvent.Label>
      <JobEvent.Content>
        {event.automaticRule ? (
          <AttributeTable>
            <AttributeTableRow title="Retry Exit Status">
              <code className="dark-gray monospace">
                {event.automaticRule.exitStatus}{" "}
                {event.automaticRule.exitStatus === "*" ? "(wildcard)" : null}
              </code>
            </AttributeTableRow>
            <AttributeTableRow title="Retry Signal">
              <code className="dark-gray monospace">
                {event.automaticRule.signal}{" "}
                {event.automaticRule.signal === "*" ? "(wildcard)" : null}
              </code>
            </AttributeTableRow>
            <AttributeTableRow title="Retry Signal Reason">
              <code className="dark-gray monospace">
                {event.automaticRule.signalReason}{" "}
                {event.automaticRule.signalReason === "*" ? "(wildcard)" : null}
              </code>
            </AttributeTableRow>
            <AttributeTableRow title="Retry Limit">
              <code className="dark-gray monospace">{event.automaticRule.limit}</code>
            </AttributeTableRow>
            <AttributeTableRow title="Retried Job UUID">
              <code className="dark-gray monospace">{event.retriedInJob.uuid}</code>
            </AttributeTableRow>
          </AttributeTable>
        ) : null}
      </JobEvent.Content>
    </JobEvent>
  );
}

function JobEventRetriedManually({
  event: { timestamp, actor, retriedInJob },
  previousTimestamp,
}: Props) {
  if (!actor || !actor.node || !retriedInJob) {
    return null;
  }

  return (
    <JobEvent timestamp={timestamp} previousTimestamp={previousTimestamp}>
      <JobEvent.Label>
        {actor.node.uuid && (
          <>
            <UserAvatar className="mr-1" size="MICRO" user={actor.node} suppressAltText={true} />
            {actor.node.name}{" "}
          </>
        )}
        {"Manually "}
        <a
          onClick={handleJobClick}
          href={`#job-${retriedInJob.uuid}`}
          data-job-uuid={retriedInJob.uuid}
        >
          Retried
        </a>
        {" Job"}
      </JobEvent.Label>
      <JobEvent.Content>
        <AttributeTable>
          <AttributeTableRow title="Retried Job UUID">
            <code className="dark-gray monospace">{retriedInJob.uuid}</code>
          </AttributeTableRow>
        </AttributeTable>
      </JobEvent.Content>
    </JobEvent>
  );
}

function JobEventRetried(props: Props) {
  switch (props.event.actor.type) {
    case ACTOR_TYPES.SYSTEM:
      return <JobEventRetriedAutomatically {...props} />;
    case ACTOR_TYPES.USER:
      return <JobEventRetriedManually {...props} />;
  }

  return null; // In case something weird happens, lol?
}

export default createFragmentContainer(JobEventRetried, {
  event: graphql`
    fragment JobEventRetried_event on JobEvent {
      ... on JobEvent {
        timestamp

        actor {
          type
          node {
            ... on User {
              name
              uuid
              ...UserAvatar_user
            }
          }
        }

        ... on JobEventRetried {
          automaticRule {
            exitStatus
            signal
            signalReason
            limit
          }
          retriedInJob {
            uuid
          }
        }
      }
    }
  `,
});

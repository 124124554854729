import { createFragmentContainer, graphql } from "react-relay";
import AgentBadge from "app/components/AgentBadge";
import AgentUpgradeBanner from "app/components/AgentUpgradeBanner";
import { AttributeTable, AttributeTableRow } from "app/components/shared/AttributeTable";
import JobEvent from "../JobEvent";

type Props = {
  event: any;
  previousTimestamp: string | null | undefined;
};

function JobEventAccepted({ event, previousTimestamp }: Props) {
  const {
    timestamp,
    actor: { node: agent },
  } = event;

  return (
    <JobEvent timestamp={timestamp} previousTimestamp={previousTimestamp}>
      <JobEvent.Label>{agent ? <AgentBadge agent={agent} /> : "Agent"} Accepted Job</JobEvent.Label>
      {agent ? (
        <JobEvent.Content>
          {agent.versionHasKnownIssues ? <AgentUpgradeBanner agent={agent} /> : null}
          <AttributeTable>
            <AttributeTableRow title="Host name">
              <code className="dark-gray monospace">{agent.hostname}</code>
            </AttributeTableRow>
            {agent.operatingSystem ? (
              <AttributeTableRow title="Operating System">
                <code className="dark-gray monospace">{agent.operatingSystem.name}</code>
              </AttributeTableRow>
            ) : null}
            <AttributeTableRow title="Agent Version">
              <code className="dark-gray monospace">{agent.version}</code>
            </AttributeTableRow>
          </AttributeTable>
        </JobEvent.Content>
      ) : null}
    </JobEvent>
  );
}

export default createFragmentContainer(JobEventAccepted, {
  event: graphql`
    fragment JobEventAccepted_event on JobEvent {
      ... on JobEvent {
        timestamp

        actor {
          node {
            ...AgentBadge_agent
            ...AgentUpgradeBanner_agent
            ... on Agent {
              hostname
              version
              versionHasKnownIssues
              isDeprecated
              operatingSystem {
                name
              }
            }
          }
        }
      }
    }
  `,
});

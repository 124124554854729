import { useMostRecentJob } from "app/components/build/Show/lib/BuildContext";
import { twMerge } from "tailwind-merge";
import { shortCommit } from "app/lib/commits";
import { isFailed, TriggerStep } from "app/lib/pipeline";
import { TriggerJob } from "app/components/build/Show/lib/types";
import { StateIcon } from "app/components/Playground/Node/BaseNode/StateIcon";
import { textColorForStep } from "app/components/Playground/Node/colors";
import Emojify from "app/components/shared/Emojify";
import { Label } from "./Label";
import { StepLink } from "./StepLink";
import { RunDuration } from "app/components/job/Job/JobDuration";
import { resolveStepUrl } from "app/components/build/Show/lib/urlForView";
import useCurrentJobOrStep from "app/components/build/Show/lib/useCurrentJobOrStep";

interface TriggerStepListItemProps {
  step: TriggerStep;
  depth?: number;
}

export function TriggerStepListItem({ step, depth = 0 }: TriggerStepListItemProps) {
  const job = useMostRecentJob<TriggerJob>(step.uuid);
  const { currentStep } = useCurrentJobOrStep();

  let label;
  let meta;

  if (job) {
    const commit = shortCommit(job.triggerCommit, job.triggerCommitShortLength);
    const pipeline = job.triggerProject?.name || step.triggerPipelineSlug;
    label = step.label || "Trigger build";
    meta = `${job.triggerBranch}\x2F${commit} on ${pipeline}`;
  } else {
    label = step.label || "Trigger step";
    meta = null;
  }

  return (
    <StepLink
      to={resolveStepUrl(step.uuid)}
      type={step.type}
      active={step.uuid === currentStep?.uuid}
      failed={isFailed(step)}
      depth={depth}
    >
      <Label
        duration={job?.startedAt && !job.triggerAsync && <RunDuration job={job} />}
        status={<StateIcon className={twMerge("w-4 -mr-0.5", textColorForStep(step))} {...step} />}
      >
        <Emojify text={label} />
        {meta && <span className="font-mono font-normal text-charcoal-300 text-xs">{meta}</span>}
      </Label>
    </StepLink>
  );
}

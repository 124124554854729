import { Edge } from "reactflow";

// Take an array of edges and remove any that are transitively implied by other edges.
//
// https://en.wikipedia.org/wiki/Transitive_reduction
//
// This is a port of the algorithm described here:
// https://www.cs.tufts.edu/comp/150FP/archive/al-aho/transitive-reduction.pd
export function transitiveReduction(edges: Edge[]): Edge[] {
  type AdjacencyMap = Map<string, Map<string, Edge>>;

  const graph: AdjacencyMap = new Map();

  // Build an adjacency map of the graph to make it easier to traverse.
  for (const edge of edges) {
    const { source, target } = edge;
    if (!graph[source]) {
      graph[source] = new Map();
    }
    graph[source][target] = edge;
  }

  // Depth-first search with a callback for each vertex visited.
  //
  // If the callback returns true, the search is terminated early.
  //
  function dfs(graph: AdjacencyMap, start: string, visit: (vertex: string) => boolean | undefined) {
    const stack: string[] = [start];
    const visited = new Set();

    while (stack.length) {
      const vertex = stack.pop();

      if (vertex && !visited.has(vertex)) {
        if (visit(vertex)) {
          return;
        }

        visited.add(vertex);

        for (const neighbor of graph[vertex]) {
          stack.push(neighbor);
        }
      }
    }
  }

  let output: Edge[] = [...edges];

  // Iterate over all direct successors of all vertices in the graph, and start a
  // depth-first search from each successor.
  //
  // For each vertex visited in the DFS, take a look at its direct successors.
  // If there is an edge between the top-level iteration vertex and the successor
  // of the current node, this edge can be removed.
  //
  for (const vertex in graph) {
    for (const successor in graph[vertex]) {
      dfs(graph, successor, (current: string) => {
        if (!graph[current]) {
          return true;
        }

        for (const edge in graph[current]) {
          if (graph[vertex][edge]) {
            // eslint-disable-next-line id-length
            const e = graph[vertex][edge];
            output = output.filter((edge) => edge.id !== e.id);
          }
        }
      });
    }
  }

  return output;
}

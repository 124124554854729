/* eslint-disable id-length */
import Icon from "app/components/shared/Icon";
import BaseNode from "./BaseNode";
import * as HoverCard from "./StepHoverCard";
import { NodeProps, getIncomers, useReactFlow } from "reactflow";
import { Outcome, State } from "app/lib/pipeline/Step";
import { WaitStep } from "app/lib/pipeline";

const useCheckIfAllIncomingHavePassed = (nodeId: string) => {
  const { getNodes, getEdges } = useReactFlow();

  const incomers = getIncomers(
    { id: nodeId, position: { x: 0, y: 0 }, data: {} },
    getNodes(),
    getEdges(),
  );

  let passed = true;
  for (const node of incomers) {
    if (node.data.state && node.data.outcome !== "passed") {
      passed = false;
      break;
    }
  }

  return passed;
};

export const WaitIcon = (props: { passed: boolean }) => (
  <Icon
    className="text-current h-5 w-5 stroke-2"
    icon={props.passed ? "heroicons/outline/chevron-right" : "heroicons/solid/pause"}
  />
);

/**
 * A node representing a wait step
 *
 * Wait step is slightly different from other steps in that it doesn't have a good state or outcome for
 * theming purposes. It's either waiting for dependencies or it's not. It can never be in a failed state.
 *
 * For theming, we determine the state of all upstream dependencies and if they all passed, we theme appropriately.
 * If any of them failed, we theme as if it's waiting for dependencies.
 */
export default function WaitStepNode(props: NodeProps<WaitStep>) {
  let passed = false;
  let { state, outcome } = props.data;

  if (state !== State.Ignored) {
    state = props.data.state;
    outcome = props.data.outcome;
    passed = state === State.Finished;
  }

  return (
    <HoverCard.Root>
      <HoverCard.Portal>
        {props.data.state && (
          <HoverCard.Content
            {...props}
            // manually set the tooltip state and outcome
            data={{
              ...props.data,
              state,
              outcome,
            }}
          >
            Wait
          </HoverCard.Content>
        )}
      </HoverCard.Portal>

      <HoverCard.Trigger asChild={true}>
        <div data-testid="wait-step-node">
          <BaseNode
            hideStateIcon={true}
            {...props}
            data={{
              ...props.data,
              state,
              outcome,
            }}
            icon={<WaitIcon passed={passed} />}
          />
        </div>
      </HoverCard.Trigger>
    </HoverCard.Root>
  );
}

import Chooser from "app/components/shared/Chooser";
import Dropdown from "app/components/shared/Dropdown";
import PermissionDescription from "app/components/shared/PermissionDescription";
import PermissionSelectOptionDescriptions from "app/components/shared/PermissionSelectOptionDescriptions";

const MANAGE_AND_READ = "MANAGE_AND_READ";
const READ_ONLY = "READ_ONLY";

const getLabel = (value) => {
  switch (value) {
    case MANAGE_AND_READ:
      return "Full Access";
    case READ_ONLY:
      return "Read Only";
  }
};

const DropdownWithChooser = ({ saving, selected, onAccessLevelChange }) => (
  <Dropdown width={270}>
    <div className="underline-dotted cursor-pointer inline-block font-normal">
      {getLabel(selected)}
    </div>

    <Chooser selected={selected} onSelect={onAccessLevelChange}>
      <Chooser.SelectOption
        value={MANAGE_AND_READ}
        saving={saving === MANAGE_AND_READ}
        selected={selected === MANAGE_AND_READ}
        label={getLabel(MANAGE_AND_READ)}
        description={
          <PermissionSelectOptionDescriptions>
            <PermissionDescription allowed={true} permission="view runs" />
            <PermissionDescription allowed={true} permission="edit suite settings" />
          </PermissionSelectOptionDescriptions>
        }
      />
      <Chooser.SelectOption
        value={READ_ONLY}
        label={getLabel(READ_ONLY)}
        saving={saving === READ_ONLY}
        selected={selected === READ_ONLY}
        description={
          <PermissionSelectOptionDescriptions>
            <PermissionDescription allowed={true} permission="view runs" />
            <PermissionDescription allowed={false} permission="edit suite settings" />
          </PermissionSelectOptionDescriptions>
        }
      />
    </Chooser>
  </Dropdown>
);

const AccessLevel = ({ saving, teamSuite, onAccessLevelChange }) => {
  if (teamSuite.permissions.teamSuiteUpdate.allowed) {
    return (
      <DropdownWithChooser
        saving={saving}
        selected={teamSuite.accessLevel}
        onAccessLevelChange={onAccessLevelChange}
      />
    );
  }
  return <span className="dark-gray">{getLabel(teamSuite.accessLevel)}</span>;
};

export default AccessLevel;

import { Component } from "react";

import LegacyDispatcher from "app/lib/legacyDispatcher";
import BuildRow from "app/components/build/BuildRow";

import BranchStore, { Branch, Project } from "app/stores/BranchStore";
import Icon from "app/components/shared/Icon";

type Props = {
  branch: Branch;
  project: Project;
  store: BranchStore;
};

// eslint-disable-next-line react/require-optimization
export default class BranchListBranch extends Component<Props> {
  render() {
    const starredClassName = this.props.branch.starred
      ? "better-branch-starred"
      : "better-branch-not-starred";

    return (
      <div
        className={`panel mb-4 panel-default better-branch-panel ${starredClassName} mb-6 border-none depth-150 rounded-1.5 antialiased`}
        data-branch={this.props.branch.name}
      >
        <div className="panel-heading flex items-center bg-slate-100 rounded-top-1.5 charcoal-700">
          <div className="flex-auto min-w-0">
            <h2 className="flex items-center text-sm m-0 font-normal">
              <Icon
                icon="custom/outline/14x/branch"
                className="mr0.5"
                style={{ height: 14, width: 14 }}
              />
              <a
                className="no-highlight-link better-branch-panel-name hover-color-inherit"
                href={this.props.branch.path}
              >
                {this.props.branch.name}
              </a>
            </h2>
          </div>
          <div className="flex items-center">
            {this.renderStarButton()}
            {this.renderRemoveButton()}
          </div>
        </div>
        {this.props.branch.builds &&
          this.props.branch.builds.map((build) => (
            <BuildRow
              key={build.id}
              // @ts-expect-error - TS2740 - Type 'Build' is missing the following properties from type '{ id: string; authorName: string; blockedState: string | null; branchName: string; branchPath: string; canceledAt: string | null; commitId: string; commitShortLength: number; commitUrl: string; ... 10 more ...; project: { ...; }; }': blockedState, canceledAt, commitUrl, finishedAt, and 2 more.
              build={build}
              // @ts-expect-error - TS2740 - Type 'BranchStore' is missing the following properties from type 'BuildsStore': database, query, reloadInProgress, reload, and 2 more.
              store={this.props.store}
              showStepsToggle={false}
            />
          ))}
      </div>
    );
  }

  renderStarButton() {
    if (this.props.project.permissions.favoriteProject.allowed) {
      if (this.props.branch.starred) {
        return (
          <a
            href="#"
            onClick={this.handleUnstarClick}
            className="better-branch-star-button no-title-tooltip h-4 w-4"
            aria-label={`Unfavorite ${this.props.branch.name} branch`}
            title={`Unfavorite ${this.props.branch.name} branch`}
          >
            <Icon icon="heroicons/solid/star" className="h-4 w-4 block" />
          </a>
        );
      }
      return (
        <a
          href="#"
          onClick={this.handleStarClick}
          className="better-branch-star-button no-title-tooltip h-4 w-4"
          aria-label={`Favorite ${this.props.branch.name} branch`}
          title={`Favorite ${this.props.branch.name} branch`}
        >
          <Icon icon="heroicons/outline/star" className="h-4 w-4 block" />
        </a>
      );
    }

    return null;
  }

  renderRemoveButton() {
    if (this.props.project.permissions.edit.allowed) {
      return (
        <a
          href="#"
          onClick={this.handleRemoveClick}
          className="better-branch-remove-button hover-color-inherit no-title-tooltip h-4 w-4"
          aria-label={`Remove ${this.props.branch.name} branch`}
          title={`Remove ${this.props.branch.name} branch`}
        >
          <Icon icon="heroicons/outline/no-symbol" className="h-4 w-4 block" />
        </a>
      );
    }
  }

  handleStarClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    // @ts-expect-error - TS2554 - Expected 6 arguments, but got 2.
    LegacyDispatcher.emit("project_branch:star", {
      project: this.props.project,
      branch: this.props.branch.name,
    });
  };

  handleUnstarClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    // @ts-expect-error - TS2554 - Expected 6 arguments, but got 2.
    LegacyDispatcher.emit("project_branch:unstar", {
      project: this.props.project,
      branch: this.props.branch.name,
    });
  };

  handleRemoveClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    if (confirm("Remove this branch until new builds are pushed?")) {
      this.props.store.removeBranch(this.props.branch.name);
    }
  };
}

import { PureComponent } from "react";
import { createFragmentContainer, graphql } from "react-relay";
import classNames from "classnames";

import BuildState from "app/components/icons/BuildState";
import { getColourForConnectionState, getLabelForConnectionState } from "./shared";

import BuildStates from "app/constants/BuildStates";

type Props = {
  agent: {
    connectionState: string;
    isRunningJob: boolean;
  };
  className?: string;
  style?: any;
};

class AgentStateIcon extends PureComponent<Props> {
  render() {
    const { agent, className } = this.props;

    let title;
    let icon;

    // If the agent is running a job, we'll steal the "running" icon
    // from the build state to show that the agent is doing something.
    if (agent.connectionState === "connected" && agent.isRunningJob) {
      title = "Connected - Running Job";

      icon = <BuildState.XSmall state={BuildStates.RUNNING} style={{ display: "inline-block" }} />;
    } else {
      const color = getColourForConnectionState(agent.connectionState, "bg-");

      title = getLabelForConnectionState(agent.connectionState);

      icon = (
        <div
          className={classNames("inline-block rounded-full", color)}
          style={{ width: 13, height: 13 }}
          title={getLabelForConnectionState(agent.connectionState)}
        />
      );
    }

    return (
      <div
        title={title}
        className={classNames("inline align-middle", className)}
        style={this.props.style}
      >
        {icon}
      </div>
    );
  }
}

export default createFragmentContainer(AgentStateIcon, {
  agent: graphql`
    fragment AgentStateIcon_agent on Agent {
      connectionState
      isRunningJob
    }
  `,
});

/* eslint-disable react/display-name */

import { useState } from "react";
import { commitMutation, createRefetchContainer, graphql } from "react-relay";

import AutocompleteDialog from "app/components/shared/Autocomplete/Dialog";
import Button from "app/components/shared/Button";
import Emojify from "app/components/shared/Emojify";
import permissions from "app/lib/permissions";
import Environment from "app/lib/relay/environment";
import FlashesStore from "app/stores/FlashesStore";

const Chooser = ({ team, relay, onChoose, disabled }) => {
  const [loading, setLoading] = useState(false);
  const [showingDialog, setShowingDialog] = useState(false);
  const [search, setSearch] = useState(null);

  const handleDialogOpen = () => {
    setLoading(true);

    relay.refetch(
      { teamSelector: `!${team.slug}`, suiteAddSearch: "" },
      null,
      () => {
        setLoading(false);
      },
      { force: true },
    );

    setShowingDialog(true);
  };

  const handleDialogClose = () => {
    setShowingDialog(false);
    setSearch(null);
  };

  const handleSuiteSearch = (suiteAddSearch) => {
    setSearch(suiteAddSearch);

    relay.refetch(
      {
        teamID: team.id,
        suiteAddSearch: suiteAddSearch,
        teamSelector: `!${team.slug}`,
      },
      null,
      null,
      { force: true },
    );
  };

  const handleSuiteSelect = (suite) => {
    setShowingDialog(false);
    const environment = Environment.get();

    const input = { teamID: team.id, suiteID: suite.id };
    commitMutation(environment, {
      mutation: graphql`
        mutation SuitesChooserMutation($input: TeamSuiteCreateInput!) {
          teamSuiteCreate(input: $input) {
            clientMutationId
            team {
              id
              suites {
                count
              }
            }
          }
        }
      `,
      variables: { input },
      onCompleted: onChoose,
      onError: (error) => {
        FlashesStore.flash(FlashesStore.ERROR, error.message);
      },
    });
  };

  // Logic to render dropdown suggestion
  const renderAutoCompleteSuggestions = () => {
    // Render Loader if we're still loading or org has no suites has no edges.
    if (!team.organization?.suites || loading) {
      return [<AutocompleteDialog.Loader key="loading" />];
    }

    // If we have suites from the response, render them as suggestions
    if (team.organization.suites.edges.length > 0) {
      return team.organization.suites.edges.map(({ node }) => {
        return [
          <div key={node.id}>
            <strong className="truncate max-w-full semi-bold block" title={node.name}>
              <Emojify text={node.name} />
            </strong>
            <small className="truncate max-w-full dark-gray block" title={node.slug}>
              {node.slug}
            </small>
          </div>,
          node,
        ];
      });
    } else if (search !== "") {
      // If suites is empty but user has searched for a suite, render
      return [
        <AutocompleteDialog.ErrorMessage key="error">
          Could not find a test suite with name <em>{search}</em>
        </AutocompleteDialog.ErrorMessage>,
      ];
    }

    // If none of the above match - The user most likely hasn't searched anything and also has no suites.
    // i.e they have no suites in their org.
    return [
      <AutocompleteDialog.ErrorMessage key="error">
        Could not find any more test suites to add
      </AutocompleteDialog.ErrorMessage>,
    ];
  };

  return permissions(team.permissions).check({
    allowed: "teamSuiteCreate",
    render: () => (
      <div>
        <Button className="mb-3" theme="primary" onClick={handleDialogOpen} disabled={disabled}>
          Add Test Suite
        </Button>
        <AutocompleteDialog
          isOpen={showingDialog}
          onRequestClose={handleDialogClose}
          onSearch={handleSuiteSearch}
          onSelect={handleSuiteSelect}
          items={renderAutoCompleteSuggestions()}
          placeholder="Search all test suites…"
          selectLabel="Add"
          popover={false}
        />
      </div>
    ),
  });
};

const TeamsSuitesChooserContainer = createRefetchContainer(
  Chooser,
  {
    team: graphql`
      fragment SuitesChooser_team on Team
      @argumentDefinitions(
        suiteAddSearch: { type: "String", defaultValue: "" }
        teamSelector: { type: "TeamSelector" }
      ) {
        id
        slug
        organization {
          suites(search: $suiteAddSearch, first: 10, order: NAME, team: $teamSelector) {
            edges {
              node {
                id
                name
                slug
              }
            }
          }
        }

        permissions {
          teamSuiteCreate {
            allowed
          }
        }
      }
    `,
  },
  graphql`
    query SuitesChooserQuery($team: ID!, $suiteAddSearch: String, $teamSelector: TeamSelector) {
      team(slug: $team) {
        ...SuitesChooser_team
          @arguments(suiteAddSearch: $suiteAddSearch, teamSelector: $teamSelector)
      }
    }
  `,
);

export default TeamsSuitesChooserContainer;

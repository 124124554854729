import { useState, useRef, useEffect } from "react";
import { Goal } from "../form";
import FeedItem from "./FeedItem";
import Card from "./Card";
import SlideDownTransition from "./SlideDownTransition";
import Icon from "app/components/shared/Icon";
import { AnimatePresence } from "framer-motion";

type FeatureKey = keyof typeof features;

const mapping: { [key in Goal]: FeatureKey } = {
  [Goal.Security]: "CLUSTERS",
  [Goal.Performance]: "DYNAMIC_PIPELINES",
  [Goal.UnblockDevelopers]: "UNLIMITED_CONCURRENCY",
  [Goal.ReduceCosts]: "SELF_HOSTED_AGENTS",
  [Goal.ImproveTestQuality]: "FLAKY_TEST_DETECTION",
  [Goal.NewTools]: "FREE_TRIAL",
  [Goal.Other]: "SUPPORT",
  [Goal.Flexibility]: "INTEGRATIONS",
};

const features = {
  SELF_HOSTED_AGENTS: {
    title: "Self-hosted agents",
    description: "You control the environment where builds run",
    icon: "heroicons/solid/shield-check",
  },
  UNLIMITED_CONCURRENCY: {
    title: "Unlimited concurrency",
    description: "Scale based on your usage",
    icon: "heroicons/solid/arrows-pointing-in",
  },
  DYNAMIC_PIPELINES: {
    title: "Dynamic pipelines",
    description: "Generate and update pipeline definitions at runtime",
    icon: "heroicons/solid/bolt",
  },
  CLUSTERS: {
    title: "Clusters",
    description: "Create isolated workflows to enforce and delegate control",
    icon: "custom/outline/cluster",
  },
  INTEGRATIONS: {
    title: "Integrations",
    description: "Customize with webhooks, APIs, plugins, and more",
    icon: "heroicons/solid/rectangle-group",
  },
  FLAKY_TEST_DETECTION: {
    title: "Flaky test detection",
    description: "Get insights to improve your tests",
    icon: "heroicons/solid/beaker",
  },
  FREE_TRIAL: {
    title: "Free trial",
    description: "Use all the Pro features for 30 days before paying",
    icon: "heroicons/solid/building-office",
  },
  SUPPORT: {
    title: "Support",
    description: "Follow our guides and reach out if you need help",
    icon: "custom/outline/life-buoy",
  },
};

interface Props {
  goals: Goal[];
}

export default function Features({ goals }: Props) {
  const [items, setItems] = useState<FeatureKey[]>(goals.map((goal) => mapping[goal]));

  // Keep track of previous goals so we can add/remove items in a push based way
  const goalsRef = useRef<Goal[]>(goals);

  useEffect(() => {
    const addItem = (goal: Goal) => {
      setItems((items) => [mapping[goal], ...items]);
    };

    const removeItem = (goal: Goal) => {
      setItems((items) => items.filter((item) => item !== mapping[goal]));
    };

    if (goals.length > goalsRef.current.length) {
      addItem(goals.filter((goal) => !goalsRef.current.includes(goal))[0]);
    } else if (goals.length < goalsRef.current.length) {
      removeItem(goalsRef.current.filter((goal) => !goals.includes(goal))[0]);
    }

    goalsRef.current = goals;
  }, [goals]);

  if (!goals.length) {
    return null;
  }

  return (
    <Card title="We think you’ll love">
      <AnimatePresence initial={false}>
        {items.map((key, idx) => {
          return (
            <SlideDownTransition
              key={key}
              style={{
                position: "relative",
                zIndex: items.length - idx,
                background: "var(--base-0)",
              }}
            >
              <FeedItem
                title={features[key].title}
                image={<Icon icon={features[key].icon} />}
                divider={idx > 0}
                backgroundColor="#f1efff"
                imageColor="var(--purple-600)"
              >
                {features[key].description}
              </FeedItem>
            </SlideDownTransition>
          );
        })}
      </AnimatePresence>
    </Card>
  );
}

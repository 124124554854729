import { queue } from "async";
import moment from "moment";

import { BuildStates } from "app/components/icons/BuildState";

import cable from "app/lib/cable";
import Database from "app/lib/Database";
import Logger from "app/lib/Logger";
import BaseStore from "app/stores/BaseStore";
import UserStore from "app/stores/UserStore";

export type Project = {
  id: string;
  defaultBranch: string | null | undefined;
  name: string;
  permissions: {
    edit: {
      allowed: boolean;
    };
    favoriteProject: {
      allowed: boolean;
    };
  };
};

export type Branch = {
  name: string;
  path: string;
  starred?: boolean;
  project?: Project;
  builds?: Array<Build>;
};

export type Build = {
  id: string;
  number: number;
  message: string;
  state: BuildStates;
  path: string;
  project: Project;
  commitId: string;
  commitShortLength: number;
  branchName: string;
  branchPath: string;
  createdAt: string;
  authorName: string;
};

// NOTE: Keep in sync with Project::Summary::BUILDS_PER_BRANCH_LIMIT
const BUILDS_PER_BRANCH_LIMIT = 5;

export default class BranchStore extends BaseStore {
  branches: {
    [key: string]: Branch;
  };

  constructor(project: Project, builds: Array<Build>) {
    // Call super on the parent store
    super();

    // Parse the project data
    // @ts-expect-error - TS2339 - Property 'project' does not exist on type 'BranchStore'.
    this.project = Database.parse(project);

    // Create a database and seed it with data
    // @ts-expect-error - TS2339 - Property 'database' does not exist on type 'BranchStore'.
    this.database = new Database(builds);

    // Index the builds based on branch
    this.branches = {};
    // @ts-expect-error - TS2339 - Property 'database' does not exist on type 'BranchStore'.
    for (const build of this.database.all()) {
      this._parseBranchInformation(build);
    }

    // Create a queue for updates so we're not overloading the backend with requests
    // @ts-expect-error - TS2339 - Property 'queue' does not exist on type 'BranchStore'.
    this.queue = queue((number, callback) => {
      Logger.info(`[BranchStore] Refreshing build number ${number}`);

      // @ts-expect-error - TS2339 - Property 'project' does not exist on type 'BranchStore'.
      return fetch(`${this.project.path}/builds/${number}.json?index=true`, {
        headers: { "X-Buildkite-Frontend-Version": BUILDKITE_FRONTEND_VERSION },
      })
        .then((response) => response.json())
        .then((data) => this.loadAndEmit(data))
        .then(callback)
        .catch((error) => callback(error));
    });

    // @ts-expect-error - TS2339 - Property 'pipelineSubscription' does not exist on type 'BranchStore'.
    this.pipelineSubscription = cable.subscriptions.create(
      // @ts-expect-error - TS2339 - Property 'project' does not exist on type 'BranchStore'.
      { channel: "Pipelines::PipelineChannel", uuid: this.project.id },
      {
        store: this,
        received({ event, ...payload }) {
          if (event === "build:created") {
            this.store.queue.push(payload.number);
          }
        },
      },
    );

    // @ts-expect-error - TS2339 - Property 'buildSubscriptions' does not exist on type 'BranchStore'.
    this.buildSubscriptions = {};
    // @ts-expect-error - TS2339 - Property 'database' does not exist on type 'BranchStore'.
    for (const build of this.database.all()) {
      this._subscribeToBuild(build);
    }
  }

  all(): Array<Branch> {
    // The starred branches are in the order at which they should appear on the page
    let viewRecord;
    const starredBranches: Array<Branch> = [];
    this._starredBranches().forEach((branch) => {
      viewRecord = this._createBranchViewRecord(branch, true);
      if (viewRecord) {
        starredBranches.push(viewRecord);
      }
    });

    // The rest of the branches
    const nonStarredBranches: Array<Branch> = [];
    this._nonStarredBranches().forEach((branch) => {
      viewRecord = this._createBranchViewRecord(branch, false);
      if (viewRecord) {
        nonStarredBranches.push(viewRecord);
      }
    });

    // Return the view records
    return this._sortBranches(starredBranches).concat(this._sortBranches(nonStarredBranches));
  }

  getProject() {
    // @ts-expect-error - TS2339 - Property 'project' does not exist on type 'BranchStore'.
    return this.project;
  }

  removeBranch(branch: string) {
    // Fire and forget
    const formData = new FormData();
    formData.append("branch", branch);

    // @ts-expect-error - TS2339 - Property 'project' does not exist on type 'BranchStore'.
    fetch(`${this.project.path}/branches`, {
      method: "DELETE",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": window._csrf.token,
        "X-Buildkite-Frontend-Version": BUILDKITE_FRONTEND_VERSION,
      },
      body: formData,
    });

    delete this.branches[branch];

    this.emit("change");
  }

  _sortBranches(branches: Array<Branch>): Array<Branch> {
    // First, split the branches up into default/non-default
    const defaultBranch: Array<Branch> = [];
    const otherBranches: Array<Branch> = [];

    for (const branch of branches) {
      if (
        // @ts-expect-error - TS2339 - Property 'project' does not exist on type 'BranchStore'.
        this.project.defaultBranch &&
        // @ts-expect-error - TS2339 - Property 'project' does not exist on type 'BranchStore'.
        branch.name === this.project.defaultBranch
      ) {
        defaultBranch.push(branch);
      } else {
        otherBranches.push(branch);
      }
    }

    // Now sort the other branches by last build
    const sortedBranches = otherBranches.sort((branchA, branchB) => {
      if (!Array.isArray(branchA.builds) || branchA.builds.length === 0) {
        return -1;
      }

      const buildALastBuild = moment(branchA.builds[0].createdAt).unix();

      if (!Array.isArray(branchB.builds) || branchB.builds.length === 0) {
        return 1;
      }

      const buildBLastBuild = moment(branchB.builds[0].createdAt).unix();

      if (buildALastBuild === buildBLastBuild) {
        return 0;
      } else if (buildALastBuild >= buildBLastBuild) {
        return -1;
      }

      return 1;
    });

    // Return the default branch first, followed by other branches
    return defaultBranch.concat(sortedBranches);
  }

  _createBranchViewRecord(branchName: string, starred: boolean) {
    const branch = this.branches[branchName];

    if (!branch) {
      return null;
    }

    return {
      name: branchName,
      starred,
      path: branch.path,
      builds: this._findBuildsForBranch(branchName).slice(0, BUILDS_PER_BRANCH_LIMIT), // only show the last BUILDS_PER_BRANCH_LIMIT
    };
  }

  // Find the builds for a given branch
  _findBuildsForBranch(branchName: string) {
    return (
      // @ts-expect-error - TS2339 - Property 'database' does not exist on type 'BranchStore'.
      this.database
        .all()
        .filter((build) => build.branchName === branchName)
        // Sort the builds on the branch by createdAt desc (newest first)
        .sort((buildA, buildB) => moment(buildB.createdAt).unix() - moment(buildA.createdAt).unix())
    );
  }

  // Stores information about the branch as we parse builds
  _parseBranchInformation(build: Build) {
    return (
      this.branches[build.branchName] ||
      (this.branches[build.branchName] = {
        name: build.branchName,
        path: build.branchPath,
      })
    );
  }

  loadAndEmit(build: Build) {
    // We only care about this build if it matches the project
    // @ts-expect-error - TS2339 - Property 'project' does not exist on type 'BranchStore'.
    if (build.project.id === this.project.id) {
      // @ts-expect-error - TS2339 - Property 'database' does not exist on type 'BranchStore'.
      const newBuild = this.database.load(build);
      this._parseBranchInformation(newBuild);
      this._subscribeToBuild(newBuild);
      this._cleanUpSubscriptions(newBuild.branchName);
      this.emit("change");
    }
  }

  _cleanUpSubscriptions(branchName: string) {
    this._findBuildsForBranch(branchName)
      .slice(BUILDS_PER_BRANCH_LIMIT)
      .forEach((build) => {
        // @ts-expect-error - TS2339 - Property 'buildSubscriptions' does not exist on type 'BranchStore'.
        if (this.buildSubscriptions[build.id]) {
          // @ts-expect-error - TS2339 - Property 'buildSubscriptions' does not exist on type 'BranchStore'.
          this.buildSubscriptions[build.id].unsubscribe();
          // @ts-expect-error - TS2339 - Property 'buildSubscriptions' does not exist on type 'BranchStore'.
          delete this.buildSubscriptions[build.id];
        }
      });
  }

  _subscribeToBuild(build: Build) {
    // @ts-expect-error - TS2339 - Property 'buildSubscriptions' does not exist on type 'BranchStore'.
    if (!this.buildSubscriptions[build.id]) {
      // @ts-expect-error - TS2339 - Property 'buildSubscriptions' does not exist on type 'BranchStore'.
      this.buildSubscriptions[build.id] = cable.subscriptions.create(
        { channel: "Pipelines::BuildChannel", uuid: build.id },
        {
          store: this,
          number: build.number,

          received({ event }) {
            // This view only cares about commit/branch/message and state
            // changes. Step and annotation changes are irrelevant.
            //
            // XXX: how do we manage a good list of state change events? Maybe
            // it should be one event with a "new state" in the payload?
            //
            if (
              event === "updated" ||
              event === "started" ||
              event === "finished" ||
              event === "skipped" ||
              event === "canceling"
            ) {
              this.store.queue.push(this.number);
            }
          },
        },
      );
    }
  }

  _starredBranches() {
    // @ts-expect-error - TS2339 - Property 'project' does not exist on type 'BranchStore'.
    return UserStore.getStarredBranches(this.project);
  }

  // Return non-starred branches that we know about.
  _nonStarredBranches(): Array<string> {
    const starredBranches = this._starredBranches();

    return Object.keys(this.branches).filter((branch) => starredBranches.indexOf(branch) === -1);
  }
}

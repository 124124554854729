import { PureComponent } from "react";

import Spinner from "app/components/shared/Spinner";

export default class Loader extends PureComponent {
  static displayName = "Autocomplete.Loader";

  render() {
    return (
      <li className="text-center py-4">
        <Spinner />
      </li>
    );
  }
}

import { PureComponent } from "react";
import PropTypes from "prop-types";
import { createRefetchContainer, graphql } from "react-relay";
import debounce from "lodash/debounce";

import cable from "app/lib/cable";
import { formatNumber } from "app/lib/number";

// We need a `requestUpdate` queue above the React component level so
// AgentsCount components will only perform one `forceFetch` in any
// given time window. I wish this was cleaner, kid, I really do.
//
// Wait 1000 ms after latest event, up to 4000 ms total if events keep coming.
const requestUpdate = debounce((callback) => callback(), 1000, {
  maxWait: 4000,
});

class AgentsCount extends PureComponent {
  static propTypes = {
    organization: PropTypes.shape({
      id: PropTypes.string.isRequired,
      uuid: PropTypes.string.isRequired,
      agents: PropTypes.shape({
        count: PropTypes.number.isRequired,
      }),
    }),
    relay: PropTypes.object.isRequired,
  };

  state = {
    agentCount: this.props.organization.agents ? this.props.organization.agents.count : 0,
  };

  componentDidMount() {
    // Reload the component if the agent count changes
    this.organizationSubscription = cable.subscriptions.create(
      {
        channel: "Pipelines::OrganizationChannel",
        uuid: this.props.organization.uuid,
      },
      {
        component: this,

        received({ event, agents }) {
          if (event === "agents:changed") {
            if (agents !== undefined && agents.count !== undefined) {
              this.component.setState({ agentCount: agents.count });
            } else {
              this.component.reload();
            }
          }
        },
      },
    );

    this.reload();
  }

  componentWillUnmount() {
    if (this.organizationSubscription) {
      this.organizationSubscription.unsubscribe();
    }
  }

  reload() {
    requestUpdate(() =>
      this.props.relay.refetch({ organizationID: this.props.organization.id }, null, null, {
        force: true,
      }),
    );
  }

  // Like in MyBuilds, we don't take the pushed data for granted - we instead
  // take it as a cue to update the data store backing the component.
  handleWebsocketEvent = ({ agentsConnectedCount }) => {
    // We need a "global" last agents connected count so we only ask it to update
    // once per changed count. This prevents calls from multiple AgentsCount
    // components triggering repeated forceFetch calls for one event
    if (AgentsCount.lastAgentsConnectedCount !== agentsConnectedCount) {
      AgentsCount.lastAgentsConnectedCount = agentsConnectedCount;
      this.reload();
    }
  };

  // Only once Relay comes back with an updated agentCount do we trust that data!
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.organization.agents) {
      this.setState({ agentCount: nextProps.organization.agents.count });
    }
  };

  render() {
    return <span>{formatNumber(this.state.agentCount)}</span>;
  }
}

export default createRefetchContainer(
  AgentsCount,
  {
    organization: graphql`
      fragment AgentsCount_organization on Organization {
        id
        uuid
        agents {
          count
        }
      }
    `,
  },
  graphql`
    query AgentsCountRefetchQuery($organizationID: ID!) {
      organization: node(id: $organizationID) {
        ...AgentsCount_organization
      }
    }
  `,
);

import { useWindowSize } from "react-use-size";
import Confetti from "react-confetti";
import { Current } from "app/utils/Current";
import { useBuild } from "app/components/build/Show/lib/BuildContext";

export function Revel() {
  const { build } = useBuild();
  const { width, height } = useWindowSize();

  if (!build) {
    throw new Error("Missing build context");
  }

  if (Current.project.revel === "true" && build.state === "passed") {
    return <Confetti width={width} height={height} style={{ zIndex: 30 }} />;
  }

  return null;
}

import { ReactNode } from "react";
import classnames from "classnames";

type Interval = "day" | "month";
const ACCEPTED_INTERVAL: Array<Interval> = ["day", "month"];

type ButtonProp = {
  children: ReactNode;
  active: boolean;
  onClick: () => void;
};

const Button = ({ children, active, onClick }: ButtonProp) => (
  <button
    className={classnames(
      "btn btn-rounded px-4 py-1 focus-purple hover-purple flex items-center medium border-none",
      { "btn--activated purple bg-light-purple semi-bold": active },
    )}
    style={{ textTransform: "capitalize" }}
    onClick={onClick}
  >
    {children}
  </button>
);

const IntervalFilter = ({ currentInterval }: { currentInterval: Interval }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const selectedInterval = currentInterval || ACCEPTED_INTERVAL[0];

  const onIntervalClick = (interval: Interval) => {
    queryParams.set("interval", interval);
    window.location.search = queryParams.toString();
  };

  return (
    <div className="rounded-2 border border-gray flex" style={{ padding: "2px" }}>
      {ACCEPTED_INTERVAL.map((interval: Interval) => (
        <Button
          key={interval}
          active={selectedInterval === interval}
          onClick={() => onIntervalClick(interval)}
        >
          {interval}
        </Button>
      ))}
    </div>
  );
};

export default IntervalFilter;

import Icon from "app/components/shared/Icon";
import CopyButton from "app/components/shared/CopyButton";
import { updateUrlTags, BRANCH_KEY } from "app/components/analytics/shared/tagHelpers";

const BranchLink = ({ branch }: { branch: string }) => (
  <a className="color-inherit" href={updateUrlTags({ key: BRANCH_KEY, value: branch })}>
    {branch}
  </a>
);

const Branch = ({ branch, linkToBranchTag }: { branch: string; linkToBranchTag?: boolean }) => (
  <span className="color-inherit hover-faded">
    <Icon icon="custom/outline/14x/branch" style={{ height: 14, width: 14 }} />
    {linkToBranchTag ? <BranchLink branch={branch} /> : <span>{branch}</span>}
    <CopyButton contentToCopy={branch} iconOnly={true} />
  </span>
);

export default Branch;

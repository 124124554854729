// Add polyfills
import "@webcomponents/custom-elements"; // window.customElements
import "whatwg-fetch"; // fetch
import "./public-path";

import Environment from "app/lib/relay/environment";
import React from "react";
import EmojiStyleManager from "./lib/EmojiStyleManager";
import reactRenderer from "./lib/reactRenderer";

// These are custom web components that just need loaded so HTML on the page
// can use them
import "@github/details-menu-element";
import "@github/include-fragment-element";
import "@hotwired/turbo-rails";
import "./turbo/custom_turbo_stream_actions";
import "./web-components/NumberWithFormatElement";
import "./web-components/ReactElement";
import "./web-components/TimeWithFormatElement";
import "./web-components/TurboReactElement";

// Create the a Relay Modern environment
Environment.create();

// Detect and adjust for custom emoji scaling
EmojiStyleManager.apply();

require("app/css");

// Toggle on development features
if (process.env.NODE_ENV === "development") {
  require("react-type-snob").default(React);
  require("./lib/Logger").default.enable();
}

// Allows old sprockets and inline-javascript to access webpack modules
// @ts-expect-error - TS2739 - Type '(module: string) => any' is missing the following properties from type 'NodeRequire': context, resolve, cache, extensions, main
window["require"] = function (module: string) {
  // @ts-expect-error - TS2339 - Property 'modules' does not exist on type 'NodeRequire'.
  const exported = window["require"].modules[module];

  if (exported) {
    return exported();
  }

  throw "No webpack module exported `" + module + "`";
};

/**
 * Expose a render function that can be called from Rails views to render a React component.
 */
window["render"] = (component: string, selector: string, props = {}) => {
  Promise.resolve(window["require"](component)).then((Component) => {
    const element = document.querySelector(selector);
    if (!element) {
      throw new Error(`Could not find element with selector: ${selector}`);
    }
    reactRenderer(React.createElement(Component, props), element);
  });
};

const modules: {
  [key: string]: () => unknown;
} = {
  "@rails/ujs": () => require("@rails/ujs"),
  autosize: () => require("autosize"),
  "credit-card-type": () => require("credit-card-type"),
  "copy-to-clipboard": () => require("copy-to-clipboard"),
  react: () => require("react"),
  "react-dom": () => require("react-dom"),

  // "shared" components can be referred to by name from Rails views
  // see `app/components/react_component.rb` for details
  "app/components/ClustersInsights/ChartLoader": () =>
    require("./components/ClustersInsights/ChartLoader").default,
  "app/components/shared/BetaBadge": () => require("./components/shared/BetaBadge").default,
  "app/components/shared/Chart": () => require("./components/shared/Chart").default,
  "app/components/shared/UsageChart": () => require("./components/shared/UsageChart").default,
  "app/components/Compute/ExpressionOfInterestForm": () =>
    require("./components/Compute/ExpressionOfInterestForm").default,
  "app/components/admin/PlansSelect": () => require("./components/admin/PlansSelect").default,
  "app/components/Settings/Usage/IntervalFilter": () =>
    require("./components/Settings/Usage/IntervalFilter").default,
  "app/components/Settings/Usage/DatePicker": () =>
    require("./components/Settings/Usage/DatePicker").default,
  "app/components/shared/CopyTextButton": () =>
    require("./components/shared/CopyTextButton").default,
  "app/components/shared/ClusterQueueMetricsAgentsChart": () =>
    require("./components/shared/ClusterQueueMetricsAgentsChart").default,
  "app/components/shared/PipelineEditorSidebar": () =>
    require("./components/shared/PipelineEditorSidebar").default,
  "app/components/shared/Dialog": () => require("./components/shared/Dialog").default,
  "app/components/shared/EmojiAvatar": () => require("./components/shared/EmojiAvatar").default,
  "app/components/shared/FormYAMLEditorField": () =>
    require("./components/shared/FormYAMLEditorField").default,
  "app/components/shared/FriendlyTime": () => require("./components/shared/FriendlyTime").default,
  "app/components/shared/GraphQLEditor": () => require("./components/shared/GraphQLEditor").default,
  "app/components/shared/HoverCard": () =>
    require("./components/shared/HoverCard").ComposedHoverCard,
  "app/components/shared/DropdownMenu": () =>
    require("./components/shared/DropdownMenu").ComposedDropdownMenu,
  "app/components/shared/PopoverNext": () =>
    require("./components/shared/PopoverNext").ComposedPopover,
  "app/components/shared/Icon": () => require("./components/shared/Icon").default,
  "app/components/shared/ImageUploadField": () =>
    require("./components/shared/ImageUploadField").default,
  "app/components/shared/InteractiveChart": () =>
    require("./components/analytics/shared/InteractiveChart").default,
  "app/components/shared/PipelineTemplateFormSelect": () =>
    require("./components/shared/PipelineTemplateFormSelect").default,
  "app/components/shared/PausedClusterQueuesNotice": () =>
    require("./components/shared/PausedClusterQueuesNotice").default,
  "app/components/shared/SecretDisclosure": () =>
    require("./components/shared/SecretDisclosure").default,
  "app/components/shared/ServiceRepositorySelector": () =>
    require("./components/shared/ServiceRepositorySelector").default,
  "app/components/shared/Spinner": () => require("./components/shared/Spinner").default,
  "app/components/shared/Tag": () => require("./components/shared/Tag").default,
  "app/components/shared/PipelineStateIcon": () =>
    require("./components/shared/PipelineStateIcon").default,
  "app/components/icons/BuildState.XSmall": () =>
    require("./components/icons/BuildState").default.XSmall,
  "app/components/icons/BuildState.Small": () =>
    require("./components/icons/BuildState").default.Small,
  "app/components/icons/BuildState.Regular": () =>
    require("./components/icons/BuildState").default.Regular,
  "app/components/shared/Duration.Micro": () =>
    require("./components/shared/Duration").default.Micro,
  "app/components/BuildState": () => require("./components/BuildState").default,

  "app/components/agent/AgentConnectedDialogRenderer": () =>
    require("./components/agent/AgentConnectedDialogRenderer").default,
  "app/components/agent/AgentsRenderer": () => require("./components/agent/AgentsRenderer").default,
  "app/components/agent/AgentJobsListRenderer": () =>
    require("./components/agent/AgentJobsListRenderer").default,
  "app/components/agent/AgentShowRenderer": () =>
    require("./components/agent/AgentShowRenderer").default,
  "app/components/agent/AgentTokenList": () => require("./components/agent/AgentTokenList").default,
  "app/components/organization/AgentsCountRenderer": () =>
    require("./components/organization/AgentsCountRenderer").default,
  "app/components/organization/FormMarkdownEditorField": () =>
    require("./components/organization/FormMarkdownEditorField").default,

  "app/components/billing/InvoiceNoteModal": () =>
    require("./components/billing/InvoiceNoteModal").default,
  "app/components/build/BuildsFilterDropdown": () =>
    require("./components/build/BuildsFilterDropdown").default,
  "app/components/build/List": () => require("./components/build/List").default,
  "app/components/build/Show": () => require("./components/build/Show").default,
  "app/components/build/DatePicker": () => require("./components/build/DatePicker").DatePicker,
  "app/components/build/Header/pipeline/Steps": () =>
    require("./components/build/Header/pipeline/Steps").default,
  "app/components/build/Show/components/Annotation": () =>
    require("./components/build/Show/components/Annotation").default,
  "app/components/build/StateSwitcher": () => require("./components/build/StateSwitcher").default,
  "app/components/ClusterPicker": () => require("./components/ClusterPicker").default,
  "app/components/job/Index": () => require("./components/job/Index").default,
  "app/components/job/DeprecatedLogViewer": () =>
    require("./components/job/DeprecatedLogViewer").default,
  "app/components/job/LogViewer": () => require("./components/job/LogViewer").default,
  "app/components/layout/navigation/MyBuilds": () =>
    require("./components/layout/Navigation/MyBuilds").default,
  "app/components/layout/Flashes": () => require("./components/layout/Flashes").default,
  "app/components/layout/NetPromoterScoreSurvey": () =>
    require("./components/layout/NetPromoterScoreSurvey").default,
  "app/components/OrganizationShow": () => require("./components/OrganizationShow").default,
  "app/components/pipeline/BranchList": () => require("./components/pipeline/BranchList").default,
  "app/components/pipeline/NewPipelineStepSettingsForm": () =>
    require("./components/pipeline/NewPipelineStepSettingsForm").default,
  "app/components/pipeline/StepSettingsForm": () =>
    require("./components/pipeline/StepSettingsForm").default,
  "app/components/pipeline/Edit/Pipeline": () =>
    require("./components/pipeline/Edit/Pipeline").default,
  "app/components/Settings/Pipeline/ExportBucketUpdater": () =>
    require("./components/Settings/Pipeline/ExportBucketUpdater").default,
  "app/components/user/PipelineEmailSettings": () =>
    require("./components/user/PipelineEmailSettings").default,
  "app/components/user/TwoFactor": () => require("./components/user/TwoFactor").default,
  "app/components/user/WelcomeForm": () => require("./components/user/WelcomeForm").default,

  "app/components/PipelineTeams": () => require("./components/PipelineTeams").default,
  "app/components/user/GraphqlExplorer/Console": () =>
    require("./components/user/GraphqlExplorer/Console").default,
  "app/components/user/GraphqlExplorer/Documentation": () =>
    require("./components/user/GraphqlExplorer/Documentation").default,
  "app/components/user/GraphqlExplorer/Documentation/Type": () =>
    require("./components/user/GraphqlExplorer/Documentation/Type").default,
  "app/components/user/GraphqlExplorer/Documentation/Field": () =>
    require("./components/user/GraphqlExplorer/Documentation/Field").default,
  "app/components/user/GraphqlExplorer/Examples": () =>
    require("./components/user/GraphqlExplorer/Examples").default,

  "app/components/analytics/execution/Header": () =>
    require("./components/analytics/execution/Header").default,
  "app/components/analytics/execution/Tabs": () =>
    require("./components/analytics/execution/Tabs").default,
  "app/components/analytics/execution/RunTable": () =>
    require("./components/analytics/execution/RunTable").default,
  "app/components/analytics/span/Table": () => require("./components/analytics/span/Table").default,
  "app/components/analytics/execution/Timeline": () =>
    require("./components/analytics/execution/Timeline").default,
  "app/components/analytics/run/Table": () => require("./components/analytics/run/Table").default,
  "app/components/analytics/run/Header": () => require("./components/analytics/run/Header").default,
  "app/components/analytics/suite/List": () => require("./components/analytics/suite/List").default,
  "app/components/analytics/suite/Setup": () =>
    require("./components/analytics/suite/Setup").default,
  "app/components/analytics/suite/Create/GithubSuite": () =>
    require("./components/analytics/suite/Create/GithubSuite").default,
  "app/components/analytics/suite/ApiKey": () =>
    require("./components/analytics/suite/ApiKey").default,
  "app/components/analytics/test/Table": () => require("./components/analytics/test/Table").default,
  "app/components/analytics/test/Tabs": () => require("./components/analytics/test/Tabs").default,
  "app/components/analytics/shared/BranchFilter": () =>
    require("./components/analytics/shared/BranchFilter").default,
  "app/components/analytics/shared/SuccessNotice": () =>
    require("./components/analytics/shared/SuccessNotice").default,
  "app/components/analytics/shared/ResultBadge": () =>
    require("./components/analytics/shared/ResultBadge").default,
  "app/components/icons/analytics/ResultIcon": () =>
    require("./components/icons/analytics/ResultIcon").default,
  "app/components/analytics/shared/MetricChartCards": () =>
    require("./components/analytics/shared/MetricChartCards").default,
  "app/components/analytics/shared/Activity": () =>
    require("./components/analytics/shared/Activity").default,
  "app/components/shared/EmptyState": () => require("./components/shared/EmptyState").default,
  "app/components/analytics/shared/FeedbackMessage": () =>
    require("./components/analytics/shared/FeedbackMessage").default,
  "app/components/analytics/shared/FailureReason": () =>
    require("./components/analytics/shared/FailureReason").default,
  "app/components/analytics/shared/LoadingChart": () =>
    require("./components/analytics/shared/LoadingStates").LoadingChart,
  "app/components/analytics/shared/LoadingPanels": () =>
    require("./components/analytics/shared/LoadingStates").LoadingPanels,
  "app/components/analytics/shared/MissingEnvVariables": () =>
    require("./components/analytics/shared/MissingEnvVariables").default,
  "app/components/analytics/test/TestAssignmentsTabs": () =>
    require("./components/analytics/test/TestAssignmentsTabs").default,
  "app/components/analytics/test/FlakyTestTable": () =>
    require("./components/analytics/test/FlakyTestTable").default,
  "app/components/analytics/testDigest/Summary": () =>
    require("./components/analytics/testDigest/Summary").default,
  "app/components/analytics/shared/AssignTeamDropdown": () =>
    require("./components/analytics/shared/AssignTeamDropdown").default,
  "app/components/Playground": async () =>
    (await import(/* webpackChunkName: "playground" */ "./components/Playground")).default,

  stepviz: async () => await import(/* webpackChunkName: "stepviz" */ "./utils/StepsRenderer"),

  "app/components/Teams": () => require("./components/Teams/TeamsRenderer").default,
  "app/components/Teams/Pipelines": () => require("./components/Teams/Pipelines/index").default,
  "app/components/Teams/Members": () => require("./components/Teams/Members/index").default,
  "app/components/Teams/Registries": () => require("./components/Teams/Registries/index").default,
  "app/components/Teams/Suites": () => require("./components/Teams/Suites/index").default,

  "app/css/style-guide": () => require("./css/style-guide").default,

  "app/lib/segmentAnalytics": () => require("./lib/segmentAnalytics"),
  "app/lib/cable": () => require("./lib/cable").default,
  "app/lib/CreditCardManager": () => require("./lib/CreditCardManager").default,
  "app/lib/legacyDispatcher": () => require("./lib/legacyDispatcher").default,
  "app/lib/Logger": () => require("./lib/Logger").default,
  "app/lib/reactRenderer": () => require("./lib/reactRenderer").default,
  "app/lib/RelayModernPreloader": () => require("./lib/RelayModernPreloader").default,
  "app/lib/TabManager": () => require("./lib/TabManager").default,
  "app/lib/Websocket": () => require("./lib/Websocket").default,

  "app/stores/BranchStore": () => require("./stores/BranchStore").default,
  "app/stores/BuildsStore": () => require("./stores/BuildsStore").default,
  "app/stores/CableStore": () => require("./stores/CableStore").default,
  "app/stores/FlashesStore": () => require("./stores/FlashesStore").default,
  "app/stores/JobInformationStore": () => require("./stores/JobInformationStore").default,
  "app/stores/JobLogStore": () => require("./stores/JobLogStore").default,
  "app/stores/PipelineBuildsStore": () => require("./stores/PipelineBuildsStore").default,
  "app/stores/ProjectPipelineStore": () => require("./stores/ProjectPipelineStore").default,
  "app/stores/UserStore": () => require("./stores/UserStore").default,
};

// @ts-expect-error - TS2339 - Property 'modules' does not exist on type 'NodeRequire'.
window["require"].modules = modules;

import FriendlyTime from "app/components/shared/FriendlyTime";
import UserAvatar from "app/components/shared/UserAvatar";
import Emojify from "./Emojify";

export type PausedAgent = {
  name: string;
  pausedAt: string;
  pausedBy: {
    name: string;
    avatar: {
      url: string;
    };
  };
  pausedNote?: string | null;
  pausedTimeoutInMinutes: number;
};

function PausedAgentNote({ note }: { note?: string | null }) {
  if (note == null || note.length === 0) {
    return null;
  }

  return (
    <div className="mt-2 mb-5">
      <p className="bg-white m-0 px5 py-5 depth rounded-1.5 inline-block charcoal-800">
        <Emojify text={note} />
      </p>
    </div>
  );
}

function PausedAgentDetails({ agent }: { agent: PausedAgent }) {
  return (
    <div className="charcoal-500 border-gray-400" role="listitem">
      <div className="flex gap2">
        {agent.pausedBy && (
          <span className="flex items-center gap1">
            <UserAvatar user={agent.pausedBy} size="MICRO" />
            {agent.pausedBy.name}
          </span>
        )}
        <span className="parsnip-400">&bull;</span>
        <FriendlyTime value={agent.pausedAt} />
        <span className="parsnip-400">&bull;</span>
        <span>
          <em>Automatically resumes after {agent.pausedTimeoutInMinutes} minutes</em>
        </span>
      </div>
      <PausedAgentNote note={agent.pausedNote} />
    </div>
  );
}

export default function PausedAgentNotice({ agent }: { agent: PausedAgent }) {
  if (!agent) {
    return null;
  }

  return (
    <div className="antialiased p6 pb-0 mb-2 border bg-parsnip-100 border-parsnip-400 rounded-1.5">
      <h2 className="charcoal-900 m-0 mb-3" style={{ fontSize: 16 }}>
        Agent Paused
      </h2>

      <div className="divide-y mb-6" role="list">
        <PausedAgentDetails key={agent.name} agent={agent} />
      </div>
    </div>
  );
}

import { PureComponent } from "react";
import PropTypes from "prop-types";

export default class PermissionSelectOptionDescriptions extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    return (
      <div className="pointer-events-none" style={{ marginTop: 8, marginLeft: -4 }}>
        {this.props.children}
      </div>
    );
  }
}

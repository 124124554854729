import * as React from "react";

import { DateLike } from "app/lib/date";
import JobLogGroupStore from "app/stores/JobLogGroupStore";

import JobLogOutputHeader from "./Header";
import JobLogOutputLine from "./Line";
import { LogGroup } from "app/components/shared/Log";

const getStateFromStores = (id: string) => ({
  expanded: JobLogGroupStore.isExpanded(id),
});

type Props = {
  id: string;
  name: string | null | undefined;
  lines: Array<{
    id: string;
    number: number;
    line: string;
    startedAt: DateLike | null | undefined;
  }>;
  number: number;
  deemphasized?: boolean;
  startedAt: DateLike | null | undefined;
  finishedAt: DateLike | null | undefined;
  finished: boolean;
  durationPercentage: number | null | undefined;
  timestampsOn: boolean | null | undefined;
  timestampsInUTC: boolean | null | undefined;
  onTimestampClick: (arg1: React.MouseEvent<HTMLElement>) => void | null | undefined;
};

type State = {
  expanded: boolean;
};

export default class JobLogOutputGroup extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = getStateFromStores(props.id);
  }

  componentDidMount() {
    JobLogGroupStore.addChangeListener(this.props.id, this.handleStoreChange);
  }

  componentWillUnmount() {
    JobLogGroupStore.removeChangeListener(this.props.id, this.handleStoreChange);
  }

  handleStoreChange = () => {
    this.setState(getStateFromStores(this.props.id));
  };

  handleTimestampClick = (evt: React.MouseEvent<HTMLElement>) => {
    if (this.props.onTimestampClick) {
      this.props.onTimestampClick(evt);
    }
  };

  constructLineNodes(): Array<React.ReactElement<any>> {
    return this.props.lines.map((line) => (
      <JobLogOutputLine
        key={line.id}
        timestampsOn={this.props.timestampsOn}
        timestampsInUTC={this.props.timestampsInUTC}
        onTimestampClick={this.handleTimestampClick}
        {...line}
      />
    ));
  }

  render() {
    // If we have a name, then show it as a collapsable group, otherwise just
    // dump all the lines in there.
    if (this.props.name) {
      const { name, lines: _lines, ...remainder } = this.props;

      return (
        <LogGroup>
          <JobLogOutputHeader
            {...remainder}
            timestampsOn={this.props.timestampsOn}
            onTimestampClick={this.handleTimestampClick}
            name={name}
          />

          {this.state.expanded && this.constructLineNodes()}
        </LogGroup>
      );
    }

    return <tbody>{this.constructLineNodes()}</tbody>;
  }
}

import * as React from "react";
import styled from "styled-components";

import upPointingWhiteNibPath from "../../../images/up-pointing-white-nib.svg";
import { twMerge } from "tailwind-merge";

const NIB_WIDTH = 32;

const Wrapper = styled.div`
  background-color: #fff;
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.1),
    0px 20px 20px rgba(0, 0, 0, 0.07),
    0px 10px 20px rgba(0, 0, 0, 0.05),
    0px 13px 18px rgba(0, 0, 0, 0.04),
    0px 7px 9px rgba(0, 0, 0, 0.03);
  box-sizing: border-box;
  color: rgb(51, 51, 51);
  margin-top: 5px;
  position: absolute;
`;

const ScrollZone = styled.div`
  max-height: 80vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const Nib = styled.img`
  position: absolute;
  pointer-events: none;
  left: 50%;
  top: -20px;
  width: ${NIB_WIDTH}px;
  height: 20px;
`;

export type Props = {
  children: React.ReactNode;
  nibOffsetX: number;
  offsetX: number;
  offsetY: number;
  style?: any;
  innerRef?: any;
  width: number;
  hideNib?: boolean;
  className?: string;
};

export default class Popover extends React.PureComponent<Props> {
  static defaultProps = {
    hideNib: false,
    nibOffsetX: 0,
    offsetX: 0,
    offsetY: 45,
    style: {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    innerRef() {},
    width: 250,
  };

  render() {
    const {
      children,
      innerRef,
      nibOffsetX,
      offsetX,
      offsetY: top,
      style,
      width,
      ...props
    } = this.props;

    const offset = width / 2 - offsetX;

    const popoverStyles = {
      left: `calc(50% - ${offset}px)`,
      top,
      transformOrigin: `${offset + nibOffsetX}px -15px`,
      width,
    } as const;

    return (
      <Wrapper
        {...props}
        ref={innerRef}
        style={Object.assign(popoverStyles, style)}
        className={twMerge(
          "py-[5px] border-none rounded-sm",
          props.className,
          "inverted-shadow z-50",
        )}
      >
        {!props.hideNib && (
          <Nib
            src={upPointingWhiteNibPath}
            style={{ marginLeft: -(NIB_WIDTH / 2) - offsetX + nibOffsetX }}
            alt=""
          />
        )}
        <ScrollZone>{children}</ScrollZone>
      </Wrapper>
    );
  }
}

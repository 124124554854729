import { Step } from "app/lib/pipeline";
import { GroupStepListItem } from "./GroupStepListItem";
import { CommandStepListItem } from "./CommandStepListItem";
import { StepListItem } from "./StepListItem";
import { WaitStepListItem } from "./WaitStepListItem";
import { TriggerStepListItem } from "./TriggerStepListItem";
import { InputStepListItem } from "./InputStepListItem";

interface CreateStepListItemProps {
  step: Step;
  className?: string;
  depth?: number;
}

export function createStepListItem({ step, ...props }: CreateStepListItemProps) {
  switch (step.type) {
    case "group":
      return <GroupStepListItem step={step} key={step.uuid} {...props} />;
    case "command":
      return <CommandStepListItem step={step} key={step.uuid} {...props} />;
    case "block":
    case "input":
      return <InputStepListItem step={step} key={step.uuid} {...props} />;
    case "trigger":
      return <TriggerStepListItem step={step} key={step.uuid} {...props} />;
    case "wait":
      return <WaitStepListItem step={step} key={step.uuid} {...props} />;
    default:
      return <StepListItem step={step} key={step.uuid} {...props} />;
  }
}

import * as React from "react";
import classNames from "classnames";

type RowProps = {
  title: string;
  children?: React.ReactNode;
};

export function AttributeTableRow(props: RowProps) {
  return (
    <tr>
      <th className="pr-2 py-1 semi-bold align-top">{props.title}</th>
      <td className="py-1 align-top">{props.children}</td>
    </tr>
  );
}

type TableProps = {
  children?: React.ReactNode;
  borderless?: boolean;
};

export function AttributeTable(props: TableProps) {
  return (
    <table
      className={classNames("m-0 text-sm font-normal", {
        "border border-gray rounded px-2 py-1 bg-silver": !props.borderless,
      })}
      style={{ borderCollapse: "separate" }}
    >
      <tbody>{props.children}</tbody>
    </table>
  );
}

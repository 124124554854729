import { InputJob } from "app/components/build/Show/lib/types";
import { useMostRecentJob } from "app/components/build/Show/lib/BuildContext";
import { UnblockButton } from "app/components/Playground/Node/InputStepNode";
import Icon from "app/components/shared/Icon";
import { BlockStep, InputStep, Outcome, State } from "app/lib/pipeline";
import { useCallback } from "react";
import { StateIcon } from "app/components/Playground/Node/BaseNode/StateIcon";
import { textColorForStep } from "app/components/Playground/Node/colors";
import { twMerge } from "tailwind-merge";
import Emojify from "app/components/shared/Emojify";
import classNames from "classnames";
import { useTrack } from "app/components/build/Show/lib/useTrack";
import { resolveStepUrl } from "app/components/build/Show/lib/urlForView";
import useCurrentJobOrStep from "app/components/build/Show/lib/useCurrentJobOrStep";
import { StepLink } from "./StepLink";

interface InputStepListItemProps {
  step: InputStep | BlockStep;
  depth?: number;
}

export function InputStepListItem({ step, depth = 0 }: InputStepListItemProps) {
  const job = useMostRecentJob<InputJob>(step.uuid);
  const { currentStep } = useCurrentJobOrStep();
  const track = useTrack();

  const url = resolveStepUrl(step.uuid);
  const label = step.label || "Continue";

  const onClick = useCallback(() => {
    track("Build Sidebar Step Clicked", { type: step.type });
  }, [url]);

  // Handle different states
  if (job?.state === "blocked_failed") {
    return (
      <div
        className={classNames("cursor-not-allowed border-0 opacity-40", {
          "pl-7": depth === 1,
          "pl-12": depth === 2,
        })}
      >
        <div className="font-medium flex flex-auto min-w-0 items-baseline justify-start text-charcoal-700 rounded-md overflow-hidden">
          <div className="self-stretch shrink-0 flex items-center justify-center w-7 -ml-2">
            <Icon className="text-current h-3 w-3 stroke-2" icon="heroicons/solid/pause" />
          </div>
          <div className="flex flex-auto min-w-0 py-1">
            <div className="truncate max-w-full w-full leading-normal">
              <Emojify text={label} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (job?.state !== "blocked") {
    return (
      <StepLink to={url} onClick={onClick} active={step.uuid === currentStep?.uuid} depth={depth}>
        {!job?.unblockedAt && (
          <div className="self-stretch shrink-0 flex items-center justify-center w-7 -ml-2">
            <Icon className="text-current h-3 w-3 stroke-2" icon="heroicons/solid/pause" />
          </div>
        )}

        <div className="flex flex-auto min-w-0 py-1">
          <div className="truncate max-w-full w-full leading-normal">
            <Emojify text={label} />
          </div>
        </div>

        {job?.unblockedAt && (
          <span className="rounded-[3px] px-0.5 border border-current text-xs bg-white inline-block ml-1">
            Unblocked
          </span>
        )}

        {job?.unblockedAt ? (
          <div className="shrink-0 flex items-center justify-center self-stretch">
            <StateIcon
              state={State.Finished}
              outcome={Outcome.Passed}
              className={twMerge(
                "-mr-0.5",
                textColorForStep({
                  state: State.Finished,
                  outcome: Outcome.Passed,
                }),
              )}
            />
          </div>
        ) : (
          <div className="shrink-0 self-stretch flex items-center bg-gray-600 w-7 justify-center">
            <Icon icon="arrow-right" className="h-4 w-4 text-white" />
          </div>
        )}
      </StepLink>
    );
  }

  return (
    <div
      onClick={onClick}
      className={classNames({
        "pl-7": depth === 1,
        "pl-12": depth === 2,
      })}
    >
      {job && (
        <UnblockButton
          job={job}
          className="hover:bg-purple-100 font-medium flex flex-auto min-w-0 items-baseline text-charcoal-700 no-underline hover:no-underline active:no-underline focus:no-underline rounded-md hover:text-inherit justify-start focus:text-inherit relative border-2 border-purple-600 p-0 w-full text-left"
        >
          <div className="self-stretch shrink-0 flex items-center justify-center w-7">
            <Icon className="text-current h-3 w-3 stroke-2" icon="heroicons/solid/pause" />
          </div>
          <div className="flex flex-auto min-w-0 py-1">
            <div className="truncate max-w-full w-full leading-normal">
              <Emojify text={label} />
            </div>
          </div>
          <div className="shrink-0 self-stretch flex items-center bg-purple-600 w-7 justify-center">
            <Icon icon="arrow-right" className="h-4 w-4 text-white" />
          </div>
        </UnblockButton>
      )}
    </div>
  );
}

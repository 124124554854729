import Button from "app/components/shared/Button";
import Spinner from "app/components/shared/Spinner";
import * as React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { createFragmentContainer, graphql } from "react-relay";
import styled from "styled-components";

const List = styled.ul`
  columns: 145px;
  column-gap: 60px;
  width: 100%;
`;

const ListItem = styled.li`
  display: block;
`;

const Code = styled.span`
  display: block;
`;

const ConsumedCode = styled.span`
  display: block;
  text-decoration: line-through;
`;

type Props = {
  isLoading: boolean;
  recoveryCodes: any | null | undefined;
  emphasise?: boolean;
  onAcknowledgeCodes?: () => void;
};

// The method values for which the user is
// considered to have "acknowledged" the codes
type ValidAcknowledgedStates = {
  copied: boolean;
  downloaded: boolean;
};

type AcknowledgedMethodNames = keyof ValidAcknowledgedStates;

type State = {
  browserCanDownload: boolean;
} & ValidAcknowledgedStates;

function formatRecoveryCodeText(recoveryCodes?: any | null): string | null | undefined {
  if (recoveryCodes && recoveryCodes.codes) {
    // @ts-expect-error - TS2347 - Untyped function calls may not accept type arguments.
    return recoveryCodes.codes
      .reduce<Array<any>>((memo, { code }) => memo.concat(code), [])
      .join("\n");
  }
  return "";
}

class RecoveryCodeList extends React.PureComponent<Props, State> {
  state = {
    browserCanDownload: typeof document.createElement("a").download === "string",
    copied: false,
    downloaded: false,
  };

  handleRecoveryCodeCopyClick = (_text: any, result: any) => {
    if (!result) {
      alert("We couldnʼt put this on your clipboard for you, please copy it manually!");
    }
    this.acknowledgeCodesVia("copied");
  };

  handleRecoveryCodeDownloadClick = () => {
    this.acknowledgeCodesVia("downloaded");
  };

  handleRecoveryCodeManualCopy = (event: ClipboardEvent) => {
    // When copying from the code list, we want to do two things;
    //
    // 1. Help make sure the user got a complete copy of one or more codes
    // 2. If the user copied all of the codes, treat the code list as copied

    // First, we grab the current selection
    const selection = document.getSelection();

    // If there's no selection, move on (something weird is going on!)
    if (!selection) {
      return;
    }

    // Get the start and end nodes from the selection
    const { anchorNode, focusNode } = selection;

    // If the selection doesn't have these, we don't want to look at it
    if (!anchorNode || !focusNode) {
      return;
    }

    // To store the start and end offsets for the selection
    let anchorOffsetIndex = null;
    let focusOffsetIndex = null;

    const recoveryCodeList = event.currentTarget;

    // Make sure this is from the element we're expecting
    if (!(recoveryCodeList instanceof HTMLUListElement)) {
      return;
    }

    const recoveryCodeListItems = recoveryCodeList.querySelectorAll("li");

    if (recoveryCodeList === anchorNode && recoveryCodeList === focusNode) {
      // If both the anchor and focus nodes are the recovery code list,
      // we're already in the sort of selection the latter case ends up
      // creating. Just set up the values.
      // @ts-expect-error - TS2322 - Type 'number' is not assignable to type 'null'.
      anchorOffsetIndex = selection.anchorOffset;
      // @ts-expect-error - TS2322 - Type 'number' is not assignable to type 'null'.
      focusOffsetIndex = selection.focusOffset;
    } else {
      // Otherwise, figure out the indexes of each item so we can select
      // the entirety of the first and last partially-selected codes.

      // Check each list item inside the recoveryCodeList, and...
      Array.from(recoveryCodeListItems).findIndex((listItem, index) => {
        // ...if it contains the anchor node, the selection "starts" here
        if (listItem.contains(anchorNode)) {
          // @ts-expect-error - TS2322 - Type 'number' is not assignable to type 'null'.
          anchorOffsetIndex = index;
        }

        // ...if it contains the focus node, the selection "ends" here
        if (listItem.contains(focusNode)) {
          // @ts-expect-error - TS2322 - Type 'number' is not assignable to type 'null'.
          focusOffsetIndex = index;
        }

        // Note that because the anchor and focus nodes can exist in any order,
        // we need to run this test until we've found both.
        // We use `findIndex` here to circuit break once both are found!
        return anchorOffsetIndex !== null && focusOffsetIndex !== null;
      });

      if (anchorOffsetIndex !== null && focusOffsetIndex !== null) {
        if (anchorOffsetIndex >= focusOffsetIndex) {
          anchorOffsetIndex++;
        } else {
          focusOffsetIndex++;
        }

        // If the selection starts and ends in the first and last recovery code elements
        // ensure the selection includes all of all the codes
        selection.setBaseAndExtent(
          recoveryCodeList,
          anchorOffsetIndex,
          recoveryCodeList,
          focusOffsetIndex,
        );
      } else {
        // Something strange happened, give up!
        return;
      }
    }

    if (
      // @ts-expect-error - TS2345 - Argument of type 'null' is not assignable to parameter of type 'number'.
      Math.min(focusOffsetIndex, anchorOffsetIndex) === 0 &&
      // @ts-expect-error - TS2345 - Argument of type 'null' is not assignable to parameter of type 'number'.
      Math.max(focusOffsetIndex, anchorOffsetIndex) === recoveryCodeListItems.length
    ) {
      this.acknowledgeCodesVia("copied");
    }
  };

  acknowledgeCodesVia(method: AcknowledgedMethodNames) {
    // @ts-expect-error - TS2345 - Argument of type '{ [x: string]: boolean; }' is not assignable to parameter of type 'State | ((prevState: Readonly<State>, props: Readonly<Props>) => State | Pick<State, "browserCanDownload" | keyof ValidAcknowledgedStates> | null) | Pick<...> | null'.
    this.setState({ [method]: true }, () => {
      if (this.props.onAcknowledgeCodes) {
        this.props.onAcknowledgeCodes();
      }

      setTimeout(() => {
        // @ts-expect-error - TS2345 - Argument of type '{ [x: string]: boolean; }' is not assignable to parameter of type 'State | ((prevState: Readonly<State>, props: Readonly<Props>) => State | Pick<State, "browserCanDownload" | keyof ValidAcknowledgedStates> | null) | Pick<...> | null'.
        this.setState({ [method]: false });
      }, 1000);
    });
  }

  renderCodes() {
    const recoveryCodeText = formatRecoveryCodeText(this.props.recoveryCodes);
    return (
      <div className="flex-auto min-w-0">
        <div className="flex justify-center items-center">
          <List
            className="text-center mx-2"
            // @ts-expect-error - TS2769 - No overload matches this call.
            onCopy={this.handleRecoveryCodeManualCopy}
          >
            {this.props.recoveryCodes && this.props.recoveryCodes.codes
              ? this.props.recoveryCodes.codes.map(({ code, consumed }) => (
                  <ListItem key={code}>
                    {consumed ? (
                      <ConsumedCode className="monospace text-xl">{code}</ConsumedCode>
                    ) : (
                      <Code className="monospace text-xl">{code}</Code>
                    )}
                  </ListItem>
                ))
              : null}
          </List>
        </div>

        <div className="flex flex-wrap justify-center border-t border-gray p-1">
          <CopyToClipboard text={recoveryCodeText} onCopy={this.handleRecoveryCodeCopyClick}>
            <Button
              className="m-1"
              disabled={this.state.copied}
              theme={this.props.emphasise ? "primary" : "default"}
              // @ts-expect-error - TS2322 - Type '{ children: string; className: string; disabled: boolean; theme: "default" | "primary"; outline: boolean; }' is not assignable to type 'IntrinsicAttributes & Pick<ButtonProps, "onError" | "cite" | "data" | "form" | "label" | "slot" | "span" | "style" | "summary" | "title" | ... 352 more ... | "iconOnly"> & RefAttributes<...>'.
              outline={!this.props.emphasise}
            >
              {this.state.copied ? "Copied to Clipboard!" : "Copy to Clipboard"}
            </Button>
          </CopyToClipboard>
          {this.state.browserCanDownload && (
            <Button
              className="m-1"
              disabled={this.state.downloaded}
              theme={this.props.emphasise ? "primary" : "default"}
              // @ts-expect-error - TS2322 - Type '{ children: string; className: string; disabled: boolean; theme: "default" | "primary"; outline: boolean; href: string; download: string; onClick: () => void; }' is not assignable to type 'IntrinsicAttributes & Pick<ButtonProps, "onError" | "cite" | "data" | "form" | "label" | "slot" | "span" | "style" | "summary" | "title" | ... 352 more ... | "iconOnly"> & RefAttributes<...>'.
              outline={!this.props.emphasise}
              href={`data:text/plain;charset=utf-8,${encodeURIComponent(recoveryCodeText || "")}`}
              download="Buildkite Recovery Codes.txt"
              onClick={this.handleRecoveryCodeDownloadClick}
            >
              {this.state.downloaded ? "Downloaded as Text!" : "Download as Text"}
            </Button>
          )}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div
        className="border border-gray rounded flex items-center justify-center"
        style={{ minHeight: 330 }}
      >
        {this.props.isLoading ? <Spinner /> : this.renderCodes()}
      </div>
    );
  }
}

export default createFragmentContainer(RecoveryCodeList, {
  recoveryCodes: graphql`
    fragment RecoveryCodeList_recoveryCodes on RecoveryCodeBatch {
      codes {
        code
        consumed
      }
    }
  `,
});

import { useCallback, useState } from "react";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import useFetch from "use-http";

import InteractiveChart, {
  InteractiveChartProps,
} from "app/components/analytics/shared/InteractiveChart";
import Dialog from "app/components/shared/Dialog";
import Tooltip from "app/components/analytics/shared/Tooltips/BasicTooltip";
import MiniChart from "./MiniChart";

const StyledCard = styled.span`
  flex: none;
  justify-content: space-between;
  min-width: 0;
  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.09),
    0px 1px 0px rgba(0, 0, 0, 0.09),
    0px 2px 2px rgba(0, 0, 0, 0.04),
    0px 3px 3px rgba(0, 0, 0, 0.02),
    0px 4px 4px rgba(0, 0, 0, 0.01);

  @media (min-width: 769px) {
    display: flex;
    align-items: stretch;
    flex: 1 1 0;
    flex-direction: column;
    max-width: 300px;
  }

  &:hover {
    box-shadow:
      0px 0px 0px 1px rgba(0, 0, 0, 0.09),
      0px 1px 0px rgba(0, 0, 0, 0.09),
      0px 2px 2px rgba(0, 0, 0, 0.04),
      0px 10px 14px rgba(0, 0, 0, 0.02),
      0px 6px 7px -2px rgba(0, 0, 0, 0.08);
  }
`;

// ChartCard renders a card/button which displays a metric value and a mini chart.
// When clicked, it opens a dialog with an interactive chart. We use the `chartMetricUrl` endpoint
// here to fetch the metric for the card, and the `chartDataUrl` to pass through into both the mini chart
// and interactive chart to fetch the data for the charts.
const ChartCard = ({
  heading,
  label,
  metric,
  chartDataUrl,
  chartMetricUrl,
  description,
  chartType,
  period,
}: InteractiveChartProps & {
  chartMetricUrl: string;
}) => {
  const url = new URL(chartMetricUrl);
  url.searchParams.set("metric", metric);
  // We still need to support the period filter changing by react state,
  // this is used in the InteractiveChart dialog and in the suite summary
  url.searchParams.set("period", period);

  const { loading, error, data } = useFetch(url.href, [period, metric]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalClose = useCallback(() => setIsModalOpen(false), []);
  const handleCardOnClick = useCallback(() => setIsModalOpen(true), []);

  return (
    <StyledCard
      id={`chart-card-${metric}`}
      className="relative cursor-pointer rounded bg-white text-left"
    >
      {description && (
        <div className="absolute top-4 right-5 z-[2]" style={{ top: "1.3em" }}>
          <Tooltip name={`${label} calculation`}>
            <>
              <p className="font-semibold m-0">{label}</p>
              <p className="m-0 mt-1" dangerouslySetInnerHTML={{ __html: description }} />
            </>
          </Tooltip>
        </div>
      )}

      <button
        aria-label="Open chart"
        className="flex flex-col h-full w-full unstyled-button p-5 font-sans font-normal text-left no-title-tooltip"
        onClick={handleCardOnClick}
      >
        <div className="text-sm mb-1 pr-6">{label}</div>
        <div className="flex flex-col justify-between h-full w-full overflow-hidden">
          <div className="mb-5 leading-6">
            {error && <div>There was an error loading {label.toLowerCase()} data</div>}
            {loading && <Skeleton containerTestId="loading-skeleton" />}
            {!loading && !error && (
              <div className="text-2xl font-semibold m-0">{data !== null ? data : "-"}</div>
            )}
          </div>

          <div>
            <MiniChart
              metric={metric}
              chartDataUrl={chartDataUrl}
              chartType={chartType}
              period={period}
            />
          </div>
        </div>
      </button>

      <Dialog isOpen={isModalOpen} onRequestClose={handleModalClose} width="80vw">
        <div className="p-5">
          <InteractiveChart
            label={label}
            metric={metric}
            chartDataUrl={chartDataUrl}
            heading={heading}
            description={description}
            chartType={chartType}
            period={period}
          />
        </div>
      </Dialog>
    </StyledCard>
  );
};

export default ChartCard;

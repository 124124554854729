import * as React from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import { cva, VariantProps } from "class-variance-authority";

import { Nib } from "app/components/shared/Nib";
import { twMerge } from "tailwind-merge";

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverAnchor = PopoverPrimitive.Anchor;

const PopoverArrow = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Arrow>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Arrow>
>(({ ...props }, ref) => (
  <PopoverPrimitive.Arrow ref={ref} asChild={true} {...props} width={16} height={8}>
    <Nib />
  </PopoverPrimitive.Arrow>
));

PopoverArrow.displayName = PopoverPrimitive.Arrow.displayName;

const popoverVariants = cva(
  "z-30 bg-white rounded px-4 py-3 shadow-depth-800 outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=open]:duration-100 data-[state=closed]:duration-100 data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
  {
    variants: {
      size: {
        sm: "w-44",
        "max-sm": "max-w-44",
        md: "w-64",
        "max-md": "max-w-64",
        lg: "w-[512px]",
        "max-lg": "max-w-[512px]",
      },
    },
    defaultVariants: {
      size: "sm",
    },
  },
);

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> &
    VariantProps<typeof popoverVariants>
>(
  (
    {
      className,
      align = "center",
      sideOffset = -3,
      collisionPadding = 10,
      size,
      children,
      ...props
    },
    ref,
  ) => (
    <PopoverPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      collisionPadding={collisionPadding}
      className={twMerge(popoverVariants({ size }), className)}
      {...props}
    >
      <PopoverArrow />
      {children}
    </PopoverPrimitive.Content>
  ),
);
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

// Composed component for rendering in Rails views
function ComposedPopover({
  trigger,
  triggerProps,
  content,
  contentProps,
  ...props
}: {
  content: string;
  trigger: string;
  contentProps?: React.ComponentPropsWithoutRef<typeof PopoverContent>;
  triggerProps?: React.ComponentPropsWithoutRef<typeof PopoverTrigger> & {
    as?: React.ElementType;
  };
} & React.ComponentPropsWithoutRef<typeof Popover>) {
  const TriggerTag = triggerProps?.as ?? "button";

  return (
    <Popover {...props}>
      <PopoverTrigger {...triggerProps} asChild={true}>
        <TriggerTag dangerouslySetInnerHTML={{ __html: trigger }} />
      </PopoverTrigger>

      <PopoverContent {...contentProps}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </PopoverContent>
    </Popover>
  );
}

export { Popover, PopoverTrigger, PopoverContent, PopoverAnchor, ComposedPopover };

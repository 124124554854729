/* global jQuery */

export default class ButtonManager {
  $el: JQuery;
  // @ts-expect-error - TS2564 - Property '_previous' has no initializer and is not definitely assigned in the constructor.
  _previous: string;

  constructor(el: JQuery) {
    this.$el = jQuery(el);
    this.$el.addClass("button-manager");
  }

  loading(message: string) {
    this._previous = this.$el.html();

    // Figure out what label to show
    const label = message || this._previous;

    this.$el.addClass("button-manager-loading").attr("disabled", "disable");

    this.$el.html(
      label +
        ' <div class="inline-block relative animation-fade-in" data-testid="spinner" style="vertical-align: middle; width: 16px; height: 16px;"><div class="absolute top-0 left-0"><svg viewBox="0 0 20 20" width="20px" height="20px" class="absolute top-0 left-0" style="width: 16px; height: 16px;"><circle class="stroke-gray" fill="transparent" stroke-miterlimit="10" stroke-width="3" cx="10" cy="10" r="7"></circle></svg><svg viewBox="0 0 20 20" width="20px" height="20px" class="absolute top-0 left-0 animation-spin" style="width: 16px; height: 16px;"><defs><clipPath id="spinner-clip-path"><rect fill="none" x="10" y="-10" width="20" height="20"></rect></clipPath></defs><g transform="translate(10, 10)"><g transform="translate(-10, -10)"><g clip-path="url(#spinner-clip-path)"><circle fill="transparent" class="stroke-lime" stroke-miterlimit="10" stroke-width="3" cx="10" cy="10" r="7"></circle></g></g></g></svg></div></div>',
    );
  }

  reset() {
    this.$el.prop("disabled", false).removeClass("button-manager-loading").html(this._previous);
  }
}

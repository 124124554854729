import Icon from "app/components/shared/Icon";
import { twMerge } from "tailwind-merge";

interface Props {
  branchName?: string;
  branchPath?: string;
  className?: string;
}

export function Branch({ branchName, branchPath, className }: Props) {
  if (!branchName) {
    return null;
  }

  return (
    <a
      className={twMerge(
        "color-inherit hover-color-inherit flex items-center min-w-0 font-mono text-[90%]",
        className,
      )}
      href={branchPath}
    >
      <div className="pl-1 pr-1 pb-1 shrink-0">
        <Icon
          icon="custom/outline/14x/branch"
          className="shrink-0 text-gray-800"
          style={{ height: 14, width: 14 }}
        />
      </div>
      <span className="truncate max-w-full" title={branchName}>
        {branchName}
      </span>
    </a>
  );
}

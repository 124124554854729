import { twMerge } from "tailwind-merge";
import { CommandJob } from "app/components/build/Show/lib/types";
import Emojify from "app/components/shared/Emojify";
import PipelineStateIcon, {
  colorForJob,
} from "app/components/shared/PipelineStateIcon/PipelineStateIcon";
import { Label } from "./Label";
import { JobRetries } from "./JobRetries";
import { useRetriesForJob } from "app/components/build/Show/lib/BuildContext";
import { RunDuration } from "app/components/job/Job/JobDuration";
import { CommandStep, isDefaultCommandStep } from "app/lib/pipeline";
import { StateIcon } from "app/components/Playground/Node/BaseNode/StateIcon";
import { textColorForStep } from "app/components/Playground/Node/colors";

export const JobLabel = ({ job, step }: { job: CommandJob; step: CommandStep }) => {
  const name = job.name || job.command || "";
  const retries = useRetriesForJob(job, { includeLatest: false });
  const waitingOnConcurrencyGroup = job.state === "limited";

  let count: string | null = null;
  if (job.parallelGroupIndex !== null && job.parallelGroupIndex !== undefined) {
    count = `${job.parallelGroupIndex + 1}/${job.parallelGroupTotal}`;
  }

  return (
    <Label
      info={retries.length > 0 && <JobRetries job={job} />}
      duration={job.startedAt && <RunDuration job={job} />}
      status={
        isDefaultCommandStep(step) ? (
          <StateIcon
            {...step}
            waitingOnConcurrencyGroup={waitingOnConcurrencyGroup}
            className={twMerge(
              "-mr-0.5",
              textColorForStep({
                ...step,
                waitingOnConcurrencyGroup,
              }),
            )}
          />
        ) : (
          <PipelineStateIcon
            job={job}
            className={twMerge("w-4 -mr-0.5", colorForJob(job))}
            style={{ width: undefined, height: undefined }}
          />
        )
      }
    >
      <div className="inline space-x-1">
        {count && (
          <span className="rounded-[3px] px-0.5 border border-current text-xs bg-white inline-block">
            {count}
          </span>
        )}
        <Emojify text={name} />
      </div>
    </Label>
  );
};

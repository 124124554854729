import { createFragmentContainer, graphql } from "react-relay";
import JobEvent from "../JobEvent";

type Props = {
  event: any;
  previousTimestamp: string | null | undefined;
};

function JobEventExpired({ event: { timestamp }, previousTimestamp }: Props) {
  return (
    <JobEvent timestamp={timestamp} previousTimestamp={previousTimestamp}>
      <JobEvent.Label>Job Expired</JobEvent.Label>
      <JobEvent.Description>Job took too long to start running on an agent.</JobEvent.Description>
    </JobEvent>
  );
}

export default createFragmentContainer(JobEventExpired, {
  event: graphql`
    fragment JobEventExpired_event on JobEvent {
      ... on JobEvent {
        timestamp
      }
    }
  `,
});

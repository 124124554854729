const moment = require("moment");

// This regular expression is also present in the agent to capture header
// timings, so if you change it here, you'll also need to change it in the
// agent.
//
// So we can delete ANSI codes from header names
const ANSI_REGEX = /\x1b\[(?:[;\d]+)?[mK]/g; // eslint-disable-line no-control-regex
// So we can delete trailing carriage returns from lines, like the agent does
const CHOMP_REGEX = /\r$/;
// Parse the group name from the group
const GROUP_REGEX = /^(?:---|\+\+\+|~~~)\s(.*)$/;
// This lets us find the default default group to expand when first displaying the log
const RUNNING_REGEX =
  /Running (?:(?:build |batch )?script|commands?|(?:local|global) command hook)/;
// Syntax used to change the current group
const CURRENT_GROUP_REGEX = /^\^\^\^\s(\+\+\+|---)?\s?(.*)?$/;
// The message added to the top of truncated logs while streaming (1MB max)
const TRUNCATION_HEADER_STREAMING =
  "+++ ⚠️️ <span class='term-fg33'>Truncation warning</span>\n" +
  "Warning: This log has exceeded the 1MB display limit for streamed logs. While the job is running, only the last 1MB of output will be shown below.\n" +
  "See <a href='https://buildkite.com/docs/guides/managing-log-output' target='_blank'>https://buildkite.com/docs/guides/managing-log-output</a> for tips on managing your log output.\n\n" +
  'You can still download the full log using the "Download" button above.\n' +
  "--- ...\n";
// The message added to the top of truncated logs when finished (2MB max)
const TRUNCATION_HEADER_FINISHED =
  "+++ ⚠️️ <span class='term-fg33'>Truncation warning</span>\n" +
  "Warning: This log has exceeded the 2MB display limit. Below we are only showing the last 2MB of output.\n" +
  "See <a href='https://buildkite.com/docs/guides/managing-log-output' target='_blank'>https://buildkite.com/docs/guides/managing-log-output</a> for tips on managing your log output.\n\n" +
  'You can still download the full log using the "Download" button above.\n' +
  "--- ...\n";
// The number of headers in the above truncation message
const TRUNCATION_HEADER_GROUPS = 2;

// Matches on a `time` element at the start of the string with a datetime attribute, and
// the same value as text content, capturing the datetime attribute value so that we can
// parse it into a timestamp
const TIME_ELEMENT_REGEX = /^<time datetime="([^"]+)">.*<\/time>/;

export function parse(log, output) {
  let header; // eslint-disable-line no-unused-vars
  let index;
  let lineNumber;

  if (log.truncated) {
    const truncation_header = log.streaming
      ? TRUNCATION_HEADER_STREAMING
      : TRUNCATION_HEADER_FINISHED;
    output = truncation_header + output;
  }

  const lines = output.split("\n");

  // Remove the last line if it's blank
  const lastIndex = lines.length - 1;
  if (lines[lastIndex] === "") {
    lines.splice(lastIndex, 1);
  }

  // The groups as they're collected
  const groups = [];

  // The current group used within the loop
  let group = null;

  // Whether or not the log contains text that looks like a SSH key failure
  let sshKeyFailure = false;

  // Whether or not the log contains text that looks like a HTTPS auth failure
  let httpsAuthFailure = false;

  // Does the log contain text that looks like a docker hub rate limit issue
  let dockerRateLimited = false;

  // Whether or not the log contains text that looksl ike the commit wasn't found in the branch
  let gitCommitNotFoundInBranchFailure = false;

  // If we've found per-line timestamps in the log
  let linesHaveTimestamps = false;

  // Shortcut function to create a group
  const createGroup = function (name, number, startedAt) {
    group = {
      id: `${log.id}/${number}`,
      number,
      name,
      startedAt,
      lines: [],
      finished: false,
      expanded: false,
    };

    // Add the group to the groups array
    groups.push(group);

    return group;
  };

  // Has a header already been expanded by a +
  let alreadyExpanded = false;

  // If the first line in the output isn't a group, we'll need to create a null group.
  const firstLine = lines[0];
  const strippedFirstLine = firstLine.replace(ANSI_REGEX, "");
  const chompedFirstLine = strippedFirstLine.replace(CHOMP_REGEX, "");
  const timestampRemovedFirstLine = chompedFirstLine.replace(TIME_ELEMENT_REGEX, "");

  header = GROUP_REGEX.test(timestampRemovedFirstLine);
  if (!header) {
    group = createGroup();
  }

  // Parse the output
  for (index = 0; index < lines.length; index++) {
    let groupMatch, currentGroupMatch, startedAt;
    let line = lines[index];
    lineNumber = index + 1;

    // A <time> element at the start of the line provides the timestamp for the line.
    // We'll remove it, parsing out its datetime value, and using that as the line's
    // `startedAt`.
    const match = line.match(TIME_ELEMENT_REGEX);
    if (match) {
      const datetime = match[1];
      // we have moment.js available in this class, but the native Date API is much more performant parsing ISO8601 date strings
      startedAt = new Date(datetime).getTime();
      line = line.replace(TIME_ELEMENT_REGEX, "");
    }

    // If we found a time above, then we've got per-line timestamps
    if (startedAt) {
      linesHaveTimestamps = true;
    }

    // Strip ANSI codes from the line
    const strippedLine = line.replace(ANSI_REGEX, "");

    // Strip optional trailing carriage return from the line
    const chompedLine = strippedLine.replace(CHOMP_REGEX, "");

    const prefix = chompedLine[0];

    // We first start with a quick check, so we don't regex every line.
    if (
      (prefix === "-" || prefix === "+" || prefix === "~") &&
      (groupMatch = GROUP_REGEX.exec(chompedLine))
    ) {
      // Grab the group name once we know it's a group
      const groupName = groupMatch[1];

      // Create the new group
      group = createGroup(groupName, lineNumber, startedAt);

      // If they've use the + syntax, it's open by default.
      if (prefix === "+") {
        group.expanded = true;
        alreadyExpanded = true;
      } else if (prefix === "~") {
        group.deemphasized = true;
      }
    } else if (prefix === "^" && (currentGroupMatch = CURRENT_GROUP_REGEX.exec(chompedLine))) {
      const groupExpandAction = currentGroupMatch[1];
      const groupNameChange = currentGroupMatch[2];

      // Toggle whether or not the current group is expanded or not
      if (groupExpandAction === "+++") {
        group.expanded = true;
      } else if (groupExpandAction === "---") {
        group.expanded = false;
      }

      // Allow the name of the current group to be changed
      if (groupNameChange) {
        group.name = groupNameChange;
      }
    } else {
      // If the line contains text that may insinuate that the build failed
      // because of an SSH key failure
      if (!sshKeyFailure && line.includes("Permission denied (publickey)")) {
        sshKeyFailure = true;
      }

      // If the line contains text insinuates that a private repo is being checked out
      // via HTTPS and is fruitlessly asking for a password
      // or if the line contains text that insinuates that the GitHub app credentials are missing
      if (
        (!httpsAuthFailure && line.includes("terminal prompts disabled")) ||
        (!httpsAuthFailure && line.includes("failed to get github app credentials"))
      ) {
        httpsAuthFailure = true;
      }

      if (
        !dockerRateLimited &&
        line.indexOf("https:&#47;&#47;www.docker.com&#47;increase-rate-limits") > -1
      ) {
        dockerRateLimited = true;
      }

      // If the line contains the git error message from doing a `git checkout -f`
      // then perhaps the commit no longer exists on the branch. So let's
      // detect it so we can show a nice message to help them.
      // See https://github.com/buildkite/agent/issues/581
      if (
        !gitCommitNotFoundInBranchFailure &&
        line.indexOf("fatal: reference is not a tree:") === 0
      ) {
        gitCommitNotFoundInBranchFailure = true;
      }

      group.lines.push({
        id: `${group.id}-${lineNumber}`,
        number: lineNumber,
        line,
        startedAt,
      });
    }
  }

  // As we calculate the durations, track the longest duration, so we can do
  // relative time calculations
  let longestDuration = 0;

  // If we know that each line has it's own timestamp, we've got a slightly
  // different way of calculating longest duration
  if (linesHaveTimestamps) {
    let nextGroup;
    for (index = 0; index < groups.length; index++) {
      group = groups[index];

      // The next groups `startedAt` is the current groups `finishedAt`
      nextGroup = groups[index + 1];
      if (nextGroup) {
        // We also set `finished` in case the next group is missing a startedAt
        group.finished = true;
        group.finishedAt = nextGroup.startedAt;
      } else if (index === groups.length - 1 && log.finishedAtFromAgent) {
        // If there's no next group, and this is the last group, then it's
        // `finishedAt` will be the time the agent reported finishing the job
        group.finished = true;
        group.finishedAt = moment.utc(log.finishedAtFromAgent).toString();
      }

      // Calculate the duration of the group, and track the largest one
      if (group.startedAt && group.finishedAt) {
        group.duration = moment(group.finishedAt).diff(group.startedAt);
        if (group.duration > longestDuration) {
          longestDuration = group.duration;
        }
      }
    }
  } else {
    const headerTimes = log.headerTimes || [];

    if (headerTimes && headerTimes.length > 0) {
      // We have to figure out if there's missing groups (in the case of a
      // truncated log) so we can adjust how we look up the headerTime for groups.
      const missingGroups = log.truncated
        ? log.finishedAtFromAgent
          ? // Finished jobs get an extra headerTime added by
            // Job::Log::HeaderTimes::Finder so in order to figure out if there's
            // missing groups, we have to take the extra headerTime into account
            headerTimes.length - 1 - (groups.length - TRUNCATION_HEADER_GROUPS)
          : headerTimes.length - (groups.length - TRUNCATION_HEADER_GROUPS)
        : 0;

      // The header times are in order that they appear in the log. So the
      // finishedAt is the previous headers startedAt
      for (index = 0; index < groups.length; index++) {
        group = groups[index];

        // The first two groups
        if (missingGroups !== 0) {
          if (index === 0) {
            // The first group is the truncation warning. No point showing a
            // timestamp.
            group.startedAt = null;
            group.finishedAt = null;
          } else if (index === 1) {
            // The second group is a '...' group that contains the log from the
            // truncation point onwards. We'll make the total duration of this go
            // from the start of the job to the first group that appears after the
            // truncation.
            group.startedAt = log.startedAtFromAgent;
            group.finishedAt = headerTimes[missingGroups];
          } else {
            // All other groups need be bumped by the number of missing groups,
            // taking into account the two extra truncation headers groups that
            // don't have timing info because they're generated by the backend,
            // not by the agent.
            group.startedAt = headerTimes[index + missingGroups - TRUNCATION_HEADER_GROUPS];
            group.finishedAt = headerTimes[index + missingGroups - TRUNCATION_HEADER_GROUPS + 1];
          }
        } else {
          group.startedAt = headerTimes[index];
          group.finishedAt = headerTimes[index + 1];
        }

        // Groups with header times are always finished iff there is a finishedAt
        group.finished = !!group.finishedAt;

        // Calculate the duration of the group, and track the largest one
        if (group.startedAt && group.finishedAt) {
          group.duration = moment(group.finishedAt).diff(group.startedAt);

          if (group.duration > longestDuration) {
            longestDuration = group.duration;
          }
        }
      }
    }
  }

  // Calculate the duration percentage for the groups
  for (group of groups) {
    if (group.duration != null) {
      group.durationPercentage = Math.floor((group.duration / longestDuration) * 100.0);
    }
  }

  // If we're streaming, don't do the smart expanding
  if (!log.streaming) {
    // If nothing has been expanded, expand the last non-deemphasized header
    if (!alreadyExpanded) {
      for (group of groups.slice(0).reverse()) {
        if (!group.deemphasized) {
          group.expanded = true;
          alreadyExpanded = true;
          break;
        }
      }
    }

    // If _still_ nothing is expanded, lets auto-expand the running build script one
    if (!alreadyExpanded) {
      for (group of groups) {
        if (RUNNING_REGEX.test(group.name)) {
          group.expanded = true;
          alreadyExpanded = true;
          break;
        }
      }
    }

    // After all this hard work, nothing is expanded (which could be the case
    // if a script doesn't get passed a git clone stage) then show the last
    // group.
    if (!alreadyExpanded) {
      const lastGroup = groups[groups.length - 1];
      if (lastGroup) {
        lastGroup.expanded = true;
      }
    }
  }

  // Return the state and final result of this parse.
  return {
    groups,
    totalLines: lineNumber,
    sshKeyFailure,
    httpsAuthFailure,
    dockerRateLimited,
    gitCommitNotFoundInBranchFailure,
    linesHaveTimestamps,
  };
}

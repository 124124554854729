import { findJobsForStep, useBuild } from "app/components/build/Show/lib/BuildContext";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { Drawer } from "../Drawer";
import { useCallback } from "react";
import { ErrorBoundary } from "app/lib/Bugsnag";
import { ErrorView } from "app/components/shared/ErrorView";
import { isMatrixCommandStep, isParallelCommandStep, Step } from "app/lib/pipeline";
import Job from "app/components/job/Job";
import { Build } from "app/components/build/Show/lib/types";
import { TriggerJobDetails } from "./TriggerJobDetails";
import { ParallelStepDetails } from "./ParallelStepDetails";
import { MatrixStepDetails } from "./MatrixStepDetails";
import { CommandJobDrawer } from "./CommandJobDrawer";

export const StepDrawer = ({ stepOrJobId = "" }) => {
  const navigate = useNavigate();
  const { build, store } = useBuild();
  const { pathname } = useLocation();

  if (!build || !store) {
    throw new Error("Missing build context");
  }

  // Close the drawer and navigate back to the build view (ie. waterfall / canvas / summary).
  const handleClose = useCallback(() => {
    navigate(pathname);
  }, [pathname]);

  // The trigger/root step isn't a real step in the pipeline so for now we
  // just ignore it.
  if (stepOrJobId === "root") {
    return null;
  }

  let job: Job | null;

  // If the stepId is a job, render the job details.
  job = store.jobs.get(stepOrJobId);
  if (job?.type === "script") {
    return <CommandJobDrawer job={job} onClose={handleClose} />;
  }

  // Redirect for unknown steps.
  const step = store.steps.get(stepOrJobId);
  if (!step) {
    return <Navigate to={`${build.path}`} />;
  }

  if (step.type === "trigger") {
    return (
      <Drawer onClose={handleClose}>
        <ErrorBoundary FallbackComponent={ErrorView}>
          <TriggerJobDetails step={step} />
        </ErrorBoundary>
      </Drawer>
    );
  }

  // Only render the drawer for command steps.
  if (step.type !== "command") {
    return null;
  }

  // Render parallel jobs for parallel command steps.
  if (isParallelCommandStep(step)) {
    return (
      <Drawer onClose={handleClose}>
        <ErrorBoundary FallbackComponent={ErrorView}>
          <ParallelStepDetails step={step} />
        </ErrorBoundary>
      </Drawer>
    );
  }

  // Render matrix jobs for matrix command steps.
  if (isMatrixCommandStep(step)) {
    return (
      <Drawer onClose={handleClose}>
        <ErrorBoundary FallbackComponent={ErrorView}>
          <MatrixStepDetails step={step} />
        </ErrorBoundary>
      </Drawer>
    );
  }

  job = findMostRecentJob(step, build);
  if (!job) {
    console.error("No job found for step", step);
    return null;
  }

  return <CommandJobDrawer job={job} onClose={handleClose} />;
};

function findMostRecentJob(step: Step, build: Build) {
  const jobs = findJobsForStep(build, step.uuid);
  return jobs[jobs.length - 1];
}

import { memo, useEffect, useRef, useState } from "react";
import classNames from "classnames";

import Button from "app/components/shared/Button";
import FormYAMLEditorField from "app/components/shared/FormYAMLEditorField";
import PipelineEditorSidebar, {
  type TrackingContext as SidebarTrackingContext,
} from "app/components/shared/PipelineEditorSidebar";
import PipelineTemplateFormSelect from "app/components/shared/PipelineTemplateFormSelect";

type PipelineTemplate = {
  uuid?: string;
  name: string;
  description?: string | null;
  configuration: string;
};

export type Props = {
  currentConfiguration: string;
  currentPipelineTemplateUuid: string;
  pipelineTemplates: Array<PipelineTemplate>;
  pipelineTemplatesRequired: boolean;
  updatePipelineStepsPath: string;
  sidebarTrackingContext: SidebarTrackingContext;
};

// required to prevent re-rendering of FormYAMLEditorField and
// loss of user configuration input when guides are toggled
const MemoizedFormYAMLEditorField = memo(FormYAMLEditorField);

const StepSettingsForm = (props: Props) => {
  const formRef = useRef<HTMLFormElement>(null);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "s" && (event.metaKey || event.ctrlKey)) {
      event.preventDefault();
      if (formRef.current) {
        formRef.current.submit();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const hasTemplates = props.pipelineTemplates.length > 0;
  const noTemplate: PipelineTemplate = {
    name: "Do not use a pipeline template",
    configuration: props.currentConfiguration,
  };

  // Provide a no template option when a pipeline does not have a template assigned or
  // an organization has not required templates for all pipelines
  const pipelineTemplates =
    props.pipelineTemplatesRequired && props.currentPipelineTemplateUuid
      ? props.pipelineTemplates
      : [noTemplate, ...props.pipelineTemplates];

  const [selectedTemplate, setSelectedTemplate] = useState(
    () =>
      props.pipelineTemplates.find(
        (template) => template.uuid === props.currentPipelineTemplateUuid,
      ) || noTemplate,
  );
  const [showGuide, setShowGuide] = useState(false);
  const isEditable = !selectedTemplate.uuid && !props.pipelineTemplatesRequired;

  function toggleGuide() {
    setShowGuide(!showGuide);
  }

  function handleSelectedTemplateChange(template: PipelineTemplate) {
    setSelectedTemplate(template);
  }

  return (
    <form
      ref={formRef}
      action={props.updatePipelineStepsPath}
      method="post"
      name="edit-pipeline-template"
    >
      <input type="hidden" name="_method" value="patch" />
      <input type="hidden" name="utf8" value="✓" />
      <input type="hidden" name={window._csrf.param} value={window._csrf.token} />

      {hasTemplates && (
        <div className="mb-2" style={{ width: "400px" }}>
          <PipelineTemplateFormSelect
            name="pipeline_template_uuid"
            selected={selectedTemplate}
            items={pipelineTemplates}
            onChange={handleSelectedTemplateChange}
          />
        </div>
      )}

      <div className="flex flex-row border border-gray rounded overflow-clip relativ min-h-[600px]">
        <div className="flex flex-col flex-auto min-w-0">
          <div
            className={classNames("p-5 flex-auto min-w-0", {
              "bg-slate-100": !isEditable,
            })}
          >
            <MemoizedFormYAMLEditorField
              name="configuration"
              value={selectedTemplate?.configuration || props.currentConfiguration}
              autofocus={true}
              lineWrapping={true}
              readOnly={!isEditable}
            />
          </div>

          <div className="flex justify-between px-4 py-2 border-t border-gray sticky bottom-0 bg-white z-10">
            <div className="flex gap1.5">
              <Button type="submit" theme="primary">
                Save Steps
              </Button>
              <Button type="submit" name="commit" value="Save and Build" theme="default">
                Save and Build
              </Button>
            </div>

            {isEditable && (
              <Button type="button" theme="default" onClick={toggleGuide}>
                {showGuide ? "Hide Guide" : "Show Guide"}
              </Button>
            )}
          </div>
        </div>

        {showGuide && isEditable && (
          <div
            className="flex-none border-l border-gray overflow-scroll"
            style={{ width: "320px" }}
          >
            <PipelineEditorSidebar trackingContext={props.sidebarTrackingContext} />
          </div>
        )}
      </div>
    </form>
  );
};

export default StepSettingsForm;

import { createFragmentContainer, graphql } from "react-relay";
import { AttributeTable, AttributeTableRow } from "app/components/shared/AttributeTable";
import JobEvent from "../JobEvent";

type Props = {
  event: any;
  previousTimestamp: string | null | undefined;
};

function JobEventLimited({ event, previousTimestamp }: Props) {
  return (
    <JobEvent timestamp={event.timestamp} previousTimestamp={previousTimestamp}>
      <JobEvent.Label>Dispatcher Limited Job</JobEvent.Label>

      <JobEvent.Content>
        <p>A matching concurrency group limit rule prevented the scheduling of this Job.</p>
        {event.job.concurrency ? (
          <AttributeTable>
            <AttributeTableRow title="Concurrency Group">
              <code className="dark-gray monospace">{event.job.concurrency.group}</code>
            </AttributeTableRow>
            <AttributeTableRow title="Concurrency Limit">
              <code className="dark-gray monospace">{event.job.concurrency.limit}</code>
            </AttributeTableRow>
          </AttributeTable>
        ) : null}
      </JobEvent.Content>
    </JobEvent>
  );
}

export default createFragmentContainer(JobEventLimited, {
  event: graphql`
    fragment JobEventLimited_event on JobEvent {
      ... on JobEvent {
        timestamp
        job {
          concurrency {
            group
            limit
          }
        }
      }
    }
  `,
});

import { PureComponent } from "react";
import { createFragmentContainer, graphql } from "react-relay";
import Button from "app/components/shared/Button";
import RecoveryCodeList from "app/components/RecoveryCodeList";

type Props = {
  onCreateNewTotp: (callback?: () => void) => void;
  onNextStep: () => void;
  recoveryCodes: any;
};

type State = {
  acknowledged: boolean;
  isLoading: boolean;
};

class TwoFactorConfigureRecoveryCodes extends PureComponent<Props, State> {
  state = {
    acknowledged: false,
    isLoading: true,
  };

  componentDidMount() {
    this.props.onCreateNewTotp(() => {
      this.setState({ isLoading: false });
    });
  }

  handleRecoveryCodesAcknowleged = () => {
    this.setState({ acknowledged: true });
  };

  handleNextClick = () => {
    this.props.onNextStep();
  };

  render() {
    return (
      <>
        <p>
          Recovery codes are the only way to login to your account if you lose access to your
          authenticator application.
        </p>
        <p>
          They should be treated like your password, so we suggest saving them in a secure password
          manager, or printing them and storing them somewhere safe.
        </p>
        <RecoveryCodeList
          recoveryCodes={this.props.recoveryCodes}
          isLoading={this.state.isLoading}
          emphasise={!this.state.acknowledged}
          onAcknowledgeCodes={this.handleRecoveryCodesAcknowleged}
        />
        <Button
          className="w-full mt-3"
          disabled={this.state.isLoading}
          theme={this.state.acknowledged ? "primary" : "default"}
          // @ts-expect-error - TS2322 - Type '{ children: string; className: string; disabled: boolean; theme: "default" | "primary"; outline: boolean; onClick: () => void; }' is not assignable to type 'IntrinsicAttributes & Pick<ButtonProps, "onError" | "cite" | "data" | "form" | "label" | "slot" | "span" | "style" | "summary" | "title" | ... 352 more ... | "iconOnly"> & RefAttributes<...>'.
          outline={!this.state.acknowledged}
          onClick={this.handleNextClick}
        >
          Next
        </Button>
      </>
    );
  }

  recoveryCodeText = () => {
    if (!this.props.recoveryCodes) {
      return "";
    }
    return this.props.recoveryCodes.codes.map(({ code }) => code).join("\n");
  };

  renderReconfigure = () => {
    return "New two-factor authentication codes have been generated.\
      Your existing two-factor authentication configuration and recovery codes have been invalidated.\
      We suggest saving your new recovery codes in a secure password manager, or printing them and storing them somewhere safe.";
  };

  renderConfigure = () => {
    return "Recovery codes should be treated like your password.\
    We suggest saving them in a secure password manager, or printing them and storing them somewhere safe.";
  };
}

export default createFragmentContainer(TwoFactorConfigureRecoveryCodes, {
  recoveryCodes: graphql`
    fragment TwoFactorConfigureRecoveryCodes_recoveryCodes on RecoveryCodeBatch {
      ...RecoveryCodeList_recoveryCodes
      codes {
        code
        consumed
      }
    }
  `,
});

import * as React from "react";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";

type Props = {
  children: React.ReactNode;
  className?: string;
  outline?: boolean;
  caps?: boolean;
  bold?: boolean;
  style?: Partial<CSSStyleDeclaration>;
};

export default class Badge extends React.PureComponent<Props> {
  render() {
    const { children, className, outline, caps, bold, ...props } = this.props;

    const badgeClassNames = twMerge(
      classNames(
        "badge ml-1 tabular-numerals",
        outline ? "badge--outline" : "badge--solid",
        caps && "uppercase tracking-widest",
        bold && "semi-bold",
      ),
      className,
    );

    return (
      // @ts-expect-error - TS2322 - Type '{ children: ReactNode; style?: Partial<CSSStyleDeclaration> | undefined; className: string; }' is not assignable to type 'DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>'.
      <span className={badgeClassNames} {...props}>
        {children}
      </span>
    );
  }
}

// We need to import React so that we can use SyntheticEvent et al
import ExtendableError from "es6-error"; // eslint-disable-line no-unused-vars

export class AssetUploaderError extends ExtendableError {
  constructor(message = "An internal error occured. Please try again.") {
    super(message);
  }
}

type AssetUploaderOptions = {
  onError: (error: AssetUploaderError) => unknown;
  onAssetUploaded: (file: AssetUploaderFile, data: any) => unknown;
};

type UploadInstruction = {
  id: string;
  url: string;
  fields: {
    [key: string]: string;
  };
};

let pasteCounter = 0;

export class AssetUploaderFile {
  data: File | Blob;
  name: string;

  constructor(data: File | Blob) {
    this.data = data;

    // @ts-expect-error - TS2339 - Property 'name' does not exist on type 'Blob | File'.
    if (typeof this.data.name === "string") {
      // @ts-expect-error - TS2339 - Property 'name' does not exist on type 'Blob | File'.
      this.name = this.data.name;
    } else {
      const parts = this.type.split("/");
      this.name = parts[0] + "-" + (pasteCounter += 1) + "." + parts[1];
    }
  }

  get type() {
    return this.data.type;
  }

  get size() {
    return this.data.size;
  }
}

class AssetUploader {
  options: AssetUploaderOptions;

  constructor(options: AssetUploaderOptions) {
    this.options = options;
  }

  doesEventContainFiles(event: DragEvent | React.DragEvent<HTMLElement>) {
    return event.dataTransfer && event.dataTransfer.types.indexOf("Files") >= 0;
  }

  uploadFromDropEvent(event: DragEvent | React.DragEvent<HTMLElement>) {
    if (event.type !== "drop") {
      throw `Unknown event type for drop upload \`${event.type}\``;
    }

    return this.uploadFromArray(this._extractFilesFromDropEvent(event));
  }

  uploadFromPasteEvent(event: ClipboardEvent | React.ClipboardEvent<HTMLElement>) {
    if (event.type !== "paste") {
      throw `Unknown event type for paste upload \`${event.type}\``;
    }

    return this.uploadFromArray(this._extractFilesFromPasteEvent(event));
  }

  uploadFromElement(element: HTMLElement) {
    if (!(element instanceof HTMLInputElement) || element.type !== "file") {
      throw `Unsuitable element for asset upload \`<${element.nodeName.toLowerCase()} type="${
        element.getAttribute("type") || ""
      }" />\``;
    }

    // @ts-expect-error - TS2345 - Argument of type 'FileList | null' is not assignable to parameter of type 'FileList | (Blob | File)[]'.
    return this.uploadFromArray(element.files);
  }

  // This will post file information to Buildkite. The resulting response from
  // the server will include instructions on where to upload the files.
  uploadFromArray(files: FileList | Array<File | Blob>) {
    const payload = { files: [] } as const;

    const filesArray: Array<AssetUploaderFile> = Array.from(files).map(
      (file: File | Blob) => new AssetUploaderFile(file),
    );

    // Add the files to the payload
    filesArray.forEach(({ name, type, size }) => {
      // @ts-expect-error - TS2339 - Property 'push' does not exist on type 'readonly []'.
      payload.files.push({ name, type, size });
    });

    // Post the files back to Buildkite
    fetch("/uploads", {
      credentials: "same-origin",
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": window._csrf.token,
        "X-Buildkite-Frontend-Version": BUILDKITE_FRONTEND_VERSION,
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      response.json().then((json) => {
        // If the request failed, then we should have an error in the JSON
        // payload that we can show.
        if (!response.ok) {
          this.options.onError(new AssetUploaderError(json.error));
        } else {
          // Now that the server has responded with upload instructions, kick
          // the uploads off
          json.assets.forEach((asset, index) => {
            this._uploadFile(asset.upload, filesArray[index]);
          });
        }
      });
    });

    return filesArray;
  }

  // Takes an upload instruction, and a file object, and uploads it as per the
  // instructions.
  _uploadFile(upload: UploadInstruction, file: AssetUploaderFile) {
    const formData = new FormData();

    // Copy the keys from our upload instructions into the form data
    for (const key in upload.fields) {
      formData.append(key, upload.fields[key]);
    }

    // AWS ignores all fields in the request after the file field, so all other
    // fields must appear before the file.
    formData.append("file", file.data);

    // Now we can upload the file
    fetch(upload.url, {
      method: "post",
      headers: {
        "X-Buildkite-Frontend-Version": BUILDKITE_FRONTEND_VERSION,
      },
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw null;
        }
        this._finalizeFileUpload(upload, file);
      })
      .catch(() => {
        this.options.onError(
          new AssetUploaderError("There was an error uploading the file. Please try again."),
        );
      });
  }

  // Once a file has finally uploaded, we need to notify Buildkite that it's
  // finished, at which point we'll get a URL back.
  _finalizeFileUpload(upload: UploadInstruction, file: AssetUploaderFile) {
    fetch("/uploads/" + upload.id, {
      credentials: "same-origin",
      method: "put",
      headers: {
        Accept: "application/json",
        "X-CSRF-Token": window._csrf.token,
        "X-Buildkite-Frontend-Version": BUILDKITE_FRONTEND_VERSION,
      },
      body: JSON.stringify({ finished: true }),
    }).then((response) => {
      response.json().then((json) => {
        if (!response.ok) {
          this.options.onError(new AssetUploaderError(json.error));
        } else {
          // Yay! File all uploaded and ready to show :)
          this.options.onAssetUploaded(file, json);
        }
      });
    });
  }

  _extractFilesFromDropEvent(event: DragEvent | React.DragEvent<HTMLElement>) {
    const files: Array<File | Blob> = [];

    if (!event.dataTransfer) {
      return files;
    }

    for (let fileIndex = 0; fileIndex < event.dataTransfer.files.length; fileIndex++) {
      const file = event.dataTransfer.files[fileIndex];
      files.push(file);
    }

    return files;
  }

  _extractFilesFromPasteEvent(event: ClipboardEvent | React.ClipboardEvent<HTMLElement>) {
    const files: Array<File | Blob> = [];

    if (!event.clipboardData) {
      return files;
    }

    for (let fileIndex = 0; fileIndex < event.clipboardData.items.length; fileIndex++) {
      const file = event.clipboardData.items[fileIndex].getAsFile();

      if (file) {
        files.push(file);
      }
    }

    return files;
  }
}

export default AssetUploader;

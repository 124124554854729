import { useBuild, useRetriesForJob } from "app/components/build/Show/lib/BuildContext";
import { Link, useNavigate } from "react-router-dom";
import BuildPageStore from "app/stores/BuildPageStore";
import Job from "app/components/job/Job";
import { useCallback, useEffect } from "react";
import { ErrorBoundary } from "app/lib/Bugsnag";
import { ErrorView } from "app/components/shared/ErrorView";
import { Drawer } from "../Drawer";
import { CommandJob } from "../../lib/types";
import { resolveJobUrl } from "../../lib/urlForView";
import Icon from "app/components/shared/Icon";
import { JobRetries } from "../Sidebar/StepListItem/JobRetries";
import ToolbarButton from "../ToolbarButton";

interface Props {
  job: CommandJob;
  onClose: () => void;
}

/**
 * A drawer that displays the details of a single command job.
 */
export const CommandJobDrawer = ({ job, onClose }: Props) => {
  return (
    <Drawer onClose={onClose} actions={<RetryJobNavigation job={job} />}>
      <ErrorBoundary FallbackComponent={ErrorView}>
        <CommandJobDetails job={job} />
      </ErrorBoundary>
    </Drawer>
  );
};

const RetryJobNavigation = ({ job }: { job: CommandJob }) => {
  const jobWithRetries = useRetriesForJob(job);
  const index = jobWithRetries.indexOf(job);

  const prev = jobWithRetries[index - 1];
  const next = jobWithRetries[index + 1];

  if (jobWithRetries.length <= 1) {
    return null;
  }

  return (
    <div className="flex flex-auto min-w-0">
      <div className="flex gap-2 items-center mr-2 ml-auto">
        <JobRetries job={job} side="bottom">
          <span className="text-xs cursor-default hover:underline">
            Retry <span className="tabular-nums">{index + 1}</span>
            {" of "}
            <span className="tabular-nums">{jobWithRetries.length}</span>
          </span>
        </JobRetries>

        <div className="flex gap-1">
          <Link
            data-testid="previous-retry-link"
            to={prev && resolveJobUrl(prev.id)}
            title="Previous"
            aria-disabled={!prev}
            className="aria-disabled:pointer-events-none aria-disabled:text-gray-700 text-inherit"
          >
            <ToolbarButton>
              <Icon icon="heroicons/outline/chevron-left" className="h-4" />
            </ToolbarButton>
          </Link>
          <Link
            data-testid="next-retry-link"
            to={next && resolveJobUrl(next.id)}
            title="Next"
            aria-disabled={!next}
            className="aria-disabled:pointer-events-none aria-disabled:text-gray-700 text-inherit"
          >
            <ToolbarButton>
              <Icon icon="heroicons/outline/chevron-right" className="h-4" />
            </ToolbarButton>
          </Link>
        </div>
      </div>
    </div>
  );
};

export const CommandJobDetails = ({ job }: { job: CommandJob }) => {
  const { build, store } = useBuild();
  const navigate = useNavigate();

  useEffect(() => {
    BuildPageStore.expandJob(job);
  }, [job.id]);

  const handleRedirectToLatestJob = useCallback(
    (latestJobUUID: string) => {
      if (latestJobUUID) {
        navigate(resolveJobUrl(latestJobUUID));
      }
    },
    [navigate],
  );

  if (!build || !store) {
    return null;
  }

  // Apply appropriate `scroll-margin-top` to ensure scrolled content isn't hidden
  // behind sticky content. The amount of margin is calculated based on the height of the
  // sticky header(s).
  return (
    <div className="job-list-pipeline [&_*]:scroll-mt-[200px]">
      <Job
        key={job.id}
        job={job}
        build={build}
        buildStore={store}
        autoFollow={true}
        onRetrySuccess={handleRedirectToLatestJob}
      />
    </div>
  );
};

import * as React from "react";

type Props = {
  repositoryProviderId: string;
  osId: string | null | undefined;
  hostedQueue: boolean | null | undefined;
};

export default class AuthWarning extends React.PureComponent<Props> {
  render() {
    return this.props.hostedQueue ? (
      <div className="alert alert-info mb-2" role="alert">
        <p>
          {"Please ensure that the Buildkite GitHub App (with code access) is "}
          <strong>
            <a href="/docs/pipelines/hosted-agents/code-access" target="_blank">
              configured for this repository.
            </a>
          </strong>
        </p>
        <p>
          Note that support for private repository access on hosted agents is currently available
          for GitHub only.
        </p>
        <p>
          {"Still need help? Email us at "}
          <strong>
            <a href="mailto:support@buildkite.com">support@buildkite.com</a>
          </strong>
          {/*
           */}
          —we’d be happy to help!
        </p>
      </div>
    ) : (
      <div className="alert alert-info mb-2" role="alert">
        <p>
          {"Having trouble configuring your git SSH keys? Check out the "}
          <strong>{this.agentKeysDocsLink()}</strong>
          {" and the "}
          <strong>{this.scmGuideLink()}</strong>
        </p>
        <p>
          {"Still need help? Email us at "}
          <strong>
            <a href="mailto:support@buildkite.com">support@buildkite.com</a>
          </strong>
          {/*
           */}
          —we’d be happy to help!
        </p>
      </div>
    );
  }

  scmGuideLink() {
    if (this.props.repositoryProviderId.indexOf("github") === 0) {
      return <a href="/docs/guides/github-repo-access">GitHub Repo Access Guide</a>;
    }

    return <a href="/docs/agent/ssh-keys">SSH Keys Guide</a>;
  }

  agentKeysDocsLink() {
    if (this.props.osId) {
      const os = this.props.osId.split("_")[0];

      if (os === "ubuntu") {
        return <a href="/docs/agent/ubuntu/#ssh-key-configuration">Ubuntu Agent SSH keys docs</a>;
      } else if (os === "osx") {
        return <a href="/docs/agent/osx/#ssh-key-configuration">OS X SSH keys docs</a>;
      } else if (os === "debian") {
        return <a href="/docs/agent/debian/#ssh-key-configuration">Debian SSH keys docs</a>;
      } else if (os === "windows") {
        return <a href="/docs/agent/windows/#ssh-key-configuration">Windows SSH keys docs</a>;
      } else if (os === "centos" || os === "rhel" || os === "amzn" || os === "fedora") {
        return <a href="/docs/agent/redhat/#ssh-key-configuration">Red Hat/CentOS SSH keys docs</a>;
      }

      return <a href="/docs/agent/linux/#ssh-key-configuration">Linux Agent SSH keys docs</a>;
    }

    return <a href="/docs/agent/linux/#ssh-key-configuration">Linux Agent SSH keys docs</a>;
  }
}

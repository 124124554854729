import * as React from "react";
import Button from "app/components/shared/Button";
import EmptyState from "app/components/shared/EmptyState";
import { trackClick } from "app/lib/segmentAnalytics";

export default function UpgadeRequired() {
  const upgradeLink = React.useRef(null);

  React.useEffect(() => {
    if (upgradeLink.current) {
      trackClick(upgradeLink.current, "Enterprise Upgrade Clicked", {
        action: "enterprise-upgrade-link",
        text: "Upgrade to Enterprise",
        source: "Waterfall Empty State",
        theme: "primary",
      });
    }
  }, [upgradeLink]);

  return (
    <div className="w-full">
      <EmptyState
        emoji="📊"
        variant="orange-gradient"
        heading="Visualise your job processes"
        subheading={
          <>
            <span>
              Waterfall view is only available on Business and Enterprise plans. Upgrade to get
              enhanced visibility of your job processes, duration and dependencies, and optimize
              your builds.
            </span>{" "}
            <a
              className="lime hover-lime no-underline hover-underline"
              href="/docs/pipelines/waterfall"
            >
              Learn more
            </a>
          </>
        }
        link={
          <Button
            href="/organizations/test/billing?feature=pipelines_waterfall_view"
            theme="primary"
            id="upgrade-link"
            ref={upgradeLink}
          >
            View upgrade plans
          </Button>
        }
      />
    </div>
  );
}

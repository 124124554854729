import React from "react";

import { getDuration, getDurationString } from "app/lib/date";
import { shortCommit } from "app/lib/commits";
import { track } from "app/lib/segmentAnalytics";
import BuildMessage from "app/components/build/Message";
import BuildsStore from "app/stores/BuildsStore";
import BuildState from "app/components/icons/BuildState";
import Duration from "app/components/shared/Duration";
import FriendlyTime from "app/components/shared/FriendlyTime";
import Icon from "app/components/shared/Icon";
import UserAvatar from "app/components/shared/UserAvatar";
import {
  getDurationPrefix,
  getDurationSuffix,
  getDurationValues,
} from "app/components/build/DurationNext";

import Steps from "./Header/pipeline/Steps";
import { Build } from "app/components/build/Show/lib/types";

function BuildMeta({ build }: { build: Build }) {
  const sha = shortCommit(build.commitId, build.commitShortLength);
  const seperator = <span>&middot;</span>;

  return (
    <div className="build-row-meta flex flex-wrap gapx1.5 charcoal-600 items-center text-xs leading-5">
      <span className="flex items-center gap1">
        <UserAvatar
          user={{
            name: build.authorName,
            avatar: { url: build.authorAvatar || "" },
          }}
          style={{ width: 20, height: 20 }}
          suppressAltText={true}
        />
        {build.authorName}
      </span>

      {seperator}

      <span className="flex items-center gap0.5">
        <a
          data-turbo="true"
          href={build.branchPath}
          className="flex color-inherit hover-color-inherit flex items-center"
        >
          <Icon icon="custom/outline/14x/branch" style={{ height: 14, width: 14 }} />
          {build.branchName}
        </a>

        <Icon icon="custom/outline/20x/slash" style={{ height: 14, width: 14 }} />

        <a
          href={build.commitUrl}
          target="_blank"
          className="monospace flex color-inherit hover-color-inherit flex items-center"
          rel="noreferrer"
          style={{ fontSize: 12 }}
        >
          <Icon
            icon={build.project.provider.frontendIcon}
            className="mr-1"
            style={{ height: 14, width: 14 }}
          />
          {sha}
        </a>
      </span>

      {seperator}

      <span>
        Created <FriendlyTime value={build.createdAt} />
      </span>
    </div>
  );
}

function getTooltip(build: Build) {
  const prefix = getDurationPrefix(build) || "";
  const suffix = getDurationSuffix(build) || "";

  const durationValues = getDurationValues(build);
  const durationString = durationValues
    ? // @ts-expect-error - TS2345 - Argument of type 'Duration' is not assignable to parameter of type 'number'.
      getDurationString(getDuration(durationValues.from, durationValues.to))
    : "";

  return `${prefix} ${durationString} ${suffix}`.trim();
}

const BuildRow = ({
  build,
  store,
  showStepsToggle,
}: {
  build: Build;
  store: BuildsStore;
  showStepsToggle: boolean;
}) => {
  const durationValues = getDurationValues(build);
  const tooltip = getTooltip(build);

  const buildState = build.state === "started" ? "RUNNING" : build.state.toUpperCase();

  const [showSteps, setShowSteps] = React.useState(false);

  function toggleSteps(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    event.currentTarget.blur();

    track("Builds List Toggle Steps Clicked", {
      action: showSteps ? "Hide" : "Show",
      text: showSteps ? `Hide steps for #${build.number}` : `Show steps for #${build.number}`,
      source: "Build Row",
    });

    setShowSteps(!showSteps);
  }

  return (
    <div className="build-row border-b border-gray antialiased">
      <div className="build-row-header gapx2.5 gapy0.5 py-2 px-4">
        <div
          className="build-row-status self-center flex justify-center cursor-help"
          title={tooltip}
        >
          <BuildState.Regular
            state={buildState}
            blockedState={build.blockedState && build.blockedState.toUpperCase()}
            className="block"
          />
        </div>
        <div
          className="build-row-duration charcoal-600 text-center whitespace-nowrap cursor-help -mx-2 tracking-tight text-xs leading-5"
          title={tooltip}
        >
          {durationValues ? (
            <Duration.Micro {...durationValues} />
          ) : (
            <span className="gray-800">—</span>
          )}
        </div>

        <a
          className="build-row-message  group hover:no-underline self-center inline-flex min-width-0"
          href={build.path}
        >
          <span className="charcoal-900 font-semibold truncate max-w-full min-w-0 group-hover:underline">
            <BuildMessage message={build.message} />
          </span>

          <span className="gray-800 ml-1 group-hover:no-underline">#{build.number}</span>
        </a>

        <BuildMeta build={build} />

        {showStepsToggle && build.jobs && (
          <div className="build-row-toggle-steps self-center -mx0.5">
            <button
              onClick={toggleSteps}
              className="unstyled-button charcoal-300 hover-charcoal-800 p-1"
              aria-label={showSteps ? "Hide steps" : "Show steps"}
            >
              <span className="sr-only">
                {showSteps ? `Hide steps for #${build.number}` : `Show steps for #${build.number}`}
              </span>

              <Icon icon="heroicons/20/solid/chevron-up-down" style={{ height: 20, width: 20 }} />
            </button>
          </div>
        )}
      </div>

      {showSteps && (
        <div
          className="build-row-steps flex flex-wrap flex-auto min-w-0 gap1 border-t border-gray-400 bg-slate-100 shadow-inset px-4 py-2"
          style={{ paddingLeft: 49 }}
        >
          <Steps build={build} store={store} small={true} />
        </div>
      )}
    </div>
  );
};

export default BuildRow;

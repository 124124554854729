import Environment from "app/lib/relay/environment";
import debounce from "lodash/debounce";
import { useState } from "react";
import { QueryRenderer, commitMutation, createRefetchContainer, graphql } from "react-relay";

import Panel from "app/components/shared/Panel";
import SearchField from "app/components/shared/SearchField";
import SectionLoader from "app/components/shared/SectionLoader";
import ShowMoreFooter from "app/components/shared/ShowMoreFooter";
import { formatNumber } from "app/lib/number";
import usePreloadedRelayQuery from "app/lib/usePreloadedRelayQuery";
import Chooser from "./PipelinesChooser";
import Row from "./row";

const PAGE_SIZE = 10;

const PipelinesPage = ({ team, relay, className }) => {
  const [loading, setLoading] = useState(false);
  const [searchingPipelines, setSearchingPipelines] = useState(false);
  const [search, setSearch] = useState(null);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);

  const renderPipelines = () => {
    if (team.pipelines.edges.length > 0) {
      return team.pipelines.edges.map(({ node }) => (
        <Row
          key={node.id}
          teamPipeline={node}
          onRemoveClick={handleTeamPipelineRemove}
          onAccessLevelChange={handleAccessLevelChange}
        />
      ));
    }

    if (search) {
      return null;
    }

    return (
      <Panel.Section className="dark-gray">
        There are no pipelines assigned to this team
      </Panel.Section>
    );
  };

  const renderPipelineSearch = () => {
    const { pipelines } = team;

    if (pipelines.edges.length > 0 || search !== null) {
      return (
        <div className="py-2 px-4">
          <SearchField
            placeholder="Search pipelines…"
            searching={searchingPipelines}
            onChange={handlePipelineSearch}
          />
        </div>
      );
    }

    return null;
  };

  const renderPipelinesSearchCount = () => {
    const { pipelines } = team;

    if (search && pipelines && !searchingPipelines) {
      return (
        <div className="bg-silver semi-bold py-2 px-4">
          <small className="dark-gray">{formatNumber(pipelines.count)} matching pipelines</small>
        </div>
      );
    }
  };

  const reloadTable = () => {
    setLoading(true);
    relay.refetch(
      {
        teamID: team.id,
        pageSize,
        search,
      },
      null,
      () => {
        setLoading(false);
      },
      { force: true },
    );
  };

  const onTeamPipelineChoose = () => {
    reloadTable();
  };

  const handlePipelineSearch = debounce(
    (pipelineSearch) => {
      setSearch(pipelineSearch);
      setSearchingPipelines(true);
      relay.refetch(
        {
          teamID: team.id,
          pageSize,
          search: pipelineSearch,
        },
        null,
        () => {
          setSearchingPipelines(false);
        },
        { force: true },
      );
    },
    500,
    { leading: false },
  );

  const handleShowMorePipelines = () => {
    setLoading(true);
    const newPageSize = pageSize + PAGE_SIZE;
    setPageSize(newPageSize);

    relay.refetch(
      {
        teamID: team.id,
        pageSize: newPageSize,
        search,
      },
      null,
      () => {
        setLoading(false);
      },
    );
  };

  const handleAccessLevelChange = (teamPipeline, accessLevel, callback) => {
    const environment = Environment.get();
    const input = { id: teamPipeline.id, accessLevel };

    commitMutation(environment, {
      mutation: graphql`
        mutation PipelinesTeamAccessLevelChangeMutation($input: TeamPipelineUpdateInput!) {
          teamPipelineUpdate(input: $input) {
            teamPipeline {
              accessLevel
            }
          }
        }
      `,
      variables: { input },
      onCompleted: () => {
        callback(null);
      },
      onError: (error) => callback(error),
    });
  };

  const handleTeamPipelineRemove = (teamPipeline, force, callback) => {
    const environment = Environment.get();
    const input = { id: teamPipeline.id, force };

    commitMutation(environment, {
      mutation: graphql`
        mutation PipelinesTeamDeleteMutation($input: TeamPipelineDeleteInput!) {
          teamPipelineDelete(input: $input) {
            deletedTeamPipelineID
            team {
              id
              pipelines {
                count
              }
            }
          }
        }
      `,
      variables: { input },
      onCompleted: () => {
        callback(null);
        reloadTable();
      },
      onError: (error) => callback(error),
    });
  };

  return (
    <div>
      <Chooser team={team} onChoose={onTeamPipelineChoose} disabled={loading} />
      <Panel className={className}>
        {renderPipelineSearch()}
        {renderPipelinesSearchCount()}
        {renderPipelines()}
        <ShowMoreFooter
          connection={team.pipelines}
          label="pipelines"
          loading={loading}
          searching={searchingPipelines}
          onShowMore={handleShowMorePipelines}
        />
      </Panel>
    </div>
  );
};

const TeamsPipelinesPageContainer = createRefetchContainer(
  PipelinesPage,
  {
    team: graphql`
      fragment Pipelines_team on Team
      @argumentDefinitions(
        pageSize: { type: "Int", defaultValue: 10 }
        search: { type: "String" }
      ) {
        ...PipelinesChooser_team
        pipelines(first: $pageSize, search: $search, order: NAME) {
          ...ShowMoreFooter_connection
          count
          edges {
            node {
              id
              accessLevel
              pipeline {
                id
                name
                url
                repository {
                  url
                }
              }
              permissions {
                teamPipelineUpdate {
                  allowed
                }
                teamPipelineDelete {
                  allowed
                }
              }
            }
          }
        }
      }
    `,
  },
  graphql`
    query Pipelines_organizationQuery($team: ID!, $pageSize: Int!, $search: String) {
      team(slug: $team) {
        ...Pipelines_team @arguments(pageSize: $pageSize, search: $search)
      }
    }
  `,
);

const PipelinesPageRenderer = ({ team }) => {
  const environment = Environment.get();

  const query = graphql`
    query Pipelines_Query($team: ID!) {
      team(slug: $team) {
        ...Pipelines_team
      }
    }
  `;

  const variables = { team };

  usePreloadedRelayQuery(
    true,
    {
      environment,
      query,
      variables,
    },
    [],
  );

  return (
    <QueryRenderer
      fetchPolicy="store-and-network"
      environment={environment}
      query={query}
      variables={variables}
      render={({ props, error }) => {
        if (error || !props) {
          return <SectionLoader />;
        }
        return <TeamsPipelinesPageContainer {...props} />;
      }}
    />
  );
};

export default PipelinesPageRenderer;

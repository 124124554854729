import * as React from "react";
import classNames from "classnames";

import Badge from "app/components/shared/Badge";

import { formatNumber } from "app/lib/number";

type SharedProps = {
  children: React.ReactNode;
  monochrome?: boolean;
  compact?: boolean;
  active?: boolean;
};

type TabButtonProps = {
  badge: boolean;
} & SharedProps;

const ACTIVE_CLASS_NAME = "btn-tab--active";

const TabButton = ({ monochrome, badge, compact, active, ...props }: TabButtonProps) => (
  <a
    className={classNames("btn btn-tab", {
      "btn-tab--monochrome": monochrome,
      "btn-tab--badged": badge,
      "btn-tab--compact": compact,
      [ACTIVE_CLASS_NAME]: active,
    })}
    {...props}
  />
);

type Props = {
  badge?: number;
} & SharedProps;

class Tab extends React.PureComponent<Props> {
  render() {
    const { badge, children, monochrome, compact, ...props } = this.props;

    const affix = badge ? <Badge outline={true}>{formatNumber(badge)}</Badge> : null;

    return (
      <li>
        <TabButton monochrome={monochrome} badge={!!badge} compact={compact} {...props}>
          {children} {affix}
        </TabButton>
      </li>
    );
  }
}

export default Tab;

import { useCallback, useState } from "react";
import { Team } from "../../shared/type";
import TabControl, { TabButton } from "app/components/analytics/shared/Tabs";
import TestAssignments from "../TestAssignments";

type TabProps = {
  teams: Team[];
  currentAccountMember: boolean;
  latestAssignmentsEndpoint: string;
  previouslyFlakyAssignmentsEndpoint: string;
};

const Tabs = ({
  teams,
  currentAccountMember,
  latestAssignmentsEndpoint,
  previouslyFlakyAssignmentsEndpoint,
}: TabProps) => {
  let anchor = window.location.hash.replace("#", "");
  if (anchor !== "recent" && anchor !== "outdated") {
    anchor = "recent";
  }
  const [activeTab, setActiveTab] = useState(anchor);
  const handleRecentFlakesTabClick = useCallback(() => setActiveTab("recent"), []);
  const handleOutdatedFlakesTabClick = useCallback(() => {
    setActiveTab("outdated");
  }, []);

  return (
    <div style={{ marginTop: "0px" }}>
      <TabControl className="justify-center">
        <TabButton
          active={activeTab === "recent"}
          href="#recent"
          onClick={handleRecentFlakesTabClick}
        >
          Flaky tests in the past 7 days
        </TabButton>
        <TabButton
          active={activeTab === "outdated"}
          href="#outdated"
          onClick={handleOutdatedFlakesTabClick}
        >
          Outdated flaky tests
        </TabButton>
      </TabControl>
      {activeTab === "recent" && (
        <TestAssignments
          teams={teams}
          currentAccountMember={currentAccountMember}
          endpoint={latestAssignmentsEndpoint}
          isFlaky={true}
        />
      )}
      {activeTab === "outdated" && (
        <TestAssignments
          teams={teams}
          currentAccountMember={currentAccountMember}
          endpoint={previouslyFlakyAssignmentsEndpoint}
          isFlaky={false}
        />
      )}
    </div>
  );
};

export default Tabs;

type Props = {
  path?: string;
};

const SupportBanner = ({ path = "" }: Props) => (
  <>
    <p>
      If you don&apos;t see branch names, build numbers, or commit hashes in the Test Analytics UI,
      then read{" "}
      <a className="purple" href="https://buildkite.com/docs/test-analytics/ci-environments">
        CI environment variables
      </a>{" "}
      to learn more about exporting your environment to the collector.
    </p>
    <section
      className="border border-gray rounded mb-3 very-dark-gray"
      style={{ borderLeft: "3px solid #14CC80" }}
    >
      <div className="m-3">
        <p>
          Having trouble with your test suite?{" "}
          <a className="purple" href={`https://buildkite.com/docs/test-analytics${path}`}>
            Read the docs
          </a>
          , or{" "}
          <a
            className="purple semi-bold"
            href="mailto:support+analytics@buildkite.com?subject=I've got questions about getting started with Test Analytics"
          >
            contact support
          </a>{" "}
          and we&apos;ll help you out.
        </p>
      </div>
    </section>
  </>
);

export default SupportBanner;

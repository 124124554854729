import * as React from "react";
import { createFragmentContainer, graphql } from "react-relay";
import Dropdown from "app/components/shared/Dropdown";
import Button from "app/components/shared/Button";
import Chooser from "app/components/shared/Chooser";
import Emojify from "app/components/shared/Emojify";
import Icon from "app/components/shared/Icon";

type Props = {
  archived: boolean;
  selectedTeamSlug: string | null | undefined;
  organization: any;
  onArchivedChange: (archived: boolean) => void;
  onTeamChange: (teamSlug: string) => void;
};

class Teams extends React.PureComponent<Props> {
  dropdownNode: Dropdown | null | undefined;

  get teamEdges() {
    if (this.props.organization.teams && this.props.organization.teams.edges) {
      return this.props.organization.teams.edges;
    }
    return [];
  }

  render() {
    // Collect all the teams that we're allowed to see pipelines on
    // @ts-expect-error - TS2347 - Untyped function calls may not accept type arguments.
    const teams = this.teamEdges.reduce<Array<any>>(
      (teams, teamEdge) =>
        teamEdge &&
        teamEdge.node &&
        teamEdge.node.permissions &&
        teamEdge.node.permissions.pipelineView &&
        teamEdge.node.permissions.pipelineView.allowed
          ? teams.concat(teamEdge.node)
          : teams,
      [],
    );

    // Don't render the select if there aren't any teams that can be viewed
    if (teams.length === 0) {
      return null;
    }

    return (
      <Dropdown
        className="ml-2"
        width={300}
        ref={(dropdownNode) => (this.dropdownNode = dropdownNode)}
      >
        <Button>
          <div className="flex">
            <span className="flex items-center">
              <span className="truncate max-w-full">{this.renderLabel()}</span>
            </span>
            <span className="flex items-center">
              <Icon icon="down-triangle" style={{ width: 8, height: 8, marginLeft: ".5em" }} />
            </span>
          </div>
        </Button>

        <Chooser selected={null} onSelect={this.handleDropdownSelect}>
          {this.renderArchivedPipelineOptions()}
          {this.renderOptions(teams)}
          <Chooser.Option value={{ team: null }} className="btn block hover-bg-silver">
            <div>All teams</div>
          </Chooser.Option>
        </Chooser>
      </Dropdown>
    );
  }

  renderArchivedPipelineOptions() {
    return (
      <>
        <Chooser.Option value={{ archived: false }} className="btn block hover-bg-silver">
          <div>Pipelines</div>
        </Chooser.Option>
        <Chooser.Option value={{ archived: true }} className="btn block hover-bg-silver">
          <div>Archived Pipelines</div>
        </Chooser.Option>
        <hr
          className="mx-3 my-1 flex-auto min-w-0 gray"
          style={{
            border: "none",
            borderBottom: "1px solid currentColor",
          }}
        />
      </>
    );
  }

  renderLabel() {
    let label = "All teams";

    if (this.props.selectedTeamSlug) {
      const selectedTeam = this.teamEdges
        .filter(Boolean)
        .find(({ node }) => node && node.slug === this.props.selectedTeamSlug);

      if (selectedTeam && selectedTeam.node) {
        // @ts-expect-error - TS2322 - Type 'Element' is not assignable to type 'string'.
        label = <Emojify text={selectedTeam.node.name} />;
      }
    }

    if (this.props.archived) {
      // @ts-expect-error - TS2322 - Type 'Element' is not assignable to type 'string'.
      label = (
        <>
          {"Archived Pipelines in "}
          {label}
        </>
      );
    }

    return <span className="block">{label}</span>;
  }

  renderOptions(teams) {
    return teams.map((team) => (
      <Chooser.Option
        key={team.id}
        value={{ team: team.slug }}
        className="btn block hover-bg-silver leading-tight"
      >
        <Emojify className="block" text={team.name} />
        {team.description && (
          <Emojify className="block dark-gray font-normal text-sm mt-1" text={team.description} />
        )}
      </Chooser.Option>
    ));
  }

  handleDropdownSelect = (selection: { team?: string; archived?: boolean }) => {
    if (this.dropdownNode) {
      this.dropdownNode.setShowing(false);
      if (selection.team !== undefined) {
        this.props.onTeamChange(selection.team);
      }
      if (selection.archived !== undefined) {
        this.props.onArchivedChange(selection.archived);
      }
    }
  };
}

export default createFragmentContainer(Teams, {
  organization: graphql`
    fragment Teams_organization on Organization {
      teams(first: 500) {
        edges {
          node {
            id
            name
            slug
            description
            permissions {
              pipelineView {
                allowed
              }
            }
          }
        }
      }
    }
  `,
});

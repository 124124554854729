const { abs, min, max } = Math;

type ViewportOffsets = {
  offsetX: number;
  offsetY: number;
  width: number;
};

// margin (in pixels) to maintain around automatically-positioned dropdowns
const SCREEN_MARGIN = 10;

const detectSafeArea = () => {
  let safeAreaProbeNode = document.getElementById("safe-area-probe");

  if (!safeAreaProbeNode) {
    safeAreaProbeNode = document.createElement("div");
    safeAreaProbeNode.setAttribute("id", "safe-area-probe");
    safeAreaProbeNode.setAttribute("data-turbo-permanent", "");
    safeAreaProbeNode.setAttribute(
      "style",
      "padding: constant(safe-area-inset-top) constant(safe-area-inset-right) constant(safe-area-inset-bottom) constant(safe-area-inset-left);" +
        "padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);" +
        "position: absolute;" +
        "opacity: 0;" +
        "visibility: hidden;",
    );
    document.body && document.body.appendChild(safeAreaProbeNode);
  }

  let {
    paddingLeft: left,
    paddingRight: right,
    paddingTop: top,
    paddingBottom: bottom,
  } = getComputedStyle(safeAreaProbeNode);

  // @ts-expect-error - TS2322 - Type 'number' is not assignable to type 'string'.
  left = parseFloat(left);
  // @ts-expect-error - TS2322 - Type 'number' is not assignable to type 'string'.
  right = parseFloat(right);
  // @ts-expect-error - TS2322 - Type 'number' is not assignable to type 'string'.
  top = parseFloat(top);
  // @ts-expect-error - TS2322 - Type 'number' is not assignable to type 'string'.
  bottom = parseFloat(bottom);

  return { left, right, top, bottom };
};

const combineAxisOffsets = (
  lowOffset: number,
  highOffset: number,
  computedScreenMargin = SCREEN_MARGIN,
) => abs(min(lowOffset - SCREEN_MARGIN, 0)) - abs(max(highOffset + computedScreenMargin, 0));

export default (
  requestedWidth: number | null | undefined = 250,
  offsetNode: HTMLElement,
): ViewportOffsets => {
  const safeArea = detectSafeArea();
  const computedScreenMargin =
    // @ts-expect-error - TS2362 - The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type.
    (safeArea.left + safeArea.right) / 2 + SCREEN_MARGIN;
  const windowWidth = window.innerWidth;
  const {
    left: offsetNodeLeft,
    width: offsetNodeWidth,
    height: offsetNodeHeight,
  } = offsetNode.getBoundingClientRect();

  const offsetNodeCenterX = offsetNodeLeft + offsetNodeWidth / 2;

  // automatically shrink the popover to fit the screen if the screen is too small
  // this shouldn't be needed often, but seems worth keeping just in case!
  // @ts-expect-error - TS2345 - Argument of type 'number | null' is not assignable to parameter of type 'number'.
  const width = min(requestedWidth, windowWidth - computedScreenMargin * 2);

  // if `leftOffset` is < 0, we need to shift the popup to the right
  const leftOffset = offsetNodeCenterX - width / 2;

  // if `rightOffset` is > 0, we need to shift the popup to the left
  const rightOffset = offsetNodeCenterX + width / 2 - windowWidth;

  // calculate the overall offset required to stay on-screen
  const offsetX = combineAxisOffsets(leftOffset, rightOffset, computedScreenMargin);
  const offsetY = offsetNodeHeight;

  return {
    offsetX,
    offsetY,
    width,
  };
};

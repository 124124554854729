import classNames from "classnames";
import BuildsStore from "app/stores/BuildsStore";
import { filterJobs } from "./utils/job";
import { Step } from "./Step";
import groupJobs from "./utils/groupJobs";
import JobGroup from "./utils/jobGroup";
import { Job } from "app/components/build/Show/lib/types";
import Database from "app/lib/Database";
import BuildShowStore from "app/stores/BuildShowStore";

// FIXME: this is a workaround to get the BuildPageStore loaded - see https://github.com/buildkite/buildkite/pull/10514 for more detail
require("app/components/job/Job");

export type Build = {
  id: string;
  path: string;
  jobs?: Array<Job>;
  jobsCount?: number;
};

/**
 * This component renders a list of build steps, and handles the grouping of jobs.
 * For builds that are truncated, it will render a link to the build page.
 */
export default function Steps({
  build,
  store,
  showHiddenJobs,
  small,
}: {
  build: Build;
  store: BuildsStore | BuildShowStore;
  showHiddenJobs?: boolean;
  small?: boolean;
}) {
  // NOTE: slightly gross workaround to deal with the fact that the Turbo builds
  // page doesn't use the BuildStore, i.e. the build is not already camel-cased.
  // Rails #camelize treats some keys slightly differently, so we're not able to
  // camel-case the object in the controller.
  build = Database.parse(build);

  if (build.jobs?.length === 0) {
    return (
      <p className="text-danger my-1">
        There are no build steps for this pipeline. To add a step, go to your Pipeline Settings.
      </p>
    );
  }

  if (!build.jobs) {
    return <p className="dark-gray my-1">Steps can&apos;t be displayed for this build.</p>;
  }

  // If a build has hidden jobs, we turn off the grouping behaviour
  const truncated = build.jobs && build.jobs.length !== build.jobsCount;

  const jobs = filterJobs(build.jobs, showHiddenJobs);
  const groupedJobs = groupJobs(jobs);

  return (
    <>
      {groupedJobs.map((job: Job | JobGroup) => (
        <Step
          key={job.id}
          job={job}
          build={build}
          buildStore={store}
          className={classNames(small && "build-pipeline-job--small")}
        />
      ))}

      {truncated && (
        <a
          key={`${build.id}-more-jobs`}
          href={build.path}
          className={classNames("build-pipeline-job truncate max-w-full align-middle", {
            "build-pipeline-job--small": small,
          })}
        >
          ...
        </a>
      )}
    </>
  );
}

import { useState } from "react";
import { countBy, map } from "lodash";
import { RunDuration } from "app/components/job/Job/JobDuration";
import { useRetriesForJob } from "app/components/build/Show/lib/BuildContext";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
  HoverCardPortal,
} from "app/components/shared/HoverCard";
import PipelineStateIcon, {
  colorForJob,
} from "app/components/shared/PipelineStateIcon/PipelineStateIcon";

import { StepLink } from "./StepLink";
import { Label } from "./Label";
import { resolveJobUrl } from "app/components/build/Show/lib/urlForView";
import useCurrentJobOrStep from "app/components/build/Show/lib/useCurrentJobOrStep";
import { CommandJob } from "app/components/build/Show/lib/types";

interface Props {
  job: CommandJob;
  side?: "left" | "right" | "top" | "bottom";
  children?: React.ReactNode;
}

export function JobRetries({ job, children, side = "right" }: Props) {
  const { currentJob } = useCurrentJobOrStep();
  const [open, setOpen] = useState(false);
  const jobs = useRetriesForJob(job);
  const retryCount = jobs.length - 1;

  if (!jobs.length) {
    return null;
  }

  const retryCounts = countBy(
    jobs.filter((job) => job.retryType === "automatic" || job.retryType === "manual"),
    "retryType",
  );
  const formattedRetryCounts = map(retryCounts, (count, type) => `${count} ${type}`).join(" · ");

  const label = (
    <span className="text-charcoal-500 underline decoration-dashed decoration-gray-600 underline-offset-2 text-xs font-medium">
      {retryCount > 1 ? `${retryCount} retries` : `${retryCount} retry`}
    </span>
  );

  return (
    <HoverCard open={open} onOpenChange={setOpen}>
      <HoverCardTrigger asChild={true}>{children ? children : label}</HoverCardTrigger>

      <HoverCardPortal>
        <HoverCardContent side={side} size="max-lg" onClick={(event) => event.stopPropagation()}>
          <div className="flex mb-1 gap-x-4 whitespace-nowrap">
            <div className="text-charcoal-600 text-xs">
              {retryCount > 1 ? `${formattedRetryCounts} retries` : `${formattedRetryCounts} retry`}
            </div>
          </div>

          {jobs.map((job) => {
            let label;
            if (job.retryType === "manual") {
              label = "Retried manually";
            } else if (job.retryType === "automatic") {
              label = "Retried automatically";
            } else {
              label = "Latest";
            }

            return (
              <StepLink
                onClick={() => {
                  setOpen(false);
                }}
                className="text-xs/5 px-2 -mx-2 gap-x-4"
                type={job.type}
                key={job.id}
                to={resolveJobUrl(job.id)}
                active={currentJob?.id === job.id}
              >
                <Label
                  duration={job.startedAt && <RunDuration job={job} />}
                  status={
                    <div className="font-normal">
                      <PipelineStateIcon
                        job={job}
                        className={colorForJob(job)}
                        style={{ width: 16, height: 16 }}
                      />
                    </div>
                  }
                >
                  {label}
                </Label>
              </StepLink>
            );
          })}
        </HoverCardContent>
      </HoverCardPortal>
    </HoverCard>
  );
}

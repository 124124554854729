import { twMerge } from "tailwind-merge";

interface Props {
  className?: string;
  project: {
    provider: {
      id?: string;
    };
  };
  pullRequest?: {
    url: string;
    id: string;
  };
}

export function PullRequest({ pullRequest, project, className }: Props) {
  if (!pullRequest) {
    return null;
  }

  let title;
  if (project.provider.id === "github") {
    title = `View pull request #${pullRequest.id} on GitHub`;
  } else if (project.provider.id === "gitlab") {
    title = `View merge request #${pullRequest.id} on GitLab`;
  }

  return (
    <a
      title={title}
      className={twMerge("color-inherit hover-color-inherit", className)}
      href={pullRequest.url}
    >
      #{pullRequest.id}
    </a>
  );
}

import { ChartType, ScriptableContext } from "chart.js";

export const linearFillGradient = <TType extends ChartType>(colorStops: string[]) => {
  return (context: ScriptableContext<TType>) => {
    if (!context.chart.chartArea) {
      return;
    }

    const {
      ctx,
      chartArea: { top, bottom },
    } = context.chart;
    const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);

    colorStops.forEach((colorStop, index) => {
      gradientBg.addColorStop(index, colorStop);
    });

    return gradientBg;
  };
};

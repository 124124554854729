import { useCallback, useState } from "react";
import Icon from "app/components/shared/Icon";
import Button from "app/components/shared/Button";
import Dropdown from "app/components/shared/Dropdown";
import { parseTagParams, BRANCH_KEY } from "../tagHelpers";

type Props = {
  current_branch: string | null;
  allow_custom_filtering: boolean;
  use_branch_from_tags: boolean;
  branches: {
    label: string;
    value: string | null;
    favorite_branch?: boolean;
  }[];
};

const BranchFilter = ({
  current_branch,
  branches,
  allow_custom_filtering,
  use_branch_from_tags,
}: Props) => {
  const [customBranchInput, setCustomBranchInput] = useState("");
  const [customBranchFocused, setCustomBranchFocused] = useState(false);

  // The current branch is a custom branch if it's not in branches
  const isCustomBranch =
    allow_custom_filtering &&
    current_branch &&
    !branches.some((branch) => branch.value === current_branch);

  const onChangeBranch = useCallback(
    (value) => {
      const link = new URL(window.location.href);

      if (use_branch_from_tags) {
        const tags = parseTagParams(link.searchParams.get("tags"));
        const updatedTags = !value
          ? tags.excludeByKey(BRANCH_KEY)
          : tags.includeByKey(BRANCH_KEY, value);

        link.searchParams.set("tags", updatedTags.toQueryString());
      } else {
        !value ? link.searchParams.delete("branch") : link.searchParams.set("branch", value);
      }

      window.location.href = link.href;
    },
    [use_branch_from_tags],
  );

  const handleCustomBranchKeyDown = (event) => {
    if (event.key === "Enter") {
      onChangeBranch(event.target.value);
    }
  };

  const handleCustomBranchOnBlur = (event) => {
    const value = event.target.value;
    if (value) {
      onChangeBranch(value);
    }
    setCustomBranchFocused(false);
  };

  return (
    <Dropdown className="flex">
      <Button theme="small" className="font-medium hover:text-purple-600 transition-colors px-3">
        <Icon
          icon="custom/outline/14x/branch"
          className="mr-2"
          style={{
            fontWeight: 400,
            fill: "var(--charcoal-500)",
            width: 14,
            height: 14,
          }}
        />

        {current_branch || "All branches"}

        <Icon icon="down-triangle" className="ml-2" style={{ width: 7, height: 7 }} />
      </Button>

      <div className="px-2 py-[3px] flex flex-col gap-2" data-testid="dropdown">
        {isCustomBranch && (
          <DropdownButton
            onClick={onChangeBranch}
            selected={isCustomBranch}
            value={current_branch}
            label={current_branch}
          />
        )}

        {branches.map(({ label, value, favorite_branch }) => (
          <DropdownButton
            key={value}
            onClick={onChangeBranch}
            value={value}
            label={label}
            selected={current_branch === value}
            favoriteBranch={favorite_branch}
          />
        ))}

        {allow_custom_filtering && (
          <>
            <div
              role="separator"
              aria-orientation="horizontal"
              className="-mx-2 h-px bg-gray-500"
            />

            <div className="p-1">
              <div className="flex items-center text-sm font-medium text-charcoal-700">
                {!customBranchFocused && (
                  <Icon
                    icon="heroicons/outline/plus"
                    style={{
                      fontWeight: 400,
                      fill: "var(--charcoal-500)",
                      width: 15,
                      height: 15,
                      marginRight: "2px",
                    }}
                  />
                )}

                <input
                  id="custom-branch"
                  type="text"
                  value={customBranchInput}
                  onChange={(event) => setCustomBranchInput(event.target.value)}
                  onFocus={() => setCustomBranchFocused(true)}
                  onBlur={handleCustomBranchOnBlur}
                  onKeyDown={handleCustomBranchKeyDown}
                  placeholder="Custom branch"
                  className="w-full placeholder:text-charcoal-700 cursor-pointer p-1 rounded focus:cursor-text focus:outline focus:outline-2 focus:outline-lime-500 focus:placeholder:text-slate-500"
                />
              </div>

              {customBranchFocused && (
                <p className="text-xs text-charcoal-300 pt-2 m-0">
                  Use a wildcard (*) to filter branches by prefix.
                </p>
              )}
            </div>
          </>
        )}
      </div>
    </Dropdown>
  );
};

type DropdownButtonProps = {
  onClick: (string) => void;
  selected: boolean;
  value: string | null;
  favoriteBranch?: boolean;
  label: string;
};

const DropdownButton = ({
  onClick,
  selected,
  value,
  favoriteBranch,
  label,
}: DropdownButtonProps) => {
  const handleButtonClick = useCallback(() => onClick(value), [value, onClick]);

  return (
    <button
      className="text-left flex p-2 text-sm text-charcoal-700 font-semibold rounded focus:no-underline focus:text-charcoal-700 focus:outline focus:outline-2 focus:outline-lime-500 hover:text-purple-600 data-[active=true]:bg-purple-100 data-[active=true]:text-purple-600 hover:no-underline"
      onClick={handleButtonClick}
      data-turbo="false"
      data-active={selected}
    >
      {favoriteBranch && "⭐️ "}
      {label}
    </button>
  );
};

export default BranchFilter;

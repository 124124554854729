import { uniqueId } from "lodash";
import { cva, VariantProps } from "class-variance-authority";
import { twMerge } from "tailwind-merge";
import Icon from "app/components/shared/Icon";
import { InfoTooltip } from "./Chart/InfoTooltip";

export function Card({ children, className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={twMerge("animation-fade-in h-full flex flex-col gap-y-2.5 gap-x-3.5", className)}
      {...props}
    >
      {children}
    </div>
  );
}

export function CardLabel({
  children,
  description,
  ...props
}: { description?: string } & React.HTMLAttributes<HTMLHeadingElement>) {
  return (
    <div {...props}>
      <h2 className="text-base leading-tight font-semibold my-0.5">{children}</h2>

      {description && <p className="m-0 text-gray-800 text-xs font-normal">{description}</p>}
    </div>
  );
}

export function CardIcon({
  color,
  className,
  ...props
}: { color: string } & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={twMerge("p-2 border rounded-md", className)}
      style={{
        backgroundColor: `${color}10`,
        color: color,
        borderColor: `${color}20`,
      }}
      {...props}
    >
      <Icon icon="heroicons/outline/clock" className="h-5 w-5 block" />
    </div>
  );
}

export function CardSection({
  children,
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={twMerge("flex justify-between items-end gap-x-3", className)} {...props}>
      {children}
    </div>
  );
}

export function CardChart({ children, className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={twMerge("grow min-h-0 -m-2 mt-0", className)} {...props}>
      {children}
    </div>
  );
}

export function CardDelta({
  formattedValue,
  color,
  value,
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement> & {
  formattedValue: number | string;
  color: string;
  value: number | null;
}) {
  let icon: React.ReactNode;

  if (value === null || value === 0) {
    icon = null;
  } else if (value > 0) {
    icon = <Icon icon="heroicons/outline/arrow-up" className="w-2 h-2 block" />;
  } else {
    icon = <Icon icon="heroicons/outline/arrow-down" className="w-2 h-2 block" />;
  }

  return (
    <span
      className={twMerge(
        "px-1.5 py-0.5 rounded-full font-semibold text-xs inline-flex items-center gap-0.5",
        className,
      )}
      style={{ color: color, backgroundColor: `${color}10` }}
      {...props}
    >
      {icon}
      {formattedValue}
    </span>
  );
}

const cardStatVariants = cva("flex", {
  variants: {
    size: {
      sm: "text-xs",
      lg: "text-2xl font-semibold",
    },
    layout: {
      inline: "flex-row gap-x-1.5",
      stacked: "flex-col-reverse",
    },
  },
  defaultVariants: {
    size: "sm",
    layout: "inline",
  },
});

export function CardStat({
  label,
  value,
  className,
  size,
  layout,
  ...props
}: {
  label?: string;
  value?: number | string;
} & React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof cardStatVariants>) {
  const labelId = label && uniqueId("stat-label-");

  return (
    <div className={twMerge(cardStatVariants({ size, layout }), className)} {...props}>
      {label && (
        <div id={labelId} className="text-xs text-gray-800">
          {label}
        </div>
      )}

      <div aria-labelledby={labelId}>{value}</div>
    </div>
  );
}

interface LabelWithTooltipProps {
  label: string | React.ReactNode;
  description?: string;
  tooltip: {
    title: string;
    description: string;
    items?: Array<string>;
  };
  className?: string;
}

export function CardLabelWithTooltip({
  label,
  description,
  tooltip,
  className,
}: LabelWithTooltipProps) {
  return (
    <div className={className}>
      <CardLabel description={description}>
        <div className="flex flex-0 items-center gap-1">
          {label}
          <div className="max-xl:hidden">
            <InfoTooltip {...tooltip} />
          </div>
        </div>
      </CardLabel>
    </div>
  );
}

import * as React from "react";

import LegacyDispatcher from "app/lib/legacyDispatcher";

import PipelineForm from "./Form";
import StepPreview from "./StepPreview";
import Icon from "app/components/shared/Icon";
import Dropdown from "app/components/shared/Dropdown";
import Button from "app/components/shared/Button";

import ProjectPipelineStore, {
  StepID,
  StepReorderPosition,
  StepPartial,
  Step,
} from "app/stores/ProjectPipelineStore";

type Props = {
  disabled: boolean;
  store: ProjectPipelineStore;
  accountID: string;
};

type State = {
  steps: Array<Step>;
};

/* eslint-disable react/require-optimization */
// TODO: Fix the architecture of these components so this one can be optimised.
// The problem is the `step` object is actually passed from the store, which is always
// the same object reference, even if mutated, so ShallowCompare functions do not work.

export default class Pipeline extends React.Component<Props, State> {
  dropdownNode: Dropdown | null | undefined;

  constructor(props: Props) {
    super(props);

    this.state = {
      steps: this.props.store.orderedSteps(),
    };
  }

  componentDidMount() {
    this.props.store.on("change", this.handleStoreChange);
  }

  componentWillUnmount() {
    this.props.store.off("change", this.handleStoreChange);
  }

  nonDeletedSteps(): Array<Step> {
    // We don't want to pass destroyed steps to the preview components. We DO want to pass destroyed
    // steps to the forms though (we want the form fields to be present when submitting)
    return this.state.steps.filter((step) => !step.destroyed);
  }

  render() {
    return (
      <div className="ProjectPipelineComponent">
        <div className="ProjectPipelineComponent__steps-panel">
          <div className="flex flex-wrap">
            {this.renderStepPreviews()}
            {this.renderAddStepButton()}
          </div>
        </div>
        {this.state.steps.map((step, index) => {
          return (
            <PipelineForm
              disabled={this.props.disabled}
              key={step.id}
              step={step}
              accountID={this.props.accountID}
              index={index}
              // @ts-expect-error - TS2769 - No overload matches this call.
              autofocus={index !== 0}
            />
          );
        })}
      </div>
    );
  }

  renderStepPreviews(): Array<React.ReactElement<any>> {
    return this.nonDeletedSteps().map((step) => (
      <StepPreview
        key={step.id}
        step={step}
        onSort={this.handleSort}
        onClick={this.handleStepPreviewClick}
      />
    ));
  }

  renderAddStepButton() {
    return (
      <Dropdown
        width={250}
        className="inline-block"
        ref={(dropdownNode) => (this.dropdownNode = dropdownNode)}
      >
        <Button
          disabled={this.props.disabled}
          type="button"
          style={{ height: 35, lineHeight: "12px" }}
        >
          Add
          <Icon
            icon="down-triangle"
            className="flex-none"
            style={{
              width: 7,
              height: 7,
              marginLeft: ".5em",
            }}
          />
        </Button>

        <div>
          <a
            href="#"
            onClick={this.handleNewScriptClick}
            className="btn black hover-lime focus-lime flex items-center flex-none semi-bold block py-2"
          >
            Run script or command
          </a>
          <a
            href="#"
            onClick={this.handleNewWaiterClick}
            className="btn black hover-lime focus-lime flex items-center flex-none semi-bold block py-2"
          >
            Wait for previous steps to pass
          </a>
          <a
            href="#"
            onClick={this.handleNewManualClick}
            className="btn black hover-lime focus-lime flex items-center flex-none semi-bold block py-2"
          >
            Wait and block pipeline
          </a>
          <hr className="my-1" />
          <a
            href="#"
            onClick={this.handleNewPipelineUploadClick}
            className="btn black hover-lime focus-lime flex items-center flex-none semi-bold block py-2"
          >
            Read steps from repository
          </a>
        </div>
      </Dropdown>
    );
  }

  deleteEmptyStep() {
    const steps = this.nonDeletedSteps();

    if (steps.length === 1 && this.isEmptyStep(steps[0])) {
      // @ts-expect-error - TS2554 - Expected 6 arguments, but got 2.
      return LegacyDispatcher.emit("project_step:destroy", { step: steps[0] });
    }
  }

  isEmptyStep(step: Step) {
    return (
      !step.persisted &&
      !step.name &&
      !step.command &&
      step.agentQueryRules &&
      (step.agentQueryRules.length === 0 ||
        (step.agentQueryRules.length === 1 && step.agentQueryRules[0] === ""))
    );
  }

  // Triggered when the store changes
  handleStoreChange = () => {
    this.setState({
      steps: this.props.store.orderedSteps(),
    });
  };

  handleNewScriptClick = (evt: React.MouseEvent<HTMLAnchorElement>) => {
    evt.preventDefault();
    if (this.dropdownNode) {
      this.dropdownNode.setShowing(false);
    }

    return this.emitNewStepEvent({
      type: "script",
    });
  };

  handleNewWaiterClick = (evt: React.MouseEvent<HTMLAnchorElement>) => {
    evt.preventDefault();
    if (this.dropdownNode) {
      this.dropdownNode.setShowing(false);
    }

    return this.emitNewStepEvent({
      type: "waiter",
    });
  };

  handleNewManualClick = (evt: React.MouseEvent<HTMLAnchorElement>) => {
    evt.preventDefault();
    if (this.dropdownNode) {
      this.dropdownNode.setShowing(false);
    }

    return this.emitNewStepEvent({
      type: "manual",
    });
  };

  handleNewPipelineUploadClick = (evt: React.MouseEvent<HTMLAnchorElement>) => {
    if (this.dropdownNode) {
      this.dropdownNode.setShowing(false);
    }
    evt.preventDefault();
    this.deleteEmptyStep();

    return this.emitNewStepEvent({
      type: "script",
      command: "buildkite-agent pipeline upload",
      name: ":pipeline:",
    });
  };

  emitNewStepEvent(stepAttributes: StepPartial) {
    // @ts-expect-error - TS2554 - Expected 6 arguments, but got 2.
    return LegacyDispatcher.emit("project_step:new", {
      attributes: stepAttributes,
    });
  }

  handleStepPreviewClick = (step: Step) => {
    // @ts-expect-error - TS2554 - Expected 6 arguments, but got 2.
    return LegacyDispatcher.emit("project_step:select", { step });
  };

  handleSort = (stepId: StepID, targetId: StepID, position?: StepReorderPosition | null) => {
    // @ts-expect-error - TS2554 - Expected 6 arguments, but got 2.
    return LegacyDispatcher.emit("project_step:sort", {
      stepId,
      targetId,
      position,
    });
  };
}

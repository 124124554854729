import * as React from "react";

import * as Turbo from "@hotwired/turbo";

import Emojify from "app/components/shared/Emojify";
import FriendlyTime from "app/components/shared/FriendlyTime";
import PipelineStateIcon from "app/components/shared/PipelineStateIcon";
import RemoteButtonComponent from "app/components/shared/RemoteButtonComponent";
import UserAvatar from "app/components/shared/UserAvatar";

import Database from "app/lib/Database";

import { InputJob } from "app/components/build/Show/lib/types";

type Props = {
  job: InputJob;
  buildStore: {
    loadAndEmit: (arg1?: any) => void;
    reload: () => void;
  };
};

export default class ManualJobSummary extends React.PureComponent<Props> {
  renderSummary() {
    if (this.props.job.unblockedAt && this.props.job.unblockedBy) {
      return (
        <div className="build-details-pipeline-manual-job">
          <span className="unblocked">
            <UserAvatar
              user={UserAvatar.convertUserFromJobPresenter(this.props.job.unblockedBy)}
              style={{ width: 24, height: 24, marginRight: 6 }}
              suppressAltText={true}
            />
            <strong className="semi-bold">
              {this.props.job.unblockedBy && this.props.job.unblockedBy.name}
            </strong>
            {" unblocked "}
            <strong className="semi-bold">
              <Emojify text={this.props.job.name || "Continue"} />
            </strong>
            {this.unblockedAtNode()}
          </span>
        </div>
      );
    } else if (!this.props.job.permissions.activate.allowed) {
      return (
        <div className="build-details-pipeline-manual-job">
          <button className="btn btn-default btn--disabled" disabled={true}>
            <div className="flex items-center">
              <PipelineStateIcon job={this.props.job} />
              <strong className="semi-bold ml-1">
                <Emojify text={this.props.job.name || "Continue"} />
              </strong>
            </div>
          </button>
        </div>
      );
    } else if (
      this.props.job.state === "skipped" ||
      this.props.job.state === "canceled" ||
      this.props.job.state === "blocked_failed"
    ) {
      return (
        <div className="build-details-pipeline-manual-job">
          <button
            // @ts-expect-error - TS2322 - Type '{ children: Element; href: string; className: string; disabled: true; }' is not assignable to type 'DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>'.
            href={this.props.job.unblockPath}
            className="btn btn-default btn--disabled"
            disabled={true}
          >
            <div className="flex items-center">
              <PipelineStateIcon job={this.props.job} />
              <strong className="semi-bold ml-1">
                <Emojify text={this.props.job.name || "Continue"} />
              </strong>
            </div>
          </button>
        </div>
      );
    }

    if (this.props.job.fields) {
      return (
        <div className="build-details-pipeline-manual-job">
          <a href="#" className="btn btn-default" onClick={this.handleContinueButtonClick}>
            <div className="flex items-center">
              <PipelineStateIcon job={this.props.job} />
              <strong className="semi-bold ml-1">
                <Emojify text={this.props.job.name || "Continue"} />
              </strong>
            </div>
          </a>
        </div>
      );
    }

    return (
      <div className="build-details-pipeline-manual-job">
        <RemoteButtonComponent
          url={this.props.job.unblockPath}
          method="post"
          confirmText={this.props.job.prompt || "Unblock this step?"}
          className="btn btn-default"
          onSuccess={(_event, response) => {
            if (response?.build) {
              this.props.buildStore.loadAndEmit(Database.parse(response.build));
            }
          }}
          onError={(_event, response) => {
            if (response?.message) {
              alert(response.message);
            }
            this.props.buildStore.reload();
          }}
        >
          <div className="flex items-center">
            <PipelineStateIcon job={this.props.job} />
            <strong className="semi-bold ml-1">
              <Emojify text={this.props.job.name || "Continue"} />
            </strong>
          </div>
        </RemoteButtonComponent>
      </div>
    );
  }

  handleContinueButtonClick = (evt: React.MouseEvent<HTMLAnchorElement>) => {
    evt.preventDefault();

    Turbo.visit(this.props.job.unblockDialogPath, {
      frame: "unblock_dialog",
    });
  };

  unblockedAtNode() {
    if (this.props.job.unblockedAt) {
      return (
        <small className="text-muted">
          <br /> <FriendlyTime value={this.props.job.unblockedAt} capitalized={true} />
        </small>
      );
    }
  }

  render() {
    return <div>{this.renderSummary()}</div>;
  }
}

import { Handle, NodeProps } from "reactflow";
import { type ReactNode } from "react";
import cn from "classnames";
import { twMerge } from "tailwind-merge";
import Conditional from "./Conditional";
import { Step, State, Outcome } from "app/lib/pipeline/Step";
import { StateIcon } from "./StateIcon";
import { bgColorForStep, textColorForStep } from "../colors";
import { useBuild, useMostRecentJob } from "app/components/build/Show/lib/BuildContext";
import { useTrack } from "app/components/build/Show/lib/useTrack";

type StepProps = Pick<Step, "state" | "outcome" | "if"> & {
  emphasis?: Emphasis;
  type?: string;
};

export enum Emphasis {
  Default,
  High,
  Low,
}

export default function BaseNode(
  props: {
    label?: ReactNode;
    icon?: ReactNode;
    children?: ReactNode;
    className?: string;
    hideStateIcon?: boolean;
    count?: ReactNode;
    actions?: ReactNode;
  } & NodeProps<StepProps>,
) {
  const { state, outcome, emphasis = Emphasis.Default, type } = props.data;
  const { build } = useBuild();
  const track = useTrack();

  const job = useMostRecentJob(props.id);
  const waitingOnConcurrencyGroup = job?.state === "limited";

  return (
    <>
      <Conditional if={props.data.if} />
      <div
        onClick={() => track("Build Canvas Step Clicked", { type })}
        data-state={state}
        data-outcome={outcome}
        className={twMerge(
          "bg-white",
          "cursor-pointer",
          "rounded-xl",
          "p-3",
          "border-2",
          ["flex", "flex-col", "gap-5"],
          "transition-[color,opacity] duration-150",
          "max-w-[45ch]",
          cn({
            [textColorForStep({ ...props.data, waitingOnConcurrencyGroup })]: state,
            [bgColorForStep(props.data, build)]: state && build,
            "shadow-depth-200": !state,
            "opacity-40": state === State.WaitingForDependencies || state === State.Ignored,
            border: state === State.Finished && outcome === Outcome.SoftFailed,
            "ring-4 ring-offset-2 ring-[#086DD7]": props.selected,
          }),
          props.className,
          cn({
            "opacity-40 hover:opacity-100": emphasis === Emphasis.Low,
            "opacity-100 border-opacity-100": emphasis === Emphasis.High,
          }),
        )}
      >
        <div className="flex items-center gap-2">
          {props.icon}
          {props.label && (
            <header
              role="heading"
              className={twMerge(
                "flex-auto min-w-0 text-slate-900 truncate max-w-full font-medium text-base",
                cn({
                  "line-through": state === State.Ignored,
                }),
              )}
            >
              {props.label}
            </header>
          )}

          {!props.hideStateIcon && (
            <span className="flex items-center gap-1 ml-auto">
              {props.count}
              <StateIcon {...props.data} waitingOnConcurrencyGroup={waitingOnConcurrencyGroup} />
            </span>
          )}

          {props.actions && props.actions}
        </div>
        {props.children}
      </div>

      {props.targetPosition && (
        <Handle
          type="target"
          position={props.targetPosition}
          isConnectable={props.isConnectable}
          className={cn({ "opacity-0": !props.isConnectable })}
        />
      )}
      {props.sourcePosition && (
        <Handle
          type="source"
          position={props.sourcePosition}
          isConnectable={props.isConnectable}
          className={cn({ "opacity-0": !props.isConnectable })}
        />
      )}
    </>
  );
}

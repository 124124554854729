import { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default class DropdownButton extends PureComponent {
  static displayName = "Navigation.DropdownButton";

  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
  };

  render() {
    const { className, children, ...props } = this.props;

    return (
      <button
        className={classNames(
          "btn black semi-bold leading-tight hover-purple focus-purple",
          className,
        )}
        {...props}
      >
        <div className="flex items-center flex-none">{children}</div>
      </button>
    );
  }
}

import classNames from "classnames";
import "./animations.css";

const loader = {
  icon: require.context("./icons", true, /\.svg$/),
} as const;

function icon(path: string): string {
  return loader.icon(path).default;
}

export const SIZES = {
  HUGE: "HUGE",
  BIG: "BIG",
  SMALL: "SMALL",
  MICRO: "MICRO",
} as const;

export const STATES = {
  BLOCKED: "BLOCKED",
  SCHEDULED: "SCHEDULED",
  RUNNING: "RUNNING",
  PASSED: "PASSED",

  FAILED: "FAILED",
  SOFT_FAILED: "SOFT_FAILED",
  CANCELED: "CANCELED",
  CANCELING: "CANCELING",
  CREATING: "CREATING",
  SKIPPED: "SKIPPED",
  NOT_RUN: "NOT_RUN",
} as const;

const ICONS = {
  ring: {
    [SIZES.HUGE]: icon("./Ring/Huge.svg"),
    [SIZES.BIG]: icon("./Ring/Big.svg"),
    [SIZES.SMALL]: icon("./Ring/Small.svg"),
    [SIZES.MICRO]: icon("./Ring/Micro.svg"),
  },
  [STATES.SCHEDULED]: {
    [SIZES.HUGE]: icon("./Node/Huge.svg"),
    [SIZES.BIG]: icon("./Node/Big.svg"),
    [SIZES.SMALL]: icon("./Node/Small.svg"),
    [SIZES.MICRO]: icon("./Node/Micro.svg"),
  },
  [STATES.BLOCKED]: {
    [SIZES.HUGE]: icon("./Node/Huge.svg"),
    [SIZES.BIG]: icon("./Node/Big.svg"),
    [SIZES.SMALL]: icon("./Node/Small.svg"),
    [SIZES.MICRO]: icon("./Node/Micro.svg"),
  },
  [STATES.RUNNING]: {
    [SIZES.HUGE]: icon("./Spinner/Huge.svg"),
    [SIZES.BIG]: icon("./Spinner/Big.svg"),
    [SIZES.SMALL]: icon("./Spinner/Small.svg"),
    [SIZES.MICRO]: icon("./Spinner/Micro.svg"),
  },
  [STATES.PASSED]: {
    [SIZES.HUGE]: icon("./Check/Huge.svg"),
    [SIZES.BIG]: icon("./Check/Big.svg"),
    [SIZES.SMALL]: icon("./Check/Small.svg"),
    [SIZES.MICRO]: icon("./Check/Micro.svg"),
  },
  [STATES.FAILED]: {
    [SIZES.HUGE]: icon("./Cross/Huge.svg"),
    [SIZES.BIG]: icon("./Cross/Big.svg"),
    [SIZES.SMALL]: icon("./Cross/Small.svg"),
    [SIZES.MICRO]: icon("./Cross/Micro.svg"),
  },
  [STATES.SOFT_FAILED]: {
    [SIZES.HUGE]: icon("./Triangle/Huge.svg"),
    [SIZES.BIG]: icon("./Triangle/Big.svg"),
    [SIZES.SMALL]: icon("./Triangle/Small.svg"),
    [SIZES.MICRO]: icon("./Triangle/Micro.svg"),
  },
  [STATES.CANCELED]: {
    [SIZES.HUGE]: icon("./WeirdBurger/Huge.svg"),
    [SIZES.BIG]: icon("./WeirdBurger/Big.svg"),
    [SIZES.SMALL]: icon("./WeirdBurger/Small.svg"),
    [SIZES.MICRO]: icon("./WeirdBurger/Micro.svg"),
  },
  [STATES.CANCELING]: {
    [SIZES.HUGE]: icon("./WeirdBurger/Huge.svg"),
    [SIZES.BIG]: icon("./WeirdBurger/Big.svg"),
    [SIZES.SMALL]: icon("./WeirdBurger/Small.svg"),
    [SIZES.MICRO]: icon("./WeirdBurger/Micro.svg"),
  },
  [STATES.CREATING]: {
    [SIZES.HUGE]: icon("./Spinner/Huge.svg"),
    [SIZES.BIG]: icon("./Spinner/Big.svg"),
    [SIZES.SMALL]: icon("./Spinner/Small.svg"),
    [SIZES.MICRO]: icon("./Spinner/Micro.svg"),
  },
  [STATES.SKIPPED]: {
    [SIZES.HUGE]: icon("./Bar/Huge.svg"),
    [SIZES.BIG]: icon("./Bar/Big.svg"),
    [SIZES.SMALL]: icon("./Bar/Small.svg"),
    [SIZES.MICRO]: icon("./Bar/Micro.svg"),
  },
  [STATES.NOT_RUN]: {
    [SIZES.HUGE]: icon("./Bar/Huge.svg"),
    [SIZES.BIG]: icon("./Bar/Big.svg"),
    [SIZES.SMALL]: icon("./Bar/Small.svg"),
    [SIZES.MICRO]: icon("./Bar/Micro.svg"),
  },
} as const;

const COLORS: Record<keyof typeof STATES, string> = {
  [STATES.SCHEDULED]: "#BBBBBB",
  [STATES.BLOCKED]: "#BBBBBB",
  [STATES.RUNNING]: "#FDBA12",
  [STATES.PASSED]: "#90C73E",
  [STATES.FAILED]: "#F83F23",
  [STATES.SOFT_FAILED]: "#F83F23",
  [STATES.CANCELED]: "#F83F23",
  [STATES.CANCELING]: "#F83F23",
  [STATES.CREATING]: "#83B0E4",
  [STATES.SKIPPED]: "#83B0E4",
  [STATES.NOT_RUN]: "#83B0E4",
};

const DIMENSIONS: Record<keyof typeof SIZES, [number, number]> = {
  [SIZES.HUGE]: [48, 48],
  [SIZES.BIG]: [34, 34],
  [SIZES.SMALL]: [22, 22],
  [SIZES.MICRO]: [18, 18],
};

type Props = {
  size: keyof typeof SIZES;
  state: keyof typeof STATES;
  className?: string;
  style?: {
    [key: string]: string;
  };
  animated?: boolean;
};

export default function BuildState({ size, state, ...props }: Props) {
  const color = COLORS[state] || "#CDCCCC";
  const [width, height] = DIMENSIONS[size];
  const Ring = ICONS.ring[size];
  const Inner = ICONS[state][size];

  return (
    <div
      data-testid="BuildState"
      className={props.className}
      style={{
        ...props.style,
        color,
        position: "relative",
        overflow: "hidden",
        transition: "color 150ms linear",
      }}
    >
      <Ring
        // @ts-expect-error - TS2322 - Type '{ size: "MICRO" | "SMALL" | "HUGE" | "BIG"; state: "SKIPPED" | "SCHEDULED" | "RUNNING" | "PASSED" | "SOFT_FAILED" | "FAILED" | "CANCELING" | "CANCELED" | "CREATING" | "BLOCKED" | "NOT_RUN"; width: Flow.ObjMap<...>; height: Flow.ObjMap<...>; viewBox: string; style: { ...; }; }' is not assignable to type 'IntrinsicAttributes'.
        size={size}
        state={state}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        style={{
          display: "block",
          transformOrigin: "center",
        }}
      />
      <Inner
        key={state}
        // @ts-expect-error - TS2322 - Type '{ key: "SKIPPED" | "SCHEDULED" | "RUNNING" | "PASSED" | "SOFT_FAILED" | "FAILED" | "CANCELING" | "CANCELED" | "CREATING" | "BLOCKED" | "NOT_RUN"; size: "MICRO" | "SMALL" | "HUGE" | "BIG"; ... 5 more ...; style: { ...; }; }' is not assignable to type 'IntrinsicAttributes'.
        size={size}
        state={state}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        className={classNames({
          BuildState__SlideAnimation: state === STATES.SCHEDULED,
          BuildState__SpinAnimation: state === STATES.RUNNING || state === STATES.CREATING,
        })}
        style={{
          display: "block",
          transformOrigin: "center",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      />
    </div>
  );
}

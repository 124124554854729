import Icon from "app/components/shared/Icon";
import { TestState } from "../../shared/type";

type Props = {
  state: TestState;
};

const TestStateBadge = ({ state }: Props) => {
  if (state === "Enabled") {
    return null;
  }

  const iconPath = (state: TestState) => {
    switch (state) {
      case "Muted":
        return "heroicons/outline/speaker-x-mark";
      case "Skipped":
        return "heroicons/outline/forward";
      default:
        return "";
    }
  };

  const heroIcon = iconPath(state);

  return (
    <span className="badge bg-light-purple purple semi-bold inline-flex items-center">
      {heroIcon ? <Icon icon={heroIcon} style={{ width: 18, height: 18 }} /> : null}
      {state}
    </span>
  );
};

export default TestStateBadge;

import Dropdown from "app/components/shared/Dropdown";
import Icon from "app/components/shared/Icon";
import { Build } from "app/components/build/Show/lib/types";
import { twMerge } from "tailwind-merge";

interface Props {
  build: Build;
  className?: string;
}

const DropdownItem = ({ children, ...props }: React.HTMLProps<HTMLAnchorElement>) => (
  <a
    {...props}
    rel="nofollow"
    className="mx-1.5 px-2 py-2 flex gap-1 items-center text-inherit hover:text-purple-600 hover:no-underline font-medium hover:bg-purple-100 rounded-md"
  >
    {children}
  </a>
);

export function RebuildButton({ build, className }: Props) {
  if (!build.project.archived && (build.state === "canceling" || build.finishedAt)) {
    if (build.permissions.rebuild.allowed && build.project.allowRebuilds) {
      if (Features.BuildSidebar && build.state === "passed") {
        return (
          <div>
            <a
              href={build.newPath}
              className={twMerge(
                className,
                "btn build-rebuild-button btn-flex text-center rounded-r-none",
              )}
              data-turbo={true}
              data-turbo-frame="modal_dialog"
            >
              <Icon
                icon="heroicons/outline/plus"
                style={{ width: 14, height: 14 }}
                className="mr-1 text-charcoal-700"
              />
              <span>New build</span>
            </a>

            <Dropdown width={200} className="inline-block">
              <button
                className={twMerge(
                  className,
                  "btn-flex select-none rounded-l-none border-l-0 cursor-pointer",
                )}
                title="Rebuild options"
              >
                <Icon icon="down-triangle" style={{ width: 7, height: 7 }} />
              </button>

              <DropdownItem href={build.rebuildPath} data-method="post">
                <Icon icon="custom/outline/16x/arrows-refresh" style={{ width: 16, height: 16 }} />
                <span>Rebuild</span>
              </DropdownItem>

              <DropdownItem href={build.rebuildPath} data-method="post">
                <Icon
                  icon="custom/outline/git-commit-horizontal"
                  style={{ width: 16, height: 16 }}
                />
                <span>Rebuild this commit</span>
              </DropdownItem>

              <DropdownItem href={build.rebuildBranchPath} data-method="post">
                <Icon icon="custom/outline/git-branch" style={{ width: 16, height: 16 }} />
                <span>Rebuild this branch</span>
              </DropdownItem>
            </Dropdown>
          </div>
        );
      }
      return (
        <div>
          <a
            className={twMerge(
              className,
              "btn build-rebuild-button btn-flex text-center rounded-r-none",
            )}
            data-method="post"
            href={build.rebuildPath}
            rel="nofollow"
          >
            <Icon
              icon="custom/outline/16x/arrows-refresh"
              style={{ width: 16, height: 16 }}
              className="mr-1 text-charcoal-700"
            />

            <span>Rebuild</span>
          </a>

          <Dropdown width={200} className="inline-block">
            <button
              className={twMerge(
                className,
                "btn btn-flex select-none rounded-l-none border-l-0 cursor-pointer",
              )}
              title="Rebuild options"
            >
              <Icon icon="down-triangle" style={{ width: 7, height: 7 }} />
            </button>

            <DropdownItem href={build.rebuildPath} data-method="post">
              <Icon icon="custom/outline/git-commit-horizontal" style={{ width: 16, height: 16 }} />
              <span>Rebuild this commit</span>
            </DropdownItem>

            <DropdownItem href={build.rebuildBranchPath} data-method="post">
              <Icon icon="custom/outline/git-branch" style={{ width: 16, height: 16 }} />
              <span>Rebuild this branch</span>
            </DropdownItem>

            <DropdownItem href={build.newPath} data-turbo={true} data-turbo-frame="modal_dialog">
              <Icon icon="heroicons/outline/plus" style={{ width: 14, height: 14 }} />
              <span>New build</span>
            </DropdownItem>
          </Dropdown>
        </div>
      );
    } else if (build.permissions.rebuild.reason !== "anonymous") {
      return (
        <span
          className={twMerge(
            "btn btn-flex btn-default build-rebuild-button text-center btn--disabled",
            className,
          )}
          aria-label={build.permissions.rebuild.message}
        >
          <Icon
            icon="custom/outline/16x/arrows-refresh"
            style={{ width: 16, height: 16 }}
            className="mr-1"
          />
          <span>Rebuild</span>
        </span>
      );
    }
  }

  return null;
}

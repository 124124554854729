import { PureComponent } from "react";
import PropTypes from "prop-types";

import Dialog from "app/components/shared/Dialog";
import SearchField from "app/components/shared/SearchField";
import Suggestion from "./suggestion";
import ErrorMessage from "app/components/shared/Autocomplete/error-message";
import Loader from "app/components/shared/Autocomplete/loader";

class AutocompleteDialog extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    selectLabel: PropTypes.string,
    items: PropTypes.array,
    hideSearch: PropTypes.bool,
  };

  state = {
    searching: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.items && this.state.searching) {
      // We can turn off searching now since we've got some items
      this.setState({ searching: false });
    }
  }

  isNonSuggestionComponent(node) {
    return (
      node &&
      node.type &&
      (node.type.displayName === ErrorMessage.displayName ||
        node.type.displayName === Loader.displayName)
    );
  }

  render() {
    return (
      <Dialog
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
        dialogStyle={{
          maxWidth: "90vw",
          width: "650px",
          maxHeight: "90vh",
          height: "600px",
        }}
      >
        {!this.props.hideSearch && (
          <div className="px-5 pt-5 pb-4">
            <SearchField
              onChange={this.handleSearchChange}
              onKeyDown={this.handleKeyDown}
              placeholder={this.props.placeholder}
              searching={this.state.searching}
              autofocus={true}
            />
          </div>
        )}
        <hr className="m-0 bg-gray border-none height-0" style={{ height: 1 }} />
        {this.renderSuggestions()}
      </Dialog>
    );
  }

  renderSuggestions() {
    const items = this.props.items;

    // Insert a separator between each section
    const suggestions = [];
    let key = 0;
    for (let index = 0, length = items.length; index < length; index++) {
      if (this.isNonSuggestionComponent(items[index])) {
        suggestions.push(
          <div key={(key += 1)} className="px-4 py-2 text-center">
            {items[index]}
          </div>,
        );
      } else {
        suggestions.push(
          <Suggestion
            key={(key += 1)}
            className="rounded"
            suggestion={items[index][1]}
            onSelect={this.handleSuggestionSelection}
            selectLabel={this.props.selectLabel}
          >
            {items[index][0]}
          </Suggestion>,
        );

        suggestions.push(
          <hr key={(key += 1)} className="p-0 m-0 bg-gray" style={{ border: "none", height: 1 }} />,
        );
      }
    }

    return (
      <div
        className="block"
        style={{
          width: "100%",
          maxHeight: 530,
          height: "100%",
          overflow: "auto",
        }}
      >
        <ul>{suggestions}</ul>
      </div>
    );
  }

  handleSuggestionSelection = (suggestion) => {
    this.props.onSelect(suggestion);
    this.props.onRequestClose();
  };

  handleSearchChange = (value) => {
    this.props.onSearch(value);
    this.setState({ searching: true });
  };
}

AutocompleteDialog.ErrorMessage = ErrorMessage;
AutocompleteDialog.Loader = Loader;

export default AutocompleteDialog;

import { PureComponent } from "react";
import PropTypes from "prop-types";

import Icon from "./Icon";

export default class PermissionDescription extends PureComponent {
  static propTypes = {
    allowed: PropTypes.bool.isRequired,
    permission: PropTypes.string.isRequired,
  };

  render() {
    const { allowed, permission } = this.props;

    const icon = allowed ? "permission-small-tick" : "permission-small-cross";

    const words = allowed
      ? `Can ${permission}`
      : `Can not ${permission.replace(/\sand\s/g, " or ")}`;

    return (
      <div className="flex mt-1" style={{ lineHeight: 1.4 }}>
        <Icon
          icon={icon}
          className="dark-gray flex-none"
          style={{ width: 16, height: 16, marginRight: 3 }}
        />
        {words}
      </div>
    );
  }
}

import {
  useBuild,
  useMostRecentMatrixJobsForStep,
} from "app/components/build/Show/lib/BuildContext";
import { CommandStep } from "app/lib/pipeline";
import Emojify from "app/components/shared/Emojify";
import { JobPillLink } from "./JobPillLink";

interface Props {
  step: CommandStep;
}

export const MatrixStepDetails = ({ step }: Props) => {
  const jobs = useMostRecentMatrixJobsForStep(step.uuid);

  return (
    <div className="flex flex-col flex-auto min-w-0 gap-2 pb-2">
      <div className="gap-1.5 flex flex-wrap">
        {jobs.map((job) => {
          const name = job.name || job.command || "";

          return (
            <JobPillLink job={job} key={job.id}>
              <Emojify text={name} className="font-medium truncate max-w-full" />
            </JobPillLink>
          );
        })}
      </div>
    </div>
  );
};

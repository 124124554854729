import EventEmitter from "eventemitter3";
import LegacyDispatcher from "app/lib/legacyDispatcher";
import JobLogStore from "app/stores/JobLogStore";

declare let Buildkite: {
  JobLogStore: {
    get: (jobId: string) => {
      groups: Array<{
        id: string;
      }>;
    };
  };
};

class JobLogGroupStore {
  eventEmitter: EventEmitter;
  state: {
    [key: string]: boolean;
  };

  constructor() {
    this.eventEmitter = new EventEmitter();
    this.state = {};

    LegacyDispatcher.on("job_log_group:toggle", ({ groupId }) => this.toggle(groupId));
    LegacyDispatcher.on("job_log_group:expand_all", ({ jobId }) => this.setAll(jobId, true));
    LegacyDispatcher.on("job_log_group:collapse_all", ({ jobId }) => this.setAll(jobId, false));
  }

  addChangeListener(groupId: string, callback: any) {
    return this.eventEmitter.addListener(`${groupId}/change`, callback);
  }

  removeChangeListener(groupId: string, callback: any) {
    return this.eventEmitter.removeListener(`${groupId}/change`, callback);
  }

  toggle(groupId: string) {
    return this.set(groupId, !this.state[groupId]);
  }

  set(groupId: string, expand: boolean) {
    this.state[groupId] = expand;
    return this.eventEmitter.emit(`${groupId}/change`);
  }

  setAll(jobId: string, expand: boolean) {
    const log = JobLogStore.get(jobId);

    log.groups.map((group) => {
      this.set(group.id, expand);
    });
  }

  isExpanded(groupId: string) {
    return this.state[groupId];
  }

  // Will only change the group's expanded state, if it hasn't already been set
  // to something else.
  setDefault(groupId: string, expanded: boolean) {
    if (this.state[groupId] === undefined) {
      return this.set(groupId, expanded);
    }
  }
}

export default new JobLogGroupStore();

// IMPORTANT: These states correspond to those used in the GraphQL API.
//            State values in the underlying database and Ruby side of the
//            application are lower-case.

// app/models/api/graph/enums/job_state_enum.rb
export const JOB_STATES = {
  PENDING: "PENDING",
  WAITING: "WAITING",
  WAITING_FAILED: "WAITING_FAILED",
  BLOCKED: "BLOCKED",
  BLOCKED_FAILED: "BLOCKED_FAILED",
  UNBLOCKED: "UNBLOCKED",
  UNBLOCKED_FAILED: "UNBLOCKED_FAILED",
  SCHEDULED: "SCHEDULED",
  ASSIGNED: "ASSIGNED",
  ACCEPTED: "ACCEPTED",
  RUNNING: "RUNNING",
  FINISHED: "FINISHED",
  CANCELING: "CANCELING",
  CANCELED: "CANCELED",
  TIMING_OUT: "TIMING_OUT",
  TIMED_OUT: "TIMED_OUT",
  SKIPPED: "SKIPPED",
  BROKEN: "BROKEN",
  LIMITED: "LIMITED",
  LIMITING: "LIMITING",
  EXPIRED: "EXPIRED",
} as const;

// This isn't ideal, but when we want to work with Job States in a context that expects
// string literals of type `JobStates`
// (frontend/app/components/build/Header/pipeline/types/jobStates.js), we need the mapped
// values to be in the same case as the JobStates type definition (i.e. lowercase) to keep
// Flow happy
export const JOB_STATES_DOWNCASED = {
  PENDING: "pending",
  WAITING: "waiting",
  WAITING_FAILED: "waiting_failed",
  BLOCKED: "blocked",
  BLOCKED_FAILED: "blocked_failed",
  UNBLOCKED: "unblocked",
  UNBLOCKED_FAILED: "unblocked_failed",
  SCHEDULED: "scheduled",
  ASSIGNED: "assigned",
  ACCEPTED: "accepted",
  RUNNING: "running",
  FINISHED: "finished",
  CANCELING: "canceling",
  CANCELED: "canceled",
  TIMING_OUT: "timing_out",
  TIMED_OUT: "timed_out",
  SKIPPED: "skipped",
  BROKEN: "broken",
  LIMITED: "limited",
  LIMITING: "limiting",
  EXPIRED: "expired",
} as const;

export default JOB_STATES;

const { FINISHED, CANCELED, SKIPPED, TIMED_OUT, EXPIRED, RUNNING, CANCELING, TIMING_OUT } =
  JOB_STATES;

export const FINISHED_STATES = {
  FINISHED,
  CANCELED,
  SKIPPED,
  TIMED_OUT,
  EXPIRED,
} as const;

export const RUNNING_STATES = { RUNNING, CANCELING, TIMING_OUT } as const;

// These two are NOT actual states from `JobStateEnum` but I think they probably
// really should be at some point to make logic simpler and match Build enum states,
// especially now that steps are a thing. Currently these need to be derived from multiple
// other properties of a Job.
export const VIRTUAL_JOB_STATES = {
  SOFT_FAILED: "SOFT_FAILED",
  PASSED: "PASSED",
  FAILED: "FAILED",
  STARTED: "STARTED",
} as const;

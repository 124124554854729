import styled from "styled-components";

import FriendlyTime from "app/components/shared/FriendlyTime";
import ResultBadge from "app/components/analytics/shared/ResultBadge";
import { StyledUnderline } from "app/components/analytics/shared/styles";
import MissingEnvVariables, {
  OPTIMAL_ENV_VARIABLES,
} from "app/components/analytics/shared/MissingEnvVariables";
import { _trackTALinkClicked } from "../segmentEvents";
import { EnvironmentVariables, TestPlanProps } from "../type";
import BuildNumber from "./BuildNumber";
import TestPlansDropdown from "./TestPlansDropdown";
import CommitSha from "./CommitSha";
import Branch from "app/components/analytics/shared/Metadata/Branch";

type Props = RunMetadata | MetadataType;

export type MetadataType = {
  result?: string;
  created_at: string;
  env: EnvironmentVariables;
  hideMissingEnvNotice?: boolean;
  testPlans?: TestPlanProps[];
  repoUrl?: string | null | undefined;
  linkToBranchTag?: boolean;
};

type RunMetadata = MetadataType & {
  type: "run";
};

const MetadataWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  color: var(--charcoal-600);
  font-weight: 400;
  font-size: 13px;

  &:not(:first-of-type) {
    padding-left: 10px;
    border-left: 1px solid var(--gray-500);
  }
`;

const Metadata = (props: Props) => {
  const { result, created_at, env, hideMissingEnvNotice, testPlans, repoUrl, linkToBranchTag } =
    props;
  const missingEnvProps = !OPTIMAL_ENV_VARIABLES.every((envVar) =>
    Object.keys(env).includes(envVar),
  );
  const missingPropValues = Object.values(env).some((envVar) => !envVar);

  const missingEnvVariables = missingEnvProps || missingPropValues;
  const showBuild = env.number || env.url;

  return (
    <div className="py-5">
      <MetadataWrapper>
        {result && (
          <div>
            <ResultBadge result={result} />
          </div>
        )}

        <Row>
          {showBuild && (
            <Column>
              <BuildNumber number={env.number} url={env.url} />
            </Column>
          )}
          {env.commit_sha && (
            <Column className="monospace">
              <CommitSha commitSha={env.commit_sha} repoUrl={repoUrl} />
            </Column>
          )}

          {env.branch && (
            <Column>
              <Branch branch={env.branch} linkToBranchTag={linkToBranchTag} />
            </Column>
          )}

          {testPlans && testPlans.length > 0 && (
            <Column>
              <TestPlansDropdown testPlans={testPlans} />
            </Column>
          )}

          <Column>
            <StyledUnderline as={FriendlyTime} value={created_at} capitalized={true} />
          </Column>

          {!hideMissingEnvNotice && missingEnvVariables && (
            <Column>
              <MissingEnvVariables {...env} />
            </Column>
          )}
        </Row>
      </MetadataWrapper>
    </div>
  );
};

export default Metadata;

import * as React from "react";
import { createRefetchContainer, graphql } from "react-relay";

import { elementIdFor } from "app/components/job/Job";
import ShowMoreFooter from "app/components/shared/ShowMoreFooter";
import Annotation, { AnnotationStyles } from "./Annotation";

import cable from "app/lib/cable";
import LegacyDispatcher from "app/lib/legacyDispatcher";
import AnnotationsEmptyState from "./AnnotationsEmptyState";

const PAGE_SIZE = 10;

type Props = {
  build: {
    id: string;
    uuid: string;
    annotations: {
      edges: Array<{
        node: {
          id: string;
          context: string;
          style: AnnotationStyles;
          body: {
            html: string | null | undefined;
          };
        };
      }>;
    };
  };
  buildSlug: string;
  filterIssues: boolean;
  hasFailedJobs: boolean;
  relay: any;
};

type State = {
  loadingMore: boolean;
  pageSize: number;
  style: Array<string> | string | null;
};

class AnnnotationsList extends React.PureComponent<Props, State> {
  buildSubscription: any;

  constructor(props: any) {
    super(props);

    this.state = {
      loadingMore: false,
      pageSize: PAGE_SIZE,
      style: null,
    };
  }

  getAnnotationStyleFilters() {
    return this.props.hasFailedJobs ? ["ERROR", "INFO"] : "ERROR";
  }

  componentDidMount() {
    if (this.props.filterIssues) {
      this.setState(
        {
          loadingMore: true,
          style: this.getAnnotationStyleFilters(),
        },
        () => {
          this.reload();
        },
      );
    }

    // Reload the component if the build annotations change
    this.buildSubscription = cable.subscriptions.create(
      { channel: "Pipelines::BuildChannel", uuid: this.props.build.uuid },
      {
        component: this,

        received({ event }) {
          if (event === "annotations:changed") {
            this.component.reload();
          }
        },
      },
    );
  }

  componentWillUnmount() {
    if (this.buildSubscription) {
      this.buildSubscription.unsubscribe();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.filterIssues !== this.props.filterIssues) {
      this.setState(
        {
          loadingMore: true,
          style: nextProps.filterIssues ? this.getAnnotationStyleFilters() : null,
        },
        () => {
          // Relay will cache query responses against the variables they've used, returning those
          // responses for successive queries with the same variables (i.e. the `style` variable set
          // above). If we're regularly changing between filtering and not filtering, and annotation
          // data is changing server-side, our cached annotations collections will fall out of sync.
          // To avoid this we'll reload the annotations data on filter change.
          this.reload();
        },
      );
    }
  }

  render() {
    if (!this.props.build.annotations?.edges.length) {
      if (Features.BuildSidebar) {
        return <AnnotationsEmptyState />;
      }

      return null;
    }

    return (
      <div onClick={this.handleAnnotationClick}>
        {this.props.build.annotations.edges.map((edge) => (
          <Annotation key={edge.node.id} annotation={edge.node} />
        ))}

        <div className="mb-2">
          <ShowMoreFooter
            connection={this.props.build.annotations}
            loading={this.state.loadingMore}
            onShowMore={this.handleShowMoreAnnotations}
            label="annotations"
          />
        </div>
      </div>
    );
  }

  reload() {
    this.props.relay.refetch(
      {
        slug: this.props.buildSlug,
        pageSize: this.state.pageSize,
        style: this.state.style,
        order: "PRIORITY_RECENTLY_CREATED",
      },
      null,
      (error) => {
        if (!error) {
          this.setState({ loadingMore: false });
        }
      },
      { force: true },
    );
  }

  handleShowMoreAnnotations = () => {
    this.setState(
      {
        loadingMore: true,
        pageSize: this.state.pageSize + PAGE_SIZE,
      },
      () => {
        // Only refetch query once state is update
        this.reload();
      },
    );
  };

  handleAnnotationClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // Don't change anything if the user is using any modifier keys
    if (event.button !== 0 || event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    // Don't change clicks in things which aren't <a /> elements, or don't have a `href`
    if (!(event.target instanceof HTMLAnchorElement) || !event.target.href) {
      return;
    }

    // Parse the link URL, relative to the current location
    const linkUrl = new URL(event.target.href, window.location.href);

    // Don't do anything to links which go somewhere other than this current page
    if (
      `${linkUrl.origin}${linkUrl.pathname}` !==
      `${window.location.origin}${window.location.pathname}`
    ) {
      return;
    }

    const jobId = linkUrl.hash.slice(1);

    // If we can't find a job component for that job ID, don't do anything
    if (!document.getElementById(elementIdFor(jobId))) {
      return;
    }

    // Finally, if we now know this is a valid URL and job ID,
    // cancel the browser's usual navigation, and emit a "job:focus" event
    event.preventDefault();

    // NOTE: You're supposed to pass a "full" job object here, but
    // only actually consumes "id" and "path" - so here we fake it!
    // @ts-expect-error - TS2554 - Expected 6 arguments, but got 2.
    LegacyDispatcher.emit("job:focus", {
      job: {
        id: jobId,
        path: (event.target as HTMLAnchorElement).href,
      },
    });
  };

  handleWebsocketEvent = (payload: any) => {
    if (payload.buildID === this.props.build.id) {
      this.reload();
    }
  };
}

export default createRefetchContainer(
  AnnnotationsList,
  {
    build: graphql`
      fragment AnnotationsList_build on Build
      @argumentDefinitions(
        pageSize: { type: "Int", defaultValue: 10 }
        style: { type: "[AnnotationStyle!]", defaultValue: [] }
        order: { type: "AnnotationOrder", defaultValue: RECENTLY_CREATED }
      ) {
        id
        uuid
        annotations(first: $pageSize, style: $style, order: $order) {
          edges {
            node {
              id
              context
              style
              createdAt
              priority
              body {
                html
              }
            }
            cursor
          }
          ...ShowMoreFooter_connection
        }
      }
    `,
  },
  graphql`
    query AnnotationsList_buildQuery(
      $slug: ID!
      $pageSize: Int!
      $style: [AnnotationStyle!]
      $order: AnnotationOrder
    ) {
      build(slug: $slug) {
        ...AnnotationsList_build @arguments(pageSize: $pageSize, style: $style, order: $order)
      }
    }
  `,
);

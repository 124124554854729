import classNames from "classnames";
import "intersection-observer";
import { second } from "metrick/duration";
import * as React from "react";
import { InView } from "react-intersection-observer";

import { getDuration, getDurationString, DateLike } from "app/lib/date";
export type { DateLike };

type DurationFormats = "full" | "medium" | "short" | "micro";

type PartialProps = {
  from?: DateLike | null | undefined;
  to?: DateLike | null | undefined;
  className?: string;
  tabularNumerals?: boolean;
  updateFrequency?: number;
};

type Props = PartialProps & {
  format: DurationFormats;
};

type State = {
  seconds?: number;
};

function makeDurationFormat(format: DurationFormats) {
  const Component = (props: PartialProps) => <Duration {...props} format={format} />;
  Component.displayName = format.charAt(0).toUpperCase() + format.slice(1);
  return Component;
}

export default class Duration extends React.PureComponent<Props, State> {
  static Full = makeDurationFormat("full");
  static Medium = makeDurationFormat("medium");
  static Short = makeDurationFormat("short");
  static Micro = makeDurationFormat("micro");

  static defaultProps = {
    updateFrequency: second.bind(1),
    tabularNumerals: true,
  };

  // @ts-expect-error - TS2564 - Property '_timeout' has no initializer and is not definitely assigned in the constructor.
  _timeout: number;

  state = {};

  tick() {
    const { from, to } = this.props;
    const seconds = getDuration(from, to).asSeconds();

    this.setState({ seconds }, this.maybeScheduleTick);
  }

  componentDidMount() {
    this.maybeScheduleTick();
  }

  maybeScheduleTick() {
    const { from, to, updateFrequency } = this.props;

    // We only want to schedule ticks if our duration is indeterminate,
    // and our update frequency isn't zero
    if (!(from && to) && typeof updateFrequency == "number" && updateFrequency > 0) {
      this._timeout = setTimeout(() => {
        this.tick();
      }, updateFrequency);
    }
  }

  maybeCancelTick() {
    if (this._timeout) {
      clearTimeout(this._timeout);
    }
  }

  componentWillUnmount() {
    this.maybeCancelTick();
  }

  static getDerivedStateFromProps(nextProps: Props, currentState: State) {
    const seconds = getDuration(nextProps.from, nextProps.to).asSeconds();

    if (seconds !== currentState.seconds) {
      return { seconds };
    }

    return null;
  }

  componentDidUpdate(prevProps: Props) {
    const { updateFrequency, to } = this.props;

    if (updateFrequency !== prevProps.updateFrequency || to !== prevProps.to) {
      this.maybeCancelTick();
      this.maybeScheduleTick();
    }
  }

  handleVisibilityChange = (visible: boolean) => {
    this.maybeCancelTick();

    if (visible) {
      this.tick();
    }
  };

  render() {
    const spanClassName = classNames(this.props.className, {
      "tabular-numerals": this.props.tabularNumerals,
    });

    const durationString = getDurationString(
      // @ts-expect-error - TS2339 - Property 'seconds' does not exist on type '{}'.
      this.state.seconds || 0,
      this.props.format,
    );

    if (this.props.to) {
      return <span className={spanClassName}>{durationString}</span>;
    }

    return (
      <InView as="span" className={spanClassName} onChange={this.handleVisibilityChange}>
        {durationString}
      </InView>
    );
  }
}

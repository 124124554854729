import { useState, useEffect, useCallback } from "react";
import useFetch from "use-http";

import Indicator from "app/components/shared/Indicator";

type Props = {
  job?: { id: string };
  build: {
    account: { slug: string };
    id: string;
  };
  translateX?: string;
  translateY?: string;
};

const FailedTestExecutionsIndicator = ({
  job,
  build: {
    account: { slug },
    id: build_uuid,
  },
  translateX = "-6px",
  translateY = "5px",
}: Props) => {
  const failedExecutionsUrl = `/organizations/${slug}/analytics/builds/${build_uuid}${job ? `/jobs/${job.id}` : ""}/failed_executions_present`;

  const [failures, setFailures] = useState(false);
  const { get, response, error } = useFetch(failedExecutionsUrl);

  const getFailures = useCallback(async () => {
    const data = await get();
    setFailures(response.ok);
  }, [get, response]);

  useEffect(() => {
    getFailures();
  }, [getFailures]);

  if (!failures || error) {
    return null;
  }

  return (
    <span className="absolute right-0 top-0" style={{ translate: `${translateX} ${translateY}` }}>
      <Indicator />
    </span>
  );
};

export default FailedTestExecutionsIndicator;

import * as React from "react";
import { createFragmentContainer, graphql } from "react-relay";
import { AttributeTable, AttributeTableRow } from "app/components/shared/AttributeTable";
import AgentBadge from "app/components/AgentBadge";
import FriendlyTime from "app/components/shared/FriendlyTime";
import JobEvent from "../JobEvent";
import {
  NEVER_STARTED_SIGNAL_REASONS,
  PROCESS_KILLED_SIGNAL_REASONS,
} from "app/constants/SignalReasons";

type Props = {
  event: any;
  previousTimestamp: string | null | undefined;
};

// Status labels for the exitStatus == '-1' case for a given actor type
const AGENT_MINUSONE_STATUS_LABELS: {
  [key: string]: string;
} = {
  // If the job was finished by the system, then the agent was likely lost.
  SYSTEM: "Agent Lost",
  // If the process was killed, the agent will tell us and give us the -1.
  AGENT: "Process Killed",
};

// Explanations for each possible "signal reason," should be kept in sync with `job_event_signal_reason_enum.rb`
const SIGNAL_REASON_EXPLANATIONS: {
  [key: string]: React.ReactNode;
} = {
  AGENT_STOP: "The agent sent the signal to the process because the agent was stopped",
  CANCEL: "The agent sent the signal to the process because the job was canceled",
  PROCESS_RUN_ERROR:
    "The agent was unable to start the job process, often due to memory or resource constraints",
  AGENT_REFUSED: (
    <>
      The agent <code className="dark-gray monospace">pre-bootstrap</code> hook was configured to
      refuse this job. See the agent process logs for more details.
    </>
  ),
  SIGNATURE_REJECTED: "because the job's signature could not be verified",
};

function JobEventFinished({ event, previousTimestamp }: Props) {
  const {
    job,
    actor: { type: actorType },
    signal,
    signalReason,
  } = event;

  // Refine types here — this sucks a bit because we’re using unions
  // and interfaces and junk for all these types :(
  if (!job) {
    return null;
  }

  let agentStatus;
  if (job.exitStatus === "-1") {
    if (PROCESS_KILLED_SIGNAL_REASONS.includes(signalReason)) {
      agentStatus = AGENT_MINUSONE_STATUS_LABELS[actorType];
    } else if (NEVER_STARTED_SIGNAL_REASONS.includes(signalReason)) {
      agentStatus = "Never Started";
    }
  }

  // Default label and attribute list; this should only be used as-is by events
  // which are missing an actorType or for whom the actorType is unexpected.
  let label: React.ReactNode = "Job Finished";

  let attributes: {
    [key: string]: React.ReactNode;
  } = {
    "Command Exit Status": (
      <code className="dark-gray monospace">
        {event.exitStatus}
        {job.softFailed && " (Soft Failed)"} {agentStatus && ` (${agentStatus})`}
      </code>
    ),
  };

  // If we have either a signal or signalReason, show both as we can give explanations
  if (signal || signalReason) {
    attributes["Command Exit Signal"] = signal ? (
      // If there's an exit signal, show that.
      <code className="dark-gray monospace">{signal}</code>
    ) : // If there isn't, and there's a signalReason, show a helpful explanation
    signalReason && NEVER_STARTED_SIGNAL_REASONS.includes(signalReason) ? (
      "None: The process intercepted the signal and exited with the above status"
    ) : (
      "None"
    );

    attributes["Exit Signal Reason"] = signalReason ? (
      <>
        <code className="dark-gray monospace">{signalReason}</code>
        {SIGNAL_REASON_EXPLANATIONS[signalReason] && (
          <>: {SIGNAL_REASON_EXPLANATIONS[signalReason]}</>
        )}
      </>
    ) : job.exitStatus === "-1" || !job.exitStatus ? (
      "None: The process was terminated by the operating system or another process"
    ) : (
      "None"
    );
  }

  switch (actorType) {
    case "AGENT":
      label = <>{job.agent ? <AgentBadge agent={job.agent} /> : "Agent"} Finished Running Job</>;
      break;

    case "SYSTEM":
      // If the job was finished by the system, then the agent was likely lost.
      if (job.exitStatus === "-1") {
        label = "Dispatcher Cancelled Job";

        if (job.agent && job.agent.heartbeatAt && job.agent.heartbeatAt < event.timestamp) {
          // We'll pull its details from the job for reference, but only if it
          // hasn't come back since this job was finished.
          attributes = {
            "Last Agent Heartbeat": (
              <FriendlyTime
                value={job.agent.heartbeatAt}
                capitalized={true}
                fractionalSeconds={true}
              />
            ),
            ...attributes,
          };
        }
      } else {
        label = "Job Finished by System";
      }
      break;
  }

  return (
    <JobEvent timestamp={event.timestamp} previousTimestamp={previousTimestamp}>
      <JobEvent.Label>{label}</JobEvent.Label>
      <JobEvent.Content>
        <AttributeTable>
          {Object.keys(attributes).map((label) => (
            <AttributeTableRow key={label} title={label}>
              {attributes[label]}
            </AttributeTableRow>
          ))}
        </AttributeTable>
      </JobEvent.Content>
    </JobEvent>
  );
}

export default createFragmentContainer(JobEventFinished, {
  event: graphql`
    fragment JobEventFinished_event on JobEvent {
      timestamp
      ... on JobEventFinished {
        exitStatus
        signal
        signalReason
      }
      actor {
        type
      }
      job {
        softFailed
        exitStatus
        agent {
          ...AgentBadge_agent
          heartbeatAt
        }
      }
    }
  `,
});

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { fromPairs, get, isEmpty, map, pipe, values } from "lodash/fp";
import classNames from "classnames";

import Dropdown from "app/components/shared/Dropdown";
import Emojify from "app/components/shared/Emojify";
import Icon from "app/components/shared/Icon";
import cable from "app/lib/cable";
import { getMicrosecondDurationFormattedString } from "app/lib/date";
import { CommandStep } from "app/lib/pipeline";
import { track } from "app/lib/segmentAnalytics";
import { Current } from "app/utils/Current";
import bktecHero from "app/images/bktec-hero.png";
import { BktecLogo } from "app/components/shared/BktecLogo/BktecLogo";
import { useBuild } from "app/components/build/Show/lib/BuildContext";

import { useTestEngineStore, TestPlan } from "./useTestEngineStore";

interface Props {
  buildId: string;
  pipelineHasHistoricalTestPlans: boolean;
  steps: CommandStep[];
}

type BktecBadgeState = "running" | "passed" | "failed" | "unknown";

const testPlanResultSelector = pipe(get("testPlans"), values);

const BktecBadge = ({ buildId, pipelineHasHistoricalTestPlans, steps }: Props) => {
  const setTestPlan = useTestEngineStore((state) => state.setTestPlan);

  const [opened, setOpened] = useState(false);
  const buildHasTestPlans = useTestEngineStore((state) => state.hasTestPlans);

  const { build } = useBuild();
  const buildState = build?.state;

  const bktecBadgeState: BktecBadgeState = useMemo(() => {
    // Marketing state is always unknown
    if (!pipelineHasHistoricalTestPlans || buildState === undefined) {
      return "unknown";
    }

    const simpleState =
      {
        creating: "running",
        started: "running",
        running: "running",
        passed: "passed",
        failed: "failed",
        failing: "failed",
        soft_failed: "failed",
      }[buildState] || "unknown";

    if (simpleState === "running") {
      return buildHasTestPlans ? "running" : "unknown";
    } else {
      return simpleState;
    }
  }, [pipelineHasHistoricalTestPlans, buildHasTestPlans, buildState]);

  useEffect(() => {
    const subscription = cable.subscriptions.create(
      {
        channel: "TestEngine::TestPlanChannel",
        uuid: buildId,
        organization_uuid: Current.organization.uuid,
      },
      {
        received({ testPlan }) {
          setTestPlan(testPlan);
        },
      },
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [buildId, setTestPlan]);

  const handleClick = useCallback(() => {
    if (!opened) {
      // send segment event when badge is opened for the first time
      track("TE Bktec Badge Clicked", {
        pipeline_slug: Current.project.slug,
        pipeline_uuid: Current.project.uuid,
        organization_slug: Current.organization.slug,
        organization_uuid: Current.organization.uuid,
      });
    }
    setOpened(true);
  }, [opened]);

  return (
    <Dropdown
      width={300}
      className="inline-block"
      popoverClassName="p-0 rounded-none border border-solid border-black border-b-[2px]"
      hideNib={true}
    >
      <BktecLogo onClick={handleClick} state={bktecBadgeState} />
      {pipelineHasHistoricalTestPlans ? (
        <InformationalPanelContent steps={steps} />
      ) : (
        <MarketingPanelContent />
      )}
    </Dropdown>
  );
};

const MarketingPanelContent = () => (
  <div>
    <img alt="bktec" src={bktecHero} />
    <div className="px-3 py-2 font-mono flex flex-col gap-2 text-xs">
      <strong>Turbocharge your tests</strong>
      <p>
        Use Buildkite Test Engine Client (bktec) to speed up test times with automatic test
        splitting, muting, and flaky retries.
      </p>
      <br />
      <a
        target="_blank"
        className="font-bold text-charcoal-700 focus:text-charcoal-500 hover:text-charcoal-500 hover:text-underline underline-offset-4"
        rel="noreferrer"
        href="https://buildkite.com/docs/test-engine/test-splitting"
      >
        [ See what you could save → ]
      </a>
    </div>
  </div>
);

const InformationalPanelContent = ({ steps }: { steps: CommandStep[] }) => {
  const testPlans = useTestEngineStore(testPlanResultSelector);
  const buildHasTestPlans = useTestEngineStore((state) => state.hasTestPlans);

  const labels = useMemo(
    () =>
      pipe(
        map((step: CommandStep) => [step.uuid, step.label || step.commandScript]),
        fromPairs,
      )(steps),
    [steps],
  );

  return (
    <div className="flex flex-col">
      {buildHasTestPlans ? (
        map(
          (testPlan) => (
            <details
              key={testPlan.id}
              name="test-plan-result"
              className="group flex flex-col font-mono border-b border-gray-400"
            >
              <summary className="list-outside list-['→'] group-open:list-['↓'] cursor-pointer leading-[28px] ml-4 px-2">
                <Emojify text={get(get("stepId", testPlan), labels)} />
              </summary>
              <div className="pl-6 pr-2">
                {isEmpty(testPlan.statistics.results) && (
                  <div className="mb-2 text-xs">
                    <Icon icon="heroicons/outline/exclamation-circle" className="w-4 h-4" /> Upgrade
                    your bktec version to access detailed results.
                  </div>
                )}
                <ResultTable testPlan={testPlan} />
              </div>
            </details>
          ),
          testPlans,
        )
      ) : (
        <div className="px-3 py-2 font-mono text-xs">
          <b>bktec is enabled on this pipeline</b>
          <br />
          <b>---------------------------------</b>
          <br />
          Details will be displayed here for steps running bktec in this build.
          <br />
          <br />
          <a
            href="https://github.com/buildkite/test-engine-client"
            target="_blank"
            className="font-bold text-charcoal-700 focus:text-charcoal-500 hover:text-charcoal-500 hover:text-underline underline-offset-4"
            rel="noreferrer"
          >
            [ Learn more → ]
          </a>
        </div>
      )}
    </div>
  );
};

const ResultTable = ({ testPlan }: { testPlan: TestPlan }) => {
  const result = testPlan.statistics.results;

  const totalDuration = testPlan.count * testPlan.statistics.mean_node_duration_ms;
  const timeSavedMs = totalDuration - testPlan.statistics.max_node_duration_ms;

  return (
    <div className="py-1">
      <table className="table-auto w-full leading-[20px]">
        <tbody>
          <tr>
            <td rowSpan={2} className="align-top">
              Passed
            </td>

            <td>on first run</td>

            <td className="text-right">{result?.passed_on_first_run || "-"}</td>
          </tr>

          <tr>
            <td>on retry</td>

            <td className="text-right">{result?.passed_on_retry || "-"}</td>
          </tr>

          <tr>
            <td rowSpan={2} className="align-top">
              Muted
            </td>

            <td>passed</td>

            <td className="text-right">{result?.muted_passed || "-"}</td>
          </tr>

          <tr>
            <td>failed</td>

            <td className="text-right">{result?.muted_failed || "-"}</td>
          </tr>

          <tr>
            <td colSpan={2}>Failed</td>

            <td className="text-right">{result?.failed || "-"}</td>
          </tr>

          <tr>
            <td colSpan={2}>Skipped</td>

            <td className="text-right">{result?.skipped || "-"}</td>
          </tr>

          <tr className="font-bold">
            <td colSpan={2}>Total tests</td>

            <td className="text-right">{result?.total || "-"}</td>
          </tr>

          <tr className="font-bold">
            <td colSpan={2}>Time saved</td>

            <td className="text-right">
              {timeSavedMs > 0 ? (
                <>~{getMicrosecondDurationFormattedString(timeSavedMs * 1000)}</>
              ) : (
                <>-</>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BktecBadge;

import { useState, useEffect, useRef, useMemo } from "react";
import { debounce, isEqual } from "lodash";
import { useCombobox, useMultipleSelection } from "downshift";
import styled from "styled-components";
import Emojify from "app/components/shared/Emojify";
import Icon from "app/components/shared/Icon";
import Tag from "app/components/shared/Tag";

const SearchIcon = styled(Icon).attrs({ icon: "search" })`
  flex: 0 0 auto;
  height: 20px;
  width: 20px;
  color: var(--gray-600);
`;

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 12px;
  padding-right: 8px;
  border: 1px solid var(--gray-500);
  border-radius: 4px;
  overflow: hidden;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

  &:focus-within {
    padding-left: 11px;
    padding-right: 7px;
    border-color: var(--lime-500);
    border-width: 2px;

    & ${SearchIcon} {
      color: var(--charcoal-700);
    }
  }
`;

const Label = styled.label`
  padding-right: 8px;
`;

const SearchInput = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: scroll;
  display: grid;
  align-items: center;
  grid-template-columns: min-content auto;

  // Pseudo element to size the input
  &:after {
    content: attr(data-value) " ";
    grid-area: 1 / 2;
    visibility: hidden;
    white-space: nowrap;
  }

  // Hide scrollbar
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const TextInput = styled.input`
  grid-area: 1 / 2;
  height: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  box-shadow: none;
  color: var(--charcoal-700);
  background: transparent;

  &::placeholder {
    color: var(--gray-700);
  }
`;

const Menu = styled.ul<{ visible: boolean }>`
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  background-color: white;
  position: absolute;
  border-radius: 4px;
  padding: 8px;
  margin-top: 4px;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  box-shadow:
    0px 20px 20px rgba(0, 0, 0, 0.07),
    0px 10px 20px rgba(0, 0, 0, 0.05),
    0px 13px 18px rgba(0, 0, 0, 0.04),
    0px 7px 9px rgba(0, 0, 0, 0.03);
  z-index: 1;
`;

export const MenuItem = styled.li<{ highlighted: boolean }>`
  background-color: ${({ highlighted }) => (highlighted ? "var(--purple-100)" : "white")};
  color: ${({ highlighted }) => (highlighted ? "var(--purple-600)" : "var(--charcoal-700)")};
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
`;

MenuItem.displayName = "MenuItem";

const MenuHeading = styled.div`
  padding: 8px;
  font-size: 11px;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
`;

function getFilteredItems(tags: Array<string>, selectedItems: Array<string>, inputValue: string) {
  const lowerCasedInputValue = inputValue.toLowerCase();

  return tags.filter(
    (element) =>
      !selectedItems.includes(element) && element.toLowerCase().includes(lowerCasedInputValue),
  );
}

type Props = {
  tags: Array<string>;
  onTextChange: (text: string) => void;
  onSelectedTagsChange: (tags: Array<string>) => void;
  initialText: string | null | undefined;
  initialSelectedTags: Array<string> | null | undefined;
};

export default function PipelinesSearchBar({
  initialSelectedTags = [],
  initialText = "",
  onTextChange,
  onSelectedTagsChange,
  tags,
}: Props) {
  const [inputValue, setInputValue] = useState<string>(initialText || "");
  const [selectedItems, setSelectedItems] = useState<string[]>(initialSelectedTags || []);

  // Update internal state if tags are changed externally
  useEffect(() => {
    if (!isEqual(initialSelectedTags, selectedItems)) {
      setSelectedItems(initialSelectedTags || []);
    }
  }, [initialSelectedTags]);

  const debouncedOnTextChange = useRef(debounce(onTextChange, 300)).current;

  useEffect(() => {
    if (initialText !== inputValue) {
      debouncedOnTextChange(inputValue);
    }

    return () => {
      debouncedOnTextChange.cancel();
    };
  }, [inputValue]);

  // update the route when the tags change
  useEffect(() => {
    if (!isEqual(initialSelectedTags, selectedItems)) {
      onSelectedTagsChange(selectedItems);
    }
  }, [selectedItems]);

  const items = useMemo(
    () => getFilteredItems(tags, selectedItems, inputValue),
    [tags, selectedItems, inputValue],
  );

  const scrollRef = useRef(null);

  function scrollToEnd() {
    requestAnimationFrame(() => {
      if (scrollRef.current) {
        // @ts-expect-error - TS2339 - Property 'scrollLeft' does not exist on type 'never'. | TS2339 - Property 'scrollWidth' does not exist on type 'never'.
        scrollRef.current.scrollLeft = scrollRef.current.scrollWidth;
      }
    });
  }

  const { getSelectedItemProps, getDropdownProps, removeSelectedItem } = useMultipleSelection({
    selectedItems,
    onStateChange({ selectedItems: newSelectedItems = [], type }) {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          setSelectedItems(newSelectedItems);
          break;
        default:
          break;
      }
    },
  });

  const { isOpen, getLabelProps, getMenuProps, getInputProps, highlightedIndex, getItemProps } =
    useCombobox({
      items,
      inputValue,
      selectedItem: null,
      onStateChange({ type, selectedItem: newSelectedItem }) {
        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
            if (newSelectedItem) {
              setSelectedItems([...selectedItems, newSelectedItem]);
              setInputValue("");
              scrollToEnd();
            }
            break;
          default:
            break;
        }
      },
    });

  function handleTagClick(selectedItem: string) {
    return () => removeSelectedItem(selectedItem);
  }

  return (
    <div className="relative">
      <SearchBar>
        <Label {...getLabelProps()}>
          <SearchIcon />
          <span className="sr-only">Search pipelines</span>
        </Label>

        <SearchInput data-value={inputValue} ref={scrollRef}>
          {selectedItems.length ? (
            <div className="flex gap0.5" style={{ marginRight: 8 }}>
              {selectedItems.map((selectedItem, index) => (
                <Tag
                  key={`selected-item-${selectedItem}`}
                  {...getSelectedItemProps({ selectedItem, index })}
                  onClick={handleTagClick(selectedItem)}
                  closeable={true}
                  text={selectedItem}
                />
              ))}
            </div>
          ) : null}

          <TextInput
            className="input"
            placeholder={selectedItems.length ? "" : "Search name or tags"}
            onKeyUp={scrollToEnd}
            spellCheck={false}
            {...getInputProps({
              ...getDropdownProps(),
              onChange: (event) => setInputValue(event.target.value),
            })}
          />
        </SearchInput>
      </SearchBar>

      {/* @ts-expect-error - TS2769 - No overload matches this call. */}
      <Menu visible={isOpen && items.length} {...getMenuProps({})}>
        {isOpen && (
          <>
            <MenuHeading>Tags</MenuHeading>

            {items.map((item, index) => (
              <MenuItem
                key={item}
                highlighted={highlightedIndex === index}
                {...getItemProps({ item, index })}
              >
                <Emojify className="block truncate max-w-full" text={item} />
              </MenuItem>
            ))}
          </>
        )}
      </Menu>
    </div>
  );
}

import find from "lodash/find";
import Dropdown from "./shared/Dropdown";
import EmojiAvatar from "./shared/EmojiAvatar";
import Emojify from "./shared/Emojify";
import Icon from "./shared/Icon";
import classNames from "classnames";

type Props = {
  currentClusterId?: string;
  unclustered: boolean;
  unclusteredPath: string;
  newClusterPath: string | null;
  clusters: Array<{
    id: string;
    name: string;
    path: string;
    color: string;
    emoji: string;
  }>;
};

export default function ClusterPicker({
  currentClusterId,
  unclustered,
  unclusteredPath,
  newClusterPath,
  clusters,
}: Props) {
  const currentCluster = find(clusters, { id: currentClusterId });

  return (
    <Dropdown className="block" width={270} nibOffsetX={-115} style={{ maxWidth: "270px" }}>
      <div
        className="flex items-center gap1.5 cursor-pointer"
        data-testid="clusters-dropdown-trigger"
      >
        <EmojiAvatar
          emoji={currentCluster?.emoji}
          color={currentCluster?.color}
          fallbackIcon={unclustered ? "custom/outline/broken-cluster" : "custom/outline/cluster"}
        />

        <Emojify
          className="text-lg semi-bold m-0 truncate max-w-full leading-tight charcoal-900"
          text={unclustered ? "Unclustered" : currentCluster?.name}
        />

        <Icon icon="down-triangle" className="flex-none" style={{ width: 7, height: 7 }} />
      </div>

      {unclusteredPath && (
        <div className="border-b border-gray mb-1">
          <a
            key={unclusteredPath}
            className={classNames(
              "btn black hover-purple focus-purple flex items-center flex-none semi-bold gap1.5 py-1 px-1 m-1 rounded",
              {
                "btn--activated purple bg-light-purple semi-bold": unclustered,
              },
            )}
            href={unclusteredPath}
          >
            <EmojiAvatar
              emoji={null}
              color={unclustered ? "#FFFFFF" : null}
              fallbackIcon="custom/outline/broken-cluster"
              className="very-dark-gray border border-white"
            />

            <Emojify text="Unclustered" className="truncate max-w-full" />
          </a>
        </div>
      )}

      {clusters.map((cluster) => (
        <a
          key={cluster.path}
          className={classNames(
            "btn black hover-purple focus-purple flex items-center flex-none semi-bold gap1.5 py-1 px-1 mx-1 rounded",
            {
              "btn--activated purple bg-light-purple semi-bold": currentClusterId === cluster.id,
            },
          )}
          href={cluster.path}
        >
          <EmojiAvatar
            emoji={cluster.emoji}
            color={cluster.color}
            fallbackIcon="custom/outline/cluster"
            className="very-dark-gray border border-white"
          />

          <Emojify text={cluster.name} className="truncate max-w-full" />
        </a>
      ))}
      {newClusterPath !== null ? (
        <a
          className="btn black hover-purple focus-purple flex items-center flex-none semi-bold gap1.5 py-1 px-1 m-1 rounded"
          href={newClusterPath}
        >
          <div
            className="flex items-center justify-center bg-gray-200"
            style={{ width: "40px", height: "40px", borderRadius: "50%" }}
          >
            <Icon
              icon="heroicons/outline/plus"
              className="flex-none"
              style={{ width: 24, height: 24 }}
            />
          </div>
          New Cluster
        </a>
      ) : null}
    </Dropdown>
  );
}

import { useState } from "react";
import styled from "styled-components";
import Icon from "app/components/shared/Icon";
import DateFilters, { DateFilter } from "app/components/analytics/shared/DateFilters";
import { Metrics } from "app/components/analytics/shared/type";
import ChartCard from "./ChartCard";

const CardContainer = styled.div`
  gap: 15px;
  padding-bottom: 20px;
  justify-content: center;

  @media (max-width: 768px) {
    flex: 1 1 auto;
    flex-direction: column;
  }

  > div {
    @media (max-width: 1320px) {
      flex: 1 0 25%;
    }
  }
`;

type Tag = {
  value: string;
  removePath: string;
};

type Props = {
  heading: string;
  chartDataUrl: string;
  chartMetricUrl: string;
  modal_heading: string;
  metrics: Array<Metrics>;
  hideDateFilter?: boolean;
  period?: DateFilter | null;
  groupByTag?: Tag[];
};

// MetricChartCards is used to render a set of metric as cards,
// each with a non-interactive mini chart and a popover interactive chart.
// This component also handles react-based date filtering and displaying tag filters.
const MetricChartCards = ({
  heading,
  metrics,
  chartDataUrl,
  chartMetricUrl,
  modal_heading,
  hideDateFilter,
  period,
  groupByTag,
}: Props) => {
  // If period is set in the URL, we pass it through from the view and
  // use that as the default
  const [metricCardPeriod, setMetricCardPeriod] = useState<DateFilter>(period || "28days");

  return (
    <div className="flex flex-col flex-auto min-w-0">
      <DateFilters
        heading={heading}
        filter={metricCardPeriod}
        setFilter={setMetricCardPeriod}
        hideDateFilter={hideDateFilter}
      />

      {groupByTag && groupByTag.length > 0 && (
        <div className="flex flex-wrap items-center gap1">
          <h3 className="text-sm text-gray-800 m-0">Grouped by</h3>
          {groupByTag.map((group) => (
            <a
              key={group.value}
              href={group.removePath}
              className="badge badge--outline truncate max-w-full flex pr-0"
            >
              {group.value}
              <span className="border-l border-gray-300 ml-1 flex items-center">
                <Icon icon="close" className="flex-none" style={{ height: 18, width: 18 }} />
              </span>
            </a>
          ))}
        </div>
      )}

      <CardContainer className="flex mt-3">
        {metrics.map(({ label, metric, description, chartType }) => (
          <ChartCard
            key={`${metric}-${label}`}
            metric={metric}
            label={label}
            period={metricCardPeriod}
            chartDataUrl={chartDataUrl}
            chartMetricUrl={chartMetricUrl}
            heading={modal_heading}
            description={description}
            chartType={chartType}
          />
        ))}
      </CardContainer>
    </div>
  );
};

export default MetricChartCards;

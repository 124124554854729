import { default as React, Children, ReactNode } from "react";
import Duration from "app/components/shared/Duration";
import FriendlyTime from "app/components/shared/FriendlyTime";
import "./styles.css";

function Label(props: { children: ReactNode }) {
  return <span className="semi-bold">{props.children}</span>;
}

function Content(props: { children: ReactNode }) {
  return <div className="mt-2">{props.children}</div>;
}

function Description(props: { children: ReactNode }) {
  return <p className="dark-gray small">{props.children}</p>;
}

type Props = {
  timestamp: string;
  previousTimestamp: string | null | undefined;
  children: ReactNode;
};

const JobEvent = ({ timestamp, previousTimestamp, children }: Props) => {
  let label = null;
  let content = null;
  let description = null;

  Children.map(children, (child) => {
    if (!child) {
      return null;
    }

    // @ts-expect-error - TS2339 - Property 'type' does not exist on type 'Node'.
    switch (child.type) {
      case Label:
        // @ts-expect-error - TS2322 - Type 'Node' is not assignable to type 'null'.
        label = child;
        break;
      case Content:
        // @ts-expect-error - TS2322 - Type 'Node' is not assignable to type 'null'.
        content = child;
        break;
      case Description:
        // @ts-expect-error - TS2322 - Type 'Node' is not assignable to type 'null'.
        description = child;
        break;
    }

    return null;
  });

  return (
    <section className="JobEvent" data-testid="JobEvent">
      <header className="flex-col">
        <div className="flex flex-auto min-w-0">
          <div className="flex flex-auto min-w-0">{label}</div>
          <div className="flex flex-col items-end">
            <small className="dark-gray">
              <FriendlyTime value={timestamp} capitalized={true} fractionalSeconds={true} />
            </small>
            {previousTimestamp ? (
              <small className="ml-1 dark-gray">
                (+
                <Duration.Micro from={previousTimestamp} to={timestamp} tabularNumerals={true} />)
              </small>
            ) : null}
          </div>
        </div>
        {description}
      </header>
      {content}
    </section>
  );
};

JobEvent.Label = Label;
JobEvent.Description = Description;
JobEvent.Content = Content;

export default JobEvent;

import * as React from "react";
import { createFragmentContainer, graphql } from "react-relay";
import PipelineIcon from "app/components/icons/Pipeline";

type Props = {
  organization: any;
  team: string | null | undefined;
};

class Welcome extends React.PureComponent<Props> {
  get canCreatePipeline(): boolean {
    return this.props.organization.permissions &&
      this.props.organization.permissions.pipelineCreate &&
      this.props.organization.permissions.pipelineCreate.allowed
      ? true
      : false;
  }

  render() {
    if (!this.canCreatePipeline) {
      if (this.props.organization.public) {
        return (
          <div className="text-center p-5">
            <PipelineIcon />
            <p>{this.props.organization.name} doesn’t have any public pipelines just yet.</p>
          </div>
        );
      }

      return (
        <div className="text-center p-5">
          <PipelineIcon />
          <p>
            You don’t have permission to view or create any pipelines because you’ve not been
            assigned to a team.
          </p>
          <p>
            Please contact one of your team maintainers or organization administrators for help.
          </p>
        </div>
      );
    }

    return this.renderWelcomeMessage();
  }

  renderWelcomeMessage() {
    // Attach the current team to the "New Pipeline" URL
    let newPipelineURL = `/organizations/${this.props.organization.slug}/pipelines/new`;
    if (this.props.team) {
      newPipelineURL += `?team=${this.props.team}`;
    }

    return (
      <div className="text-center p-5">
        <PipelineIcon />
        <h1 className="text-xl m-0 mt-2 mb-4">Create your first pipeline</h1>
        <p className="mx-auto" style={{ maxWidth: "30em" }}>
          Pipelines define the tasks to be run on your agents. It’s best to keep each pipeline
          focussed on a single part of your delivery pipeline, such as testing, deployments
          or&nbsp;infrastructure. Once&nbsp;created, you can connect your pipeline with your source
          control or trigger it via the&nbsp;API.
        </p>
        <p className="dark-gray">
          Need inspiration? See the{" "}
          <a
            className="lime hover-lime no-underline hover-underline"
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/buildkite/example-pipelines"
          >
            example pipelines
          </a>{" "}
          GitHub repo.
        </p>
        <p>
          <a
            className="mt-4 btn btn-primary bg-lime hover-white white rounded"
            href={newPipelineURL}
          >
            New Pipeline
          </a>
        </p>
      </div>
    );
  }
}

export default createFragmentContainer(Welcome, {
  organization: graphql`
    fragment Welcome_organization on Organization {
      slug
      name
      public
      permissions {
        pipelineCreate {
          allowed
        }
      }
    }
  `,
});

import { createFragmentContainer, graphql } from "react-relay";
import { AttributeTable, AttributeTableRow } from "app/components/shared/AttributeTable";
import { UserAvatarRelay as UserAvatar } from "app/components/shared/UserAvatar";
import JobEvent from "../JobEvent";

type Props = {
  event: {
    timestamp: string;
    actor: any;
    retryFailedReason: string;
  };
  previousTimestamp: string | null | undefined;
};

const ACTOR_TYPES = {
  SYSTEM: "SYSTEM",
  USER: "USER",
} as const;

function RetryFailedReason(props: { retryFailedReason: string }) {
  return (
    <AttributeTable>
      <AttributeTableRow title="Retry Failed Reason">
        <code className="dark-gray monospace">{props.retryFailedReason}</code>
      </AttributeTableRow>
    </AttributeTable>
  );
}

function JobEventRetryFailed(props: Props) {
  if (!Object.keys(ACTOR_TYPES).includes(props.event.actor.type)) {
    return null; // Not sure how to render this event actor type.
  }

  if (props.event.actor.type === ACTOR_TYPES.SYSTEM) {
    return (
      <JobEvent timestamp={props.event.timestamp} previousTimestamp={props.previousTimestamp}>
        <JobEvent.Label>Job Failed to be Automatically Retried</JobEvent.Label>
        <JobEvent.Content>
          <RetryFailedReason retryFailedReason={props.event.retryFailedReason} />
        </JobEvent.Content>
      </JobEvent>
    );
  }

  const missingActor = !props.event.actor?.node;

  if (props.event.actor.type === ACTOR_TYPES.USER && missingActor) {
    return (
      <JobEvent timestamp={props.event.timestamp} previousTimestamp={props.previousTimestamp}>
        <JobEvent.Label>Job Failed to be Manually Retried</JobEvent.Label>
        <JobEvent.Content>
          <RetryFailedReason retryFailedReason={props.event.retryFailedReason} />
        </JobEvent.Content>
      </JobEvent>
    );
  }

  return (
    <JobEvent timestamp={props.event.timestamp} previousTimestamp={props.previousTimestamp}>
      <JobEvent.Label>
        Job Failed to be Manually Retried by{" "}
        <UserAvatar
          className="mr-1"
          size="MICRO"
          user={props.event.actor.node}
          suppressAltText={true}
        />
        {props.event.actor.node.name}
      </JobEvent.Label>
      <JobEvent.Content>
        <RetryFailedReason retryFailedReason={props.event.retryFailedReason} />
      </JobEvent.Content>
    </JobEvent>
  );
}

export default createFragmentContainer(JobEventRetryFailed, {
  event: graphql`
    fragment JobEventRetryFailed_event on JobEventRetryFailed {
      ... on JobEvent {
        timestamp

        actor {
          type
          node {
            ... on User {
              name
              uuid
              ...UserAvatar_user
            }
          }
        }

        ... on JobEventRetryFailed {
          retryFailedReason
        }
      }
    }
  `,
});

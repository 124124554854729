import { PureComponent } from "react";
import PropTypes from "prop-types";

import BaseButton from "app/components/shared/Button";

export default class Button extends PureComponent {
  static displayName = "PageHeader.Button";

  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    return (
      <BaseButton {...this.props} className="PageHeader__Menu__Button ml-1">
        {this.props.children}
      </BaseButton>
    );
  }
}

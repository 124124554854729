import * as React from "react";
import DocumentTitle from "react-document-title";
import PageHeader from "app/components/shared/PageHeader";
import TabControl from "app/components/shared/TabControl";
import Icon from "app/components/shared/Icon";
import classNames from "classnames";

type Props = {
  children: React.ReactNode;
};

const Tab = (props: any) => (
  <a
    className={classNames("btn btn-tab cursor-pointer", {
      "btn-tab--active": window.location.pathname.startsWith(props.href),
    })}
    {...props}
  />
);

const Layout = ({ children }: Props) => (
  <DocumentTitle title="GraphQL Console">
    <div className="container">
      <PageHeader>
        <PageHeader.Icon>
          <Icon icon="graphql" style={{ width: 34, height: 34, marginTop: 3, marginLeft: 3 }} />
        </PageHeader.Icon>
        <PageHeader.Title>GraphQL Explorer</PageHeader.Title>
        <PageHeader.Description>
          Interact with the Buildkite GraphQL API right in your browser. This is production data
          you’re playing with, so take care!
        </PageHeader.Description>
      </PageHeader>

      <div>
        <TabControl>
          <Tab href="/user/graphql/console">Console</Tab>
          <Tab href="/user/graphql/documentation">Documentation</Tab>
          <Tab href="/user/graphql/examples">Examples</Tab>
        </TabControl>

        {children}
      </div>
    </div>
  </DocumentTitle>
);

export default Layout;

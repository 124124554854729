import merge from "lodash/merge";

import { Metrics, ChartData } from "app/components/analytics/shared/type";

export const chartOptions = (
  chartType: Metrics["chartType"],
  chartData: ChartData,
  metric: Metrics["metric"],
  label: string | null | undefined,
  options: object | null | undefined,
) => {
  const defaultColor = "#BFEDFF";
  // Set colors for dataset to cycle through
  const datasetColors = ["#FF9F40", "#A195EF", "#4BC1C0", "#FF6383", "#35A1E9", "#C9CBCf"];
  const defaultLabel = label || metric;

  const isMultiDataSet = Array.isArray(chartData);

  const formatData = isMultiDataSet
    ? {
        labels: chartData[0]?.data?.x || null,
        datasets: chartData.map((data, index) => ({
          label: data.label || defaultLabel,
          fill: false,
          backgroundColor: datasetColors[index % datasetColors.length] || defaultColor,
          borderColor: datasetColors[index % datasetColors.length] || defaultColor,
          borderRadius: 5,
          borderWidth: 2,
          pointRadius: 1,
          data: data.data?.y || null,
        })),
      }
    : {
        labels: chartData?.x || null,
        datasets: [
          {
            label: defaultLabel,
            fill: false,
            backgroundColor: defaultColor,
            borderColor: defaultColor,
            borderRadius: 5,
            data: chartData?.y || null,
          },
        ],
      };

  const defaultOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: "index",
    },
    elements: {
      line: { spanGaps: true },
    },
    scales: {
      // eslint-disable-next-line id-length
      y: {
        grid: { drawBorder: false },
        beginAtZero: true,
      },
      // eslint-disable-next-line id-length
      x: {
        grid: { display: false },
      },
    },
    plugins: {
      legend: {
        display: isMultiDataSet,
      },
    },
  };

  const chartOptions = {
    type: chartType || "bar",
    data: formatData,
    options: defaultOptions,
  };

  merge(chartOptions, options);

  return chartOptions;
};

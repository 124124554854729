import camelize from "lodash/fp";

export const Current = new Proxy(
  {},
  {
    get(target, propertyName) {
      const result = {};
      const prefix = `current-${propertyName}-`;
      for (const { name, content } of document.head.querySelectorAll(`meta[name^=${prefix}]`)) {
        const key = camelize(name.slice(prefix.length));
        result[key] = content;
      }
      return result;
    },
  },
);

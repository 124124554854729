import Icon from "app/components/shared/Icon";
import {
  HoverCard,
  HoverCardTrigger,
  HoverCardContent,
  HoverCardPortal,
} from "app/components/shared/HoverCard";

export default function ClusterQueue({
  queueName,
  agentsPath,
  onNestedLinkTrigger,
}: {
  queueName: string;
  agentsPath: string;
  onNestedLinkTrigger: (e: React.MouseEvent | React.KeyboardEvent) => void;
}) {
  return (
    <HoverCard>
      <HoverCardTrigger asChild={true}>
        <a
          href={agentsPath}
          className="max-w-[240px] min-w-[70px] badge badge--outline flex items-center gapx0.5 job-stats gap-x-1 justify-center"
          onClick={onNestedLinkTrigger}
          onKeyDown={onNestedLinkTrigger}
        >
          <Icon icon="custom/outline/14x/queue" className="flex-shrink-0 w-3.5 h-3.5" />

          {queueName ? "Queue" : "Unclustered"}
        </a>
      </HoverCardTrigger>

      {queueName && (
        <HoverCardPortal>
          <HoverCardContent
            size="max-lg"
            onClick={onNestedLinkTrigger}
            onKeyDown={onNestedLinkTrigger}
          >
            <div className="flex">
              <a href={agentsPath} className="text-inherit hover:text-inherit truncate max-w-full">
                {queueName}
              </a>
            </div>
          </HoverCardContent>
        </HoverCardPortal>
      )}
    </HoverCard>
  );
}

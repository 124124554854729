import agentWarning from "app/images/agentWarning.svg";
import { createFragmentContainer, graphql } from "react-relay";

type Props = {
  agent: any;
};

function AgentUpgradeBanner({ agent }: Props) {
  if (!agent.version) {
    return null;
  }

  return (
    <section
      className="border border-gray rounded very-dark-gray mb-2"
      style={{ borderLeft: "3px solid #fbca44" }}
    >
      <div className="m-2">
        <h4 className="text-sm m-0 p-0 mb-1 semi-bold">
          <img src={agentWarning} className="inline mr-1" />
          <span>Agent Upgrade Required</span>
        </h4>
        <p className="dark-gray">
          buildkite-agent {agent.version} has known issues and should be upgraded to a later
          version. See the{" "}
          <a
            href={`https://github.com/buildkite/agent/releases/tag/v${agent.version}`}
            className="lime no-underline hover-underline"
          >
            release notes on GitHub
          </a>{" "}
          for more information.
        </p>
      </div>
    </section>
  );
}

export default createFragmentContainer(AgentUpgradeBanner, {
  agent: graphql`
    fragment AgentUpgradeBanner_agent on Agent {
      ... on Agent {
        version
      }
    }
  `,
});

import Queue from "throttle-queue";
import debounce from "lodash/debounce";
import moment from "moment";

import cable from "app/lib/cable";
import Logger from "app/lib/Logger";
import Database from "app/lib/Database";
import BaseStore from "app/stores/BaseStore";
import { Build } from "app/components/build/Show/lib/types";

export type Query = {
  page?: number;
  perPage?: number;
  state?: "running" | "scheduled";
  creator?: string;
};

declare let jQuery: JQueryStatic;

export default class BuildsStore extends BaseStore {
  database: Database;
  query: Query;
  reloadInProgress = false;

  constructor(builds: Array<Build>, query: Query) {
    // Call super on the parent store
    super();

    // Create a database and seed it with data
    this.database = new Database(builds);
    this.query = Database.parse(query);

    // Create a queue for updates so we're not overloading the backend with
    // requests. This queue runs one at a time, and makes sure each item is
    // unique.
    // @ts-expect-error - TS2339 - Property 'queue' does not exist on type 'BuildsStore'.
    this.queue = new Queue({ concurrency: 1, aging: false });
    // @ts-expect-error - TS2339 - Property 'queue' does not exist on type 'BuildsStore'.
    this.queue.setExecutor(async (url) => {
      Logger.info(`[BuildStore] Refreshing build: ${url}`);

      // Make sure we're asking for a truncated set of jobs
      // @ts-expect-error - TS2345 - Argument of type 'Location' is not assignable to parameter of type 'string | URL | undefined'.
      const truncatedUrl = new URL(url, window.location);
      truncatedUrl.searchParams.set("index", "true");

      const response = await fetch(truncatedUrl.toString());
      if (!response.ok) {
        Logger.error(
          `[BuildStore] Failed to read updated build: ${truncatedUrl.toString()} => ${
            response.status
          }`,
        );
        return;
      }

      const contentType = response.headers.get("Content-Type");
      if (contentType !== null && contentType.indexOf("application/json") === -1) {
        Logger.error(
          `[BuildStore] Invalid build data: ${truncatedUrl.toString()} => ${contentType}`,
        );
        return;
      }
      const data = await response.json();
      this.loadAndEmit(data);
    });

    // Subscribe to build changes for all builds in the database
    // @ts-expect-error - TS2339 - Property 'buildSubscriptions' does not exist on type 'BuildsStore'.
    this.buildSubscriptions = {};
    for (const build of this.database.all()) {
      this._subscribeToBuild(build);
    }
  }

  // Return all the builds sorted by updated at.
  all() {
    // Sort the builds by created at desc (newest first)
    const sortedBuilds = this.database
      .all()
      .sort((buildA, buildB) => moment(buildB.createdAt).unix() - moment(buildA.createdAt).unix());

    // Only show the right number of builds.
    return sortedBuilds.slice(0, this.query.perPage);
  }

  reload = debounce(
    (url?: string | null) => {
      this._serialReload(url);
    },
    1000,
    { maxWait: 4000 },
  );

  _serialReload(url?: string | null) {
    // A reload request is still in progress, so we need to defer this reload
    // again - we can do so by sending it back to the debounced method:
    if (this.reloadInProgress) {
      this.reload(url);
    } else {
      this.reloadInProgress = true;

      this._reload(url).always(() => {
        this.reloadInProgress = false;
      });
    }
  }

  _reload(url?: string | null) {
    if (!url) {
      url = location.href.replace(/(\?|$)/, ".json$1");
    }

    return jQuery.ajax({
      url,
      headers: {
        "X-Buildkite-Frontend-Version": BUILDKITE_FRONTEND_VERSION,
      } as {
        [key: string]: string;
      },
      success: (builds) => {
        this.database.load(builds);
        for (const build of this.database.all()) {
          this._subscribeToBuild(build);
        }
        this.emit("change");
      },
    });
  }

  loadAndEmit(build: Build) {
    const parsedBuild = this.database.load(build);
    this._subscribeToBuild(parsedBuild);
    this.emit("change");
  }

  _subscribeToBuild(build: Build) {
    // @ts-expect-error - TS2339 - Property 'buildSubscriptions' does not exist on type 'BuildsStore'.
    if (!this.buildSubscriptions[build.id]) {
      const reloadBuild = debounce(
        (url: string, id: string) => {
          // If we already have an in-flight request for this build in the queue,
          // enqueue a delayed update attempt.
          // @ts-expect-error - TS2339 - Property 'queue' does not exist on type 'BuildsStore'.
          if (this.queue.hasJob(id)) {
            return reloadBuild(url, id);
          }

          // @ts-expect-error - TS2339 - Property 'queue' does not exist on type 'BuildsStore'.
          this.queue.process(url, id);
        },
        1000,
        { maxWait: 4000 },
      );

      // @ts-expect-error - TS2339 - Property 'buildSubscriptions' does not exist on type 'BuildsStore'.
      this.buildSubscriptions[build.id] = cable.subscriptions.create(
        { channel: "Pipelines::BuildChannel", uuid: build.id },
        {
          store: this,
          id: build.id,
          url: build.jsonPath,

          reload() {
            reloadBuild(this.url, this.id);
          },

          received({ event }) {
            // This view only cares about commit/branch/message, state, and
            // step changes. Annotation changes are irrelevant.
            //
            // XXX: how do we manage a good list of state change events? Maybe
            // it should be one event with a "new state" in the payload?
            //
            if (
              // Generically changed
              event === "updated" ||
              // Build state transitions which change appearance
              event === "started" ||
              event === "finished" ||
              event === "skipped" ||
              event === "canceling" ||
              // Happens when the commit is resolved from HEAD to a sha
              event === "commit:changed" ||
              // The steps have changed, maybe a pipeline upload or a step has
              // changed state, and we might need to re-render the build pipeline
              event === "steps:changed"
            ) {
              this.reload();
            }
          },
        },
      );
    }
  }
}

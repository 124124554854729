import { forwardRef } from "react";
import classNames from "classnames";
import Icon from "./Icon";
import styled from "styled-components";
import Emojify from "./Emojify";

type Props = {
  large?: boolean;
  closeable?: boolean;
  text: string;
  title?: string;
  onClick?: () => void;
};

type BaseTagProps = {
  large: boolean;
  onClick?: () => void;
};

const Base = styled.span<BaseTagProps>`
  height: ${(props) => (props.large ? 32 : 26)}px;
  padding: 0 ${(props) => (props.large ? "0.625" : "0.5")}rem;
  font-size: ${(props) => (props.large ? "0.875" : "0.75")}rem;
  max-width: 20ch;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  &:hover {
    border-color: ${(props) => (props.onClick ? "rgba(0, 0, 0, 0.2)" : 0)};
  }
`;

const Tag = forwardRef<HTMLSpanElement, Props>(
  ({ closeable = false, large = false, text, title, onClick, ...props }: Props, ref) => {
    return (
      <Base
        {...props}
        ref={ref}
        as={onClick ? "button" : "span"}
        onClick={onClick}
        large={large}
        className={classNames("flex items-center unstyled-button font-normal gap0.5")}
      >
        <Emojify className="truncate max-w-full" title={title} text={text} />

        {closeable && <Icon icon="close" className="flex-none" style={{ height: 18, width: 18 }} />}
      </Base>
    );
  },
);

Tag.displayName = "Tag";

export default Tag;

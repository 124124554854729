import classNames from "classnames";
import React from "react";

function ChartBar(props: { leftPercentage: string; widthPercentage: string }) {
  return (
    <div className="w-full relative h-full flex items-center">
      <div
        className="absolute h-2.5 w-full bg-gray-400 animate-pulse"
        style={{
          left: props.leftPercentage,
          width: props.widthPercentage,
        }}
      />
    </div>
  );
}

function RowLabel(props: { child: boolean; lastRow: boolean }) {
  return (
    <>
      {props.child && <div className="w-4" />}
      <div
        className={classNames(
          "flex-1 leading-normal px-[15px] truncate max-w-full border-gray no-underline block",
          !props.lastRow && "border-b",
          props.child && "border-l bg-white",
          Features.BuildSidebar ? "h-[37.5px]" : "h-[52px]",
        )}
      >
        <div className="flex items-center w-full h-full">
          <div className="bg-gray-400 animate-pulse h-4 w-6 rounded-sm" />
        </div>
      </div>
    </>
  );
}

const CHART_DATA = [
  {
    width: "100%",
    left: "0%",
    child: false,
  },
  {
    width: "50%",
    left: "50%",
    child: false,
  },
  {
    width: "22%",
    left: "50%",
    child: true,
  },
  {
    width: "22%",
    left: "50%",
    child: true,
  },
  {
    width: "26%",
    left: "50%",
    child: true,
  },
  {
    width: "50%",
    left: "50%",
    child: true,
  },
  {
    width: "50%",
    left: "50%",
    child: true,
  },
];

export default function Skeleton() {
  return (
    <div className="flex-1">
      <section
        className={classNames(
          Features.BuildSidebar ? "" : "border border-gray-400",
          "relative rounded-lg w-full overflow-clip",
        )}
      >
        {CHART_DATA.map((props, index) => {
          const lastRow = CHART_DATA.length - 1 === index;
          return (
            <div
              data-testid="waterfall-chart-row"
              className="flex items-center"
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              <div className="flex-none bg-silver w-1/4 border-r border-gray flex">
                <RowLabel lastRow={lastRow} child={props.child} />
              </div>
              <div
                className={classNames(
                  "w-full self-stretch flex border-gray relative items-center px-2",
                  lastRow ? "" : "border-b",
                )}
              >
                <ChartBar leftPercentage={props.left} widthPercentage={props.width} />
              </div>
            </div>
          );
        })}
      </section>
    </div>
  );
}

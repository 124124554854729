import * as React from "react";
import classNames from "classnames";

import { formatNumber } from "app/lib/number";

type Props = {
  runningBuildsCount: number;
  scheduledBuildsCount: number;
  className?: string;
  state?: string;
  path: string;
  testId?: string;
  branchName?: string;
};

function createURL(
  path: string,
  state: string | null | undefined,
  branchName: string | null | undefined,
) {
  if (state) {
    return `${path}?state=${state}`;
  } else if (branchName) {
    return `${path}?branch=${branchName}`;
  }
  return `${path}`;
}

class StateSwitcher extends React.PureComponent<Props> {
  renderLink(
    label: React.ReactNode,
    state: string | null | undefined,
    count: number,
    branchName?: string | null,
  ) {
    const url = createURL(this.props.path, state, branchName);
    const active = this.props.state === state;
    const classes = classNames("block text-center hover-black hover-bg-silver no-underline", {
      "dark-gray": !active,
      black: active,
    });

    return (
      <a
        href={url}
        className={classes}
        style={{
          lineHeight: 1.2,
          padding: ".75em 1em",
          width: "100%",
        }}
      >
        {label} {formatNumber(count)}
      </a>
    );
  }

  render() {
    return (
      <div data-testid={this.props.testId} className={classNames("flex", this.props.className)}>
        <div
          data-testid="RunningBuildCount"
          className="border-l border-t border-b border-gray flex items-center rounded-l"
        >
          {this.renderLink(
            "Running",
            "running",
            this.props.runningBuildsCount,
            this.props.branchName,
          )}
        </div>
        <div
          data-testid="ScheduledBuildCount"
          className="rounded-r border border-gray flex items-center"
        >
          {this.renderLink(
            "Scheduled",
            "scheduled",
            this.props.scheduledBuildsCount,
            this.props.branchName,
          )}
        </div>
      </div>
    );
  }
}

export default StateSwitcher;

import Emojify from "app/components/shared/Emojify";
import FriendlyTime from "app/components/shared/FriendlyTime";
import Icon from "app/components/shared/Icon";
import UserAvatar from "app/components/shared/UserAvatar";

interface Props {
  authorName?: string;
  authorAvatar?: string;
  creatorVerified: boolean;
  createdAt: string;
  source: string;
  rebuiltFrom?: {
    url: string;
    number: number;
  };
  pipelineSchedule?: {
    url: string;
    label: string;
  };
  triggeredFrom?: {
    uuid: string;
    name: string;
    url: string;
    project: {
      name: string;
    };
    build: {
      number: number;
    };
  };
}

/**
 * Create a human-readable representation of a build trigger.
 */
export function Trigger({
  createdAt,
  source,
  rebuiltFrom,
  triggeredFrom,
  pipelineSchedule,
  authorName,
  authorAvatar,
  creatorVerified,
}: Props) {
  const user = (
    <User
      name={authorName || "Unknown"}
      avatar={authorAvatar || ""}
      creatorVerified={creatorVerified}
    />
  );
  const relativeTime = <FriendlyTime value={createdAt} capitalized={false} />;
  const sourceLabel = labelsForSource[source] || "unknown";

  // e.g. Schedule Daily triggered build today at 12:00 PM
  if (pipelineSchedule) {
    const schedule = <Schedule url={pipelineSchedule.url} label={pipelineSchedule.label} />;

    return (
      <>
        {schedule} triggered build {relativeTime}
      </>
    );
  }

  // e.g. Hagrid triggered rebuild of #1 from API today at 12:00 PM
  if (rebuiltFrom) {
    const buildLink = (
      <a href={rebuiltFrom.url} className="semi-bold color-inherit hover-color-inherit">
        {`#${rebuiltFrom.number}`}
      </a>
    );

    return (
      <>
        {user} triggered rebuild of {buildLink} from {sourceLabel} {relativeTime}
      </>
    );
  }

  // e.g. Hagrid triggered build from Deploy! in Buildkite #99 today at 12:00 PM
  if (triggeredFrom) {
    const triggerLink = (
      <a href={triggeredFrom.url} className="color-inherit hover-color-inherit">
        <span>
          {triggeredFrom.name && (
            <>
              <Emojify text={triggeredFrom.name} className="semi-bold" />
              {" in "}
            </>
          )}
          <span className="semi-bold">{triggeredFrom.project.name}</span>{" "}
          <span className="semi-bold">#{triggeredFrom.build.number}</span>
        </span>
      </a>
    );

    return (
      <>
        {user} triggered build from {triggerLink} {relativeTime}
      </>
    );
  }

  // e.g. Hagrid triggered build from API today at 12:00 PM
  return (
    <>
      {user} triggered build from {sourceLabel} {relativeTime}
    </>
  );
}

// Human-readable labels for build sources
const labelsForSource = {
  ui: "website",
  webhook: "webhook",
  api: "API",
  trigger_job: "pipeline",
  schedule: "schedule",
};

const User = ({
  name,
  avatar,
  creatorVerified,
}: {
  name: string;
  avatar: string;
  creatorVerified: boolean;
}) => (
  <span className="inline-flex gap-1 items-center shrink-0">
    <UserAvatar
      size="SMALL"
      user={{
        name: name,
        avatar: { url: avatar },
      }}
    />
    <span>
      <span className="font-medium">{name}</span>
      {creatorVerified && <div className="build-verified">Verified</div>}
    </span>
  </span>
);

const Schedule = ({ url, label }: { url: string; label: string }) => (
  <span className="inline-flex gap-1 items-center shrink-0">
    Schedule{" "}
    <a href={url} className="semi-bold color-inherit hover-color-inherit">
      <Icon
        icon="schedules"
        style={{
          width: 15,
          height: 15,
        }}
      />{" "}
      <Emojify text={label} />
    </a>
  </span>
);

import * as React from "react";
import {
  RelayProp,
  createRefetchContainer,
  graphql,
  fetchQuery,
  commitMutation,
} from "react-relay";
import GraphQLErrors from "app/constants/GraphQLErrors";
import WorkflowProgress from "app/components/shared/WorkflowProgress";
import TwoFactorConfigureRecoveryCodes from "./TwoFactorConfigureRecoveryCodes";
import TwoFactorConfigureActivate from "./TwoFactorConfigureActivate";
import TotpCreateMutation from "./TotpCreateMutation";
import TotpDeleteMutation from "./TotpDeleteMutation";
// Required for Relay fragments...
import TwoFactor from "app/components/user/TwoFactor"; // eslint-disable-line
import RecoveryCodeList from "app/components/RecoveryCodeList"; // eslint-disable-line

const STEPS = {
  RECOVERY_CODES: "RECOVERY_CODES",
  ACTIVATE_TOTP: "ACTIVATE_TOTP",
  COMPLETE: "COMPLETE",
} as const;

type StepType = keyof typeof STEPS;
// @ts-expect-error - TS2456 - Type alias 'TotpType' circularly references itself.
type TotpType = TotpType["totp"];
type RecoveryCodesType = TotpType["recoveryCodes"];

function getNextStep(currentStep: StepType): StepType | null | undefined {
  switch (currentStep) {
    case STEPS.RECOVERY_CODES:
      return STEPS.ACTIVATE_TOTP;
    case STEPS.ACTIVATE_TOTP:
      return STEPS.COMPLETE;
    case STEPS.COMPLETE:
      return STEPS.COMPLETE;
  }
}

type Props = {
  relay: RelayProp;
  viewer: any;
  onConfigurationComplete: () => void;
};

type State = {
  step: StepType;
  didActivateNewOtp: boolean;
  newTotpConfig:
    | {
        totp: TotpType;
        provisioningUri: string;
      }
    | null
    | undefined;
};

class TwoFactorConfigure extends React.Component<Props, State> {
  state = {
    step: STEPS.RECOVERY_CODES,
    newTotpConfig: null,
    didActivateNewOtp: false,
  };

  get recoveryCodes(): RecoveryCodesType {
    if (this.state.newTotpConfig) {
      // @ts-expect-error - TS2339 - Property 'totp' does not exist on type 'never'.
      return this.state.newTotpConfig.totp.recoveryCodes;
    }
    if (this.props.viewer.totp) {
      return this.props.viewer.totp.recoveryCodes;
    }
    return null;
  }

  get provisioningUri(): string {
    if (this.state.newTotpConfig) {
      // @ts-expect-error - TS2339 - Property 'provisioningUri' does not exist on type 'never'.
      return this.state.newTotpConfig.provisioningUri;
    }
    return "";
  }

  get hasActivatedTotp(): boolean {
    return this.props.viewer.totp ? true : false;
  }

  get steps(): Array<StepType> {
    return [STEPS.RECOVERY_CODES, STEPS.ACTIVATE_TOTP];
  }

  currentStepIndex(currentStep: StepType): number {
    return this.steps.indexOf(currentStep);
  }

  getStepTitle(): string {
    if (this.state.step === STEPS.RECOVERY_CODES) {
      return "Step 1: Store Recovery Codes";
    }
    return "Step 2: Configure Authenticator Application";
  }

  componentWillUnmount() {
    if (this.state.newTotpConfig && !this.state.didActivateNewOtp) {
      TotpDeleteMutation({
        environment: this.props.relay.environment,
        variables: {
          input: {
            // @ts-expect-error - TS2339 - Property 'totp' does not exist on type 'never'.
            id: this.state.newTotpConfig.totp.id,
          },
        },
      });
    }
  }

  render() {
    return (
      <div className="p-5" data-testid="TwoFactorConfigure">
        {this.renderReconfigureNotice()}
        <div className="flex items-top mb-3">
          <div className="flex-auto min-w-0">
            <div className="flex">
              <h1 className="m-0 text-xl semi-bold">
                {this.hasActivatedTotp ? "Reconfigure" : "Setup"} Two-Factor Authentication
              </h1>
            </div>
            <h2 className="m-0 mt-3 text-sm font-semibold mb-5">{this.getStepTitle()}</h2>
          </div>
          <WorkflowProgress
            stepCount={this.steps.length}
            currentStepIndex={this.currentStepIndex(this.state.step)}
          />
        </div>
        <div>{this.renderCurrentStep()}</div>
      </div>
    );
  }

  renderReconfigureNotice() {
    if (this.state.step === STEPS.RECOVERY_CODES && this.hasActivatedTotp) {
      return (
        <div className="mb-4 border orange rounded border-orange p-4">
          <div className="font-semibold">
            Youʼre about to reconfigure two-factor authentication.
          </div>
          <div>This will invalidate your existing configuration and recovery codes.</div>
        </div>
      );
    }
  }

  renderCurrentStep() {
    switch (this.state.step) {
      case STEPS.RECOVERY_CODES:
        return (
          <TwoFactorConfigureRecoveryCodes
            onNextStep={this.handleNextStep}
            recoveryCodes={this.recoveryCodes}
            onCreateNewTotp={this.handleCreateNewTotp}
          />
        );
      // @ts-expect-error - TS2678 - Type '"ACTIVATE_TOTP"' is not comparable to type '"RECOVERY_CODES"'.
      case STEPS.ACTIVATE_TOTP:
        return (
          <TwoFactorConfigureActivate
            // @ts-expect-error - TS2769 - No overload matches this call.
            onNextStep={this.handleNextStep}
            hasActivatedTotp={this.hasActivatedTotp}
            provisioningUri={this.provisioningUri}
            onActivateOtp={this.handleActivateOtp}
          />
        );
    }
  }

  handleNextStep = () => {
    const step = getNextStep(this.state.step);
    if (step) {
      this.setState({ step });
    }
  };

  refetchTotpById = (id: string): Promise<any> => {
    return fetchQuery(
      this.props.relay.environment,
      graphql`
        query TwoFactorConfigureRefetchNewTotpConfigQuery($id: ID!) {
          viewer {
            totp(id: $id) {
              id
              recoveryCodes {
                ...TwoFactorConfigureRecoveryCodes_recoveryCodes
              }
            }
          }
        }
      `,
      {
        id,
      },
    ).toPromise();
  };

  handleCreateNewTotp = (callback?: () => void) => {
    TotpCreateMutation({
      environment: this.props.relay.environment,
      onCompleted: ({ totpCreate }) => {
        this.refetchTotpById(totpCreate.totp.id).then(({ viewer: { totp } }) => {
          this.setState(
            {
              newTotpConfig: {
                totp,
                provisioningUri: totpCreate.provisioningUri,
              },
            },
            () => {
              if (callback) {
                callback();
              }
            },
          );
        });
      },
    });
  };

  handleActivateOtp = (token: string, callback?: (errors: any) => void) => {
    if (this.state.newTotpConfig) {
      commitMutation(this.props.relay.environment, {
        mutation: graphql`
          mutation TwoFactorConfigureActivateMutation($input: TOTPActivateInput!) {
            totpActivate(input: $input) {
              viewer {
                ...TwoFactor_viewer
              }
            }
          }
        `,
        // @ts-expect-error - TS2339 - Property 'totp' does not exist on type 'never'.
        variables: { input: { id: this.state.newTotpConfig.totp.id, token } },
        onCompleted: () => {
          this.setState({ didActivateNewOtp: true }, () => {
            this.props.onConfigurationComplete();
            if (callback) {
              // @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
              callback();
            }
          });
        },
        onError: (error) => {
          if (error) {
            if (error.source && error.source.type) {
              switch (error.source.type) {
                case GraphQLErrors.ESCALATION_ERROR:
                  location.reload();
                  return;
                case GraphQLErrors.RECORD_VALIDATION_ERROR:
                  if (callback) {
                    callback(error.source.errors);
                  }
                  return;
                default:
                  return;
              }
            } else {
              alert(error);
            }
          }
          if (callback) {
            // @ts-expect-error - TS2554 - Expected 1 arguments, but got 0.
            callback();
          }
        },
      });
    }
  };
}

export default createRefetchContainer(
  TwoFactorConfigure,
  {
    viewer: graphql`
      fragment TwoFactorConfigure_viewer on Viewer {
        id
        totp {
          id
          recoveryCodes {
            ...TwoFactorConfigureRecoveryCodes_recoveryCodes
          }
        }
      }
    `,
  },
  graphql`
    query TwoFactorConfigureRefetchQuery {
      viewer {
        ...TwoFactorConfigure_viewer
      }
    }
  `,
);

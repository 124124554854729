import EmptyState from "app/components/shared/EmptyState";

export default function AnnotationsEmptyState() {
  return (
    <div className="w-full">
      <EmptyState
        heading="No annotations"
        subheading={
          <>
            <span>
              There are no annotations for this build. To add annotations to your build, use the{" "}
            </span>
            <a
              href="https://buildkite.com/docs/agent/v3/cli-annotate"
              target="_blank"
              rel="noopener noreferrer"
              className="monospace"
            >
              buildkite-agent annotate
            </a>
            <span> command.</span>
          </>
        }
      />
    </div>
  );
}

import Tooltip from "../../shared/Tooltips/BasicTooltip";

type Props = {
  detectedFlakyTestCount: number;
  detectedFailedTestCount: number;
};

const Summary = ({ detectedFlakyTestCount, detectedFailedTestCount }: Props) => {
  return (
    <table className="table max-w-7xl mx-auto">
      <thead>
        <tr className="text-center">
          <td>
            Failed tests
            <Tooltip classNames="ml-1 text-left" name="Failed tests">
              <p className="font-semibold m-0 charcoal-800">Failed tests</p>
              <p className="m-0 mt-1 charcoal-800">
                This is a count of the tests that failed during this build.
              </p>
            </Tooltip>
          </td>
          <td>
            Flaky tests
            <Tooltip classNames="ml-1 text-left" name="Flaky tests">
              <p className="font-semibold m-0 charcoal-800">Flaky tests</p>
              <p className="m-0 mt-1 charcoal-800">
                Tests are labelled as being flaky when they have flaked at least once in the last 7
                days.
              </p>
            </Tooltip>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr className="text-center font-semibold">
          <td>{detectedFailedTestCount}</td>
          <td>{detectedFlakyTestCount}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default Summary;

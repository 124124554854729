import Step from "./Step";
import { useFormContext } from "react-hook-form";
import { Form, Goal } from "../form";
import styled from "styled-components";
import { Checkable } from "../Field/Checkable";

interface Props {
  step: number;
  of: number;
  displayProgress: boolean;
  onSubmit: () => void;
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1em;
`;

const options = [
  { value: Goal.Security, label: "Secure pipelines" },
  { value: Goal.UnblockDevelopers, label: "Unblock developers" },
  { value: Goal.Performance, label: "Fast builds" },
  { value: Goal.ReduceCosts, label: "Reduce costs" },
  { value: Goal.Flexibility, label: "Flexible workflows" },
  { value: Goal.ImproveTestQuality, label: "Improve test quality" },
  { value: Goal.NewTools, label: "Try new tools" },
  { value: Goal.Other, label: "Other" },
];

export default function Goals(props: Props) {
  const { watch, register, handleSubmit } = useFormContext<Form>();
  const goals = watch("goals");

  return (
    <Step
      {...props}
      title="What are your CI/CD goals?"
      disabled={!goals.length}
      onSubmit={handleSubmit(props.onSubmit)}
    >
      <Grid>
        {options.map(({ value, label }) => (
          <Checkable type="checkbox" key={value} value={value} {...register("goals")}>
            {value === Goal.Other && goals.includes(value) ? (
              <input autoFocus={true} type="text" {...register("otherGoals")} placeholder={label} />
            ) : (
              label
            )}
          </Checkable>
        ))}
      </Grid>
    </Step>
  );
}

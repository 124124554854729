/* eslint-disable react/require-optimization */
import * as React from "react";
import {
  RefetchOptions,
  RelayRefetchProp,
  createRefetchContainer,
  commitMutation,
  graphql,
} from "react-relay";
import styled from "styled-components";
import { getCssValue } from "app/lib/cssValues";
import classNames from "classnames";

import Environment from "app/lib/relay/environment";
import cable from "app/lib/cable";
import permissions from "app/lib/permissions";

import Favorite from "app/components/icons/Favorite";
import Emojify from "app/components/shared/Emojify";
import Dropdown from "app/components/shared/Dropdown";
import Icon from "app/components/shared/Icon";
import PipelineStatus from "app/components/shared/PipelineStatus";

import Status from "./Status";
import Metrics from "./Metrics";
import Tag from "./Tag";
import Graph from "./Graph";
import BranchSettingsDialog from "./BranchSettingsDialog";
import { BAR_HEIGHT_MINIMUM, GRAPH_HEIGHT, GRAPH_WIDTH } from "./constants";

import EmojiAvatar from "app/components/shared/EmojiAvatar";

type Props = {
  pipeline: any;
  includeGraphData: boolean;
  relay: RelayRefetchProp;
  onTagClick: (tag: string) => void;
};

type State = {
  showingMenu: boolean;
  showingBranchSettingsDialog: boolean;
};

const PipelineActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  background-color: ${getCssValue("--slate-100")};
  border-radius: 4px;
  cursor: pointer;
`;

const PipelineContainer = styled.div.attrs({
  "data-testid": "pipeline",
})`
  min-height: 82px;
  min-height: 120px;
  border-radius: 5px;

  .pipeline-actions {
    width: 100%;
    opacity: 0;
    transition: opacity 0.2s;
    will-change: opacity;
  }

  .pipeline-status {
    transition: opacity 0.3s;
    will-change: opacity;
  }

  ${PipelineActionButton} {
    transform: scale(0);
    transition: transform 0.3s;
    will-change: transform;
  }

  &:hover,
  &:focus,
  &:active,
  &:focus-visible,
  &:focus-within {
    .pipeline-actions {
      opacity: 1;
    }
    .pipeline-status {
      opacity: 0;
    }
    ${PipelineActionButton} {
      transform: scale(1);
    }
  }
`;

const PipelineActionIcon = styled(Icon)`
  color: ${getCssValue("--charcoal-700")};
`;

class Pipeline extends React.Component<Props, State> {
  state = {
    showingMenu: false,
    showingBranchSettingsDialog: false,
  };

  actionsDropdown: Dropdown | null | undefined;

  subscription: any;

  componentDidMount() {
    this.subscription = cable.subscriptions.create(
      { channel: "Pipelines::PipelineChannel", uuid: this.props.pipeline.uuid },
      {
        component: this,
        received({ event }) {
          if (event === "stats:changed") {
            this.component.reload({ force: true });
          }
        },
      },
    );
  }

  componentWillUnmount() {
    // Jest mocks subscription creation, which means we won't have a
    // subscription here during tests
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  render() {
    return (
      <PipelineContainer
        data-pipeline={this.props.pipeline.uuid}
        className="antialiased bg-white depth flex items-center xs-px4 px6 gap3 md-gap4"
      >
        <div className="flex flex-none items-center">
          <EmojiAvatar emoji={this.props.pipeline.emoji} color={this.props.pipeline.color} />
        </div>

        <div className="flex-auto min-w-0">
          <a
            href={this.props.pipeline.url}
            className="flex flex-auto min-w-0 items-center text-decoration-none color-inherit"
          >
            <div className="truncate max-w-full">
              <div className="flex items-center">
                <h2
                  className="inline m-0 leading-tight black semi-bold truncate max-w-full"
                  style={{ fontSize: "16px" }}
                >
                  <Emojify text={this.props.pipeline.name} />
                </h2>
                <PipelineStatus public={this.props.pipeline.public} />
              </div>
              {this.renderDescription()}
            </div>
          </a>

          {this.renderTags()}
        </div>

        {this.renderGraph()}

        <div className="flex flex-none items-center justify-start max-md:sr-only">
          <Metrics pipeline={this.props.pipeline} />
        </div>

        <div className="relative flex-none" style={{ width: "44px", height: "60px" }}>
          <div className="pipeline-status absolute top-0 left-0">
            <Status pipeline={this.props.pipeline} />
          </div>

          <div className="pipeline-actions absolute top-0 right-0 bg-white">
            {this.renderActions()}
          </div>
        </div>
      </PipelineContainer>
    );
  }

  renderDescription() {
    if (this.props.pipeline.description) {
      return (
        <div
          className="truncate max-w-full dark-gray"
          style={{ marginTop: 3, color: getCssValue("--charcoal-500") }}
        >
          <Emojify className="text-sm font-normal" text={this.props.pipeline.description} />
        </div>
      );
    }
  }

  renderTags() {
    if (this.props.pipeline.tags.length) {
      return (
        <div className="flex flex-wrap items-center mt-1 gap0.5">
          {this.props.pipeline.tags.map((tag) => (
            <Tag key={tag.label} tag={tag} onClick={this.props.onTagClick} />
          ))}
        </div>
      );
    }
  }

  renderGraph() {
    // Toggle between showing the graph, or showing a placeholder until the
    // data is finally loaded in.

    let graph;

    if (this.props.includeGraphData) {
      graph = <Graph pipeline={this.props.pipeline} />;
    } else {
      graph = (
        <div style={{ height: GRAPH_HEIGHT, width: GRAPH_WIDTH }} className="flex">
          <div
            style={{
              height: BAR_HEIGHT_MINIMUM,
              width: "100%",
              marginTop: "auto",
            }}
            className="bg-gray animation-loading-bar"
          />
        </div>
      );
    }

    return (
      <div className="flex-none max-lg:sr-only">
        <div className="text-xs font-normal dark-gray mb-1 leading-none">
          <Icon icon="code" style={{ width: 10, height: 10 }} />
          {this.props.pipeline.defaultBranch || "All Builds"}
        </div>
        {graph}
      </div>
    );
  }

  renderActions() {
    // Make sure we're allowed to favorite this pipeline
    const favoriteButton = permissions(this.props.pipeline.permissions).check({
      allowed: "pipelineFavorite",
      render: () => {
        return window.Features.BuildsUsabilityPipelineRowIconsAndStyles ? (
          <PipelineActionButton
            className="unstyled-button no-title-tooltip"
            aria-label={this.props.pipeline.favorite ? "Remove bookmark" : "Bookmark"}
            title={this.props.pipeline.favorite ? "Remove bookmark" : "Bookmark"}
            onClick={this.handleFavoriteClick}
          >
            <PipelineActionIcon
              icon={this.props.pipeline.favorite ? "bookmark-strike" : "bookmark"}
              style={{ height: 24, width: 24 }}
            />
          </PipelineActionButton>
        ) : (
          <button
            key="favorite"
            className="btn p-0 dark-gray leading-none"
            onClick={this.handleFavoriteClick}
          >
            <Favorite favorite={this.props.pipeline.favorite} />
          </button>
        );
      },
    });

    if (window.Features.BuildsUsabilityPipelineRowIconsAndStyles) {
      //TODO when removing the `BuildsUsabilityPipelineRowIconsAndStyles` feature flag:
      // - remove all code in this function below this return block
      // - clean up the BranchSettingsDialog state and component
      return (
        <div className="flex flex-none flex-col justify-center items-center ml-auto gap1">
          {favoriteButton}
          <PipelineActionButton
            as="a"
            className="no-title-tooltip"
            title="All builds"
            href={`${this.props.pipeline.url}/builds`}
          >
            <PipelineActionIcon icon="table-3-row" style={{ height: 24, width: 24 }} />
          </PipelineActionButton>
        </div>
      );
    }

    // Make sure we can perform the actions inside the dropdown
    const dropdownActions = permissions(this.props.pipeline.permissions).collect(
      {
        always: true,
        render: () => {
          return (
            <a
              key="builds-view"
              href={`${this.props.pipeline.url}/builds`}
              className="btn block hover-lime"
            >
              View Builds
            </a>
          );
        },
      },
      {
        allowed: "pipelineUpdate",
        render: () => {
          return (
            <button
              key="branch-settings"
              className="btn block hover-lime"
              onClick={this.handleBranchSettingsShow}
            >
              Branch Settings
            </button>
          );
        },
      },
      {
        allowed: "pipelineUpdate",
        render: () => {
          return (
            <a
              key="pipeline-settings"
              className="btn block hover-lime"
              href={`${this.props.pipeline.url}/settings`}
            >
              Pipeline Settings
            </a>
          );
        },
      },
    );

    // Only render the dropdown button if there's something to put inside it
    let dropdownButton;
    if (dropdownActions.length > 0) {
      dropdownButton = (
        <Dropdown
          key="dropdown"
          // @ts-expect-error - TS2769 - No overload matches this call.
          align="center"
          width={150}
          onToggle={this.handleMenuToggle}
          ref={(actionsDropdown) => (this.actionsDropdown = actionsDropdown)}
        >
          <button
            className="btn p-0 gray hover-dark-gray no-title-tooltip"
            aria-label="More Actions"
          >
            <Icon icon="menu" className={classNames({ "dark-gray": this.state.showingMenu })} />
          </button>
          {dropdownActions}
        </Dropdown>
      );
    }

    let branchSettingsDialog;
    if (this.props.pipeline.permissions.pipelineUpdate.allowed) {
      branchSettingsDialog = (
        <BranchSettingsDialog
          isOpen={this.state.showingBranchSettingsDialog}
          onRequestClose={this.handleBranchSettingsRequestClose}
          pipeline={this.props.pipeline}
        />
      );
    }

    return (
      <div className="flex flex-none flex-col justify-center ml-auto pr-2">
        {favoriteButton}
        {favoriteButton && dropdownButton && <div className="mb-1" />}
        {dropdownButton}
        {branchSettingsDialog}
      </div>
    );
  }

  reload = (option?: RefetchOptions) => {
    const { pipeline, includeGraphData } = this.props;
    this.props.relay.refetch(
      {
        slug: `${pipeline.organization.slug}/${pipeline.slug}`,
        includeGraphData,
      },
      null,
      null,
      option,
    );
  };

  handleWebsocketEvent = (payload: any) => {
    if (payload.subevent === "project:updated" && payload.graphql.id === this.props.pipeline.id) {
      this.reload({ force: true });
    }
  };

  handleBranchSettingsShow = () => {
    if (this.state.showingMenu && this.actionsDropdown) {
      this.actionsDropdown.setShowing(false);
      this.setState({ showingMenu: false });
    }

    this.setState({ showingBranchSettingsDialog: true });
  };

  handleBranchSettingsRequestClose = () => {
    this.setState({ showingBranchSettingsDialog: false });
  };

  handleMenuToggle = (visible: any) => {
    this.setState({ showingMenu: visible });
  };

  handleFavoriteClick = () => {
    const environment = Environment.get();
    const { pipeline } = this.props;
    const input = { id: pipeline.id, favorite: !pipeline.favorite } as const;

    commitMutation(environment, {
      mutation: graphql`
        mutation PipelineFavoriteMutation($input: PipelineFavoriteInput!) {
          pipelineFavorite(input: $input) {
            pipeline {
              favorite
            }
          }
        }
      `,
      variables: { input },
      optimisticResponse: { pipelineFavorite: { pipeline: input } },
    });
  };
}

/*
Disabling @graphql-eslint/no-deprecated here since public is deprecated
visibility is prefered over public
*/
export default createRefetchContainer(
  Pipeline,
  {
    pipeline: graphql`
      fragment Pipeline_pipeline on Pipeline
      @argumentDefinitions(includeGraphData: { type: "Boolean!" }) {
        ...Status_pipeline
        ...Metrics_pipeline
        ...BranchSettingsDialog_pipeline
        ...Graph_pipeline @arguments(includeGraphData: $includeGraphData)
        id
        uuid
        name
        slug
        #eslint-disable-next-line @graphql-eslint/no-deprecated
        public
        description
        defaultBranch
        url
        favorite
        color
        emoji
        tags {
          ...Tag_tag
          label
        }
        permissions {
          pipelineFavorite {
            allowed
          }
          pipelineUpdate {
            allowed
          }
        }
        organization {
          slug
        }
      }
    `,
  },
  graphql`
    query PipelineRefetchQuery($slug: ID!, $includeGraphData: Boolean!) {
      pipeline(slug: $slug) {
        ...Pipeline_pipeline @arguments(includeGraphData: $includeGraphData)
      }
    }
  `,
);

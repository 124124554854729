/* eslint-disable id-length */
import moment from "moment";
import { get, maxBy, minBy, sumBy } from "lodash";
import { DefaultDataPoint } from "chart.js";

import { getMicrosecondDurationFormattedString } from "app/lib/date";
import { TimePeriod } from "../Renderer";

import { ThemeDefaultTrend, ThemeNegativeTrend } from "../Theme";

export function getLatestValue<T = DefaultDataPoint<"line">>(data: T[], attribute = "y") {
  return data.at(-1)?.[attribute] ?? 0;
}

export function getOldestValue<T = DefaultDataPoint<"line">>(data: T[], attribute = "y") {
  return data.at(0)?.[attribute] ?? 0;
}

export function calculateMin<T = DefaultDataPoint<"line">>(data: T[], attribute = "y") {
  return get(minBy(data, attribute), attribute);
}

export function calculateMax<T = DefaultDataPoint<"line">>(data: T[], attribute = "y") {
  return get(maxBy(data, attribute), attribute);
}

export function calculateSum<T = DefaultDataPoint<"line">>(data: T[], attribute = "y") {
  return sumBy(data, attribute);
}

export function calculateAverage<T = DefaultDataPoint<"line">>(data: T[], attribute = "y") {
  return sumBy(data, attribute) / data.length;
}

export function formatDuration(ms: number) {
  if (ms === 0) {
    return 0;
  }

  return getMicrosecondDurationFormattedString(ms * 1000);
}

export function formatTooltipDateString(time: number) {
  return moment(time).format("MMM D, HH:mm"); // Jan 1, 18:35
}
export function calculatePercentage(a: number, b: number) {
  if (b === 0) {
    return null;
  }

  return (a / b) * 100;
}

export function formatPercentage(percentage: number | null, nullText = "–") {
  if (percentage === null) {
    return nullText;
  }

  return new Intl.NumberFormat("default", {
    style: "percent",
  }).format(percentage / 100);
}

export function getTimePeriodString(timePeriod: TimePeriod) {
  switch (timePeriod) {
    case TimePeriod.Hour:
      return "Last hour";
    case TimePeriod.Day:
      return "Last 24 hours";
    case TimePeriod.Week:
      return "Last 7 days";
  }
}

export function getThemeFromDelta(delta: number | null, invertTheme = false) {
  const positiveTrend = (delta ?? 0) >= 0;

  if (invertTheme) {
    return positiveTrend ? ThemeNegativeTrend : ThemeDefaultTrend;
  }

  return positiveTrend ? ThemeDefaultTrend : ThemeNegativeTrend;
}

export enum Scope {
  Queue = "queue",
  Cluster = "cluster",
  Organization = "organization",
}

export function labelHeader(scope: Scope, queueLabel = "Queue") {
  switch (scope) {
    case "queue":
      return queueLabel;
    case "cluster":
      return "Queue";
    case "organization":
      return "Cluster";
  }
}

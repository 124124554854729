import React from "react";
import { Link, LinkProps } from "react-router-dom";

type CommonProps = {
  className?: string;
  children: React.ReactNode;
};

type LinkPropsWithTo = CommonProps &
  Omit<LinkProps, "to"> & {
    to: string | null | undefined;
    newTab?: boolean;
  };

type DivPropsWithoutTo = React.HTMLAttributes<HTMLDivElement> & CommonProps;

type MaybeLinkProps = LinkPropsWithTo | DivPropsWithoutTo;
/**
 * Conditionally render a Link or a div based on the presence and value of the 'to' prop.
 * @returns A <Link/> if 'to' is provided (and the value is truthy), otherwise a <div />.
 */
export default function MaybeLink(props: MaybeLinkProps) {
  if ("to" in props && props.to) {
    const { to, newTab = false, ...linkProps } = props;

    const additionalProps = newTab ? { rel: "noopener noreferrer", target: "_blank" } : {};

    return (
      <Link to={to} {...linkProps} {...additionalProps}>
        {props.children}
      </Link>
    );
  }

  // We've already checked that 'to' is not provided, so we can safely cast the props to DivPropsWithoutTo
  const divProps = props as DivPropsWithoutTo;
  return <div {...divProps}>{props.children}</div>;
}

import * as React from "react";

const Nib = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>((props, ref) => (
  <svg {...props} ref={ref} viewBox="0 0 16 8" fill="none">
    <g>
      <path
        d="M14.5 0.34314L8.17157 6.67157L1.84314 0.343139L8.17157 -5.98529L14.5 0.34314Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        d="M8.87866 7.37866C8.48814 7.76919 7.85497 7.76919 7.46445 7.37866L1.13602 1.05023L1.84313 0.343124L8.17155 6.67155L14.5 0.343125L15.2071 1.05023L8.87866 7.37866Z"
        fill="black"
        fillOpacity="0.1"
      />
    </g>
    <path d="M13.8477 1L2.5 0.999999L1.5 -1.16689e-06L14.8477 0L13.8477 1Z" fill="white" />
  </svg>
));

Nib.displayName = "Nib";

export { Nib };

/* eslint-disable id-length */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { JSONSchemaForBuildkitePipelineConfigurationFiles as Pipeline } from "./schema";

import * as BlockStep from "./Step/BlockStep";
import * as InputStep from "./Step/InputStep";
import * as CommandStep from "./Step/CommandStep";
import * as WaitStep from "./Step/WaitStep";

import schema from "./schema.json";
import { Validator, Schema } from "jsonschema";
import YAML from "yaml";
import { DependencyType, Step, Type } from "app/lib/pipeline/Step";

type StepSchema = Pipeline["steps"][number];

// A parsed step with some dependency information
type StepWithDependsOn = Step & {
  key?: string;
  depends_on?:
    | string
    | (
        | string
        | {
            step?: string | undefined;
            allow_failure?: boolean | undefined;
          }
      )[]
    | null;
};

interface Dependency {
  type: DependencyType;
  source: string;
  target: string;
}

let nodeCounter = 0;
function generateStepUUID(): string {
  nodeCounter++;
  return `${nodeCounter}`;
}

function initialiseStepUUIDs() {
  nodeCounter = 0;
}

/**
 * Validate and parse pipeline YAML into normalised steps with dependencies.
 */
export function parseYAML(yaml: string): Step[] {
  const pipeline = YAML.parse(yaml);
  new Validator().validate(pipeline, schema as Schema, {
    throwError: true,
  });
  return transform(pipeline);
}

/**
 * Transform pipeline configuration into normalised steps with dependencies.
 */
function transform(config: Pipeline): Step[] {
  initialiseStepUUIDs();

  const steps: Step[] = [];

  config.steps.forEach((stepConfig: StepSchema) => {
    const step = createStep(stepConfig);
    if (step) {
      steps.push(step);
    }
  });

  const dependencies = [...createExplicitDependencies(steps), ...createImplicitDependencies(steps)];

  // Replace `depends_on` (keys) with resolved `dependencies` (uuids)
  dependencies.forEach((dependency) => {
    const source = steps.find((step) => step.uuid === dependency.source);
    const target = steps.find((step) => step.uuid === dependency.target);

    if (source && target) {
      target.dependencies.push({ uuid: source.uuid, type: dependency.type });

      // Drop some of the dependency properties for the final output
      delete (target as any).depends_on;
      delete (source as any).depends_on;
      delete (target as any).key;
      delete (source as any).key;
    }
  });

  return steps;
}

/**
 * Wait and block steps provide implicit dependencies.
 *
 * - A wait or block step, is dependent on all previous steps completing successfully;
 * - All steps following up until the next wait or block, are dependent on the step;
 *
 * @see https://buildkite.com/docs/pipelines/dependencies#implicit-dependencies-with-wait-and-block
 */
function createImplicitDependencies(steps: Step[]): Dependency[] {
  let output: Dependency[] = [];

  // Find all the wait/block steps
  const waitSteps = steps.filter((node) => node.type === Type.Wait || node.type === Type.Block);

  // Create dependencies for every node _before_ each wait/block node.
  waitSteps.reduce((fromIndex, waitStep) => {
    const idx = steps.indexOf(waitStep);
    const implicitDeps = steps.slice(fromIndex, idx);

    implicitDeps.forEach((step) => {
      const dependecy = createDependency(step.uuid, waitStep.uuid, DependencyType.Gate);
      output = [...output, dependecy];
    });

    return idx + 1;
  }, 0);

  // Create dependencies for every node _after_ each wait/block node.
  waitSteps.reduceRight((fromIndex, waitStep) => {
    const idx = steps.indexOf(waitStep) + 1;
    const implicitDeps = steps.slice(idx, fromIndex);

    implicitDeps.forEach((step) => {
      const dependecy = createDependency(waitStep.uuid, step.uuid, DependencyType.Gate);
      output = [...output, dependecy];
    });

    return idx;
  }, steps.length);

  return output;
}

/**
 * Explicit dependencies are defined using the `depends_on` key.
 *
 * - depends_on can be either a string;
 * - an array of strings;
 * - or an array of objects with `step` keys
 *
 * @see https://buildkite.com/docs/pipelines/dependencies#defining-explicit-dependencies
 */
function createExplicitDependencies(steps: StepWithDependsOn[]): Dependency[] {
  let output: Dependency[] = [];

  function findStepByKey(key: string): Step {
    const step = steps.find((step) => step.key === key);
    if (!step) {
      throw new Error(`Invalid step key: ${key}`);
    }
    return step;
  }

  steps
    .filter((step) => step.depends_on)
    .forEach((step) => {
      let dependencies: Step[] = [];

      if (typeof step.depends_on === "string") {
        const dependency = findStepByKey(step.depends_on);
        dependencies = [dependency];
      }

      if (Array.isArray(step.depends_on)) {
        dependencies = step.depends_on.map((dependency) => {
          if (typeof dependency === "string") {
            return findStepByKey(dependency);
          } else if (dependency.step) {
            return findStepByKey(dependency.step);
          }
          throw new Error(`Invalid depends_on object: ${JSON.stringify(dependency)}`);
        });
      }

      output = [
        ...output,
        ...dependencies.map((dependency) =>
          createDependency(dependency.uuid, step.uuid, DependencyType.Direct),
        ),
      ];
    });

  return output;
}

/**
 * Create a step dependency
 */
function createDependency(from: string, to: string, type: DependencyType): Dependency {
  return {
    source: from,
    target: to,
    type,
  };
}

/**
 * Create a parse pipeline step
 */
function createStep(config: StepSchema): StepWithDependsOn | null {
  const step = parseStep(config) as StepWithDependsOn;

  if (!step) {
    console.error(`Unknown node type for step:`, config);
    return null;
  }

  // Give the node a unique ID
  step.uuid = generateStepUUID();

  if (typeof config === "object" && "if" in config) {
    step.if = config.if || "";
  }

  if (typeof config === "object" && "key" in config) {
    step.key = config.key;
  }

  if (typeof config === "object" && "depends_on" in config) {
    step.depends_on = config.depends_on;
  }

  return step;
}

// Infer type and parse the step config.
export function parseStep(step: StepSchema) {
  if (WaitStep.isWaitStep(step)) {
    return WaitStep.parseWaitStep(step);
  }

  if (WaitStep.isStringWaitStep(step)) {
    return WaitStep.parseStringWaitStep(step);
  }

  if (BlockStep.isBlockStep(step)) {
    return BlockStep.parseBlockStep(step);
  }

  if (BlockStep.isStringBlockStep(step)) {
    return BlockStep.parseStringBlockStep(step);
  }

  if (InputStep.isInputStep(step)) {
    return InputStep.parseInputStep(step);
  }

  if (InputStep.isStringInputStep(step)) {
    return InputStep.parseStringInputStep(step);
  }

  if (CommandStep.isCommandStepSchema(step)) {
    return CommandStep.parseCommandStep(step);
  }

  return null;
}

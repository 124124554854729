/* eslint-disable react/jsx-no-bind */
import { StepsDisplayMode, useBuildPreferencesStore } from "../lib/useBuildPreferencesStore";
import React, { useMemo } from "react";
import CanvasPage from "./canvas";
import TablePage from "./table";
import WaterfallPage from "./waterfall";
import FilterByDropdown from "../components/Sidebar/FilterByDropdown";
import { useMobileBreakpoint } from "../lib/useBreakpoints";
import ToolbarButton, { ToolbarButtonGroup } from "../components/ToolbarButton";
import { MobileStepsView } from "../components/MobileStepsView";
import Badge from "app/components/shared/Badge";
import { useBuild } from "../lib/BuildContext";

const displayModes = [
  { id: StepsDisplayMode.Canvas, element: CanvasPage, label: "Canvas" },
  { id: StepsDisplayMode.Table, element: TablePage, label: "Table" },
  {
    id: StepsDisplayMode.Waterfall,
    element: WaterfallPage,
    label: "Waterfall",
  },
];

export default function StepsPage() {
  const { store } = useBuild();
  const isMobile = useMobileBreakpoint();

  const [currentModeId, setDisplayMode] = useBuildPreferencesStore((state) => [
    state.stepsDisplayMode,
    state.setStepsDisplayMode,
  ]);

  const displayMode = useMemo(() => {
    const mode = displayModes.find((view) => view.id === currentModeId);
    if (!mode) {
      throw new Error(`Invalid display mode: ${currentModeId}`);
    }
    return mode;
  }, [currentModeId]);

  return (
    <div className="flex-auto min-w-0 flex flex-col">
      <div className="flex justify-between px-2 py-1 bg-white border-b border-b-gray-400 shadow-sm z-20">
        <FilterByDropdown />

        <div className="hidden md:!block">
          <ToolbarButtonGroup>
            {displayModes.map((mode) => (
              <ToolbarButton
                key={mode.id}
                isActive={currentModeId === mode.id}
                onClick={() => setDisplayMode(mode.id)}
                className="py-1 px-2 relative group border border-transparent aria-checked:border-purple-300 aria-checked:shadow-sm"
              >
                {mode.label}

                {mode.id === StepsDisplayMode.Waterfall && !store?.waterfallAvailable && (
                  <Badge className="white bg-purple">Upgrade</Badge>
                )}
              </ToolbarButton>
            ))}
          </ToolbarButtonGroup>
        </div>
      </div>

      {isMobile ? (
        <div className="mt-1">
          <MobileStepsView />
        </div>
      ) : (
        React.createElement(displayMode.element)
      )}
    </div>
  );
}

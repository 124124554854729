import FeedItem from "./FeedItem";
import Card from "./Card";
import logo from "app/components/shared/logo.svg";

export default function Support({ vendors }: { vendors: string[] }) {
  if (!vendors.length) {
    return null;
  }

  return (
    <Card>
      <FeedItem
        imageShape="circle"
        backgroundColor="#ddfffa"
        image={<img src={logo} width={24} />}
        title="We're here for you"
      >
        Use our docs, Slack community, and support to move quickly
      </FeedItem>
    </Card>
  );
}

import {
  HoverCard,
  HoverCardTrigger,
  HoverCardContent,
  HoverCardPortal,
} from "app/components/shared/HoverCard";
import Icon from "app/components/shared/Icon";

interface InfoTooltipProps {
  title: string;
  description: string;
  items?: Array<string>;
}

export function InfoTooltip({ title, description, items }: InfoTooltipProps) {
  return (
    <HoverCard>
      <HoverCardTrigger asChild={true}>
        <button type="button" className="cursor-help">
          <Icon icon="info-circle" className="w-5 h-5 opacity-40" />
        </button>
      </HoverCardTrigger>
      <HoverCardPortal>
        <HoverCardContent size="max-lg" className="w-[320px] font-normal text-sm">
          <div className="max-w-xs">
            <p className="font-medium mb-1">{title}</p>
            <p className="text-sm text-charcoal-400">{description}</p>
            {items && (
              <ul className="text-sm text-charcoal-400 mt-2">
                {items.map((item, index) => (
                  <li key={index}>• {item}</li>
                ))}
              </ul>
            )}
          </div>
        </HoverCardContent>
      </HoverCardPortal>
    </HoverCard>
  );
}

import { createFragmentContainer, graphql } from "react-relay";
import AgentBadge from "app/components/AgentBadge";
import CodeBlock from "./legacy-code-block";
import JobEvent from "../JobEvent";

type Props = {
  event: any;
  previousTimestamp: string | null | undefined;
};

function JobEventBuildStepUploadCreated({
  event: {
    buildStepUpload,
    timestamp,
    actor: { node: agent },
  },
  previousTimestamp,
}: Props) {
  if (!buildStepUpload) {
    return null;
  }

  return (
    <JobEvent timestamp={timestamp} previousTimestamp={previousTimestamp}>
      <JobEvent.Label>
        <AgentBadge agent={agent} /> Uploaded Pipeline Steps
      </JobEvent.Label>
      <JobEvent.Content>
        <CodeBlock language="yaml">{buildStepUpload.definition.yaml}</CodeBlock>
      </JobEvent.Content>
    </JobEvent>
  );
}

export default createFragmentContainer(JobEventBuildStepUploadCreated, {
  event: graphql`
    fragment JobEventBuildStepUploadCreated_event on JobEvent {
      ... on JobEvent {
        timestamp

        actor {
          node {
            ...AgentBadge_agent
          }
        }

        ... on JobEventBuildStepUploadCreated {
          buildStepUpload {
            definition {
              yaml
            }
          }
        }
      }
    }
  `,
});

import { useBuild } from "app/components/build/Show/lib/BuildContext";
import V2TestEngine from "app/components/build/Show/V2TestEngine";

export default function Tests() {
  const { build } = useBuild();

  if (!build) {
    throw new Error("Missing build context");
  }

  return (
    <div className="flex-auto min-w-0 relative">
      <div className="h-full w-full absolute overflow-y-auto p-4">
        <V2TestEngine build={build} />
      </div>
    </div>
  );
}

import { useState } from "react";
import { v4 } from "uuid";

import Icon from "app/components/shared/Icon";
import Colors from "app/constants/analytics/Colors";

const ICONS = {
  FAILED: "FAILED",
  PASSED: "PASSED",
  PENDING: "PENDING",
  UNDEFINED: "UNDEFINED",
} as const;

const SIZE_DEFINITIONS = {
  Regular: {
    size: 32,
  },
  Small: {
    size: 20,
  },
  XSmall: {
    size: 13,
  },
} as const;

const RESULT_COLORS = {
  [ICONS.FAILED]: Colors.FAIL_RED,
  [ICONS.PASSED]: Colors.PASS_GREEN,
  [ICONS.PENDING]: Colors.STORM_GRAY,
  [ICONS.UNDEFINED]: Colors.STORM_GRAY,
} as const;

type Props = {
  icon: keyof typeof ICONS;
  size: "XSmall" | "Small" | "Regular";
  result: string;
};

const renderPaths = (
  icon:
    | string
    | keyof {
        FAILED: string;
        PASSED: string;
        PENDING: string;
        UNDEFINED: string;
      },
  uuid: string,
) => {
  const applyStroke = {
    fill: "none",
    stroke: RESULT_COLORS[icon],
    strokeWidth: 2,
  } as const;

  const maskId = `ResultIcon_${uuid}_mask`;

  let defs;
  let content;

  switch (icon) {
    case "FAILED":
      content = (
        <g transform="translate(10.000000, 10.000000)" {...applyStroke}>
          <path d="M0.600275489,0.600275489 L11.3997245,11.3997245" />
          <path d="M11.3997245,0.600275489 L0.600275489,11.3997245" />
        </g>
      );
      break;
    case "PASSED":
      content = (
        <polyline points="10 17.61 14.38 20.81 21 11.41" {...applyStroke} strokeMiterlimit="10" />
      );
      break;
    case "PENDING":
      defs = (
        <mask id={maskId} x="9" y="9" width="14" height="14" maskUnits="userSpaceOnUse">
          <polygon
            className="animation-spin-slow"
            style={{ transformOrigin: "center" }}
            fill="#fff"
            points="16 16 9 16 9 9 16 9 16 16 23 16 23 23 16 23 16 16"
          />
        </mask>
      );
      content = (
        <g mask={`url(#${maskId})`}>
          <circle cx="16" cy="16" r="6" {...applyStroke} />
        </g>
      );
      break;
    case "UNDEFINED":
    default:
      // @ts-expect-error - TS2783 - 'fill' is specified more than once, so this usage will be overwritten.
      content = <path d="M11,16H21" fill="none" {...applyStroke} />;
  }

  return { defs, content };
};

const ResultIcon = (props: Props) => {
  const [uuid] = useState(v4());

  const { size } = SIZE_DEFINITIONS[props.size];
  const title = `Result: ${props.result}`;
  const outerCircleId = `ResultIcon_${uuid}_circle`;
  const strokeClipPathId = `ResultIcon_${uuid}_strokeClipPath`;

  const { defs, content } = renderPaths(props.icon, uuid);

  if (props.icon === ICONS.FAILED && props.size === "Regular") {
    /* The regular sized `FAILED` result uses a new blob icon
    which does not fit into the generated inline SVG code that
    the other result icons use
    */
    return (
      <Icon
        icon="failed-medium-blob"
        style={{
          width: size,
          height: size,
          fill: "none",
          color: RESULT_COLORS[props.icon],
        }}
        title={title}
      />
    );
  }

  return (
    // @ts-expect-error - TS2322 - Type '{ children: any[]; width: 20 | 32 | 13; height: 20 | 32 | 13; viewBox: string; title: string; }' is not assignable to type 'SVGProps<SVGSVGElement>'.
    <svg width={size} height={size} viewBox="0 0 32 32" title={title}>
      <defs>
        <circle
          id={outerCircleId}
          data-testid="svg-circle"
          fill="none"
          cx="16"
          cy="16"
          r="15"
          stroke={RESULT_COLORS[props.icon]}
          strokeWidth="4"
        />
        <clipPath id={strokeClipPathId}>
          <use xlinkHref={`#${outerCircleId}`} />
        </clipPath>
        {defs}
      </defs>
      <use xlinkHref={`#${outerCircleId}`} clipPath={`url(#${strokeClipPathId})`} />
      {content}
    </svg>
  );
};

export default ResultIcon;

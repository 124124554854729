import { useBuild } from "app/components/build/Show/lib/BuildContext";
import EmojiAvatar from "app/components/shared/EmojiAvatar";
import Emojify from "app/components/shared/Emojify";
import { PipelineSettings } from "./PipelineSettings";
import Badge from "app/components/shared/Badge";

export const PipelineInfo = () => {
  const { build } = useBuild();
  if (!build) {
    throw new Error("Missing build context");
  }

  const { project } = build;

  return (
    <div className="flex min-width-0 max-w-xs">
      <a
        className="flex items-center gap-2 text-inherit hover:text-inherit no-underline group truncate max-w-full"
        href={project.url}
      >
        <EmojiAvatar
          emoji={project.emoji}
          color={project.color}
          className="!h-6 !w-6 [&>svg]:w-4 [&>svg]:h-4 !text-sm"
        />
        <span
          className="text-base/5 font-medium truncate max-w-full group-hover:underline"
          data-testid="projectUrl"
        >
          <Emojify text={project.name} />
        </span>
      </a>

      {project.public && (
        <Badge outline={true} className="text-charcoal-600 ml-1">
          Public
        </Badge>
      )}

      {project.permissions.edit?.allowed && <PipelineSettings className="ml-0.5" />}
    </div>
  );
};

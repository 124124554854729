import { GroupStep } from "app/lib/pipeline";
import { PropsWithChildren } from "react";
import { CollapsibleStepListItem } from "./CollapsibleStepListItem";
import { createStepListItem } from ".";

/**
 * A group step list item.
 */
export function GroupStepListItem({ step }: PropsWithChildren<{ step: GroupStep }>) {
  return (
    <CollapsibleStepListItem step={step}>
      {step.steps?.map((step) => createStepListItem({ step, depth: 1 }))}
    </CollapsibleStepListItem>
  );
}

/**
 * @file Hook and components for virtualizing tables using @tanstack/react-virtual.
 *
 * @remarks
 * To maintain the table layout, spacer rows are added before and after the virtualized items
 * to account for non-rendered items, instead of absolutely positioning rows.
 *
 * @example
 * ```tsx
 * function MyComponent() {
 *   const columns = [
 *     // ...
 *   ];
 *
 *   const exampleItems = [
 *     // ...
 *   ];
 *
 *   const parentRef = useRef(null);
 *
 *   const { virtualizer, virtualItems, heightBeforeItems, heightAfterItems } =
 *     useTableVirtualizer({
 *       items: exampleItems,
 *       getScrollElement: () => parentRef.current,
 *       estimateSize: () => 40,
 *       overscan: 5,
 *     });
 *
 *   return (
 *     <VirtualizedTable ref={parentRef} virtualizer={virtualizer}>
 *       <table>
 *         <thead></thead>
 *
 *         <tbody>
 *           <VirtualizedTableSpacer
 *             height={heightBeforeItems}
 *             colSpan={columns.length}
 *           />
 *
 *           {virtualItems.map((virtualRow) => {
 *             const item = exampleItems[virtualRow.index];
 *
 *             // Render the row...
 *           })}
 *
 *           <VirtualizedTableSpacer
 *             height={heightAfterItems}
 *             colSpan={columns.length}
 *           />
 *         </tbody>
 *       </table>
 *     </VirtualizedTable>
 *   );
 * }
 * ```
 */

import { notUndefined, useVirtualizer, VirtualItem } from "@tanstack/react-virtual";
import classNames from "classnames";
import { forwardRef } from "react";

/**
 * Extends useVirtualizer to virtualize a table.
 */
function useTableVirtualizer<T>({
  items,
  ...options
}: Omit<Parameters<typeof useVirtualizer>[0], "count"> & {
  items: T[];
}): {
  virtualItems: VirtualItem[];
  virtualizer: ReturnType<typeof useVirtualizer>;
  heightBeforeItems: number;
  heightAfterItems: number;
} {
  const virtualizer = useVirtualizer({
    ...options,
    count: items.length,
  });

  const virtualItems = virtualizer.getVirtualItems();

  const [heightBeforeItems, heightAfterItems] =
    virtualItems.length > 0
      ? [
          notUndefined(virtualItems[0]).start - virtualizer.options.scrollMargin,
          virtualizer.getTotalSize() - notUndefined(virtualItems[virtualItems.length - 1]).end,
        ]
      : [0, 0];

  return { virtualizer, virtualItems, heightBeforeItems, heightAfterItems };
}

/**
 * Wraps <table> element to be virtualized.
 */
const VirtualizedTable = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    /** Virtualizer instance from `useTableVirtualizer`. */
    virtualizer: ReturnType<typeof useVirtualizer>;
  }
>(({ className, virtualizer, children, ...props }, ref) => {
  return (
    <div ref={ref} className={classNames("w-full overflow-auto", className)} {...props}>
      <div style={{ height: `${virtualizer.getTotalSize()}px` }}>{children}</div>
    </div>
  );
});
VirtualizedTable.displayName = "VirtualizedTable";

/**
 * Adds space before or after virtualized table items to account for non-rendered items.
 */
function VirtualizedTableSpacer({
  height,
  colSpan,
}: {
  /** Spacer height; `heightBeforeItems` or `heightAfterItems` from `useTableVirtualizer`. */
  height: number;
  /** Number of columns in the table. */
  colSpan: number;
}) {
  if (!height) {
    return null;
  }

  return (
    <tr>
      <td colSpan={colSpan} style={{ height }} />
    </tr>
  );
}

export { useTableVirtualizer, VirtualizedTable, VirtualizedTableSpacer };

import { twMerge } from "tailwind-merge";

interface Props {
  children: React.ReactNode;
  status: React.ReactNode;
  duration?: React.ReactNode;
  className?: string;
  info?: React.ReactNode;
}

export const Label = ({ children, className, status, duration, info }: Props) => {
  return (
    <>
      <div
        className={twMerge(
          "flex-auto min-w-0 flex items-center text-charcoal-700 py-1 text-left",
          className,
        )}
      >
        <div className="truncate max-w-full w-full font-medium space-x-2">{children}</div>
      </div>

      {info && <span className="mr-0.5 shrink-0">{info}</span>}

      <div className="self-stretch flex items-center justify-center shrink-0 gap-1 text-xs">
        {duration}
        {status}
      </div>
    </>
  );
};

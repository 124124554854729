/* eslint-disable react/display-name */

import { useState } from "react";
import { commitMutation, createRefetchContainer, graphql } from "react-relay";

import AutocompleteDialog from "app/components/shared/Autocomplete/Dialog";
import Button from "app/components/shared/Button";
import Emojify from "app/components/shared/Emojify";
import permissions from "app/lib/permissions";
import Environment from "app/lib/relay/environment";
import FlashesStore from "app/stores/FlashesStore";

const Chooser = ({ team, relay, onChoose, disabled }) => {
  const [loading, setLoading] = useState(false);
  const [showingDialog, setShowingDialog] = useState(false);
  const [search, setSearch] = useState(null);

  const handleDialogOpen = () => {
    setLoading(true);

    relay.refetch(
      { teamSelector: `!${team.slug}`, pipelineAddSearch: "" },
      null,
      () => {
        setLoading(false);
      },
      { force: true },
    );

    setShowingDialog(true);
  };

  const handleDialogClose = () => {
    setShowingDialog(false);
    setSearch(null);
  };

  const handlePipelineSearch = (pipelineAddSearch) => {
    setSearch(pipelineAddSearch);

    relay.refetch(
      {
        teamID: team.id,
        pipelineAddSearch: pipelineAddSearch,
        teamSelector: `!${team.slug}`,
      },
      null,
      null,
      { force: true },
    );
  };

  const handlePipelineSelect = (pipeline) => {
    setShowingDialog(false);
    const environment = Environment.get();

    const input = { teamID: team.id, pipelineID: pipeline.id };
    commitMutation(environment, {
      mutation: graphql`
        mutation PipelinesChooserMutation($input: TeamPipelineCreateInput!) {
          teamPipelineCreate(input: $input) {
            clientMutationId
            team {
              id
              pipelines {
                count
              }
            }
          }
        }
      `,
      variables: { input },
      onCompleted: onChoose,
      onError: (error) => {
        FlashesStore.flash(FlashesStore.ERROR, error.message);
      },
    });
  };

  // Logic to render dropdown suggestion
  const renderAutoCompleteSuggestions = () => {
    // Render Loader if we're still loading or org has no pipelines has no edges.
    if (!team.organization?.pipelines || loading) {
      return [<AutocompleteDialog.Loader key="loading" />];
    }

    // If we have pipelines from the response, render them as suggestions
    if (team.organization.pipelines.edges.length > 0) {
      return team.organization.pipelines.edges.map(({ node }) => {
        return [
          <div key={node.id}>
            <strong className="truncate max-w-full semi-bold block" title={node.name}>
              <Emojify text={node.name} />
            </strong>
            <small className="truncate max-w-full dark-gray block" title={node.repository.url}>
              {node.repository.url}
            </small>
          </div>,
          node,
        ];
      });
    } else if (search !== "") {
      // If pipeline is empty but user has searched for a pipeline, render
      return [
        <AutocompleteDialog.ErrorMessage key="error">
          Could not find a pipeline with name <em>{search}</em>
        </AutocompleteDialog.ErrorMessage>,
      ];
    }

    // If none of the above match - The user most likely hasn't searched anything and also has no pipelines.
    // i.e they have no pipelines in their org.
    return [
      <AutocompleteDialog.ErrorMessage key="error">
        Could not find any more pipelines to add
      </AutocompleteDialog.ErrorMessage>,
    ];
  };

  return permissions(team.permissions).check({
    allowed: "teamPipelineCreate",
    render: () => (
      <div>
        <Button className="mb-3" theme="primary" onClick={handleDialogOpen} disabled={disabled}>
          Add Pipeline
        </Button>
        <AutocompleteDialog
          isOpen={showingDialog}
          onRequestClose={handleDialogClose}
          onSearch={handlePipelineSearch}
          onSelect={handlePipelineSelect}
          items={renderAutoCompleteSuggestions()}
          placeholder="Search all pipelines…"
          selectLabel="Add"
          popover={false}
        />
      </div>
    ),
  });
};

const TeamsPipelinesChooserContainer = createRefetchContainer(
  Chooser,
  {
    team: graphql`
      fragment PipelinesChooser_team on Team
      @argumentDefinitions(
        pipelineAddSearch: { type: "String", defaultValue: "" }
        teamSelector: { type: "TeamSelector" }
      ) {
        id
        slug
        organization {
          pipelines(search: $pipelineAddSearch, first: 10, order: RELEVANCE, team: $teamSelector) {
            edges {
              node {
                id
                name
                repository {
                  url
                }
              }
            }
          }
        }

        permissions {
          teamPipelineCreate {
            allowed
          }
        }
      }
    `,
  },
  graphql`
    query PipelinesChooserQuery(
      $team: ID!
      $pipelineAddSearch: String
      $teamSelector: TeamSelector
    ) {
      team(slug: $team) {
        ...PipelinesChooser_team
          @arguments(pipelineAddSearch: $pipelineAddSearch, teamSelector: $teamSelector)
      }
    }
  `,
);

export default TeamsPipelinesChooserContainer;

import * as React from "react";
import * as Turbo from "@hotwired/turbo";
import moment from "moment";
import { DateRange, DayPicker, SelectRangeEventHandler } from "react-day-picker";

const formatDateParam = (date: Date) => moment(date).format("YYYY-MM-DD");
const parseDate = (date?: string) => (date ? moment(date).toDate() : undefined);

type Props = {
  today: string;
  from?: string;
  to?: string;
  buildsFilterPath: string;
};

export function DatePicker(props: Props) {
  const today = parseDate(props.today);
  const from = parseDate(props.from);
  const to = parseDate(props.to);

  const [selectedRange, setSelectedRange] = React.useState<DateRange | undefined>({ from, to });

  const handleSelect: SelectRangeEventHandler = async (range, selectedDay) => {
    // If a range is already selected, clear it and set the selected day as the from date
    if (selectedRange?.from && selectedRange?.to) {
      range = { from: selectedDay, to: undefined };
    }

    setSelectedRange(range);

    if (range?.from && range?.to) {
      const url = new URL(props.buildsFilterPath, window.location.origin);

      url.searchParams.set("created_from", formatDateParam(range.from));
      url.searchParams.set("created_to", formatDateParam(range.to));

      Turbo.visit(url.toJSON());
    }
  };

  return (
    <div id="builds-datepicker">
      <DayPicker
        mode="range"
        selected={selectedRange}
        onSelect={handleSelect}
        defaultMonth={from || today}
        today={today}
        weekStartsOn={1}
        initialFocus={true}
        showOutsideDays={true}
        className="m-0 [--rdp-accent-color:var(--purple-600)] [--rdp-background-color:var(--purple-100)] [--rdp-selected-color:var(--base-0)] [--rdp-cell-size:36px]"
        classNames={{
          day: "rdp-day text-[13px]",
          caption_label: "rdp-caption_label text-charcoal-900 font-medium text-base",
          head_cell:
            "rdp-head_cell font-medium capitalize text-xs text-charcoal-300 no-title-tooltip",
          nav_button: "rdp-nav_button h-9 w-9 p-2.5 no-title-tooltip",
          day_range_middle: "rdp-day_range_middle !bg-purple-100 !text-purple-600",
        }}
        modifiersClassNames={{
          outside: "text-charcoal-300",
          today: "font-semibold",
        }}
      />
    </div>
  );
}

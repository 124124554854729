import { getCssValue } from "app/lib/cssValues";
import { CommandJob } from "app/components/build/Show/lib/types";
import JobGroup from "./jobGroup";

export default function getLabelColor(job: CommandJob | JobGroup) {
  switch (job.state) {
    case "blocked":
      return job.type === "job_group" ? getCssValue("--purple-300") : getCssValue("--gray-500");

    case "pending":
    case "waiting":
    case "waiting_failed":
    case "unblocked":
    case "limited":
    case "scheduled":
    case "assigned":
    case "accepted":
    case "skipped":
    default:
      return getCssValue("--gray-500");

    case "running":
      return getCssValue("--orange-500");

    case "finished":
    case "timed_out":
      if (job.passed) {
        return getCssValue("--green-500");
      }

      return getCssValue("--red-500");

    case "blocked_failed":
    case "unblocked_failed":
    case "canceling":
    case "canceled":
    case "timing_out":
    case "expired":
      return getCssValue("--red-500");
  }
}

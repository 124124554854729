import { PureComponent } from "react";
import { createFragmentContainer, graphql } from "react-relay";
import classnames from "classnames";
import styled from "styled-components";

import FriendlyTime from "app/components/shared/FriendlyTime";
import JobTitle from "app/components/shared/JobTitle";
import Panel from "app/components/shared/Panel";
import PipelineStateIcon from "app/components/shared/PipelineStateIcon";
import { JOB_STATES_DOWNCASED } from "app/constants/JobStates";

const TERMINAL_JOB_STATES = ["finished", "timed_out", "canceled", "expired"];

type JobType = "command" | "manual" | "trigger" | "waiter";

type Props = {
  job: {
    uuid: string;
    state: string;
    passed: boolean;
    scheduledAt: string;
    softFailed: boolean;
    startedAt?: string;
    url: string;
  };
  jobType: JobType;
};

const JobStateIconWrapper = styled.span<Props>`
  display: flex;
  align-items: center;
  color: ${({ job }) => {
    let synthesizedJobState;
    if (TERMINAL_JOB_STATES.includes(job.state)) {
      switch (true) {
        case job.state === "canceled" && job.passed:
          synthesizedJobState = "canceled-passed";
          break;
        case job.state === "canceled":
          synthesizedJobState = "canceled";
          break;
        case job.passed:
          synthesizedJobState = "passed";
          break;
        case job.softFailed:
          synthesizedJobState = "soft_failed";
          break;
        default:
          synthesizedJobState = "failed";
      }
    } else {
      synthesizedJobState = job.state;
    }

    switch (synthesizedJobState) {
      case "passed":
        return "var(--green-500)";
      case "running":
        return "var(--orange-500)";
      case "canceled":
      case "canceling":
        return "var(--gray-900)";
      case "canceled-passed":
        return "var(--green-500)";
      case "timing_out":
      case "failed":
      case "soft_failed":
        return "var(--red-500)";
      default:
        return "var(--gray-900)";
    }
  }};
`;

class AgentJobRow extends PureComponent<Props> {
  render() {
    const { job, jobType } = this.props;

    // This is gross, but smoothes over differences between the GraphQL and REST APIs
    const jobForPipelineStateIcon = {
      ...job,
      state: JOB_STATES_DOWNCASED[job.state],
      type: jobType,
    } as const;

    return (
      <Panel.RowLink href={job.url} className="p-4">
        <div className="flex font-normal leading-tight items-center" data-testid="job-row">
          {/* @ts-expect-error - TS2769 - No overload matches this call. */}
          <JobStateIconWrapper job={jobForPipelineStateIcon}>
            <PipelineStateIcon job={jobForPipelineStateIcon} />
          </JobStateIconWrapper>
          <div className={classnames("flex-auto min-w-0 ml-2")}>
            <JobTitle className="block flex-auto min-w-0" job={job} />
          </div>
          <FriendlyTime
            clickable={false}
            className="dark-gray mr-2"
            value={job.startedAt || job.scheduledAt}
            capitalized={false}
          />
        </div>
      </Panel.RowLink>
    );
  }
}

export default createFragmentContainer(AgentJobRow, {
  job: graphql`
    fragment AgentJobRow_job on JobTypeCommand {
      ...JobTitle_job
      uuid
      state
      passed
      scheduledAt
      startedAt
      url
      softFailed
    }
  `,
});

import * as React from "react";
import DocumentTitle from "react-document-title";
import { QueryRenderer, createRefetchContainer, graphql } from "react-relay";
import Badge from "app/components/shared/Badge";
import Button from "app/components/shared/Button";
import Panel from "app/components/shared/Panel";
import PageHeader from "app/components/shared/PageHeader";
import Dialog from "app/components/shared/Dialog";
import TwoFactorConfigure from "app/components/user/TwoFactor/TwoFactorConfigure";
import TwoFactorDelete from "app/components/user/TwoFactor/TwoFactorDelete";
import RecoveryCodeList from "app/components/RecoveryCodeList"; // eslint-disable-line
import RecoveryCodes from "./RecoveryCodes";
import Environment from "app/lib/relay/environment";
import SectionLoader from "app/components/shared/SectionLoader";
import RelayModernPreloader from "app/lib/RelayModernPreloader";

function AuthenticatorUrl({ name, url }: { name: string; url: string }) {
  return (
    <a
      className="lime hover-lime no-underline hover-underline"
      key={name}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {name}
    </a>
  );
}

const AUTHENTICATORS = [
  { name: "1Password", url: "https://1password.com" },
  { name: "OTP Auth", url: "https://cooperrs.de/otpauth.html" },
  {
    name: "Duo Mobile",
    url: "https://duo.com/product/trusted-users/two-factor-authentication/duo-mobile",
  },
  { name: "Authy", url: "https://authy.com" },
  {
    name: "Google Authenticator",
    url: "https://support.google.com/accounts/answer/1066447",
  },
];

const AUTHENTICATOR_LIST = AUTHENTICATORS.reduce<Array<any>>(
  (memo, authenticator, index, { length }) => [
    ...memo,
    <AuthenticatorUrl {...authenticator} key={authenticator.name} />,
    index < length - 1 ? (index < length - 2 ? ", " : " and ") : "",
  ],
  [],
);

const TwoFactorQuery = graphql`
  query TwoFactorQuery {
    viewer {
      ...TwoFactor_viewer
    }
  }
`;

type Props = {
  viewer: any;
};

type State = {
  configureDialogOpen: boolean;
  recoveryCodeDialogOpen: boolean;
  recoveryCodeListDialogOpen: boolean;
  deactivateDialogOpen: boolean;
};

class TwoFactor extends React.PureComponent<Props, State> {
  state = {
    configureDialogOpen: false,
    recoveryCodeDialogOpen: false,
    recoveryCodeListDialogOpen: false,
    deactivateDialogOpen: false,
  };

  get recoveryCodesRemaining(): number | null {
    if (this.props.viewer.totp) {
      return this.props.viewer.totp.recoveryCodes.codes.filter(({ consumed }) => !consumed).length;
    }
    return null;
  }

  hasTotp(): boolean {
    if (this.props.viewer.totp) {
      return true;
    }
    return false;
  }

  renderActivateSection() {
    return (
      <Panel className="mb-4">
        <Panel.Section>
          <p>
            Once 2FA is enabled on your Buildkite account, the only way to login to your account is
            by knowing both your password and a unique code generated by a third-party application
            such as {AUTHENTICATOR_LIST}
          </p>

          <Button theme="primary" onClick={this.handleConfigureDialogClick}>
            Set up Two-Factor Authentication
          </Button>
        </Panel.Section>
      </Panel>
    );
  }

  renderManageSection() {
    return (
      <>
        <Panel className="mb-4">
          <Panel.Section>
            <div className="flex flex-wrap items-center">
              <div className="flex-auto min-w-0">
                <div className="flex mb-1">
                  <strong>Two-Factor Authentication</strong>
                  <Badge className="bg-lime">Enabled</Badge>
                </div>
                <div>Your account is secured with two-factor authentication</div>
              </div>
              <div className="flex flex-wrap">
                <Button onClick={this.handleConfigureDialogClick} className="m-1">
                  Reconfigure
                </Button>
                <Button onClick={this.handleDeactivateDialogClick} className="m-1">
                  Deactivate
                </Button>
              </div>
            </div>
          </Panel.Section>
          <Panel.Section>
            <div className="flex flex-wrap items-center">
              <div className="flex-auto min-w-0">
                <header className="font-semibold mb-1">Recovery Codes</header>
                <p className="p-0 m-0">
                  There are{" "}
                  <a
                    onClick={this.handleRecoveryCodeListDialogClick}
                    className="cursor-pointer lime hover-lime no-underline hover-underline"
                  >
                    {this.recoveryCodesRemaining} recovery codes
                  </a>{" "}
                  remaining
                </p>
              </div>
              <Button onClick={this.handleRecoveryCodeDialogClick} className="m-1">
                Generate New Codes
              </Button>
            </div>
          </Panel.Section>
        </Panel>

        <Dialog
          isOpen={this.state.recoveryCodeListDialogOpen}
          width={500}
          onRequestClose={this.handleRecoveryCodeListDialogClose}
        >
          <div className="p-5">
            <h2 className="m-0 text-xl semi-bold mb-5">Recovery Codes</h2>
            <p>
              Recovery codes are the only way to get access to your account if you lose access to
              your authenticator application.
            </p>
            <p>
              Recovery codes should be treated like your password. We suggest saving them in a
              secure password manager, or printing them and storing them somewhere safe.
            </p>
            {this.props.viewer.totp && this.props.viewer.totp.recoveryCodes ? (
              <RecoveryCodeList
                recoveryCodes={this.props.viewer.totp.recoveryCodes}
                className="border-transparent"
              />
            ) : null}
          </div>
        </Dialog>

        <Dialog
          isOpen={this.state.recoveryCodeDialogOpen}
          width={500}
          onRequestClose={this.handleRecoveryCodeDialogClose}
        >
          <RecoveryCodes totp={this.props.viewer.totp} />
        </Dialog>

        <Dialog
          isOpen={this.state.deactivateDialogOpen}
          width={400}
          onRequestClose={this.handleDeactivateDialogClose}
        >
          <TwoFactorDelete
            viewer={this.props.viewer}
            onDeactivationComplete={this.handleDeactivateDialogClose}
          />
        </Dialog>
      </>
    );
  }

  renderNoPasswordSection() {
    return (
      <p>
        You do not have a password set for your account. Two-factor authentication is available
        without setting a password.
      </p>
    );
  }

  renderUnverifiedEmailSection() {
    return (
      <Panel className="mb-4">
        <Panel.Section>
          <p>
            You must{" "}
            <a href="/user/emails" className="lime hover-lime no-underline hover-underline">
              verify your email address
            </a>{" "}
            to configure two-factor authentication.
          </p>
        </Panel.Section>
      </Panel>
    );
  }

  renderMessageSection(message: any) {
    return (
      <Panel className="mb-4">
        <Panel.Section>
          <p>{message}</p>
        </Panel.Section>
      </Panel>
    );
  }

  render() {
    let contents;
    if (!this.props.viewer.permissions.totpConfigure.allowed) {
      switch (this.props.viewer.permissions.totpConfigure.code) {
        case "no_password":
          contents = this.renderNoPasswordSection();
          break;
        case "unverified_email":
          contents = this.renderUnverifiedEmailSection();
          break;
        default:
          contents = this.renderMessageSection(this.props.viewer.permissions.totpConfigure.message);
          break;
      }
    } else if (this.props.viewer.totp) {
      contents = this.renderManageSection();
    } else {
      contents = this.renderActivateSection();
    }

    return (
      <DocumentTitle title="Two-Factor Authentication">
        <div data-testid="TwoFactor">
          <PageHeader>
            <PageHeader.Title>Two-Factor Authentication</PageHeader.Title>
            <PageHeader.Description>
              Two-factor authentication (2FA) adds an additional layer of security to your Buildkite
              account.
            </PageHeader.Description>
          </PageHeader>

          {contents}

          <Dialog
            isOpen={this.state.configureDialogOpen}
            width={570}
            onRequestClose={this.handleConfigureDialogClose}
          >
            <TwoFactorConfigure
              viewer={this.props.viewer}
              onConfigurationComplete={this.handleConfigureDialogClose}
            />
          </Dialog>
        </div>
      </DocumentTitle>
    );
  }

  handleDeactivateDialogClick = () => {
    this.setState({ deactivateDialogOpen: true });
  };

  handleDeactivateDialogClose = () => {
    this.setState({ deactivateDialogOpen: false });
  };

  handleConfigureDialogClick = () => {
    this.setState({ configureDialogOpen: true });
  };

  handleConfigureDialogClose = () => {
    this.setState({ configureDialogOpen: false });
  };

  handleRecoveryCodeListDialogClick = () => {
    this.setState({ recoveryCodeListDialogOpen: true });
  };

  handleRecoveryCodeListDialogClose = () => {
    this.setState({ recoveryCodeListDialogOpen: false });
  };

  handleRecoveryCodeDialogClick = () => {
    this.setState({ recoveryCodeDialogOpen: true });
  };

  handleRecoveryCodeDialogClose = () => {
    this.setState({ recoveryCodeDialogOpen: false });
  };

  componentDidUpdate(prevProps: Props) {
    // the user had TOTP, and now doesn't, and we want to ensure the deactivate dialog is closed
    if (!this.props.viewer.totp && prevProps.viewer.totp !== this.props.viewer.totp) {
      this.handleDeactivateDialogClose();
    }
  }
}

const TwoFactorRefetchContainer = createRefetchContainer(
  TwoFactor,
  {
    viewer: graphql`
      fragment TwoFactor_viewer on Viewer {
        ...TwoFactorConfigure_viewer
        ...TwoFactorDelete_viewer

        permissions {
          totpConfigure {
            allowed
            code
            message
          }
        }

        totp {
          ...RecoveryCodes_totp

          id
          recoveryCodes {
            ...RecoveryCodeList_recoveryCodes
            codes {
              code
              consumed
            }
          }
        }
      }
    `,
  },
  TwoFactorQuery,
);

type ContainerProps = Record<any, any>;

/* eslint-disable react/no-multi-comp */
export default class TwoFactorQueryContainer extends React.PureComponent<ContainerProps> {
  environment = Environment.get();

  constructor(props: ContainerProps) {
    super(props);

    RelayModernPreloader.preload({
      query: TwoFactorQuery,
      environment: this.environment,
    });
  }

  render() {
    return (
      <QueryRenderer
        fetchPolicy="store-and-network"
        environment={this.environment}
        query={TwoFactorQuery}
        render={this.renderQuery}
      />
    );
  }

  renderQuery({ props }: { props: any }) {
    if (props) {
      return <TwoFactorRefetchContainer {...props} />;
    }
    return <SectionLoader />;
  }
}

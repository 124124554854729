import * as React from "react";
import classNames from "classnames";
import Icon from "app/components/shared/Icon";

export type AnnotationStyles = "DEFAULT" | "SUCCESS" | "ERROR" | "INFO" | "WARNING";

const ANNOTATION_STYLES: Partial<
  Record<
    AnnotationStyles,
    {
      annotationClass: string;
      icon: string;
    }
  >
> = {
  DEFAULT: {
    annotationClass: "build-annotation-default",
    icon: "heroicons/outline/pencil",
  },
  SUCCESS: {
    annotationClass: "build-annotation-success",
    icon: "check-large",
  },
  ERROR: {
    annotationClass: "build-annotation-error",
    icon: "heroicons/16/solid/x-mark",
  },
  INFO: {
    annotationClass: "build-annotation-info",
    icon: "heroicons/outline/information-circle",
  },
  WARNING: {
    annotationClass: "build-annotation-warning",
    icon: "heroicons/16/solid/exclamation-triangle",
  },
};

type Props = {
  annotation: {
    context: string;
    style: AnnotationStyles;
    body: {
      html: string | null | undefined;
    };
  };
};

export default class Annnotation extends React.PureComponent<Props> {
  render() {
    const { annotation } = this.props;

    const annotationId = `annotation-${annotation.context}`;
    const styles = ANNOTATION_STYLES[annotation.style];

    return (
      <div
        id={annotationId}
        className={classNames(
          `rounded flex items-stretch border mb-2 build-annotation`,
          // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
          styles.annotationClass,
        )}
      >
        <a
          href={`#${annotationId}`}
          className="flex border-r annotation-link items-start flex-shrink-0 py-4"
        >
          <Icon
            /* @ts-expect-error - TS2532 - Object is possibly 'undefined'. */
            icon={styles.icon}
            className="h-4 w-4 mt-[2px] annotation-icon flex-auto min-w-0"
          />
        </a>
        <div className="flex-auto min-w-0">
          <div
            className="m-4 annotation-body"
            dangerouslySetInnerHTML={{
              __html: annotation.body && annotation.body.html ? annotation.body.html : "",
            }}
          />
        </div>
      </div>
    );
  }
}

import { useState, useCallback, useEffect, useRef, useMemo } from "react";
import { Res, useFetch } from "use-http";
import Icon from "app/components/shared/Icon";
import Dropdown from "app/components/shared/Dropdown";
import { FlakyTest, Team } from "../type";
import NoTeamsAvailable from "../../test/ManageFlakyDropdown/NoTeamsAvailable";

interface AssignTeamResponseData {
  name: string;
  slug: string;
  assignment_id: string;
}

type FlakyTestAssignment = Pick<
  FlakyTest,
  "test_id" | "test_assignment_endpoint" | "assigned_team"
>;

type Props = {
  isAdmin: boolean;
  flakyTestAssignmentFeatureAvailable: boolean;
  billingUrl: string;
  manageTeamsUrl: string;
  flakyTest: FlakyTestAssignment;
  teams: Team[];
};

const useAssignTeam = (
  flakyTest: FlakyTestAssignment,
  fetchData: ReturnType<typeof useFetch>,
  dropdown: React.RefObject<Dropdown>,
) => {
  const [assignedTeam, setAssignedTeam] = useState<Team | undefined>(flakyTest.assigned_team);
  const [error, setError] = useState<string | null>(null);

  const handleCloseDropdown = () => {
    if (dropdown.current) {
      dropdown.current.setShowing(false);
    }
  };

  const handleAssignTeam = useCallback(
    async (teamId: string) => {
      setError(null);
      await fetchData.post(
        `${flakyTest.test_assignment_endpoint}?test_uuid=${flakyTest.test_id}&team_id=${teamId}`,
      );
      const response = fetchData.response as Res<AssignTeamResponseData>;

      if (response?.ok && response.data) {
        const assignedTeam: Team = {
          id: teamId,
          name: response.data.name,
          slug: response.data.slug,
          assignment_id: response.data.assignment_id,
        };
        setAssignedTeam(assignedTeam);
        handleCloseDropdown();
      } else {
        setError(`Something went wrong, please refresh and try again.`);
      }
    },
    [flakyTest, fetchData],
  );

  const handleUnassignTeam = useCallback(async () => {
    if (!assignedTeam) {
      return;
    }

    setError(null);
    await fetchData.delete(
      `${flakyTest.test_assignment_endpoint}/${flakyTest.test_id}?team_id=${assignedTeam.id}`,
    );
    const response = fetchData.response as Res<unknown>;

    if (response?.ok) {
      setAssignedTeam(undefined);
      handleCloseDropdown();
    } else {
      setError(`Something went wrong, please refresh and try again.`);
    }
  }, [flakyTest, fetchData, assignedTeam]);

  return {
    assignedTeam,
    error,
    handleAssignTeam,
    handleUnassignTeam,
  };
};

const AssignTeamDropdown = ({
  isAdmin,
  flakyTestAssignmentFeatureAvailable,
  billingUrl,
  manageTeamsUrl,
  flakyTest,
  teams,
}: Props) => {
  const [searchInput, setSearchInput] = useState<string>("");
  const searchInputRef = useRef<HTMLInputElement>(null);
  const dropdown = useRef<Dropdown>(null);

  const fetchData = useFetch({
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": window._csrf.token,
    },
  });

  const { assignedTeam, error, handleAssignTeam, handleUnassignTeam } = useAssignTeam(
    flakyTest,
    fetchData,
    dropdown,
  );

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  const handleSearchInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  }, []);

  const filteredTeams = useMemo(
    () => teams.filter((team) => team.name.toLowerCase().includes(searchInput.toLowerCase())),
    [teams, searchInput],
  );

  const renderButtonText = () => {
    if (assignedTeam) {
      return (
        <>
          Flaky assigned to{" "}
          <div className="bg-purple-100 text-purple-600 truncate max-w-[18ch] rounded-sm py-0.5 px-1 capitalize ml-1 font-medium group-hover:bg-gray-400 group-hover:!text-charcoal-900">
            {assignedTeam.name}
          </div>
        </>
      );
    }
    return "Flaky unassigned";
  };

  return (
    <Dropdown ref={dropdown} className="flex">
      <button
        className="btn flex justify-center items-center btn-small btn-default group focus:outline focus:outline-2 focus:outline-lime-500 rounded focus:shadow-none focus:border-none"
        data-testid="assign-team-dropdown-button"
      >
        {renderButtonText()}
        <Icon icon="down-triangle" className="ml-2 w-2 h-2" />
      </button>
      {flakyTestAssignmentFeatureAvailable && teams.length ? (
        <>
          {error && <p className="text-red-500 bg-red-100 rounded-sm m-1 p-2 text-sm">{error}</p>}
          <div className="flex items-center h-10 pl-3 pr-2 mx-1 mb-1 border border-gray-500 rounded overflow-hidden focus-within:border-lime-500 focus-within:border-2">
            <Icon icon="search" className="flex-none h-5 w-5 text-gray-600" />
            <input
              type="text"
              placeholder="Filter teams..."
              value={searchInput}
              onChange={handleSearchInputChange}
              ref={searchInputRef}
              autoFocus={true}
              className="h-full w-full pl-2 border-none outline-none bg-transparent text-charcoal-700 placeholder-gray-700"
            />
          </div>
          <ul className="max-h-[600px] overflow-y-auto">
            {filteredTeams.map((team) => {
              const selected = assignedTeam?.id === team.id;
              return (
                <li key={team.id}>
                  <button
                    className={`w-full cursor-pointer hover:bg-gray-100 py-2 pl-6 flex items-center relative capitalize text-left ${
                      selected ? "bg-purple-100 text-purple font-semibold" : ""
                    }`}
                    onClick={() => handleAssignTeam(team.id)}
                  >
                    {selected && (
                      <Icon
                        icon="permission-small-tick"
                        className="absolute top-3 left-1 w-4 h-4 text-purple-600"
                      />
                    )}
                    {team.name}
                  </button>
                </li>
              );
            })}
          </ul>
          {assignedTeam && (
            <>
              <hr className="divider my-1 py-0" />
              <button
                className="cursor-pointer hover:bg-gray-100 px-6 py-2 text-red-500 block w-full text-left"
                onClick={handleUnassignTeam}
              >
                Unassign team
              </button>
            </>
          )}
        </>
      ) : (
        <NoTeamsAvailable
          {...{
            isAdmin,
            flakyTestAssignmentFeatureAvailable,
            manageTeamsUrl,
            billingUrl,
          }}
        />
      )}
    </Dropdown>
  );
};

export default AssignTeamDropdown;

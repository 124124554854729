/* eslint-disable id-length */

import { Edge, Node, Position, getIncomers, getOutgoers } from "reactflow";
import { NodeType } from "./NodeType";
import { transitiveReduction } from "./transitiveReduction";
import { Graph } from "./Graph";
import { Step, State, Outcome, DependencyType, Type, BlockStep } from "app/lib/pipeline/Step";
import { EdgeType } from "./EdgeType";

export enum EdgeState {
  // Open is when the source node has finished and the target node is ready to run (or already has run).
  Open = "open",
  // Closed is when the source node hasn't yet finished and is blocking the target from running.
  Closed = "closed",
}

export interface EdgeData {
  /**
   * Parent node ID for edge source node.
   */
  sourceParentId?: string;

  /**
   * Parent node ID for edge target node.
   */
  targetParentId?: string;

  state: EdgeState;
}

function createEdge(
  source: Node<{ state?: State; outcome?: Outcome }>,
  target: Node<{ state?: State; outcome?: Outcome }>,
  type: EdgeType,
): Edge<EdgeData> {
  let state: EdgeState;

  // TODO: Handle `allowed_dependency_failure` here.
  // TODO: Handle `wait` steps not tranisitioning to `finished` state.

  if (source.type === NodeType.Root) {
    state = EdgeState.Open;
  } else if (
    (target.type === NodeType.Input ||
      target.type === NodeType.Wait ||
      target.type === NodeType.Block) &&
    source.data.outcome === Outcome.Passed
  ) {
    state = EdgeState.Open;
  } else if (
    target.data.state === State.WaitingForDependencies ||
    (target.data.state === State.Ignored && source.data.outcome !== Outcome.Passed)
  ) {
    state = EdgeState.Closed;
  } else {
    state = EdgeState.Open;
  }

  return {
    id: `${source.id}-${target.id}`,
    source: source.id,
    target: target.id,
    sourceNode: source,
    targetNode: target,
    type,
    data: {
      state,
      sourceParentId: source.parentNode,
      targetParentId: target.parentNode,
    },
  };
}

function isBlockStepNode(step: Step): step is BlockStep {
  return (
    step.type === Type.Block ||
    (step.type === Type.Input &&
      step.dependencies.filter((dep) => dep.type === DependencyType.Gate).length > 0)
  );
}

function getNodeTypeForStep(step: Step): NodeType {
  switch (step.type) {
    case "input":
      if (isBlockStepNode(step)) {
        return NodeType.Block;
      }
      return NodeType.Input;
    case "block":
      return NodeType.Block;
    case "wait":
      return NodeType.Wait;
    case "group":
      return NodeType.Group;
    case "command":
      return NodeType.Command;
    case "trigger":
      return NodeType.Trigger;
    default:
      console.error("Unknown step type: %o", step.type);
      return NodeType.Unknown;
  }
}

function createNodesForSteps(steps: Step[], expandedGroupSteps?: Set<string>): Map<string, Node> {
  const root: Node = {
    id: "root",
    type: NodeType.Root,
    position: { x: 0, y: 0 },
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    data: {},
  };

  const nodes = steps.reduce(
    (acc, step) => {
      const node: Node = {
        id: step.uuid,
        type: getNodeTypeForStep(step),
        position: { x: 0, y: 0 },
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        data: step,
      };

      if (step.type === Type.Group) {
        node.expandParent = expandedGroupSteps?.has(step.uuid);
      }

      if (step.groupUuid) {
        node.parentNode = step.groupUuid;
        node.extent = "parent";
      }

      acc.set(node.id, node);
      return acc;
    },
    new Map<string, Node>([[root.id, root]]),
  );

  return nodes;
}

function createEdgesForSteps(steps: Step[], nodes: Map<string, Node>): Map<string, Edge> {
  const edges = new Map<string, Edge>();

  // Create edges for all the dependency-less steps, connecting them to the root node.
  steps
    .filter((step) => step.dependencies.length === 0)
    .forEach((step) => {
      const root = nodes.get("root");
      if (!root) {
        throw new Error(`root node not found`);
      }

      const target = nodes.get(step.uuid);
      if (!target) {
        throw new Error(`target node not found: ${step.uuid}`);
      }

      // If the node is a child a group, don't create an edge to the root.
      if (target && target.parentNode) {
        return;
      }

      const edge = createEdge(root, target, EdgeType.ImplicitDependency);
      edges.set(edge.id, edge);
    });

  // Create edges for all the steps with dependencies.
  steps.flatMap((step) =>
    step.dependencies
      .map((dep) => {
        // If the dependency has yet to be resolved, connect it to the root node.
        if (!dep.uuid) {
          const source = nodes.get("root");
          if (!source) {
            throw new Error(`root node not found`);
          }

          const target = nodes.get(step.uuid);
          if (!target) {
            throw new Error(`target node not found: ${step.uuid}`);
          }

          return createEdge(source, target, EdgeType.ExplicitDependency);
        }

        const source = nodes.get(dep.uuid);
        if (!source) {
          throw new Error(`source node not found: ${dep.uuid}`);
        }

        const target = nodes.get(step.uuid);
        if (!target) {
          throw new Error(`target node not found: ${step.uuid}`);
        }

        return createEdge(
          source,
          target,
          dep.type === DependencyType.Direct
            ? EdgeType.ExplicitDependency
            : EdgeType.ImplicitDependency,
        );
      })
      .forEach((edge) => edges.set(edge.id, edge)),
  );

  return edges;
}

/**
 * Remove ignored steps and re-route outgoing steps.
 *
 * - If a step explicitly depends on an ignored step, it's re-routed to the root node.
 * - If a step implicitly depends on an ignored step, it's re-routed to either to the nearest wait of block if one exists, otherwise the root node.
 * - If a group sub-step explicitly depends on an ignored step, it's incoming edges are removed.
 * - If a group sub-step implictly depends on an ignored step, it's re-routed to the nearest wait or block if one exists inside the group.
 * - If a group is now empty after removing ignored steps, it's removed.
 * - If a wait step is part of a chain of wait steps, only the first one is kept.
 * - If a wait step (outside of a group) doesn't have any downstream steps, it's removed.
 */
function removeIgnoredSteps(nodes: Map<string, Node>, edges: Map<string, Edge>) {
  // Function to remove a node and re-route the edges.
  const removeNode = (node: Node) => {
    const nodesArray = Array.from(nodes.values());
    const edgesArray = Array.from(edges.values());

    const incomers = getIncomers(node, nodesArray, edgesArray);
    const outgoers = getOutgoers(node, nodesArray, edgesArray);

    // Find the nearest prior wait, block, or root node to re-route the edges from.
    const source = Array.from(nodes.values())
      .slice(0, nodesArray.indexOf(node))
      .filter((n) => {
        if (node.parentNode) {
          return (
            (n.type === NodeType.Wait || n.type === NodeType.Block) &&
            n.data.state !== State.Ignored &&
            n.parentNode === node.parentNode
          );
        }
        return (
          ((n.type === NodeType.Wait || n.type === NodeType.Block) &&
            n.data.state !== State.Ignored) ||
          n.type === NodeType.Root
        );
      })
      .pop();

    // Loop over all the outgoing nodes, essentially the ones dependant on the ignored node.
    outgoers.forEach((outgoer) => {
      const outgoingEdge = edges.get(`${node.id}-${outgoer.id}`) as Edge;
      edges.delete(outgoingEdge.id);

      if (outgoer.data.state === State.Ignored) {
        return;
      }

      // If there's nothing to re-route to (ie. inside a group step) don't create a new edge.
      if (!source) {
        return;
      }

      // If the node is a sub-step of a **group** and the outgoing edge is an _explicit_ dependency, don't create a new edge.
      if (
        outgoer.parentNode &&
        (source.type === NodeType.Root || outgoingEdge.type === EdgeType.ExplicitDependency)
      ) {
        return;
      }

      if (outgoingEdge.type === EdgeType.ExplicitDependency) {
        // If the outgoing edge is an _explicit_ dependency, re-route it to the root node.
        const edge = createEdge(nodesArray[0], outgoer, EdgeType.ImplicitDependency);
        edges.set(edge.id, edge);
      } else {
        // If the outgoing edge is an _implicit_ dependency, re-route it to the nearest wait node (or root node).
        const edge = createEdge(source, outgoer, EdgeType.ImplicitDependency);
        edges.set(edge.id, edge);
      }
    });

    // Remove all incoming edges to the ignored node.
    incomers.forEach((incomer) => {
      edges.delete(`${incomer.id}-${node.id}`);
    });

    nodes.delete(node.id);
  };

  // Remove all the ignored steps
  Array.from(nodes.values())
    .filter((node: Node) => node.data.state === State.Ignored)
    .forEach(removeNode);

  // Remove all the groups that are now empty after removing ignored steps.
  Array.from(nodes.values())
    .filter(
      (node) =>
        node.type === NodeType.Group &&
        Array.from(nodes.values()).filter((n) => n.parentNode === node.id).length === 0,
    )
    .forEach(removeNode);

  // Remove wait steps that are part of a chain of wait steps, keeping only the first one.
  Array.from(edges.values())
    .filter(
      (edge) => edge.sourceNode?.type === NodeType.Wait && edge.targetNode?.type === NodeType.Wait,
    )
    .forEach((edge) => {
      if (!edge?.targetNode) {
        return;
      }

      const nodesArray = Array.from(nodes.values());
      const edgesArray = Array.from(edges.values());

      // Only remove the wait node if it has a single incoming edge.
      const incomers = getIncomers(edge?.targetNode, nodesArray, edgesArray);
      if (incomers.length === 1) {
        removeNode(edge?.targetNode);
      }
    });

  // Remove orphaned wait steps (outside of a group) that don't have any downstream steps.
  Array.from(nodes.values())
    .filter((node) => node.type === NodeType.Wait && !node.parentNode)
    .forEach((node) => {
      const nodesArray = Array.from(nodes.values());
      const edgesArray = Array.from(edges.values());

      const outgoers = getOutgoers(node, nodesArray, edgesArray);
      if (outgoers.length > 0) {
        return;
      }

      const incomers = getIncomers(node, nodesArray, edgesArray);
      incomers.forEach((incomer) => {
        edges.delete(`${incomer.id}-${node.id}`);
      });

      nodes.delete(node.id);
    });
}

/**
 * Remap group children that are dependencies of top-level wait or block steps.
 *
 * Ideally we should only have implicit dependencies between steps at the same level as each other, but currently we have
 * implicit dependencies between group children and top-level wait or block steps. This function removes those implicit dependencies
 * and adds a **new** implicit dependency between the group and wait (or block) step.
 */
function flattenImplicitGroupChildrenDependencies(
  nodes: Map<string, Node>,
  edges: Map<string, Edge<EdgeData>>,
) {
  Array.from(edges.values())
    .filter(
      (edge) =>
        edge.type === EdgeType.ImplicitDependency &&
        edge.data?.sourceParentId !== edge.data?.targetParentId,
    )
    .forEach((edge) => {
      const target = nodes.get(edge.target);
      if (!target) {
        return;
      }

      // If the target isn't a wait or block step, don't do anything.
      if (![NodeType.Wait, NodeType.Block].includes(target.type as NodeType)) {
        return;
      }

      const source = nodes.get(edge.source);
      const group = source?.parentNode ? nodes.get(source?.parentNode) : null;
      if (!group) {
        return;
      }

      const groupEdge = createEdge(group, target, EdgeType.ImplicitDependency);
      edges.set(groupEdge.id, groupEdge);
      edges.delete(edge.id);
    });
}

/**
 * Remap edges that connect nodes between different hierarchy levels.
 */
function remapCollapsedGroupChildrenEdges(nodes: Map<string, Node>, edges: Map<string, Edge>) {
  const collapsed = new Set(
    Array.from(nodes.values())
      .filter((node) => node.type === NodeType.Group && !node.expandParent)
      .map((node) => node.id),
  );

  if (collapsed.size === 0) {
    return;
  }

  Array.from(edges.values())
    .filter((edge) => edge.data.sourceParentId !== edge.data.targetParentId)
    .forEach((edge: Edge) => {
      let source = nodes.get(edge.source);
      if (source?.parentNode && collapsed.has(source.parentNode)) {
        source = nodes.get(source.parentNode);
      }

      let target = nodes.get(edge.target);
      if (target?.parentNode && collapsed.has(target.parentNode)) {
        target = nodes.get(target.parentNode);
      }

      edges.delete(edge.id);

      // Skip creating edges that already exist in either direction.
      if (edges.has(`${target?.id}-${source?.id}`) || edges.has(`${source?.id}-${target?.id}`)) {
        return;
      }

      const newEdge = createEdge(source as Node, target as Node, edge.type as EdgeType);
      edges.set(newEdge.id, newEdge);
    });

  // Remove all the nodes that are children of collapsed groups.
  Array.from(nodes.values())
    .filter((node) => node.parentNode && collapsed.has(node.parentNode))
    .forEach((node: Node) => {
      nodes.delete(node.id);
    });

  // Remove all the edges within a collapsed group that don't need remapping.
  Array.from(edges.values())
    .filter(
      (edge) =>
        edge.data.sourceParentId === edge.data.targetParentId &&
        collapsed.has(edge.data.sourceParentId),
    )
    .forEach((edge) => {
      edges.delete(edge.id);
    });
}

interface Options {
  removeIgnoredSteps?: boolean;
  flattenImplicitGroupChildrenDependencies?: boolean;
  expandedGroupSteps?: Set<string>;
}

export function createGraphFromSteps(steps: Step[], options?: Options): Graph {
  const nodes = createNodesForSteps(steps, options?.expandedGroupSteps);
  const edges = createEdgesForSteps(steps, nodes);

  if (options?.removeIgnoredSteps) {
    removeIgnoredSteps(nodes, edges);
  }

  if (options?.flattenImplicitGroupChildrenDependencies) {
    flattenImplicitGroupChildrenDependencies(nodes, edges);
  }

  remapCollapsedGroupChildrenEdges(nodes, edges);

  const graph = {
    nodes: Array.from(nodes.values()),
    edges: transitiveReduction(Array.from(edges.values())),
  };

  return graph;
}

import Pagination from "app/components/shared/Pagination";
import usePagination from "app/components/analytics/shared/hooks/usePagination";
import { LoadingPanels } from "app/components/analytics/shared/LoadingStates";
import ErrorState from "app/components/analytics/shared/ErrorState";
import EmptyState from "app/components/shared/EmptyState";
import Tooltip from "app/components/analytics/shared/Tooltips/BasicTooltip";
import Row, { Props as RowProps } from "./Row";

type Props = {
  endpoint: string;
  emptyState: "slowest" | "failed";
  tooltip: string;
};

// Displays a list of slowest or failed run_executions
const RunTable = ({ endpoint, emptyState, tooltip }: Props) => {
  const { currentData, pageCount, handleOnChange, loading, error } = usePagination({ endpoint });

  if (error) {
    return (
      <ErrorState
        emoji="🕳️"
        heading="There was an error loading the test executions"
        subheading="Try refreshing the page; if the problem persists"
        urlText="let us know"
        url="mailto:support@buildkite.com"
      />
    );
  }

  if (!loading && currentData.length === 0) {
    return (
      <EmptyState
        emoji="🤖"
        heading={emptyState === "failed" ? "No failed tests" : "No tests have been run"}
        subheading={
          emptyState === "slowest"
            ? "We haven’t received any test executions. Create a run and check back soon."
            : ""
        }
      />
    );
  }

  return (
    <section>
      {loading ? (
        <LoadingPanels rows={10} columns={1} />
      ) : (
        <ul className="ta-panel">
          <li aria-hidden={true} className="ta-panel--heading grid-cols-[3rem_auto_6rem]">
            <div>Result</div>
            <div className="main-section">Description</div>
            <div className="flex items-center justify-end">
              Duration
              <Tooltip classNames="ml-1" name="Duration">
                <p className="font-semibold m-0 charcoal-800">Duration</p>
                <p className="m-0 mt-1 charcoal-800">{tooltip}</p>
              </Tooltip>
            </div>
          </li>
          {currentData.map((test: RowProps["test"]) => (
            <Row key={test.id} test={test} />
          ))}
        </ul>
      )}
      <Pagination pageCount={pageCount} onPageChange={handleOnChange} />
    </section>
  );
};

export default RunTable;

import { create } from "zustand";
import { pipe, set as _set } from "lodash/fp";

export type TestPlan = {
  id: string;
  stepId: string;
  count: number;
  statistics: {
    max_node_duration_ms: number;
    mean_node_duration_ms: number;
    results?: {
      passed_on_first_run: number;
      passed_on_retry: number;
      muted_failed: number;
      muted_passed: number;
      failed: number;
      skipped: number;
      total: number;
    };
  };
};

interface BktecBadgeStore {
  testPlans: Record<string, TestPlan>;
  setTestPlan: (testPlan: TestPlan) => void;
  hasTestPlans: boolean;
}

export const useTestEngineStore = create<BktecBadgeStore>((set) => ({
  testPlans: {},
  setTestPlan: (testPlan) =>
    set(pipe(_set(["testPlans", testPlan.id], testPlan), _set("hasTestPlans", true))),
  hasTestPlans: false,
}));

import Dropdown from "app/components/shared/Dropdown";
import Icon from "app/components/shared/Icon";
import DropdownContent from "./DropdownContent";
import { DatePickerProps, formatDatePeriod, UsagePeriod } from "./helper";

const Button = ({ currentPeriod }: { currentPeriod: UsagePeriod }) => (
  <button className="m-0 flex items-center justify-center rounded-2 border bg-white border-gray py-1 px-2 text-sm hover-purple cursor-pointer">
    {formatDatePeriod(currentPeriod)}
    <Icon
      icon="down-triangle"
      className="flex-none"
      style={{
        width: 7,
        height: 7,
        marginLeft: ".5em",
      }}
    />
  </button>
);

const DatePicker = ({ usagePeriod, recentSubscriptionsPeriod }: DatePickerProps) => (
  // @ts-expect-error - TS2769 - No overload matches this call.
  <Dropdown width="460" className="usage-datepicker flex flex-none">
    <Button currentPeriod={usagePeriod} />
    <DropdownContent recentSubscriptions={recentSubscriptionsPeriod} />
  </Dropdown>
);

export default DatePicker;

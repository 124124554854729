import { graphql, commitMutation, Environment as RelayEnvironment } from "react-relay";

type MutationParams = {
  environment: RelayEnvironment;
  onCompleted?: (response?: any) => any;
  onError?: () => void;
  variables?: any;
};

export default function TotpDeleteMutation({ environment, variables }: MutationParams): void {
  commitMutation(environment, {
    mutation: graphql`
      mutation TotpDeleteMutationMutation($input: TOTPDeleteInput!) {
        totpDelete(input: $input) {
          clientMutationId
        }
      }
    `,
    variables,
  });
}

import { isCommandStep, isDefaultCommandStep, Step } from "app/lib/pipeline";
import { twMerge } from "tailwind-merge";
import { StepLink } from "./StepLink";
import { Label } from "./Label";
import { StateIcon } from "app/components/Playground/Node/BaseNode/StateIcon";
import { textColorForStep } from "app/components/Playground/Node/colors";
import Emojify from "app/components/shared/Emojify";
import { useMostRecentJob } from "app/components/build/Show/lib/BuildContext";
import { RunDuration } from "app/components/job/Job/JobDuration";
import CommandStepLabel from "app/components/build/Show/components/CommandStepLabel";
import { resolveStepUrl } from "app/components/build/Show/lib/urlForView";

interface Props {
  step: Step;
  onClick?: () => void;
  className?: string;
  active?: boolean;
  failed?: boolean;
  depth?: number;
}

/**
 * A catch-all step list item.
 */
export function StepListItem({ step, onClick, className, active, failed, depth = 0 }: Props) {
  const job = useMostRecentJob(step.uuid);
  const waitingOnConcurrencyGroup = job?.state === "limited";

  let label;
  if (isCommandStep(step)) {
    label = <CommandStepLabel {...step} />;
  } else {
    // Add a non-breaking space to prevent empty label from collapsing
    label = <Emojify text={step.label} className="empty:before:content-['\00a0']" />;
  }

  return (
    <StepLink
      onClick={onClick}
      className={twMerge("rounded-md hover:bg-purple-100", className)}
      type={step.type}
      to={resolveStepUrl(step.uuid)}
      active={active}
      failed={failed}
      depth={depth}
    >
      <Label
        duration={
          ((isDefaultCommandStep(step) && job?.type === "script") || job?.type === "trigger") &&
          job?.startedAt && <RunDuration job={job} />
        }
        status={
          <StateIcon
            className={twMerge(
              "-mr-0.5",
              textColorForStep({
                ...step,
                waitingOnConcurrencyGroup,
              }),
            )}
            waitingOnConcurrencyGroup={waitingOnConcurrencyGroup}
            {...step}
          />
        }
      >
        {label}
      </Label>
    </StepLink>
  );
}

import classNames from "classnames";

import Icon from "app/components/shared/Icon";
import capitalize from "lodash/capitalize";
import Emojify from "app/components/shared/Emojify";
import * as React from "react";
import cable from "app/lib/cable";
import { JobStates, Build, Job } from "app/components/build/Show/lib/types";
import Duration from "app/components/shared/Duration";
import Database from "app/lib/Database";

type LimitingJob = {
  uuid: string;
  webUrl: string;
  state: JobStates;
  name: string;
  pipelineName: string;
  buildNumber: number;
  currentStateSince: string | null;
};

const STATE_TO_TEXT: Partial<Record<JobStates, string>> = {
  scheduled: "Waiting for agent",
  limited: "Waiting on concurrency group",
  assigned: "Waiting to accept",
  timing_out: "Timing out",
};

// If this number of limiting jobs is returned, assume there may be more that were not returned
// To change this limit, first update the value in React::JobPresenter
const LIMITING_JOBS_LIMIT = 30;

function JobRow({ job }: { job: LimitingJob | null }) {
  if (job === null) {
    return (
      <li className="monospace border-gray-300 text-xs p-2.5 m-0">A job owned by another team</li>
    );
  }

  // The only states that should appear here are ConcurrencyGroup::JOB_STATES_ACTIVE_IN_QUEUE
  const stateText = STATE_TO_TEXT[job.state] || capitalize(job.state);

  const duration = job.currentStateSince ? (
    <>
      {" "}
      <Duration.Short from={job.currentStateSince} />
    </>
  ) : null;

  return (
    <li className="monospace border-gray-300 text-xs p-2.5 m-0">
      <a href={job.webUrl}>
        <Emojify text={job.name} />
        <span className="dark-gray"> in </span>
        <Emojify text={job.pipelineName} />
        {` `}
        <span className="dark-gray">#{job.buildNumber}</span>
      </a>{" "}
      ({stateText}
      {duration})
    </li>
  );
}

export function LimitedByConcurrencyGroup({
  concurrencyGroup,
  concurrency,
  build,
  job,
}: {
  concurrencyGroup: string;
  concurrency: number;
  build: Build;
  job: Job;
}) {
  const [limitingJobs, setLimitingJobs] = React.useState<Array<LimitingJob | null>>([]);

  const getLimitingJobs = React.useCallback(() => {
    fetch(job.limitingJobsPath)
      .then((response) => response.json())
      .then((data) => {
        const limitingJobs = Database.parse(data);
        setLimitingJobs(limitingJobs);
      });
  }, [build.account.slug, build.project.slug, build.id, job.id]);

  React.useEffect(() => {
    getLimitingJobs();
  }, [getLimitingJobs]);

  React.useEffect(() => {
    // actioncable is an un-typed vendored js library
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const subscriptions: any[] = [];

    limitingJobs.forEach((job) => {
      if (job === null || job.state === "limited") {
        // Limited jobs will only change state when the jobs in front of them in the concurrency group
        // change state, so no need to subscribe to them
        return;
      }

      const subscription = cable.subscriptions.create(
        { channel: "JobEventsChannel", uuid: job.uuid },
        {
          received: (data: any) => {
            if (data.changed) {
              getLimitingJobs();
            }
          },
        },
      );
      subscriptions.push(subscription);
    });

    return () => {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, [limitingJobs]);

  if (limitingJobs.length === 0) {
    return null;
  }

  return (
    <div className={classNames("border rounded flex overflow-hidden", "border-orange-500")}>
      <div
        className={classNames(
          "w-8 flex-shrink-0 flex justify-center border-r pt-4",
          "border-orange-500",
          "bg-orange-100",
        )}
      >
        <Icon
          icon="heroicons/16/solid/exclamation-triangle"
          className={classNames("w-4 h-4", "text-orange-500")}
        />
      </div>

      <div className="px-4 py-3 flex-grow">
        <h3 className="text-base mt-0 mb-2">Limited by concurrency group</h3>

        <p className="mt-0 mb-3">
          This job is limited by the{" "}
          <span className="monospace border-gray-300 text-xs">{concurrencyGroup}</span> concurrency
          group with a concurrency of {concurrency}. The following jobs are ahead in the group:
        </p>

        <ul className="divide-y depth rounded max-w-3xl mb-3">
          {limitingJobs.map((job, index) => (
            <JobRow key={job?.uuid || index} job={job} />
          ))}

          {limitingJobs.length >= LIMITING_JOBS_LIMIT && (
            <li key={null} className="monospace border-gray-300 text-xs p-2.5 m-0">
              Displaying only the first {limitingJobs.length} jobs
            </li>
          )}
        </ul>

        <p className="m-0">
          For more information, see the{" "}
          <a
            href="/docs/pipelines/controlling-concurrency"
            className="text-inherit underline"
            target="_blank"
          >
            documentation
          </a>
          .
        </p>
      </div>
    </div>
  );
}

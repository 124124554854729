import { JOB_STATES_DOWNCASED } from "app/constants/JobStates";

import { CommandJob, Job } from "app/components/build/Show/lib/types";
import JobGroup from "./jobGroup";

/**
 * Derives the title for a broken job. A broken job typically has a `tooltip` attribute attached
 * which we should make available to the user. We do this via html `title` attributes on the job's
 * element, but we also want to include the job's name where possible, so this utility figures out
 * what content to use for the `title` attribute as follows:
 * - If a job has both a tooltip and a name, use both
 * - If a job has a tooltip but no name, use the tooltip
 * - If a job has a name but no tooltip, use the name
 *
 * @param {Job} job the job to derive a `title` from - must be a job with a `broken` state attribute
 * @returns a string to use as the `title` for the broken job
 */
export function titleForBrokenJob(job: Job): string | null | undefined {
  if (job.state !== JOB_STATES_DOWNCASED.BROKEN) {
    throw new Error("titleForBrokenJob() can only be used for broken jobs");
  }

  // @ts-expect-error - TS2339 - Property 'tooltip' does not exist on type 'Job'. | TS2339 - Property 'tooltip' does not exist on type 'Job'.
  const jobTooltip = typeof job.tooltip === "string" ? job.tooltip : "";
  const jobName = typeof job.name === "string" ? job.name : "";

  switch (true) {
    case jobTooltip.length > 0 && jobName.length > 0:
      return `${jobName}\n\n${jobTooltip}`;
    case jobTooltip.length > 0:
      return jobTooltip;
    case jobName.length > 0:
      return jobName;
    default:
      return null;
  }
}

export function jobName(job: CommandJob | JobGroup) {
  return job.name || job.command || "";
}

export function isJobTerminated(job: Job) {
  return (
    // @ts-expect-error - TS2339 - Property 'passed' does not exist on type 'Job'.
    !job.passed &&
    (job.state === "finished" ||
      job.state === "timed_out" ||
      job.state === "canceled" ||
      job.state === "expired")
  );
}

export function filterJobs(jobs: Array<Job>, showHiddenJobs?: boolean): Array<Job> {
  if (showHiddenJobs) {
    return jobs;
  }

  return jobs.filter(
    (job) =>
      job.state !== "broken" &&
      !((job.type === "script" || job.type === "trigger") && job.retriedInJobUuid),
  );
}

import { RowTest } from ".";
import TestStateBadge from "./TestStateBadge";
import TestLocation from "../../shared/Metadata/TestLocation";

type Props = {
  test: RowTest;
};

const Row = ({ test }: Props) => (
  <li className="row-link__container">
    <div className="ta-panel-row grid-cols-[auto_7rem_8rem]">
      <div className="main-section">
        {test.state && <TestStateBadge state={test.state} />}
        <a
          href={test.url}
          className="block font-medium text-inherit no-underline hover:underline focus:underline truncate"
        >
          {test.scope} {test.name}
        </a>

        <div className="flex">
          <TestLocation location={test.location} locationUrl={test.location_url} />

          {test.labels_turbo_stream_id && (
            <turbo-frame
              class="flex items-center ml-1 gap-1"
              id={test.labels_turbo_stream_id}
              src={`${window.location.pathname}/${test.id}/labels${window.location.search.toString()}`}
            />
          )}
        </div>
      </div>
      <div className="text-right">
        {test.reliability}
        <span className="sr-only"> reliable</span>
      </div>
      <div className="text-right">
        {test.average_duration}
        <span className="sr-only"> average duration</span>
      </div>
    </div>
  </li>
);

export default Row;

import EventEmitter from "eventemitter3";
import Immutable from "immutable";

const CHANGE_EVENT = "change";

type jQueryPromise = {
  done: (callback: (result: Array<any>) => unknown) => jQueryPromise;
};

class AgentStore extends EventEmitter {
  MAX_AGENTS = 100;

  _agents = Immutable.Map();
  _loaded = {};
  _intervals = {};
  _ruleMaps = {};
  // @ts-expect-error - TS2564 - Property '_getAllForAccountFromAPIPendingRequest' has no initializer and is not definitely assigned in the constructor.
  _getAllForAccountFromAPIPendingRequest: jQueryPromise;

  normalizedQuery(query: Array<string>) {
    const normalized: Array<string> = [];
    let hasQueue = false;

    for (let rule of Array.from(query)) {
      // Strip the whitespace from the rule
      rule = rule.trim();

      // Skip `*` and empty lines
      if (rule !== "*" && rule !== "") {
        normalized.push(rule);
      }

      // Have a we found a queue rule?
      if (rule.indexOf("queue=") >= 0) {
        hasQueue = true;
      }
    }

    // If no queue is present, add the default queue
    if (normalized.length === 0 || !hasQueue) {
      normalized.push("queue=default");
    }

    return normalized;
  }

  emitChange() {
    return this.emit(CHANGE_EVENT);
  }

  addChangeListener(callback: any) {
    return this.addListener(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback: any) {
    return this.removeListener(CHANGE_EVENT, callback);
  }

  hasChangeListeners() {
    // @ts-expect-error - TS2339 - Property 'getListeners' does not exist on type 'AgentStore'.
    return this.getListeners(CHANGE_EVENT).length !== 0;
  }

  _addAgent(agent: any) {
    // Load the agent into the map
    this._agents = this._agents.set(agent.id, agent);

    // Invalidate the rule map cache
    return (this._ruleMaps[agent.id] = null);
  }

  getAllForAccountFromAPI(accountID: any) {
    // Return the existing request if it exists
    if (this._getAllForAccountFromAPIPendingRequest) {
      return this._getAllForAccountFromAPIPendingRequest;
    }

    // Start a request to retrieve the list of agents
    this._getAllForAccountFromAPIPendingRequest = jQuery
      .get(`/organizations/${accountID.toString()}/agents.json`)
      .done(() => {
        // @ts-expect-error - TS2790 - The operand of a 'delete' operator must be optional.
        delete this._getAllForAccountFromAPIPendingRequest;
      });

    // Return the jQuery promise
    return this._getAllForAccountFromAPIPendingRequest;
  }

  _loadAgentsForAccount(accountID: any) {
    if (!this._loaded[accountID]) {
      this.getAllForAccountFromAPI(accountID).done((agents) => {
        for (const agent of agents) {
          this._addAgent(agent);
        }

        // Setup a poller to keep getting new agents since we don't have any push
        // events. We'll just update it every 5 seconds since that seems like a
        // "good enough" number
        if (!this._intervals[accountID]) {
          this._intervals[accountID] = setInterval(() => {
            return this.getAllForAccountFromAPI(accountID).done((agents) => {
              this._agents = this._agents.clear();
              for (const agent of agents) {
                this._addAgent(agent);
              }
              return this.emitChange();
            });
          }, 5000);
        }

        this._loaded[accountID] = true;

        return this.emitChange();
      });
      return null;
    }
    return this._agents;
  }

  // Based on the logic here: app/models/queue_manager/job_queue.rb
  _generateRuleMapForAgent(agent: {
    id: any;
    matched: undefined | boolean;
    metaData: any;
    name: any;
  }) {
    const ruleMap: Record<string, any> = {};

    // Add in a queue of `default` if one doesnt' exist
    let hasQueue = false;
    for (const data of agent.metaData) {
      if (data.indexOf("queue=") >= 0) {
        hasQueue = true;
      }
    }

    if (!hasQueue) {
      ruleMap["queue=default"] = true;
      ruleMap["queue=*"] = true;
    }

    for (const metaData of agent.metaData) {
      const parts = metaData.split(/([^=]+)=(.+)?/);
      const key = parts[1];
      const value = parts[2]; // eslint-disable-line no-unused-vars

      // Add the meta data value to the map
      ruleMap[metaData] = true;

      // Add the wildcard version
      ruleMap[`${key}=*`] = true;
    }

    return ruleMap;
  }

  _doesQueryMatchAgent(
    query: Array<string>,
    agent: {
      id: any;
      matched: undefined | boolean;
      metaData: any;
      name: any;
    },
  ) {
    const ruleMap =
      this._ruleMaps[agent.id] || (this._ruleMaps[agent.id] = this._generateRuleMapForAgent(agent));

    for (const rule of Array.from(query)) {
      // If the rule doesn't exist within the agent's rule map, then it doesn't
      // match.
      if (!ruleMap[rule]) {
        return false;
      }
    }

    // If nothing _didnt_ match, then it does match!
    return true;
  }

  getAllForAccount(accountID: any) {
    const agents = this._loadAgentsForAccount(accountID);

    if (agents) {
      return agents.toList().toJS();
    }
    return null;
  }

  getAllForAccountWithQuery(accountID: any, query: Array<string>) {
    const agents = this._loadAgentsForAccount(accountID);

    // Return null if the agents haven't been loaded into memory yet
    if (!agents) {
      return null;
    }

    if (agents.count() > this.MAX_AGENTS) {
      return {
        error: `Live preview of agents has been disabled since there are more than ${this.MAX_AGENTS} connected. Sorry!`,
      };
    }

    // Normalize the query
    query = this.normalizedQuery(query);

    // Keep some counters
    let matchCount = 0;
    let totalCount = 0;

    // We only care about connected & registred agents
    let results = agents.filter(
      // @ts-expect-error - TS2571 - Object is of type 'unknown'. | TS2571 - Object is of type 'unknown'.
      (agent) => agent.registered && agent.connection_state === "connected",
    );

    // Return a new set of agents with matched (true/false) depending on whether
    // or not it matched the query
    results = results.map((agent) => {
      const payload = {
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        id: agent.id,
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        name: agent.name,
        // @ts-expect-error - TS2571 - Object is of type 'unknown'.
        metaData: agent.meta_data,
        matched: undefined,
      } as const;

      // @ts-expect-error - TS2540 - Cannot assign to 'matched' because it is a read-only property.
      payload.matched = this._doesQueryMatchAgent(query, payload);

      totalCount += 1;
      if (payload.matched) {
        matchCount += 1;
      }

      return payload;
    });

    // Sort the agents by `matched` then `name`
    results = results.sort((agentA, agentB) => {
      // @ts-expect-error - TS2571 - Object is of type 'unknown'. | TS2571 - Object is of type 'unknown'.
      if (agentA.matched && !agentB.matched) {
        return -1;
        // @ts-expect-error - TS2571 - Object is of type 'unknown'. | TS2571 - Object is of type 'unknown'.
      } else if (!agentA.matched && agentB.matched) {
        return 1;
      }
      // @ts-expect-error - TS2571 - Object is of type 'unknown'. | TS2571 - Object is of type 'unknown'.
      if (agentA.name === agentB.name) {
        return 0;
        // @ts-expect-error - TS2571 - Object is of type 'unknown'. | TS2571 - Object is of type 'unknown'.
      } else if (agentA.name < agentB.name) {
        return -1;
      }
      return 1;
    });

    return {
      agents: results.toList().toJS(),
      counts: {
        total: totalCount,
        matched: matchCount,
      },
      query,
      error: null,
    };
  }
}

export default new AgentStore();

/* eslint-disable react/require-optimization */
import * as React from "react";
import classNames from "classnames";
import { createRefetchContainer, graphql } from "react-relay";

import { shortMessage, shortCommit } from "app/lib/commits";
import { getCssValue } from "app/lib/cssValues";
import BuildStates from "app/constants/BuildStates";
import AnchoredPopover from "app/components/shared/Popover/anchored";

import BuildTooltip from "./BuildTooltip";
import { BAR_HEIGHT_MINIMUM, BAR_WIDTH, BAR_WIDTH_WITH_SEPARATOR, GRAPH_HEIGHT } from "./constants";

const GREEN = getCssValue("--green-400");
const RED = getCssValue("--red-500");
const ORANGE = getCssValue("--orange-500");
const SLATE = getCssValue("--slate-500");

const STATE_COLORS = {
  [BuildStates.SCHEDULED]: SLATE,
  [BuildStates.RUNNING]: ORANGE,
  [BuildStates.PASSED]: GREEN,
  [BuildStates.FAILING]: RED,
  [BuildStates.FAILED]: RED,
  [BuildStates.CANCELED]: SLATE,
  [BuildStates.CANCELING]: SLATE,
  [BuildStates.BLOCKED]: {
    [BuildStates.PASSED]: GREEN,
    [BuildStates.RUNNING]: ORANGE,
    [BuildStates.FAILED]: RED,
  },
} as const;

type Props = {
  href?: string;
  duration: number;
  graph: {
    maximumDuration: number;
  };
  left: number;
  build: any;
  showFullGraph: boolean;
};

type State = {
  hover: boolean;
};

class Bar extends React.Component<Props, State> {
  static defaultProps = {
    showFullGraph: true,
  };

  state = {
    hover: false,
  };

  handleMouseOver = () => {
    this.setState({ hover: true });
  };

  handleMouseOut = () => {
    this.setState({ hover: false });
  };

  colorForBuild(state: string, blockedState?: string | null) {
    if (state === BuildStates.BLOCKED) {
      return blockedState ? STATE_COLORS[BuildStates.BLOCKED][blockedState] : GREEN;
    }

    return STATE_COLORS[state] || SLATE;
  }

  calculateSizes() {
    // Calcualte what percentage this bar is in relation to the longest
    // running build
    let height = this.props.duration / this.props.graph.maximumDuration;
    let transform = "none";

    // See if the height is less than our minimum. If it is, set a hard pixel
    // height, otherwise make the height a percentage. We use percentages so
    // we can animate the height of the graph as it loads in.
    const heightInPixels = height * GRAPH_HEIGHT;
    if (heightInPixels < BAR_HEIGHT_MINIMUM) {
      height = BAR_HEIGHT_MINIMUM;
    } else {
      // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'number'.
      height = `${height * 100}%`;
      if (!this.props.showFullGraph) {
        transform = `scaleY(${BAR_HEIGHT_MINIMUM / heightInPixels})`;
      }
    }

    return { height, transform };
  }

  render() {
    if (!this.props.href) {
      return null;
    }

    const { build } = this.props;
    const { height, transform } = this.calculateSizes();
    const stateColor = this.colorForBuild(build.state, build.blockedState);
    const animate =
      build.state === BuildStates.RUNNING ||
      build.state === BuildStates.FAILING ||
      build.state === BuildStates.CANCELING;

    return (
      <AnchoredPopover
        position="absolute"
        style={{ height: "100%", left: this.props.left, bottom: 0 }}
        width={300}
      >
        <a
          href={this.props.href}
          className="inline-block color-inherit"
          style={{ height: "100%", width: BAR_WIDTH_WITH_SEPARATOR }}
          onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseOut}
        >
          <div
            className={classNames("inline-block absolute", {
              "animation-running-bar": animate,
            })}
            style={{
              height,
              width: BAR_WIDTH,
              left: 0,
              bottom: 0,
              transform,
              transformOrigin: "bottom",
              transition: "transform 200ms ease-in-out",
              backgroundColor: stateColor,
              filter: this.state.hover ? "brightness(0.8)" : "none",
              backgroundSize: ".5rem .5rem",
              borderRadius: "2px 2px 0 0",
              backgroundImage: animate
                ? "linear-gradient(45deg, rgba(255,255,255,.5) 25%, transparent 25%, transparent 50%, rgba(255,255,255,.5) 50%, rgba(255,255,255,.5) 75%, transparent 75%, transparent)"
                : "none",
            }}
          />
          <span className="sr-only">{this.a11yText()}</span>
        </a>
        <BuildTooltip build={build} visible={this.state.hover} />
      </AnchoredPopover>
    );
  }

  a11yText() {
    if (this.props.build.message) {
      return `${shortMessage(this.props.build.message)} ${this.branchOrCommit()}`;
    }

    return "";
  }

  branchOrCommit() {
    if (this.props.build.pipeline.defaultBranch) {
      return shortCommit(
        this.props.build.commit,
        (this.props.build.pipeline && this.props.build.pipeline.commitShortLength) || undefined,
      );
    }

    return this.props.build.branch;
  }
}

export default createRefetchContainer(Bar, {
  build: graphql`
    fragment Bar_build on Build {
      message
      branch
      commit
      state
      blockedState
      pipeline {
        commitShortLength
        defaultBranch
      }
      ...BuildTooltip_build
    }
  `,
});

import React from "react";
import styled from "styled-components";
import { FormProvider, useForm } from "react-hook-form";
import { Form } from "./form";
import Feed from "./Feed";
import Steps from "./Steps";
import useFormPersist from "react-hook-form-persist";
import { Product } from "./Steps/Complete";
import { Organization, OrganizationContext } from "./OrganizationContext";

const Overlay = styled.div`
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: white;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  // Add padding to the top of the overlay to account for the fixed header
  padding-top: 50px;
`;

const MainLayout = styled.div`
  max-width: 1400px;
  position: relative;
  width: 90%;
  height: 100%;
  max-height: 785px;
  display: grid;
  gap: 5%;
  grid-template-columns: 45% 1fr;
  font-size: 15px;

  @media (max-width: 768px) {
    height: 100%;
    grid-template-columns: 100%;
  }

  @media (min-width: 1025px) {
    font-size: 16px;
  }

  @media (min-width: 1281px) {
    font-size: 18px;
  }
`;

// A white fade out at the bottom of the feed to make the feed look like it's
// fading into the background
const FeedFade = styled.div`
  // Use grid-column to retain grid layout / sizing
  grid-column: 2;

  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  z-index: 100;
  pointer-events: none;
`;

interface Props {
  // An optional provided product that the user just signed up for. If provided,
  // we should respect this product and render the appropriate final step.
  product?: Product;

  viewer: {
    organization: Organization | null;
    user: {
      uuid: string;
      name: string;
      avatar: {
        url: string;
      };
    };
  };
}

const WelcomeForm: React.FunctionComponent<Props> = (props) => {
  const { user } = props.viewer;
  const [organization, setOrganization] = React.useState<Organization | null>(
    props.viewer.organization,
  );

  const methods = useForm<Form>({
    defaultValues: {
      role: null,
      goals: [],
      vendors: [],
    },
  });

  // Persist form state in session storage for handling refresh / back button
  useFormPersist(`welcome:${user.uuid}:${organization?.uuid}`, methods);

  return (
    <OrganizationContext.Provider value={{ organization, setOrganization }}>
      <Overlay>
        <Main>
          <FormProvider {...methods}>
            <MainLayout>
              <Steps {...props} name={props.viewer.user.name} />
              <Feed />
              <FeedFade />
            </MainLayout>
          </FormProvider>
        </Main>
      </Overlay>
    </OrganizationContext.Provider>
  );
};

export { WelcomeForm };

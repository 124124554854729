import * as React from "react";
import { QueryRenderer, graphql } from "react-relay";
import DocumentTitle from "react-document-title";
import styled, { StyledComponent } from "styled-components";
import { stringify } from "query-string";
import Button from "app/components/shared/Button";
import SectionLoader from "app/components/shared/SectionLoader";
import PageWithContainer from "app/components/shared/PageWithContainer";
import OrganizationAvatar from "app/components/shared/OrganizationAvatar";
import Pipelines from "./Pipelines";
import PipelinesSearchBar from "./PipelinesSearchBar";
import Teams from "./Teams";
import ConferenceAnnouncementNotice from "../organization/ConferenceAnnouncementNotice";
import RelayModernPreloader from "app/lib/RelayModernPreloader";
import Environment from "app/lib/relay/environment";
import withHistory, { LocationHistoryContext } from "app/lib/withHistory";
import * as constants from "./constants";

import { uniq } from "lodash";
type Organization = NonNullable<any["organization"]>;

function canCreatePipelineForOrganization(organization: Organization): boolean {
  return organization &&
    organization.permissions &&
    organization.permissions.pipelineCreate &&
    organization.permissions.pipelineCreate.allowed === false
    ? false
    : true;
}

const OverflowWrapper = styled.div`
  overflow-x: clip;
`;

const Filters = styled.div.attrs({
  className: "flex items-center justify-center",
})`
  grid-area: bottom;

  @media (min-width: 40em) {
    grid-area: unset;
  }
`;

const Header = styled.div.attrs({
  className: "items-center mb-4",
})`
  display: grid;
  grid-template-areas:
    "left   right"
    "bottom bottom";
  grid-template-rows: 1fr 1fr;
  grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
  gap: 8px 16px;

  @media (min-width: 40em) {
    grid-template: 1fr / minmax(0, 2fr) minmax(0, 2fr) minmax(0, 1fr);
  }

  @media (min-width: 64em) {
    grid-template: 1fr / minmax(0, 1fr) 450px minmax(0, 1fr);
  }
`;

type State = {
  pageSize: number;
  pipelineFilter: string | null | undefined;
  tagsFilter: Array<string> | null | undefined;
  teamFilter: string | null | undefined;
  archivedFilter: boolean;
};

type Props = {
  params: {
    organization: string;
  };
  pipelineTags: Array<string>;
  location: {
    query: {
      team?: string;
      filter?: string;
      archived?: string;
      // @ts-expect-error - TS1337 - An index signature parameter type cannot be a literal type or generic type. Consider using a mapped object type instead.
      [key: "tags[]"]: string | Array<string>;
    };
  };
};

export class OrganizationShow extends React.PureComponent<Props, State> {
  environment = Environment.get();
  static displayName = "OrganizationShow";

  state = {
    pageSize: constants.PIPELINES_INITIAL_PAGE_SIZE,
    teamFilter: this.teamFilter,
    pipelineFilter: this.nameFilter,
    tagsFilter: this.tagsFilter,
    archivedFilter: this.archivedFilter,
  };

  static query = graphql`
    query OrganizationShowQuery(
      $organizationSlug: ID!
      $teamSearch: TeamSelector
      $pageSize: Int!
      $pipelineFilter: String
      $tagsFilter: [String!]
      $archivedFilter: Boolean
    ) {
      viewer {
        ...ConferenceAnnouncementNotice_viewer
      }
      organization(slug: $organizationSlug) {
        ...Teams_organization
        ...Pipelines_organization
          @arguments(
            teamSearch: $teamSearch
            pageSize: $pageSize
            pipelineFilter: $pipelineFilter
            archivedFilter: $archivedFilter
            tagsFilter: $tagsFilter
          )
        id
        slug
        name
        iconUrl
        permissions {
          pipelineCreate {
            code
            allowed
            message
          }
        }
      }
    }
  `;

  static contextType = LocationHistoryContext;

  get organizationSlug(): string {
    return this.props.params.organization;
  }

  get teamFilter(): string | null | undefined {
    return this.props.location.query.team || null;
  }

  get nameFilter(): string | null | undefined {
    return this.props.location.query.filter || null;
  }

  get tagsFilter(): Array<string> | null | undefined {
    if (this.props.location.query["tags[]"]) {
      const tags = this.props.location.query["tags[]"];

      return Array.isArray(tags) ? tags : [tags];
    }
    return null;
  }

  get archivedFilter(): boolean {
    return this.props.location.query.archived === "true";
  }

  get queryVariables() {
    return {
      organizationSlug: this.organizationSlug,
      teamSearch: this.state.teamFilter,
      pipelineFilter: this.state.pipelineFilter,
      tagsFilter: this.state.tagsFilter,
      archivedFilter: this.state.archivedFilter,
      pageSize: this.state.pageSize,
    };
  }

  constructor(props: Props) {
    super(props);

    RelayModernPreloader.preload({
      query: OrganizationShow.query,
      variables: this.queryVariables,
      environment: this.environment,
    });
  }

  render() {
    return (
      <QueryRenderer
        fetchPolicy="store-and-network"
        environment={this.environment}
        query={OrganizationShow.query}
        variables={this.queryVariables}
        render={this.renderQuery()}
      />
    );
  }

  /* eslint-disable react/no-unused-prop-types */
  renderQuery =
    () =>
    ({ error, props }: { error: Error | null | undefined; props: any }) => {
      if (error) {
        return null;
      }

      if (!props) {
        return <SectionLoader />;
      }

      if (props && props.organization) {
        const { organization } = props;

        return (
          <DocumentTitle title={`${organization.name}`}>
            <OverflowWrapper>
              <PageWithContainer>
                <ConferenceAnnouncementNotice viewer={props.viewer} />
                {this.renderHeader(organization)}
                <Pipelines
                  organization={organization}
                  archivedFilter={this.archivedFilter}
                  teamFilter={this.teamFilter}
                  nameFilter={this.nameFilter}
                  tagsFilter={this.tagsFilter}
                  onTagClick={this.addFilterTag(organization)}
                />
              </PageWithContainer>
            </OverflowWrapper>
          </DocumentTitle>
        );
      }
    };

  renderHeader(organization: Organization) {
    return (
      <Header>
        <div className="flex items-center">
          <OrganizationAvatar
            organization={organization}
            className="block max-md:sr-only mr-2 flex-none"
            style={{ height: 38, width: 38 }}
            suppressAltText={true}
          />
          <h1 className="text-2xl p-0 m-0 font-normal leading-tight truncate max-w-full">
            {organization.name}
          </h1>
        </div>
        <Filters>
          <div className="flex-auto min-w-0">
            <PipelinesSearchBar
              tags={this.props.pipelineTags}
              initialText={this.nameFilter}
              initialSelectedTags={this.tagsFilter}
              onTextChange={this.handleFilterChange(organization)}
              onSelectedTagsChange={this.setFilterTags(organization)}
            />
          </div>
          <Teams
            organization={organization}
            archived={this.archivedFilter}
            selectedTeamSlug={this.teamFilter}
            onTeamChange={this.handleTeamChange(organization)}
            onArchivedChange={this.handleArchivedChange(organization)}
          />
        </Filters>
        <div className="flex justify-end">{this.renderNewPipelineButton(organization)}</div>
      </Header>
    );
  }

  renderNewPipelineButton(organization: Organization) {
    // Don't render the "New Pipeline" button if they're not allowed to due to
    // a `not_member_of_team` permsission error.
    if (!canCreatePipelineForOrganization(organization)) {
      return null;
    }

    // Attach the current team to the "New Pipeline" URL
    let newPipelineURL = `/organizations/${organization.slug}/pipelines/new`;
    if (this.teamFilter) {
      newPipelineURL += `?team=${this.teamFilter}`;
    }

    return (
      <Button className="flex items-center" href={newPipelineURL}>
        New pipeline
      </Button>
    );
  }

  addFilterTag = (organization: Organization) => (tag: string) => {
    if (this.tagsFilter) {
      this.updateRoute(organization, { tags: uniq([...this.tagsFilter, tag]) });
    } else {
      this.updateRoute(organization, { tags: [tag] });
    }
  };

  removeFilterTag = (organization: Organization, tag: string) => {
    if (this.tagsFilter) {
      this.updateRoute(organization, {
        tags: this.tagsFilter.filter((label) => {
          return label !== tag;
        }),
      });
    }
  };

  setFilterTags = (organization: Organization) => (tags: Array<string>) => {
    this.updateRoute(organization, { tags });
  };

  handleTeamChange = (organization: Organization) => (team: string) => {
    this.updateRoute(organization, { team });
  };

  handleArchivedChange = (organization: Organization) => (archived: boolean) => {
    this.updateRoute(organization, { archived });
  };

  handleFilterChange = (organization: Organization) => (filter: string) => {
    this.updateRoute(organization, { filter });
  };

  updateRoute = (
    organization: Organization,
    params: {
      filter?: string;
      team?: string;
      archived?: boolean;
      tags?: string | Array<string>;
    },
  ) => {
    const processedParams = Object.assign({}, params);

    // "tags[]" is a difficult key to properly assign a type to, so let's skip that bit
    if (processedParams.tags) {
      processedParams["tags[]"] = processedParams.tags;
      delete processedParams.tags;
    }

    const query = stringify(
      Object.entries({
        ...this.props.location.query,
        ...processedParams,
      }).reduce<Record<string, any>>(
        (query, [key, value]: [any, any]) => (value ? { ...query, [key]: value } : query),
        {},
      ),
    );

    if (query) {
      this.context.history.push(`/${organization.slug}?${query}`);
    } else {
      this.context.history.push(`/${organization.slug}`);
    }
  };
}

export default withHistory<Props>(OrganizationShow);

import BaseNode from "./BaseNode";
import * as HoverCard from "./StepHoverCard";
import { InputStep, State } from "app/lib/pipeline";
import { NodeProps } from "reactflow";
import { WaitIcon } from "./WaitStepNode";
import { useBuild, useMostRecentJob } from "app/components/build/Show/lib/BuildContext";
import Button from "app/components/shared/Button";
import RemoteButtonComponent from "app/components/shared/RemoteButtonComponent";
import { ComponentProps, useCallback } from "react";
import { isInputJobUnblocked, InputJob } from "app/components/build/Show/lib/types";
import Database from "app/lib/Database";
import classNames from "classnames";

import * as Turbo from "@hotwired/turbo";
import Icon from "app/components/shared/Icon";
import UserAvatar from "app/components/shared/UserAvatar";
import FriendlyTime from "app/components/shared/FriendlyTime";
import { twMerge } from "tailwind-merge";
import Emojify from "app/components/shared/Emojify";

export const UnblockButton = ({
  job,
  children,
  ...props
}: ComponentProps<typeof Button> & { job: InputJob }) => {
  const triggerDialog = useCallback(
    (event: React.MouseEvent) => {
      // Don't select the node when clicking unblock
      event.stopPropagation();

      Turbo.visit(job.unblockDialogPath, {
        frame: "unblock_dialog",
      });
    },
    [job.unblockDialogPath],
  );

  const { setBuild } = useBuild();
  const onSuccess = useCallback(
    (_event, response) => {
      if (response && response.build) {
        setBuild(Database.parse(response.build));
      }
    },
    [setBuild],
  );

  if (job?.state !== "blocked") {
    return (
      <Button {...props} onClick={triggerDialog}>
        {children}
      </Button>
    );
  }

  // If the job has fields, we need to open a dialog to unblock it.
  if (job.fields) {
    return (
      <Button {...props} onClick={triggerDialog}>
        {children}
      </Button>
    );
  }

  // Otherwise, we can unblock the job directly with an alert.
  return (
    <RemoteButtonComponent
      url={job.unblockPath}
      method="post"
      confirmText={job.prompt || "Unblock this step?"}
      onSuccess={onSuccess}
      {...props}
    >
      {children}
    </RemoteButtonComponent>
  );
};

export default function InputStepNode(props: NodeProps<InputStep>) {
  const job = useMostRecentJob<InputJob>(props.id);

  return (
    <HoverCard.Root>
      <HoverCard.Portal>
        {job && props.data.state && (
          <HoverCard.Content {...props}>
            <div className="space-y-3">
              {isInputJobUnblocked(job) && (
                <div className="flex flex-row items-center gap-2 w-full">
                  <UserAvatar
                    className="h-10 w-10"
                    user={{
                      name: job.unblockedBy.name,
                      avatar: { url: job.unblockedBy.avatarUrl },
                    }}
                  />
                  <div className="flex flex-col flex-nowrap flex-1">
                    <div className="font-medium">{job.unblockedBy.name}</div>
                    <div className="dark-gray">
                      Unblocked <FriendlyTime value={job.unblockedAt} capitalized={false} />
                    </div>
                  </div>
                </div>
              )}

              <UnblockButton
                job={job}
                disabled={job.state !== "blocked"}
                className="disabled:opacity-50 btn btn-depth btn-small inline-flex justify-center items-center"
              >
                Unblock
              </UnblockButton>
            </div>
          </HoverCard.Content>
        )}
      </HoverCard.Portal>

      <HoverCard.Trigger asChild={true}>
        <div>
          <BaseNode
            {...props}
            hideStateIcon={props.data.state !== State.Finished}
            label={<Emojify text={props.data.label || "Continue"} />}
            icon={
              <>
                <WaitIcon passed={props.data.state === State.Finished} />
                {props.data.state !== State.Finished && (
                  <span
                    className={twMerge(
                      classNames(
                        "flex bg-purple-600 absolute top-0 bottom-0 left-full -translate-x-full rounded-r-xl text-white w-10 justify-center items-center",
                        {
                          "bg-gray-600": job?.state === "blocked_failed",
                        },
                      ),
                    )}
                  >
                    <Icon icon="arrow-right" className="h-5 w-5" />
                  </span>
                )}
              </>
            }
            className={classNames({
              "opacity-100": props.data.state !== State.Ignored && job?.state !== "blocked_failed",
              "border-purple-600 border-4 pr-12": props.data.state !== State.Finished,
              "border-gray-600 border-2": job?.state === "blocked_failed",
            })}
          />
        </div>
      </HoverCard.Trigger>
    </HoverCard.Root>
  );
}
